import { Route, Routes } from 'react-router-dom'
import { TableProjectsTemplate } from './table'
import { AddProjectsTemplate } from './add'
import { ViewProjectsTemplate } from './view'

export const BrowserProjectTemplate = () => {
  return (
    <Routes>
        <Route path={'/'} element={<TableProjectsTemplate/>} />
        <Route path={"/add"} element={<AddProjectsTemplate/>}/>
        <Route path={"view"} element={<ViewProjectsTemplate/>}/>
    </Routes>
  )
}
