import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { WarehouseItem } from "../../../interfaces/Inventory/warehouseItems";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Warehouse } from "../../../interfaces/Inventory/warehouses";
import { PrimaryButton } from "../../../theme/buttons";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";

export const UpdateItemWarehouses = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [comboWarehouses, setComboWarehouses] = useState<Warehouse[]>([]);
    const [defaultWarehouse, setDefaultWarehouse] = useState();
    const { warehouseItemLocationPayload, permissions } = props;
    const isEdit = !permissions.update;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<WarehouseItem>({
        defaultValues: {
            warehouse_id: undefined,
            stock: undefined,
        }
    });
    const onSubmit: SubmitHandler<WarehouseItem> = (data) => onPut(data);

    const onPut = (data: WarehouseItem) => {
        let myData = {
            warehouse_id: data.warehouse_id,
            item_id: warehouseItemLocationPayload.item_id,
            stock: data.stock
        }
        setLoadingPost(true);
        GenericPutResource(`/warehouseitems/${warehouseItemLocationPayload.warehouse_item_id}`, myData)
            .then((response) => {
                props.UpdateRow(response.data);
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onDelete = () => {
        GenericDeleteResource(`/warehouseitems/${warehouseItemLocationPayload?.warehouse_item_id}`)
            .then(() => {
                setOpenDialog(false);
                props.DeleteRow(warehouseItemLocationPayload?.warehouse_item_id);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
                setOpenDialog(false);
            })
    }

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/warehouses")
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboWarehouses(responses[0].data.data);

                reset({
                    warehouse_id: warehouseItemLocationPayload.warehouse_id,
                    stock: warehouseItemLocationPayload.stock,
                });

                setDefaultWarehouse(responses[0].data.data.find((warehouse: Warehouse) => warehouse.warehouse_id === warehouseItemLocationPayload.warehouse_id));

                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, 'error');
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

                    <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

                        <div className='d-flex'>
                            <TextField
                                variant="filled"
                                label={`${t("items.title-view")}`}
                                value={`${warehouseItemLocationPayload.item_code} - ${warehouseItemLocationPayload.item_description}`}
                                size="small"
                                style={{ width: "100%" }}
                                disabled={true}
                            />
                        </div>

                        <Divider />

                        <div className='mt-3 d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="warehouse_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            ref={field.ref}
                                            size="small"
                                            options={comboWarehouses}
                                            defaultValue={defaultWarehouse}
                                            getOptionLabel={(option) => `${option.warehouse_code} - ${option.warehouse_description}`}
                                            renderOption={(props, option: Warehouse) => (
                                                <div key={option.warehouse_id}>
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.warehouse_code} - {option.warehouse_description}
                                                    </Box>
                                                </div>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.warehouse_id === value.warehouse_id}
                                            onChange={(_, values) => {
                                                field.onChange(values?.warehouse_id || null)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t("warehouses.title-view")}`}
                                                    variant="filled"
                                                    value={field.value}
                                                    error={!!errors.warehouse_id}
                                                    helperText={GetError(errors.warehouse_id?.type)}
                                                    disabled={isEdit}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="stock"
                                    control={control}
                                    rules={{ required: true, min: 0 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("warehouseitems.fields.stock")}`}
                                            type='number'
                                            ref={field.ref}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                "& input[type=number]": { MozAppearance: "textfield", }
                                            }}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.stock}
                                            helperText={GetError(errors.stock?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                            <div>
                                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                                {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                            </div>
                            {permissions.delete ?
                                <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                            }
                        </div>

                    </form>

                </Box>
            }
            <GenericDialog
                title={t("generic.dialog.delete")}
                content={t("warehouses.dialog.delete")}
                open={openDialog}
                setOpen={setOpenDialog}
                onAcept={onDelete}
            />
            <SnackbarComponent />
        </>
    )
}
