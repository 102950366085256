import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDates } from '../../../../hooks/useDates';
import GenericPromises from '../../../../api/GenericPromises';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';
import { PaymentDetail } from '../../../../interfaces/Purchases/Payments/payments';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../interfaces/Security/menu';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { ButtonLoading, PrimaryButton } from '../../../../theme/buttons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GradingIcon from '@mui/icons-material/Grading';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';

export const TableAuthorizationPayments = () => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric, GenericPostResource } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [clickedButtonGoBack, setClickedButtonGoBack] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowsProp>([]);
  const [paymentsDetailsData, setPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceAuthorizePayments, setResourceAuthorizePayments] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([]);
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("authorizationpayments.comboEnum.all"), route: `/authorizationpayments` },
    { enum_id: 2, option_name: t("authorizationpayments.comboEnum.onlypaid"), route: `/authorizationpayments?filter=${btoa(`payment_id!=null`)}` },
    { enum_id: 3, option_name: t("authorizationpayments.comboEnum.onlynopaid"), route: `/authorizationpayments?filter=${btoa(`payment_id=null`)}` },
    { enum_id: 4, option_name: t("authorizationpayments.comboEnum.authorized-n-nopaid"), route: `/authorizationpayments?filter=${btoa(`payment_id=null&is_authorized=True`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[0]);
  const [defaultEnum, setDefaultEnum] = useState(comboEnum[0]);
  const [columns, setColumns] = useState<GridColDef<PaymentDetail>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("purchaseinvoices.fields.purchase_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
          window.open(`/projects/view?${stringConverted}`, '_blank');
        }}>
          {params.row.project_name}
        </Button>
      }
    },
    { field: 'supplier_business_name', headerName: t("paymentsdetails.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'customer_business_name', headerName: t("projects.fields.customer"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'shipping_company', headerName: t("projects.fields.shipping_company"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'eta', headerName: t("projects.fields.eta"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    { field: 'notes', headerName: t("purchaseinvoices.fields.notes"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("paymentsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'is_authorized', headerName: t("paymentsdetails.fields.is_authorized"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return (params.row.is_authorized) ? <CheckCircleIcon /> : <CancelIcon />
      }
    },
    {
      field: 'amount_outstanding', headerName: t("paymentsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: 'left', headerAlign: 'left',
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'expiration_date', headerName: t("purchaseinvoices.fields.expiration_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'authorization_user', headerName: t("paymentsdetails.fields.authorization_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'authorization_date', headerName: t("paymentsdetails.fields.authorization_date"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    }
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-authorizationpayments");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-authorizationpayments-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          else if (element.field === 'eta'){
            element.headerName = t(`projects.fields.${element.field}`);
          }
        }
        else {
          if (element.field === ('amount_outstanding' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'customer_business_name') {element.headerName = t(`projects.fields.customer`);}
          else if (element.field === 'shipping_company') {element.headerName = t(`projects.fields.shipping_company`);}
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`purchaseinvoices.fields.purchase_invoice_name`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`payments.fields.purchase_invoice_name`);
            element.renderCell = function (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
                window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.purchase_invoice_name}
              </Button>
            }
          }
          else {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onAuthorizePayments = () => {
    let myPaymentsDataToAuthorize = {
      data: selectedRows
        .filter(row => row.payment_detail_id && row.is_authorized === false)
        .map(row => row.payment_detail_id),
    };    

    if (myPaymentsDataToAuthorize.data.length > 0) {
      setIsLoadingPost(true);
      GenericPostResource(`/authorizationpayments/isauthorized`, myPaymentsDataToAuthorize)
        .then((response) => {
          showSnackBar(t("authorizationpayments.info.authorized"), "success");
          setClickedButtonGoBack((prev) => !prev);
          setDialogPayment(false);
          setIsLoadingPost(false);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
          setIsLoadingPost(false);
        });
    }
    else {
      showSnackBar(t("authoerizationpayments.info.already-authorized"), "info");
    }
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    setSelectedRows((prev) => {
      return paymentsDetailsData.filter(item => rowsIds.includes(item.payment_detail_id));
    });
  }

  useEffect(() => {
    setDataLoaded(false);
    GenericGetResource(myEnumValue.route)
      .then(
        (response) => {
          setPaymentsDetailsData(response.data.data);
          setDefaultFilter([]);
          setSelectedRows((prev) => []);

          let myPermissions = [
            GetResourceByUrl(`/authorizationpayments`),
            GetResourceByUrl(`/authorizationpayments/isauthorized`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceScreen((prev) => responses[0]);
              setResourceAuthorizePayments((prev) => responses[1]);
              loadColumnsOrder();
              loadPreferences();
              setIsLoadingDataTable(true);
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
  }, [clickedButtonGoBack]);

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then((response) => {
        setPaymentsDetailsData(response.data.data);
        setSelectedRows((prev) => []);
        setIsLoadingDataTable(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies")
      .then((response) => {
        document.title = `${response.data.data[0].comercial_name} - ${t("authorizationpayments.title")}`;
      })

    GenericGetResource(comboEnum[3].route)
      .then((response) => {
          setPaymentsDetailsData(response.data.data);
          setDefaultFilter([...defaultFilter, { columnfilter: "is_authorized", value: 'false' }]);

          let myPermissions = [
            GetResourceByUrl(`/authorizationpayments`),
            GetResourceByUrl(`/authorizationpayments/isauthorized`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceScreen((prev) => responses[0]);
              setResourceAuthorizePayments((prev) => responses[1]);
              loadColumnsOrder();
              loadPreferences();
              setIsLoadingDataTable(true);
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <>
        <Spinner />
        <SnackbarComponent />
      </>}
      {dataLoaded && resourceScreen?.read &&
        <div className='screen-container'>
          <Header
            title={t("authorizationpayments.title")}
            child={
              <div className="d-flex flex-row-reverse">
                <PrimaryButton size="small" variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={(selectedRows?.length === 0) || !resourceAuthorizePayments?.create} onClick={() => setDialogPayment(true)}>
                  {t("authorizationpayments.buttons.authorize-payments")}<GradingIcon sx={{ marginLeft: 1 }} />
                </PrimaryButton>
                <Autocomplete
                  size="small"
                  options={comboEnum}
                  defaultValue={defaultEnum}
                  getOptionLabel={(option) => option.option_name}
                  renderOption={(props, option) => (
                    <div key={option.enum_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.option_name}
                      </Box>
                    </div>
                  )}
                  sx={{ width: "20%", paddingRight: 1 }}
                  isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                  onChange={(_, values) => {
                    setMyEnumValue(values ?? { enum_id: 1, option_name: t("authorizationpayments.comboEnum.all"), route: `/authorizationpayments` });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("generic.filter")}`}
                      variant="filled"
                    />
                  )}
                  clearOnEscape={false}
                  clearIcon={null}
                />
              </div>
            }
          />
          <div className="d-flex justify-content-center mt-2">
            {!isLoadingDataTable && <Spinner />}
            {isLoadingDataTable &&
              <DataTable
                columns={columns}
                setColumns={setColumns}
                data={paymentsDetailsData}
                entityId={"payment_detail_id"}
                entity={`AuthorizationPayments`}
                preferences={myPreferences}
                namePreferences={"grid-authorizationpayments"}
                nameOrderColumns={"grid-authorizationpayments-columns"}
                selectRows
                onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
                isChildren={true}
                hasDoubleClick
                initFilter={defaultFilter}
                countColumns={["total_company_currency_amount"]}
              />
            }
          </div>
          <DialogEntity
            open={dialogPayment}
            setOpen={setDialogPayment}
            title={t("authorizationpayments.dialog.title")}
            content={
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>{t("authorizationpayments.dialog.content")}</Typography>
                <Box display={"flex"} flexDirection={"row-reverse"} marginTop={2}>
                  <ButtonLoading
                    disabled={!resourceAuthorizePayments?.create}
                    isLoading={isLoadingPost}
                    onClick={onAuthorizePayments}
                    className="d-flex align-self-end w-25"
                    variant="outlined"
                  >
                    {t("generic.buttons.accept")}
                  </ButtonLoading>
                  <Button onClick={() => { setDialogPayment(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
                </Box>
              </Box>
            }
          />
          <SnackbarComponent />
        </div>
      }
    </>
  )
}
