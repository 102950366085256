import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { PackagingType } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/packagingTypes';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdatePackagingTypes = ({ ...props }) => {

  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { packagingTypesPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<PackagingType>({
    defaultValues: {
      packaging_type_code: '',
      packaging_type_description: ''
    }
  });
  const onSubmit: SubmitHandler<PackagingType> = (data) => onPut(data);

  const onPut = (data: PackagingType) => {
    let myData = {
      packaging_type_code: data.packaging_type_code,
      packaging_type_description: data.packaging_type_description ?? null
    }
    setLoadingPost(true);
    GenericPutResource(`/packagingtypes/${packagingTypesPayload.packaging_type_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),"success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    reset({
      ...packagingTypesPayload
    });

    setDataLoaded(true);
  }, [])

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>

          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
              <div className="custom-card-row d-flex justify-content-between">
                <div className='w-100 d-flex'>

                  <div className='w-50'>
                    <Controller
                      name="packaging_type_code"
                      control={control}
                      rules={{ required: true, maxLength: 5 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("packagingtypes.fields.packaging_type_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.packaging_type_code}
                          helperText={GetError(errors.packaging_type_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>
                  <div className="w-50">
                    <Controller
                      name="packaging_type_description"
                      control={control}
                      rules={{ maxLength: 150 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("packagingtypes.fields.packaging_type_description")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.packaging_type_description}
                          helperText={GetError(errors.packaging_type_description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }
            </form>
          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
