import { Route, Routes } from 'react-router-dom'
import { TableReceiptsDetails } from './table'

export const BrowserReceiptDetails = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableReceiptsDetails />} />
    </Routes>
  )
}
