import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { AuthContext } from "../../../../../context/AuthContext";
import { ProjectStagesTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTaskTemplate";
import GenericPromises from "../../../../../api/GenericPromises";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Box, Button, Grid } from "@mui/material";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { AddProjectStagesTaskTemplate } from "./add";
import { Header } from "../../../../../components/Header";
import { UpdateProjectStagesTaskTemplate } from "./update";
import { Edit } from "@mui/icons-material";
import { TableProjectStagesTaskRemindersTemplate } from "../ProjectStagesTaskRemindersTemplate/table";
import { defaultTheme } from "../../../../../theme/theme";
import AddIcon from "@mui/icons-material/Add";
import "./index.css";

export const TableProjectStagesTaskTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const [projectStagesTaskTemplateLocal, setProjectStagesTaskTemplateLocal] =
    useState<ProjectStagesTaskTemplate[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [color, setColor] = useState<number[]>();

  const { user } = useContext(AuthContext);
  const { GetResourceByUrl } = usePermissions();

  const hexToRgb = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return [red, green, blue];
  };

  const handleAddTask = (newRow: ProjectStagesTaskTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesTaskTemplateLocal((prevState) => {
      return [...projectStagesTaskTemplateLocal, myRow];
    });
  };

  const handleUpdateTask = (updateRow: ProjectStagesTaskTemplate) => {
    if (projectStagesTaskTemplateLocal.length === 0) {
      return;
    }
    setProjectStagesTaskTemplateLocal((prevRows) => {
      const rowToUpdateIndex = projectStagesTaskTemplateLocal.findIndex(
        (e) =>
          e.project_stage_task_template_id ===
          updateRow.project_stage_task_template_id
      );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteTask = (deleteRow: number) => {
    if (projectStagesTaskTemplateLocal.length === 0) {
      return;
    }

    setProjectStagesTaskTemplateLocal((prevRows) => {
      const rowToDeleteIndex = projectStagesTaskTemplateLocal.findIndex(
        (e) => e.project_stage_task_template_id === deleteRow
      );

      const updatedData = [
        ...projectStagesTaskTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectStagesTaskTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projectstagestaskstemplate/byprojectstagetemplateid/${props.project_stage_template_id}`
    )
      .then(async (response) => {
        setProjectStagesTaskTemplateLocal(response.data.data);

        setColor(hexToRgb(defaultTheme.palette.primary.main));

        GetResourceByUrl(`/projectstagestaskstemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <div className="d-flex mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                className="create-button-task"
                sx={{
                  "&:hover": {
                    backgroundColor: color
                      ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                          color[2]
                        },0.09)`
                      : "rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => {
                  setOpenDialogAddTask(true);
                }}
              >
                <AddIcon /> {t("projectstagestaskstemplate.actions.create")}
              </Box>
            </Grid>
            {projectStagesTaskTemplateLocal.map((task) => (
              <Grid item key={task.project_stage_task_template_id} xs={12}>
                <Box
                  className="create-button-task"
                  sx={{
                    justifyContent: "space-between",
                    backgroundColor: color
                      ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                          color[2]
                        },0.2)`
                      : "rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      backgroundColor: color
                        ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                            color[2]
                          },0.09)`
                        : "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => {
                    setSelectedTaskId(task.project_stage_task_template_id);
                    setDrawerOpen(true);
                  }}
                >
                  {task.project_stage_task_template_name}
                  <Button
                    variant="outlined"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setSelectedTaskId(task.project_stage_task_template_id);
                      setOpenDialogUpdateTask(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Edit />
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <DialogEntity
        content={
          <AddProjectStagesTaskTemplate
            setOpenDialog={setOpenDialogAddTask}
            project_template_id={props?.project_template_id}
            project_stage_template_id={props.project_stage_template_id}
            handleAddTask={handleAddTask}
          />
        }
        open={openDialogAddTask}
        setOpen={setOpenDialogAddTask}
        title={
          <Header
            title={t("projectstagestaskstemplate.title-view")}
            size="sm"
          />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectStagesTaskTemplate
            permissions={resourceScreen}
            setOpenDialog={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_template_id={props?.project_template_id}
            projectStagesTaskTemplatePayload={
              projectStagesTaskTemplateLocal?.find(
                (task) => task.project_stage_task_template_id === selectedTaskId
              ) || {}
            }
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projectstagestaskstemplate.title-view")}
            size="sm"
          />
        }
      />
      {drawerOpen && selectedTaskId !== undefined && (
        <TableProjectStagesTaskRemindersTemplate
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          projectStagesTaskTemplatePayload={
            projectStagesTaskTemplateLocal.find(
              (task) => task.project_stage_task_template_id === selectedTaskId
            ) || {}
          }
        />
      )}
      <SnackbarComponent />
    </>
  );
};
