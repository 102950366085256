import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../api/GenericPromises";
import { SnackbarComponent } from "../../../../../components/Commons/SnackBar";
import { Button } from "@mui/material";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../../components/Header";
import { VehicleConfiguration } from "../../../../../interfaces/Sales/Catalogs/TransportationDocuments/vehicleConfigurations";
import { UpdateVehicleConfigurations } from "./update";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../../theme/buttons";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";

export const ViewVehicleConfigurations = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [vehicleConfigurationsLocal, setVehicleConfigurationsLocal] = useState<VehicleConfiguration>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();

  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/vehicleconfigurations/${vehicleConfigurationsLocal?.vehicle_config_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/vehicleconfigurations", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/vehicleconfigurations", { state: {}, replace: true });
    GenericGetResource(`/vehicleconfigurations/${location.state.row.vehicle_config_id}`)
      .then(
        (response) => {
          setVehicleConfigurationsLocal(response.data);

          GetResourceByUrl(`/vehicleconfigurations`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("vehicleconfigurations.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/vehicleconfigurations", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <UpdateVehicleConfigurations vehicleConfigurationPayload={vehicleConfigurationsLocal} isEdit={resourceScreen?.update} />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("vehicleconfigurations.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
