import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CompanyLocation } from '../../../../../interfaces/Security/companies';
import { Autocomplete, Box, Button, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';
import { Country } from '../../../../../interfaces/Commons/Locations/countries';
import { State } from '../../../../../interfaces/Commons/Locations/states';
import { City } from '../../../../../interfaces/Commons/Locations/cities';
import { emailRegex } from '../../../../../constants/Regex';
import { Divider } from '../../../../../components/Commons/Divider';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const AddCompanyLocation = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [comboCities, setComboCities] = useState<City[]>([]);

  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>();

  const [city, setCity] = useState<City | null>(null);

  useEffect(() => {
    if (stateId !== undefined) {
      setCity(null)
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
  }, [stateId])

  useEffect(() => {
    if (stateId !== undefined) {
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<CompanyLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: undefined,
      contact_name: undefined,
      phone_number: undefined,
      email: undefined,
      company_id: undefined,
      main_location: undefined,
    }
  });
  const onSubmit: SubmitHandler<CompanyLocation> = (data) => onPost(data);

  const onPost = (data: CompanyLocation) => {
    let myData = {
      country_id: data.country_id ?? null,
      state_id: data.state_id ?? null,
      city_id: data.city_id ?? null,
      postal_code: data.postal_code ?? null,
      street: data.street ?? null,
      contact_name: data.contact_name ?? null,
      phone_number: data.phone_number ?? null,
      email: data.email ?? null,
      company_id: props.company_id ?? null,
      main_location: data.main_location ?? false,
    }
    let myCountry = comboCountries.find((item: Country) => item.country_id === data.country_id);
    let myState = comboStates.find((item: State) => item.state_id === data.state_id);
    let myCity = comboCities.find((item: City) => item.city_id === data.city_id);
    setLoadingPost(true);
    GenericPostResource("/companylocations", myData)
      .then((response) => {
        let myNewData: CompanyLocation = {
          country_name: myCountry?.country_name,
          state_name: myState?.state_name,
          city_name: myCity?.city_name,
          ...response.data
        };
        if (response.data.main_location === true) {
          GenericGetResource(`/companylocations/bycompanyid/${props.company_id}`)
            .then(async (response1) => {
              for (let i = 0; i < response1.data.data.length; i++) {
                const element = response1.data.data[i];
                if (element.company_location_id !== response.data.company_location_id) {
                  let myData = {
                    state_id: element.state_id,
                    city_id: element.city_id,
                    postal_code: element.postal_code,
                    street: element.street,
                    contact_name: element.contact_name,
                    phone_number: element.phone_number,
                    email: element.email,
                    company_id: element.company_id,
                    main_location: false,
                  };
                  await GenericPutResource(`/companylocations/${element.company_location_id}`, myData)
                    .then(async (response2) => {
                      let myData = {
                        ...response2.data,
                        company_location_id: element.company_location_id,
                        main_location: false
                      }
                      await props.UpdateRow(myData);
                    })
                    .catch((error) => {
                      setLoadingPost(false);
                      showSnackBar(error.message, "error");
                    });
                }
              }
            })
            .catch((error) => {
              setLoadingPost(false);
              showSnackBar(error.message, "error");
            });
        }
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  const getCities = (stateId: number) => {
    GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setDataLoaded(true);
      });
  }

  useEffect(() => {
    if (stateId !== undefined) {
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
  }, [stateId])

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
    ]

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries((prev) => responses[0].data.data);
        setComboStates((prev) => responses[1].data.data);
        setComboCities((prev) => responses[2].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <div className='d-flex'>
              <div className='w-100 d-flex'>
                <div className='w-50'>
                  <Controller
                    name="country_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboCountries}
                        getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                        renderOption={(props, option: Country) => (
                          <div key={option.country_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.country_code} - {option.country_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                        onChange={(_, values) => {
                          field.onChange(values?.country_id || null)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("companylocations.fields.country_name")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.country_id}
                            helperText={GetError(errors.country_id?.type)}
                            sx={{ paddingRight: 2 }}
                          />
                        )}
                      />
                    }
                  />
                </div>

                <div className='w-50'>
                  <Controller
                    name="state_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboStates}
                        getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                        renderOption={(props, option: State) => (
                          <div key={option.state_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.state_code} - {option.state_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                        onChange={(_, values) => {
                          setValue("city_id", undefined);
                          field.onChange(values?.state_id || null)
                          setStateId(values?.state_id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("companylocations.fields.state_name")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.state_id}
                            helperText={GetError(errors.state_id?.type)}
                          />
                        )}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className='d-flex w-100 mt-3'>
              <div className='w-50'>
                {!isStateSelected &&
                  <Controller
                    name="city_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboCities}
                        getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                        renderOption={(props, option: City) => (
                          <div key={option.city_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.city_code} - {option.city_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                        onChange={(_, values) => {
                          field.onChange(values?.city_id || null)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("customerlocations.fields.city_name")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.city_id}
                            helperText={GetError(errors.city_id?.type)}
                            sx={{ paddingRight: 2 }}

                          />
                        )}
                        disabled
                      />
                    }
                  />
                }
                {isStateSelected &&
                  <Controller
                    name="city_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboCities}
                        value={city}
                        getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                        renderOption={(props, option: City) => (
                          <div key={option.city_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.city_code} - {option.city_name}
                            </Box>
                          </div>
                        )}

                        isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                        onChange={(_, values) => {
                          setCity(values);
                          field.onChange(values?.city_id || null)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("customerlocations.fields.city_name")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.city_id}
                            helperText={GetError(errors.city_id?.type)}
                            sx={{ paddingRight: 2 }}
                          />
                        )}
                      />
                    }
                  />
                }
              </div>

              <div className='w-50'>
                <Controller
                  name="street"
                  control={control}
                  rules={{ required: true, maxLength: 60 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("companylocations.fields.street_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.street}
                      helperText={GetError(errors.street?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <div className='w-50'>
                <Controller
                  name="postal_code"
                  control={control}
                  rules={{ maxLength: 5 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("companylocations.fields.postal_code")}`}
                      ref={field.ref}
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                      }}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.postal_code}
                      helperText={GetError(errors.postal_code?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

            </div>

            <Divider />

            <div className='d-flex w-100'>
              <div className='w-50'>
                <Controller
                  name="contact_name"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("companylocations.fields.contact_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.contact_name}
                      helperText={GetError(errors.contact_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>
              <div className='w-50'>
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{ maxLength: 20 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("companylocations.fields.phone_number")}`}
                      ref={field.ref}
                      value={field.value}
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", },
                      }}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.phone_number}
                      helperText={GetError(errors.phone_number?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex mt-3'>
              <div className='w-50'>
                <Controller
                  name="email"
                  control={control}
                  rules={{ maxLength: 50, pattern: emailRegex }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("companylocations.fields.email")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.email}
                      helperText={GetError(errors.email?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>

            </div>

            <div className='w-50 mt-3'>
              <Controller
                name="main_location"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2, justifyContent: "space-between" }}>
                    <Typography sx={{ ml: 2 }}>{t("companylocations.fields.main_location")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                  </Box>
                }
              />
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
