import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ProjectGroup } from "../../../../interfaces/Projects/Catalogs/projectGroups"
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Group } from "../../../../interfaces/Security/groups";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Divider } from "../../../../components/Commons/Divider";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";

type AddProjectGroups = {
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
  project_id: number,
  project_name: string,
  AddNewRow: (newRow: ProjectGroup) => void,
}

export const AddProjectGroups = ({
  setOpenDialog,
  project_id,
  project_name,
  AddNewRow,
}: AddProjectGroups) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<ProjectGroup>({
    defaultValues: {}
  });
  const onSubmit: SubmitHandler<ProjectGroup> = (data) => onPost(data);

  const onPost = (data: ProjectGroup) => {
    let myData = {
      group_id: data.group_id,
      project_id: project_id,
      project_group_name: data.project_group_name ?? null,
      project_group_description: data.project_group_description ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/projectgroups", myData)
      .then((response) => {
        let myGroup = comboGroups.find((item: Group) => item.group_id === myData.group_id);
        let myNewRow = {
          ...response.data,
          group_name: myGroup?.group_name,
        }
        AddNewRow(myNewRow);
        setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResourceGeneric("/groups", "/ggroups")
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboGroups(responses[0].data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex mb-2'>

              <Controller
                name="group_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboGroups}
                    getOptionLabel={(option) => option.group_name}
                    renderOption={(props, option: Group) => (
                      <div key={option.group_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.group_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                    onChange={(_, values) => {
                      field.onChange(values?.group_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("groups.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.group_id}
                        helperText={GetError(errors.group_id?.type)}
                      />
                    )}
                  />
                )}
              />

            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton
                onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }}
                variant='outlined' className='m-1' disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>
          </form>

        </Box>
      }
      <SnackbarComponent />
    </>

  )
}
