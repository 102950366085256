import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ProjectDocument } from "../../../../interfaces/Projects/Catalogs/projectDocuments"
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Box, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";

type AddProjectDocumentsProps = {
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
  project_id: number,
  isClosedProject: boolean,
  AddNewRow: (newRow: ProjectDocument) => void,
}

export const AddProjectDocuments = ({
  setOpenDialog,
  project_id,
  isClosedProject,
  AddNewRow,
}: AddProjectDocumentsProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProjectDocument>({
    defaultValues: {
      project_document_id: undefined,
      project_id: undefined,
      document_id: undefined,
      document_name: undefined,
      document_description: undefined,
      document_contents: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<ProjectDocument> = (data) => onPost(data);

  const onPost = (data: ProjectDocument) => {
    let myDataDocument = {
      document_name: data.document_name ?? null,
      document_description: data.document_description ?? null,
      document_contents: data.document_contents ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/documents", myDataDocument)
      .then(async (responseDocument) => {
        let myProjectDocument = {
          project_id: project_id,
          document_id: responseDocument.data.document_id,
        }
        const responseProjectDocument = await GenericPostResource("/projectdocuments", myProjectDocument);
        let myNewRow = {
          project_document_id: responseProjectDocument.data.project_document_id,
          project_id: project_id,
          document_id: responseDocument.data.document_id,
          creation_date: responseProjectDocument.data.creation_date,
          last_update_user: responseProjectDocument.data.last_update_user,
          last_update_date: responseProjectDocument.data.last_update_date,
          document_name: responseDocument.data.document_name,
          document_description: responseDocument.data.document_description,
          document_contents: responseDocument.data.document_contents,
        }
        AddNewRow(myNewRow);
        setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    setOpenDialog(false);
  }

  useEffect(() => {
    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-50'>
                <Controller
                  name="document_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectdocuments.fields.document_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.document_name}
                      helperText={GetError(errors.document_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={isClosedProject}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="document_description"
                  control={control}
                  rules={{ maxLength: 100 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectdocuments.fields.document_description")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.document_description}
                      helperText={GetError(errors.document_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isClosedProject}
                    />
                  }
                />
              </div>

            </div>

            <div className='mt-3 w-100'>
              <Controller
                name="document_contents"
                control={control}
                rules={{ required: true, maxLength: 500 }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    label={`${t("projectdocuments.fields.document_contents")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.document_contents}
                    helperText={GetError(errors.document_contents?.type)}
                    size="small"
                    style={{ width: "100%" }}
                    disabled={isClosedProject}
                  />
                }
              />
            </div>

            <div className='d-flex flex-row-reverse mt-3'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  );
}
