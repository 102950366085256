import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ShippingTracking } from '../../../../interfaces/Sales/Catalogs/shippingTrackings';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { PrimaryButton } from '../../../../theme/buttons';
import GenericPromises from '../../../../api/GenericPromises';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

type ShippingTrackingTabProps = {
  salesInvoiceId: number | undefined,
  isEdit: boolean
}

export const ShippingTrackingTab = ({
  salesInvoiceId,
  isEdit
}: ShippingTrackingTabProps) => {
  const [t] = useTranslation("global");
  const [shippingTrackingLocal, setShippingTrackingLocal] = useState<ShippingTracking>()
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { GenericGetResource, GenericPutResource } = GenericPromises();
  const [dataloaded, setDataLoaded] = useState(false);
  const { GetError } = FormsErrors();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    trigger,
    getValues
  } = useForm<ShippingTracking>({
    defaultValues: {
      shipping_tracking_id: undefined,
      sales_invoice_id: undefined,
      sales_shipping_document_id: undefined,
      track_id: undefined,
      gross_weight: undefined,
      real_gross_weight: undefined,
      net_weight: undefined,
      real_net_weight: undefined,
      packages_quantity: undefined,
      containers_quantity: undefined,
      pallets_quantity: undefined,
      dimensions: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });

  const onSubmit: SubmitHandler<ShippingTracking> = (data) => onPut(data);

  const onPut = async (data: ShippingTracking) => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          let myShippingTracking = {
            sales_invoice_id: salesInvoiceId,
            sales_shipping_document_id: data.sales_shipping_document_id ?? null,
            track_id: data.track_id ?? null,
            gross_weight: data.gross_weight ?? null,
            real_gross_weight: data.real_gross_weight ?? null,
            net_weight: data.net_weight ?? null,
            real_net_weight: data.real_net_weight ?? null,
            packages_quantity: data.packages_quantity ?? null,
            containers_quantity: data.containers_quantity ?? null,
            pallets_quantity: data.pallets_quantity ?? null,
            dimensions: data.dimensions ?? null,
          }

          GenericPutResource(`/shippingtrackings/${shippingTrackingLocal?.shipping_tracking_id}`, myShippingTracking)
            .then(() => {
              showSnackBar(t("generic.snackbar.update"),"success");
            })
            .catch((error) => {
              showSnackBar(error.message,"error");
            });

        }
      });
  }

  useEffect(() => {
    GenericGetResource(`/shippingtrackings/bysalesinvoiceid/${salesInvoiceId}`)
      .then(async (response) => {
        await reset({
          ...response.data.data[0]
        })

        setShippingTrackingLocal(response.data.data[0])
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        // navigate("/salesinvoices");
      });
  }, []);

  useEffect(() => { }, [isEdit]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column justify-content-center'>
          <div className="d-flex flex-row-reverse mb-2 w-100">
                  {!isEdit &&
                    <PrimaryButton onClick={() => onPut(getValues())} className='w-25' size="small" variant='outlined'>{t("generic.buttons.save")}</PrimaryButton>
                  }
                </div>
            <div className='custom-card-view w-100'>
              
              <div className="custom-card-row d-flex justify-content-between">
                
                <div className="w-100">
                  <div className='d-flex gap-3 w-100 '>
                    <div className='d-flex flex-column gap-3 w-50'>
                      <Controller
                        name="track_id"
                        control={control}
                        rules={{maxLength:100}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.track_id")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.track_id}
                            helperText={GetError(errors.track_id?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="packages_quantity"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.packages_quantity")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.packages_quantity}
                            helperText={GetError(errors.packages_quantity?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="containers_quantity"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.containers_quantity")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.containers_quantity}
                            helperText={GetError(errors.containers_quantity?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="pallets_quantity"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.pallets_quantity")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.pallets_quantity}
                            helperText={GetError(errors.pallets_quantity?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className='d-flex flex-column gap-3 w-50'>
                      <Controller
                        name="gross_weight"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.gross_weight")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.gross_weight}
                            helperText={GetError(errors.gross_weight?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="real_gross_weight"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.real_gross_weight")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.real_gross_weight}
                            helperText={GetError(errors.real_gross_weight?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="net_weight"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.net_weight")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.net_weight}
                            helperText={GetError(errors.net_weight?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                      <Controller
                        name="real_net_weight"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("shippingtrackings.fields.real_net_weight")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.real_net_weight}
                            helperText={GetError(errors.real_net_weight?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <Controller
                      name="dimensions"
                      control={control}
                      rules={{maxLength:200}}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("shippingtrackings.fields.dimensions")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.dimensions}
                          helperText={GetError(errors.dimensions?.type)}
                          multiline
                          minRows={5}
                          size="small"
                          sx={{ width: "100%" }}
                          disabled={isEdit}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      }
      <SnackbarComponent/>
    </>
  )
}
