import { useEffect, useState } from "react";
import GenericPromises from "../../../api/GenericPromises";
import { Menuitem } from "../../../interfaces/Security/menu";
import { SnackbarComponent } from "../../Commons/SnackBar";
import { AccordionExample } from "../Menu";


export const MenuPermissions = ({...props}) => {
  const [accordionData, setAccordionData] = useState<Menuitem>();
  const [dataloaded, setDataloaded] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [messageError, setMessageError] = useState("");
  const { GenericGet } = GenericPromises();

  useEffect(() => {
    GenericGet(`/menu/${props.groupId}`)
      .then((response) => {
        setAccordionData(response.data);
        setDataloaded(true);
      }).catch((error) => {
        setMessageError(error.message);
        setOpenSnack(true);
      })
      ;
  }, []);

  return (
    <div className="d-flex justify-content-center ">
      <div className="w-50 custom-card-view">
        {dataloaded && <AccordionExample accordionData={accordionData?.children} />}
        <SnackbarComponent
          openSnack={openSnack}
          setOpenSnack={setOpenSnack}
          severity={"error"}
          message={messageError}
        />
      </div>
    </div>
  );
};
