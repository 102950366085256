import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TaxRegime } from '../../../../interfaces/Sales/Catalogs/taxRegimes';
import { PrimaryButton } from '../../../../theme/buttons';
import { Box, Switch, TextField, Typography } from '@mui/material';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateTaxRegimes = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { taxRegimePayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TaxRegime>({
    defaultValues: {
      tax_regime_code: '',
      description: '',
      is_person_entity: false,
      is_legal_entity: false,
    }
  });
  const onSubmit: SubmitHandler<TaxRegime> = (data) => onPut(data);

  const onPut = (data: TaxRegime) => {
    let myData = {
      tax_regime_code: data.tax_regime_code,
      description: data.description ?? null,
      is_person_entity: data.is_person_entity ?? false,
      is_legal_entity: data.is_legal_entity ?? false
    }
    setLoadingPost(true);
    GenericPutResource(`/taxregimes/${taxRegimePayload.tax_regime_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  useEffect(() => {
    reset({
      ...taxRegimePayload
    });

    setDataLoaded(true);
  }, [])

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>

          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
              <div className="custom-card-row d-flex justify-content-between">
                <div className='w-100 d-flex'>

                  <div className='w-50'>
                    <Controller
                      name="tax_regime_code"
                      control={control}
                      rules={{ required: true, maxLength: 5 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          label={t("taxregimes.fields.tax_regime_code")}
                          error={!!errors.tax_regime_code}
                          helperText={GetError(errors.tax_regime_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>
                  <div className="w-50">
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true, maxLength: 100 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          ref={field.ref}
                          value={field.value}
                          label={t("taxregimes.fields.description")}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.description}
                          helperText={GetError(errors.description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>

                </div>

              </div>

              <div className="d-flex flex-column mt-3">
                <Controller
                  name="is_person_entity"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("taxregimes.fields.is_person_entity")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={taxRegimePayload.is_person_entity} disabled={isEdit} />
                    </Box>
                  }
                />
                <Controller
                  name="is_legal_entity"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("taxregimes.fields.is_legal_entity")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={taxRegimePayload.is_legal_entity} disabled={isEdit} />
                    </Box>
                  }
                />
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
