import { Route, Routes } from 'react-router-dom'
import { TableItemFamilies } from './table'
import { AddItemFamilies } from './add'
import { UpdateItemFamilies } from './update'
import { ViewItemFamilies } from './view'

export const BrowserItemFamilies = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableItemFamilies />} />
            <Route path={"/add"} element={<AddItemFamilies />} />
            <Route path={"/update"} element={<UpdateItemFamilies />} />
            <Route path={"/view"} element={<ViewItemFamilies />} />
        </Routes>
    )
}