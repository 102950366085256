import { Route, Routes } from 'react-router-dom'
import { TableUsers } from './table'
import { AddUser } from './add'
import { ViewUser } from './view'

export const BrowserUsers = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableUsers />} />
            <Route path={"/add"} element={<AddUser />} />
            <Route path={"/view"} element={<ViewUser />} />
        </Routes>
    )
}
