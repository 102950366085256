import { Route, Routes } from 'react-router-dom'
import { TableCancelledSalesInvoices } from './table'
import { ViewCancelledSalesInvoices } from './view'

export const BrowserCancelledSalesInvoices = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCancelledSalesInvoices />} />
      <Route path={"/view"} element={<ViewCancelledSalesInvoices />} />
    </Routes>
  )
}