import { Route, Routes } from 'react-router-dom'
import { TableSalesInvoiceRelationshipTypes } from './table'
import { AddSalesInvoiceRelationshipTypes } from './add'
import { ViewSalesInvoiceRelationshipTypes } from './view'

export const BrowserSalesInvoiceRelationshipTypes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableSalesInvoiceRelationshipTypes />} />
      <Route path={"/add"} element={<AddSalesInvoiceRelationshipTypes />} />
      <Route path={"/view"} element={<ViewSalesInvoiceRelationshipTypes />} />
    </Routes>
  )
}