import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid,} from '@mui/material';
import { CancelledSalesInvoicesInvoiceBody } from './invoiceBody';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';

type UpdateSalesInvoiceProps = {
  salesInvoicePayload: CancelledSalesInvoice | undefined,
  dataLoaded: boolean
}

export const UpdateCancelledSalesInvoices = ({ salesInvoicePayload, dataLoaded}: UpdateSalesInvoiceProps) => {
  const [t] = useTranslation("global");


  useEffect(() => {

  }, []);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
            <Grid container display={'flex'} flexDirection={'column'}>
              <Grid item sx={{ height: 'auto', marginTop: 1 }}>
                <CancelledSalesInvoicesInvoiceBody
                  salesInvoicePayload={salesInvoicePayload}
                />
              </Grid>
            </Grid>
        </>
      }
    </>
  )
}
