import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Supplier } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SupplierItem } from "../../../interfaces/Purchases/Catalogs/supplierItems";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Divider } from "../../../components/Commons/Divider";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";

export const AddItemSuppliers = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SupplierItem>({
    defaultValues: {
      supplier_id: undefined,
      item_price: undefined,
      supplier_reference: undefined,
      supplier_reference_description: undefined,
    }
  });
  const onSubmit: SubmitHandler<SupplierItem> = (data) => onPost(data);

  const onPost = (data: SupplierItem) => {
    let myData = {
      supplier_id: data.supplier_id,
      item_id: props.item_id,
      item_price: data.item_price,
      supplier_reference: data.supplier_reference ?? null,
      supplier_reference_description: data.supplier_reference_description ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/supplieritems", myData)
      .then((response) => {
        let mySupplier = comboSuppliers.find((supplier: Supplier) => supplier.supplier_id === myData.supplier_id);
        let myNewRow = {
          ...response.data,
          business_name: mySupplier?.business_name,
        }
        props.AddNewRow(myNewRow);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    props.setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/suppliers")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboSuppliers(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-50'>
                <TextField
                  variant="filled"
                  label={`${t("items.fields.item_code")}`}
                  value={props.item_code}
                  size="small"
                  style={{ width: "100%" }}
                  sx={{ paddingRight: 2 }}
                  disabled={true}
                />
              </div>

              <div className='w-50'>
                <TextField
                  variant="filled"
                  label={`${t("items.fields.item_description")}`}
                  value={props.item_description}
                  size="small"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </div>
            </div>

            <div className='mt-3 d-flex'>
              <div className='w-50'>
                <Controller
                  name="supplier_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboSuppliers}
                      getOptionLabel={(option) => option.business_name ?? ''}
                      renderOption={(props, option: Supplier) => (
                        <div key={option.supplier_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.business_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.supplier_id === value.supplier_id}
                      onChange={(_, values) => {
                        field.onChange(values?.supplier_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("supplieritems.fields.business_name")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.supplier_id}
                          helperText={GetError(errors.supplier_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="item_price"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.item_price")}`}
                      type='number'
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }
                      }}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.item_price}
                      helperText={GetError(errors.item_price?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <Divider />

            <div className='d-flex'>
              <div className='w-50'>
                <Controller
                  name="supplier_reference"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.supplier_reference")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.supplier_reference}
                      helperText={GetError(errors.supplier_reference?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="supplier_reference_description"
                  control={control}
                  rules={{ maxLength: 150 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.supplier_reference_description")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.supplier_reference_description}
                      helperText={GetError(errors.supplier_reference_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
