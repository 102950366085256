import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { TariffFraction } from '../../../../interfaces/Sales/Catalogs/tariffFractions';
import { Unit } from '../../../../interfaces/Commons/units';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateTariffFractions = ({ ...props }) => {

  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [comboUnits, setComboUnits] = useState<Unit[]>([]);
  const [defaultUnit, setDefaultUnit] = useState<Unit>();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { tariffFractionPayload } = props;
  const isEdit = !props.isEdit;
  const {showSnackBar,SnackbarComponent}= useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TariffFraction>({
    defaultValues: {
      tariff_fraction_code: '',
      tariff_fraction_general_description: '',
      tariff_fraction_specific_description: '',
      unit_id: undefined,
    }
  });
  const onSubmit: SubmitHandler<TariffFraction> = (data) => onPut(data);

  const onPut = (data: TariffFraction) => {
    let myData = {
      tariff_fraction_code: data.tariff_fraction_code,
      tariff_fraction_general_description: data.tariff_fraction_general_description ?? null,
      tariff_fraction_specific_description: data.tariff_fraction_specific_description ?? null,
      unit_id: data.unit_id ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/tarifffractions/${tariffFractionPayload.tariff_fraction_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),'success');
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,'error');
      });
  }



  useEffect(() => {
    reset({
      tariff_fraction_code: tariffFractionPayload.tariff_fraction_code,
      tariff_fraction_general_description: tariffFractionPayload.tariff_fraction_general_description ?? null,
      tariff_fraction_specific_description: tariffFractionPayload.tariff_fraction_specific_description ?? null,
      unit_id: tariffFractionPayload.unit_id ?? null
    });
    GenericGetResource("/units").then((response) => {
      setComboUnits(response.data.data);
      setDefaultUnit(response.data.data.find((unit: Unit) => unit.unit_id === tariffFractionPayload.unit_id));
      setDataLoaded(true);
    })
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="tariff_fraction_code"
                        control={control}
                        rules={{ required: true, maxLength: 10,minLength:8 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("tarifffractions.fields.tariff_fraction_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tariff_fraction_code}
                            helperText={GetError(errors.tariff_fraction_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="unit_id"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboUnits}
                            defaultValue={defaultUnit}
                            getOptionLabel={(option) => `${option.unit_symbol} - ${option.unit_description}`}
                            renderOption={(props, option: Unit) => (
                              <div key={option.unit_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.unit_symbol} - {option.unit_description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                            onChange={(_, values) => {
                              field.onChange(values?.unit_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("units.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.unit_id}
                                disabled={isEdit}
                              />
                            )}
                          />
                        )}
                      />

                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                  <div className='w-100'>
                      <Controller
                        name="tariff_fraction_specific_description"
                        control={control}
                        rules={{ maxLength: 100}}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("tarifffractions.fields.tariff_fraction_specific_description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tariff_fraction_specific_description}
                            helperText={GetError(errors.tariff_fraction_specific_description?.type)}
                            size="small"
                            style={{ width:'100%' }}
                            sx={{paddingRight:2}}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex w-100 pt-3'>
                    <div className='w-100'>
                      <Controller
                        name="tariff_fraction_general_description"
                        control={control}
                        rules={{ maxLength: 500}}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("tarifffractions.fields.tariff_fraction_general_description")}`}
                            ref={field.ref}
                            value={field.value}
                            multiline
                            minRows={5}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tariff_fraction_general_description}
                            helperText={GetError(errors.tariff_fraction_general_description?.type)}
                            size="small"
                            style={{ width:'100%' }}
                            sx={{paddingRight:2}}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>



                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
