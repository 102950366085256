import { Route, Routes } from 'react-router-dom'
import { TableAuthorizationPayments } from './table'


export const BrowserAuthorizationPayments = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableAuthorizationPayments />} />
    </Routes>
  )
}
