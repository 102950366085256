import { useTranslation } from "react-i18next";
import { AccordionDetailStatuses } from "./components/accordionDetailStatuses";
import { ReceiptDetail } from "../../../../interfaces/Sales/Receipts/receipts";

type TabStatusesProps = {
  receiptsDetailsData: ReceiptDetail[]
}

export const TabStatuses = ({
  receiptsDetailsData,
}: TabStatusesProps) => {
  const [t] = useTranslation("global");

  return (
    <>
      {receiptsDetailsData.map((receiptDetail, index) => {
        return <AccordionDetailStatuses key={index} title={receiptDetail.sales_invoice_name ?? ""} receipt_detail_id={receiptDetail.receipt_detail_id ?? 0} />
      })}
    </>
  )
}
