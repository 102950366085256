import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { SalesInvoiceRelationshipType } from "../../../../interfaces/Sales/Catalogs/salesInvoiceRelationshipTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PrimaryButton } from "../../../../theme/buttons";
import { TextField } from "@mui/material";


export const UpdateSalesInvoiceRelationshipTypes = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { salesinvoiceRelationshipTypesPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SalesInvoiceRelationshipType>({
    defaultValues: {

    }
  });

  const onSubmit: SubmitHandler<SalesInvoiceRelationshipType> = (data) => onPut(data);

  const onPut = (data: SalesInvoiceRelationshipType) => {
    let myData = {
      sales_invoice_relationship_type_code: data.sales_invoice_relationship_type_code ?? null,
      sales_invoice_relationship_type_description: data.sales_invoice_relationship_type_description ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/salesinvoicerelationshiptypes/${salesinvoiceRelationshipTypesPayload.sales_invoice_relationship_type_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  useEffect(() => {
    reset({
      sales_invoice_relationship_type_code: salesinvoiceRelationshipTypesPayload.sales_invoice_relationship_type_code ?? undefined,
      sales_invoice_relationship_type_description: salesinvoiceRelationshipTypesPayload.sales_invoice_relationship_type_description ?? undefined,
    });

    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">
                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="sales_invoice_relationship_type_code"
                        control={control}
                        rules={{ required: true, maxLength: 3 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.sales_invoice_relationship_type_code}
                            helperText={GetError(errors.sales_invoice_relationship_type_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="sales_invoice_relationship_type_description"
                        control={control}
                        rules={{ maxLength: 100 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.sales_invoice_relationship_type_description}
                            helperText={GetError(errors.sales_invoice_relationship_type_description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                  </div>

                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
