import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm} from 'react-hook-form';
import { Tax } from '../../../../interfaces/Sales/Catalogs/taxes';
import { TextField, Box, Typography, Switch, } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateTaxes = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { taxPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Tax>({
    defaultValues: {
      tax_name: '',
      tax_percent: undefined,
      tax_code: '',
      is_transferred: undefined,
      is_whithheld: undefined,
    }
  });
  const onSubmit: SubmitHandler<Tax> = (data) => onPut(data);

  const onPut = (data: Tax) => {
    let myData = {
      tax_code: data.tax_code,
      tax_name: data.tax_name,
      tax_percent: data.tax_percent,
      is_transferred: (data.is_transferred) ? 1 : 0,
      is_whithheld: (data.is_whithheld) ? 1 : 0,
    }
    setLoadingPost(true);
    GenericPutResource(`/taxes/${taxPayload.tax_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),"success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,'error');
      });
  }

  useEffect(() => {
    reset({
      tax_name: taxPayload.tax_name,
      tax_percent: taxPayload.tax_percent,
      tax_code: taxPayload.tax_code,
      is_transferred: taxPayload.is_transferred,
      is_whithheld: taxPayload.is_whithheld,
    });
    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="tax_name"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("taxes.fields.tax_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tax_name}
                            helperText={GetError(errors.tax_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className="w-50">
                      <Controller
                        name="tax_code"
                        control={control}
                        rules={{ required: true, maxLength: 3 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("taxes.fields.tax_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tax_code}
                            helperText={GetError(errors.tax_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 mt-3'>
                    <div className="w-50">
                      <Controller
                        name="tax_percent"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            variant="filled"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                              paddingRight: 2
                            }}
                            type="number"
                            value={field.value}
                            onChange={field.onChange}
                            label={t("taxes.fields.tax_percent")}
                            error={!!errors.tax_percent}
                            helperText={GetError(errors.tax_percent?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="is_transferred"
                        control={control}
                        render={({ field }) =>
                          <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("taxes.fields.is_transferred")}</Typography>
                            <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={taxPayload.is_transferred} disabled={isEdit} />
                          </Box>
                        }
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="is_whithheld"
                        control={control}
                        render={({ field }) =>
                          <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("taxes.fields.is_whithheld")}</Typography>
                            <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={taxPayload.is_whithheld} disabled={isEdit} />
                          </Box>
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
