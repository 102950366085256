import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CustomerItem } from '../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import { Customer } from '../../../interfaces/Sales/Catalogs/Customers/customers';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Divider } from '../../../components/Commons/Divider';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';

export const UpdateItemCustomers = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
    const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
    const { customerItemPayload, permissions } = props;
    const isEdit = !permissions.update;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<CustomerItem>({
        defaultValues: {
            customer_id: undefined,
            item_price: undefined,
            customer_reference: undefined,
            customer_reference_description: undefined,
        }
    });
    const onSubmit: SubmitHandler<CustomerItem> = (data) => onPut(data);

    const onPut = (data: CustomerItem) => {
        let myData = {
            customer_id: data.customer_id,
            item_id: customerItemPayload.item_id,
            item_price: data.item_price,
            customer_reference: data.customer_reference ?? null,
            customer_reference_description: data.customer_reference_description ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/customeritems/${customerItemPayload.customer_items_id}`, myData)
            .then((response) => {
                props.UpdateRow(response.data);
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
                setLoadingPost(false);
            });
    }

    const onDelete = () => {
        GenericDeleteResource(`/customeritems/${customerItemPayload?.customer_items_id}`)
            .then(() => {
                setOpenDialog(false);
                props.DeleteRow(customerItemPayload?.customer_items_id);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
                setOpenDialog(false);
            })
    }

    useEffect(() => {

        let myPromises = [
            GenericGetResource("/customers")
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboCustomers(responses[0].data.data);

                reset({
                    customer_id: customerItemPayload.customer_id,
                    item_id: customerItemPayload.item_id,
                    item_price: customerItemPayload.item_price,
                    customer_reference: customerItemPayload.customer_reference ?? undefined,
                    customer_reference_description: customerItemPayload.customer_reference_description ?? undefined,
                });

                setDefaultCustomer(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerItemPayload.customer_id));

                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, 'error');
            })
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

                        <div className='d-flex'>
                            <div className='w-50'>
                                <TextField
                                    variant="filled"
                                    label={`${t("items.fields.item_code")}`}
                                    value={customerItemPayload.item_code}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                    disabled={true}
                                />
                            </div>

                            <div className='w-50'>
                                <TextField
                                    variant="filled"
                                    label={`${t("items.fields.item_description")}`}
                                    value={customerItemPayload.item_description}
                                    size="small"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className='mt-3 d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="customer_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            ref={field.ref}
                                            size="small"
                                            sx={{ width: "100%", paddingRight: 2 }}
                                            options={comboCustomers}
                                            defaultValue={defaultCustomer}
                                            getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                                            renderOption={(props, option: Customer) => (
                                                <div key={option.customer_id}>
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                                                    </Box>
                                                </div>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                                            onChange={(_, values) => {
                                                field.onChange(values?.customer_id || null)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t("customeritems.fields.business_name")}`}
                                                    variant="filled"
                                                    value={field.value}
                                                    error={!!errors.customer_id}
                                                    helperText={GetError(errors.customer_id?.type)}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="item_price"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.item_price")}`}
                                            type='number'
                                            ref={field.ref}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                "& input[type=number]": { MozAppearance: "textfield", }
                                            }}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.item_price}
                                            helperText={GetError(errors.item_price?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <Divider />

                        <div className='d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="customer_reference"
                                    control={control}
                                    rules={{ maxLength: 50 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.customer_reference")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.customer_reference}
                                            helperText={GetError(errors.customer_reference?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="customer_reference_description"
                                    control={control}
                                    rules={{ maxLength: 150 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.customer_reference_description")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.customer_reference_description}
                                            helperText={GetError(errors.customer_reference_description?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                            <div>
                                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                                {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                            </div>
                            {permissions.delete ?
                                <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                            }
                        </div>

                    </form>

                </Box>
            }
            <GenericDialog
                title={t("generic.dialog.delete")}
                content={t("customeritems.dialog.delete")}
                open={openDialog}
                setOpen={setOpenDialog}
                onAcept={onDelete}
            />
            <SnackbarComponent />
        </>
    )
}
