import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import { ImageDataComponent } from '../../../../components/Images/ImageData';
import DownloadIcon from '@mui/icons-material/Download';
import { useFiles } from '../../../../hooks/useFiles';
import { File } from '../../../../interfaces/Commons/files';
import { useDates } from '../../../../hooks/useDates';
import { ButtonStyled } from '../../../../theme/buttons';
import { UseFormGetValues } from 'react-hook-form';
import { Receipt } from '../../../../interfaces/Sales/Receipts/receipts';
import { Divider } from '../../../../components/Commons/Divider';
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";

type StampingProps = {
  getValues?: UseFormGetValues<Receipt>;
  receiptLocal?: Receipt;
  QR: File | undefined,
  XML: File | undefined,
}

export const Stamping = ({
  getValues,
  receiptLocal,
  QR,
  XML,
}: StampingProps) => {
  const [t] = useTranslation("global");
  const { GetFiles } = useFiles();
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [fiscalXML, setFiscalXML] = useState(XML);
  const [fiscalQr, setFiscalQr] = useState(QR);

  const onDownloadXML = () => {
    const downloadLink = document.createElement('a');
    const fileName = `${fiscalXML?.file_name}.xml`;
    downloadLink.href = fiscalXML?.dataURL ?? '';
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    GetFiles((receiptLocal && receiptLocal.receipt_id) ?? 0, "Receipts")
      .then(async (response) => {
        if (response && response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            const element: File = response[i];
            if (element.file_id === (((getValues && getValues("qr_file")) ?? receiptLocal?.qr_file)) ?? 0) {
              setFiscalQr(() => element);
            }
            if (element.file_id === (((getValues && getValues("xml_file_id")) ?? receiptLocal?.xml_file_id)) ?? 0) {
              setFiscalXML(() => element);
            }
          }
          setDataLoaded(true);
        }
        else
          setDataLoaded(true);
      });
  }, getValues ? [(getValues("qr_file"), getValues("xml_file_id"))] : [receiptLocal]);

  return (
    <Box sx={{ minWidth: 1300 }}>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className='d-flex justify-content-center'>
            <div className='custom-card-view w-100'>
              <div className="custom-card-row d-flex justify-content-between">
                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-100'>
                      <TextField
                        variant="filled"
                        multiline
                        maxRows={4}
                        label={`${t("receipts.fields.original_string_SAT")}`}
                        value={(getValues && getValues("original_string_SAT")) || receiptLocal?.original_string_SAT}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("receipts.fields.certificate_number_SAT")}`}
                        value={(getValues && getValues("certificate_number_SAT")) || receiptLocal?.certificate_number_SAT}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("receipts.fields.certificate_number_CFDI")}`}
                        value={(getValues && getValues("certificate_number_CFDI")) || receiptLocal?.certificate_number_CFDI}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("receipts.fields.uuid")}`}
                        value={(getValues && getValues("uuid")) || receiptLocal?.uuid}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("receipts.fields.signing_date")}`}
                        value={(getValues && getValues("signing_date")) ? `${TimeConverter(getValues && getValues("signing_date")?.toString())}` : TimeConverter(receiptLocal?.signing_date?.toString())}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-100'>
                      <TextField
                        variant="filled"
                        multiline
                        maxRows={3}
                        label={`${t("receipts.fields.seal_SAT")}`}
                        value={(getValues && getValues("seal_SAT")) || receiptLocal?.seal_SAT}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3 mb-2'>
                    <div className='w-100'>
                      <TextField
                        variant="filled"
                        multiline
                        maxRows={3}
                        label={`${t("receipts.fields.seal_CFDI")}`}
                        value={(getValues && getValues("seal_CFDI")) || receiptLocal?.seal_CFDI}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className='d-flex w-100 pt-1'>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.cancellation_date")}`}
                        value={(getValues && getValues("cancellation_date")) ? `${TimeConverter(getValues && getValues("cancellation_date")?.toString())?.toLocaleDateString().toLocaleString()}` : TimeConverter(receiptLocal?.cancellation_date?.toString())}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.cancellation_reason")}`}
                        value={(getValues && getValues("cancellation_reason") || receiptLocal?.cancellation_reason)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.cancellation_response_code")}`}
                        value={(getValues && getValues("cancellation_response_code")) || receiptLocal?.cancellation_response_code}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={true}
                        sx={{ paddingRight: 2 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                </div>
                <Box sx={{ marginLeft: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ImageDataComponent url={fiscalQr?.dataURL} />
                  <ButtonStyled onClick={() => onDownloadXML()} variant='contained' sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }} disabled={fiscalXML?.dataURL ? false : true}>
                    <DownloadIcon />
                    {t("receipts.buttons.xml")}
                  </ButtonStyled>
                </Box>
              </div>
            </div>
          </div>
        </>
      }
    </Box >
  )
}
