import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import { BankAccount } from '../../../interfaces/Commons/bankAccounts';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Currency } from '../../../interfaces/Commons/currencies';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';

export const UpdateBankAccounts = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
    const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { bankAccountPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<BankAccount>({
        defaultValues: {
            account_alias: '',
            bank_name: '',
            card_number: '',
            account_number: '',
            interbank_key: '',
            swift_code: '',
            phone_number: '',
            bank_references: '',
            currency_id: undefined,
            balance: 0,
        }
    });
    const onSubmit: SubmitHandler<BankAccount> = (data) => onPut(data);

    const onPut = (data: BankAccount) => {
        let myData = {
            account_alias: data.account_alias,
            bank_name: data.bank_name,
            card_number: data.card_number ?? null,
            account_number: data.account_number ?? null,
            interbank_key: data.interbank_key ?? null,
            swift_code: data.swift_code ?? null,
            phone_number: data.phone_number ?? null,
            bank_references: data.bank_references ?? null,
            currency_id: data.currency_id ?? null,
            balance: data.balance ?? 0,
        }
        setLoadingPost(true);
        GenericPutResource(`/bankaccounts/${bankAccountPayload.bank_account_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
                setLoadingPost(false);
            });
    }

    useEffect(() => {
        GenericGetResource(`/currencies`)
            .then((response) => {
                setComboCurrencies(response.data.data);
                reset({
                    account_alias: bankAccountPayload.account_alias,
                    bank_name: bankAccountPayload.bank_name,
                    card_number: bankAccountPayload.card_number ?? undefined,
                    account_number: bankAccountPayload.account_number ?? undefined,
                    interbank_key: bankAccountPayload.interbank_key ?? undefined,
                    swift_code: bankAccountPayload.swift_code ?? undefined,
                    phone_number: bankAccountPayload.phone_number ?? undefined,
                    bank_references: bankAccountPayload.bank_references ?? undefined,
                    currency_id: bankAccountPayload.currency_id ?? undefined,
                    balance: bankAccountPayload.balance ?? undefined,
                });

                setDefaultCurrency(response.data.data.find((item: Currency) => item.currency_id === bankAccountPayload.currency_id));

                setDataLoaded(true);
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="account_alias"
                                                control={control}
                                                rules={{ required: true, maxLength: 50 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.account_alias")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.account_alias}
                                                        helperText={GetError(errors.account_alias?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}

                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="bank_name"
                                                control={control}
                                                rules={{ required: true, maxLength: 50 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.bank_name")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.bank_name}
                                                        helperText={GetError(errors.bank_name?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="card_number"
                                                control={control}
                                                rules={{ maxLength: 19 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.card_number")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.card_number}
                                                        helperText={GetError(errors.card_number?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}

                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="account_number"
                                                control={control}
                                                rules={{ maxLength: 24 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.account_number")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.account_number}
                                                        helperText={GetError(errors.account_number?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="interbank_key"
                                                control={control}
                                                rules={{ maxLength: 18 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.interbank_key")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.interbank_key}
                                                        helperText={GetError(errors.interbank_key?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}

                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="swift_code"
                                                control={control}
                                                rules={{ maxLength: 11 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.swift_code")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.swift_code}
                                                        helperText={GetError(errors.swift_code?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="bank_references"
                                                control={control}
                                                rules={{ maxLength: 30 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.bank_references")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.bank_references}
                                                        helperText={GetError(errors.bank_references?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>

                                        <div className='w-50'>
                                            <Controller
                                                name="phone_number"
                                                control={control}
                                                rules={{ maxLength: 20 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.phone_number")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.phone_number}
                                                        helperText={GetError(errors.phone_number?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}

                                                    />
                                                }
                                            />
                                        </div>

                                    </div>

                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>

                                            <Controller
                                                name="currency_id"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        sx={{ width: "100%", paddingRight: 2 }}
                                                        options={comboCurrencies}
                                                        defaultValue={defaultCurrency}
                                                        getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                                                        renderOption={(props, option: Currency) => (
                                                            <div key={option.currency_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option.currency_code} - {option.currency_description}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.currency_id || null)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("currencies.title-view")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.currency_id}
                                                                helperText={GetError(errors.currency_id?.type)}
                                                            />
                                                        )}
                                                        disabled={isEdit}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="balance"
                                                control={control}
                                                rules={{ required: true, min: 0 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("bankaccounts.fields.balance")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.balance}
                                                        helperText={GetError(errors.balance?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        type='number'
                                                        sx={{
                                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                            "& input[type=number]": { MozAppearance: "textfield", },
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
