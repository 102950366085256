import { useState } from "react";
import { Parameter } from "../../interfaces/Commons/parameters";
import { ProjectExtraField } from "../../interfaces/Projects/Catalogs/projectExtraFields";
import GenericPromises from "../../api/GenericPromises";

export const useFormulaEvaluator = () => {
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const { GenericGetResource } = GenericPromises();

  // Función para obtener parámetros desde la API
  const fetchParameters = async () => {
    try {
      const response = await GenericGetResource("/parameters");
      setParameters(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  // Función para reemplazar variables por sus valores
  const replaceVariables = (formula: string, fields: ProjectExtraField[], parameters: Parameter[]): string => {
    let formulaWithValues = formula;

    // Reemplazo de valores de fields (solo aquellos rodeados de '{}')
    fields.forEach(field => {
      const { project_extra_field_name, project_extra_field_value, field_type_name } = field;
      let valueStr;

      if (field_type_name === 'Date') {
        valueStr = `new Date("${project_extra_field_value}")`;
      } else if (field_type_name === 'number') {
        valueStr = `${project_extra_field_value}`;
      } else if (field_type_name === 'string') {
        valueStr = `"${project_extra_field_value}"`;
      } else {
        valueStr = `${project_extra_field_value}`;
      }

      // Reemplazo solo los nombres de campos rodeados de {}
      formulaWithValues = formulaWithValues.replace(new RegExp(`\\{${project_extra_field_name}\\}`, 'g'), valueStr);
    });

    // Reemplazo de valores de parameters (solo aquellos rodeados de '<>')
    parameters.forEach(param => {
      const { parameter_name, parameter_value, field_type_name } = param;

      if (parameter_name && parameter_value) {
        let paramValue: string | number = parameter_value;

        if (field_type_name === 'Date') {
          paramValue = `new Date("${parameter_value}")`;
        } else if (field_type_name === 'number') {
          paramValue = `${parameter_value}`;
        } else if (field_type_name === 'string') {
          paramValue = `"${parameter_value}"`;
        }

        // Reemplazar solo los nombres de parámetros rodeados de <>
        formulaWithValues = formulaWithValues.replace(new RegExp(`<${parameter_name}>`, 'g'), paramValue);
      }
    });

    // Reemplazo de funciones como ROUND, AVERAGE, etc.
    formulaWithValues = replaceFunctions(formulaWithValues);

    return formulaWithValues;
  };

  // Función auxiliar para reemplazar funciones de manera recursiva
  const replaceFunctions = (formula: string): string => {
    // Expresión regular para encontrar funciones con su argumento
    const functionRegex = /(\b(ROUND|FLOOR|CEIL)\b)\(([^)]+)\)/g;

    // Mientras se encuentren funciones, se siguen reemplazando
    while (functionRegex.test(formula)) {
      formula = formula.replace(functionRegex, (match, functionName, _, param) => {
        switch (functionName) {
          case 'FLOOR':
            // Redondear el valor
            return `Math.floor(${param})`;
          case 'ROUND':
            // Redondear el valor
            return `Math.round(${param})`;
          case 'CEIL':
            // Redondear el valor
            return `Math.ceil(${param})`;
          default:
            return match; // Devolver la expresión original si no es soportada
        }
      });
    }

    return formula;
  };

  const CalculateValue = (formulaWithValues: string): number => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const dateRegex = /new Date\("([^"]+)"\)/g;

    // Paso 1: Convertir todas las fechas a milisegundos
    let processedFormula = formulaWithValues;
    processedFormula = processedFormula.replace(dateRegex, (match, p1) => {
      const dateObj = new Date(p1);
      return dateObj.getTime().toString();
    });

    // Paso 2: Reemplazar diferencias de fechas en milisegundos por días
    const diffRegex = /(\d+)\s*-\s*(\d+)/g;
    processedFormula = processedFormula.replace(diffRegex, (match, p1, p2) => {
      if (p1.length > 10 && p2.length > 10) { // Fechas en milisegundos
        const diffInMs = parseInt(p1) - parseInt(p2);
        return (diffInMs / millisecondsPerDay).toString();
      }
      return match;
    });

    // Paso 3: Evaluar la fórmula procesada
    return new Function(`return ${processedFormula};`)();
  };

  // Función para evaluar la fórmula
  const EvaluateFormula = async (formula: string, fields: ProjectExtraField[]): Promise<number> => {
    let myParameters = await fetchParameters();
    const formulaWithValues = replaceVariables(formula, fields, myParameters);
    return CalculateValue(formulaWithValues);
  };

  return {
    EvaluateFormula,
  };
};
