import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import { UpdateCancelledSalesInvoices } from './update';
import InvoiceDetail from './invoiceDetail';
import { CancelledInvoicing } from './invoicing';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Button, ListItemButton, Popover, TextField } from '@mui/material';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import { UpdateCancelledInvoiceHeader } from './updateInvoiceHeader';
import { AnnotationsCancelledSalesInvoice } from './annotations';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { CustomerLocation } from '../../../../interfaces/Sales/Catalogs/Customers/customers';
import { IndexCancelledTransportationDocument } from '../CancelledTransportationDocument';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CancelledShippingTrackingTab } from '../CancelledShippingTracking';
import { IndexCancelledForeignTrade } from '../CancelledForeignTrades';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { CancelledRealtedSalesInvoices } from '../CancelledRelatedSalesInvoices';

export const ViewCancelledSalesInvoices = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [salesInvoiceLocal, setSalesInvoiceLocal] = useState<CancelledSalesInvoice>();
  const [dataloaded, setDataLoaded] = useState(false);
  const [isDeaccounted, setIsDeaccounted] = useState(false);
  const { GenericGetResource, GenericPutAction } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceAccounted, setResourceAccounted] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popover' : undefined;

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const onDeaccounted = async () => {
    // get sales invoice by sales_invoice_name  and update by id with is accounted = false
    let salesInvoiceNameBase64 = btoa(`sales_invoice_name=${salesInvoiceLocal?.sales_invoice_name}`);
    await GenericGetResource(`/salesinvoices?filter=${salesInvoiceNameBase64}`)
      .then(async (response) => {
        await GenericPutAction(`/salesinvoices/isaccounted/${response.data.data[0].sales_invoice_id}`, { is_accounted: false }, false)
          .then(async () => {
            setIsDeaccounted(true);
            showSnackBar(t("generic.snackbar.update"), "success");
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }

  useEffect(() => {
    if (location.state === null) return navigate("/cancelledsalesinvoices", { state: {}, replace: true });
    GenericGetResource(`/cancelledsalesinvoices/${location.state.row.cancelled_sales_invoice_id}`)
      .then(async (response1) => {
        setSalesInvoiceLocal(response1.data);

        let myCustomerLocation: CustomerLocation = {
          customer_location_id: 0,
          street: response1.data.street_name,
          city_name: response1.data.city_name,
          state_name: response1.data.state_name,
          country_name: response1.data.country_name
        }
        setDefaultCustomerLocation((prev) => myCustomerLocation);

        await GetResourceByUrl("/cancelledsalesinvoices")
          .then((response) => {
            response && setResourceScreen(response);
          });

        await GetResourceByUrl("/salesinvoices/isaccounted")
          .then((response) => {
            response && setResourceAccounted(response);
          });

        let salesInvoiceNameBase64 = btoa(`sales_invoice_name=${response1.data?.sales_invoice_name}`);
        await GenericGetResource(`/salesinvoices?filter=${salesInvoiceNameBase64}`)
          .then((response) => {
            setIsDeaccounted(!response.data.data[0].is_accounted);
          });

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
        navigate("/cancelledsalesinvoices");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [isDeaccounted]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("cancelledsalesinvoices.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                <div className='d-flex flex-row-reverse pt-1 w-50'>
                  <Button aria-describedby={id1} onClick={handleClick1}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { onDeaccounted(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={resourceAccounted?.update ? isDeaccounted : true}>
                      {t("salesinvoices.actions.deaccount")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                  </Popover>
                  <Button variant="text" onClick={() => { navigate("/cancelledsalesinvoices", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <div className='d-flex w-100 m-2 flex-row-reverse'>
                  <div className="w-75 d-flex flex-row-reverse mx-2 ">
                    <div className='w-25'>

                      <DatePicker
                        label={`${t("salesinvoices.fields.sales_invoice_date")}`}
                        slotProps={{
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            sx: { paddingRight: 2 },
                          }
                        }}
                        defaultValue={dayjs(salesInvoiceLocal?.sales_invoice_date)}
                        format="DD/MM/YYYY"
                        disableFuture
                        readOnly={true}
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        label={`${t("invoicedocumenttypes.title-view")}`}
                        variant="filled"
                        value={`${salesInvoiceLocal?.invoice_doc_type_name || ""}`}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.sales_invoice_name")}`}
                        value={`${salesInvoiceLocal?.sales_invoice_name || ""}`}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>

                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.purchase_order")}`}
                        value={`${salesInvoiceLocal?.purchase_order || ""}`}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                  </div>
                  <div className='w-50'></div>
                </div>
                <div className='custom-card-view'>
                  <div>
                    <UpdateCancelledInvoiceHeader
                      SalesInvoiceLocal={salesInvoiceLocal}
                      defaultCustomerLocation={defaultCustomerLocation}
                      isDeaccounted={isDeaccounted}
                    />
                  </div>
                </div>
              </div>
              <div>
                <InvoiceDetail
                  Update={
                    <UpdateCancelledSalesInvoices
                      salesInvoicePayload={salesInvoiceLocal}
                      dataLoaded={dataloaded}
                    />
                  }
                  Invoicing={
                    <CancelledInvoicing
                      salesInvoiceLocal={salesInvoiceLocal}
                      props
                    />
                  }
                  Annotations={
                    <AnnotationsCancelledSalesInvoice
                      SalesInvoiceLocal={salesInvoiceLocal}
                    />
                  }
                  TransportationDocument={
                    <IndexCancelledTransportationDocument
                      salesInvoicePayload={salesInvoiceLocal}
                    />
                  }
                  includesTransportationDocument={!salesInvoiceLocal?.includes_transportation_document}
                  includesExteriorComplement={!salesInvoiceLocal?.includes_exterior_complement}
                  ShippingTracking={
                    <CancelledShippingTrackingTab
                      cancelledSalesInvoiceId={salesInvoiceLocal?.cancelled_sales_invoice_id}
                    />
                  }
                  ForeignTrade={
                    <IndexCancelledForeignTrade salesInvoicePayload={salesInvoiceLocal} />
                  }
                  RelatedInvoices={
                    <CancelledRealtedSalesInvoices salesInvoicePayload={salesInvoiceLocal} />
                  }
                />
              </div>

            </>
          }
        </>
      }
      <SnackbarComponent />
    </>
  )
}
