import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Country } from '../../../interfaces/Commons/Locations/countries';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddCountry = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Country>({
    defaultValues: {
      country_code: undefined,
      country_name: "",
    }
  });
  const onSubmit: SubmitHandler<Country> = (data) => onPost(data);

  const onPost = (data: Country) => {
    let myData = {
      country_code: data.country_code ?? null,
      country_name: data.country_name,
    }
    setLoadingPost(true);
    GenericPostResource("/countries", myData)
      .then((response) => {
        navigate("/countries", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/countries", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("countries.title")}`;
    })
    setDataLoaded(true);
  }, [])

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("countries.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="custom-card-row d-flex justify-content-between">

                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="country_code"
                        control={control}
                        rules={{ maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("countries.fields.country_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.country_code}
                            helperText={GetError(errors.country_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="country_name"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("countries.fields.country_name")}`}
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.country_name}
                            helperText={GetError(errors.country_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        }
                      />
                    </div>

                  </div>

                </div>
                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
