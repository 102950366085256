import { DataGrid, GridColDef, GridColumnMenu, GridColumnMenuColumnsItem, GridColumnMenuProps, GridEventListener, GridPagination, GridRowModel, GridRowModesModel, GridRowsProp, GridValidRowModel, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import React from 'react'
import { ForeignTradeMerchandise, ForeignTrade } from '../../../interfaces/Sales/Catalogs/ForeignTrade/foreignTrade';
import { useTranslation } from 'react-i18next';
import { Box, TablePaginationProps } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import { CustomColumnOrderSalesInvoice } from '../GridTableMaterialUI/CustomColumnOrderSalesInvoice';

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  foreignTrade: number,
  columns: GridColDef[],
  nameOrderColumns?: string,
  setColumns?: React.Dispatch<React.SetStateAction<GridColDef<any>[]>>;
  rowModesModel?: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<ForeignTradeMerchandise[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  isEdit?: boolean,
  ChangeTotalForeignTrade: () => void,
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  foreignTrade: number,
  isEdit: boolean,
  ChangeTotalUSD: () => void,
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}


function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function ComponentTableForeignTradeMerchandise({
  rows,
  foreignTrade,
  columns,
  setColumns,
  nameOrderColumns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  setRows,
  setRowModesModel,
  isEdit,
  ChangeTotalForeignTrade,
}: EditableTableProps) {
  const [t] = useTranslation("global");


function CustomColumnsItems({ ...props }) {
  return <div>
    <CustomColumnOrderSalesInvoice columns={columns} setColumns={setColumns}  nameOrderColumns={nameOrderColumns} />
    <GridColumnMenuColumnsItem colDef={props.colDef} onClick={props.onClick} />
  </div>
}

function CustomColumnMenuComponent(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: CustomColumnsItems,
      }}
    />
  );
}

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => (row["foreign_trade_merchandise_id"] || row["cancelled_foreign_trade_merchandise_id"])}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          // toolbar: EditToolbar,
          columnMenu:CustomColumnMenuComponent,
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, isEdit, foreignTrade, ChangeTotalForeignTrade },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  )
}
