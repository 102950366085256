import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import { CancelledTransportationDocumentLocation } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledTransportationDocuments';

type LocationProps = {
  title: string,
  isSource?: boolean;
  transportationDocumentLocation: CancelledTransportationDocumentLocation,
}

export const Locations = ({
  title,
  isSource,
  transportationDocumentLocation
}: LocationProps) => {
  const [t] = useTranslation("global");

  return (
    <div className='d-flex flex-column'>
      <div className='mb-1'>
        {title}
      </div>
      <div className='d-flex justify-content-between mx-2'>
        <div className='w-25 d-flex'>
          <DatePicker
            label={isSource ? `${t("transportationdocumentlocations.fields.date_hour_departure")}` : `${t("transportationdocumentlocations.fields.date_hour_arrival")}`}
            slotProps={{
              textField: {
                variant: "filled",
                size: "small",
                fullWidth: true,
                sx: { paddingRight: 2 },
              }
            }}
            defaultValue={dayjs(transportationDocumentLocation.date_hour_departure_arrival)}
            format="DD/MM/YYYY"
            readOnly={true}
          />
        </div>
        <div className='w-50 d-flex'>
          <TextField
            label={`${t("transportationdocumentlocations.fields.location")}`}
            variant="filled"
            value={`${transportationDocumentLocation.street}, ${transportationDocumentLocation.city_name}, ${transportationDocumentLocation.state_name}, ${transportationDocumentLocation.country_name}`}
            size="small"
            sx={{ width: "100%", paddingRight: 2 }}
            InputProps={{
              readOnly: true
            }}
          />
        </div>
        {!isSource ? 
                <div className='w-25 d-flex'>
                <TextField
                  label={`${t("transportationdocumentlocations.fields.distance")}`}
                  variant="filled"
                  value={transportationDocumentLocation.distance}
                  size="small"
                  sx={{ width: "100%", paddingRight: 2 }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </div>
      :
      <div className='w-25 d-flex'></div>
      }
      </div>
    </div>
  )
}
