import { Route, Routes } from 'react-router-dom'
import { TableTaxObjects } from './table'
import { AddTaxObjects } from './add'
import { ViewTaxObjects } from './view'

export const BrowserTaxObjects = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableTaxObjects />} />
      <Route path={"/add"} element={<AddTaxObjects />} />
      <Route path={"/view"} element={<ViewTaxObjects />} />
    </Routes>
  )
}
