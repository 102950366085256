import { Route, Routes } from "react-router-dom"
import { AddCustomsKeyCodes } from "./add"
import { TableCustomsKeyCodes } from "./table"
import { ViewCustomsKeyCodes } from "./view"

export const BrowserCustomsKeyCodes= () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableCustomsKeyCodes/>}/>
            <Route path={"/add"} element={<AddCustomsKeyCodes/>}/>
            <Route path={"/view"} element={<ViewCustomsKeyCodes/>}/>
        </Routes>
    )
}