import { useEffect } from "react";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { ProjectQueue } from "../../table";
import { CircularProgress, List, ListItem } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

type LoadingScreenFunctionsProps = {
  projectsQueue: ProjectQueue[],
}

export const LoadingScreenFunctions = ({
  projectsQueue,
}: LoadingScreenFunctionsProps) => {

  useEffect(() => { }, [projectsQueue]);

  return (
    <>
      {projectsQueue.length === 0 && <Spinner isBox={false} />}
      {(projectsQueue.length > 0) &&
        <List>
          {projectsQueue.map((item) => {
            return <ListItem className="d-flex justify-content-between">
              <div>{item.data.project_name}</div> 
              <div>
                {item.status === 'inprogress' && <CircularProgress />} 
                {item.status === 'done' && <CheckCircleIcon fontSize="large"/>}
                {item.status === 'error' && <ErrorIcon fontSize="large"/>}
              </div>
            </ListItem>
          })}
        </List>}
    </>
  )
}
