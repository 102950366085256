import * as React from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridValidRowModel,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridColumnMenuColumnsItem,
  GridColumnMenuProps,
  GridColumnMenu,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TablePaginationProps, TextField } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import { useLocation } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Retention } from '../../../interfaces/Sales/Catalogs/retentions';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../Commons/SnackBar/useSnackBar';
import { PurchaseInvoiceDetail } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { SupplierItems } from '../../../interfaces/Purchases/Catalogs/suppliersItems';
import { CustomColumnOrderSalesInvoice } from '../GridTableMaterialUI/CustomColumnOrderSalesInvoice';

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  columns: GridColDef[],
  nameOrderColumns: string;
  setColumns: React.Dispatch<React.SetStateAction<GridColDef<any>[]>>;
  rowModesModel: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<PurchaseInvoiceDetail[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  isEdit?: boolean,
  myLocalInvoiceDocType: number,
  loadingPut: boolean
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  isEdit: boolean,
  includesForeignTradeComplement: boolean;
  myLocalInvoiceDocType: number,
  loadingPut: boolean
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, changeTotalValue, includesForeignTradeComplement } = props;
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [isLoading, setIsloading] = React.useState(false);
  const [comboItems, setComboItems] = React.useState<SupplierItems[]>([]);
  const [comboTaxes, setComboTaxes] = React.useState<Tax[]>([]);
  const [comboRetentions, setComboRetentions] = React.useState<Retention[]>([]);
  const [myItem, setMyitem] = React.useState<SupplierItems | null>(null);
  const [myTax, setMyTax] = React.useState<Tax | null>(null);
  const [myRetention, setMyRetention] = React.useState<Retention | null>(null);
  const [myQuantity, setMyQuantity] = React.useState<number>();
  const [myPrice, setMyPrice] = React.useState<number>();
  const [myDiscount, setMyDiscount] = React.useState<number>();
  const myQuantityRef = React.useRef<any>(null);
  const location = useLocation();
  const { GenericPostResource } = GenericPromises();

  React.useEffect(() => {
    let SupplierId;
    if (location.search.length > 0 && location.state === null) {
      const params = new URLSearchParams(location.search);
      SupplierId = params.get('supplier_id');
    }
    let myPromises = [
      GenericGetResource(`/supplieritems/bysupplierid/${((location.state && location.state.row.supplier_id) ?? SupplierId) ?? 0}`),
      GenericGetResource(`/taxes`),
      GenericGetResource(`/retentions`),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboItems(responses[0].data.data);
        setComboTaxes(responses[1].data.data);
        setComboRetentions(responses[2].data.data);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  const handleChange = (e: any, value: any) => {
    if (value === null) {
      setMyQuantity((prev) => 0);
      setMyPrice((prev) => 0);
    }
    else {
      myItem && myQuantityRef.current && myQuantityRef.current.focus()
      setMyPrice(comboItems.find((item: SupplierItems) => item.supplier_item_id === value.supplier_item_id)?.item_price ?? 0);
      setMyTax(comboTaxes.find((item: Tax) => item.tax_id === value.tax_id) ?? null);
      setMyRetention(comboRetentions.find((item: Retention) => item.retention_id === value.retention_id) ?? null);
    }
    setMyitem((prev) => value);
  };

  const handleChangeTax = (e: any, value: any) => {
    setMyTax((prev) => value);
  }

  const handleChangeRetention = (e: any, value: any) => {
    setMyRetention((prev) => value);
  }

  const handleChangeTextfields = (e: any) => {
    let { name, value } = e.target;
    if (name === 'price') {
      setMyPrice((prev) => value)
    }
    else if (name === 'quantity') {
      setMyQuantity((prev) => value);
    }
    else if (name === 'discount') {
      setMyDiscount((prev) => value);
    }
  }

  const AddRecord = () => {
    if (
      (myQuantity === undefined || myQuantity <= 0) ||
      (myPrice === undefined || myPrice <= 0) ||
      (parseFloat(myDiscount?.toString() ?? "") < 0) ||
      (parseFloat(myDiscount?.toString() ?? "") > 100)
    ) {
      showSnackBar(t("purchaseinvoicesdetails.snackbar.warning"), "warning");
    }
    else {
      setIsloading(true);
      let myPurchaseInvoicesDetail = {
        quantity: myQuantity,
        price_by_unit: myPrice,
        discount: myDiscount ?? 0,
        subtotal_tax_amount: null,
        subtotal_amount: 0,
        total_amount: myQuantity * myPrice,
        tax_id: myTax?.tax_id ?? null,
        tax_name: myTax?.tax_name ?? null,
        tax_percent: myTax?.tax_percent ?? null,
        tax_code: myTax?.tax_code ?? null,
        name_item_family: null,
        name_item_type: null,
        item_weight: myItem?.weight ?? null,
        item_description: myItem?.item_description,
        item_code: myItem?.item_code,
        item_id: myItem?.item_id,
        unit_id: myItem?.unit_id,
        unit_code: myItem?.unit_code,
        unit_description: myItem?.unit_description,
        unit_symbol: myItem?.unit_symbol,
        international_unit_symbol: myItem?.international_unit_symbol,
        tax_object: myItem?.tax_object,
        product_service_key: myItem?.product_service_key,
        retention_id: myRetention?.retention_id ?? null,
        retention_code: myRetention?.retention_code ?? null,
        retention_name: myRetention?.retention_name ?? null,
        retention_percent: myRetention?.retention_percent ?? null,
        purchase_invoice_id: location.state.row.purchase_invoice_id,
      }
      if (props.myLocalInvoiceDocType === 2) {
        myPurchaseInvoicesDetail.price_by_unit = (myPurchaseInvoicesDetail.price_by_unit && myPurchaseInvoicesDetail.price_by_unit > 0) ? - myPurchaseInvoicesDetail.price_by_unit : myPurchaseInvoicesDetail.price_by_unit;
        myPurchaseInvoicesDetail.subtotal_amount = (myPurchaseInvoicesDetail.subtotal_amount && myPurchaseInvoicesDetail.subtotal_amount > 0) ? - myPurchaseInvoicesDetail.subtotal_amount : myPurchaseInvoicesDetail.subtotal_amount;
        myPurchaseInvoicesDetail.total_amount = (myPurchaseInvoicesDetail.total_amount && myPurchaseInvoicesDetail.total_amount > 0) ? - myPurchaseInvoicesDetail.total_amount : myPurchaseInvoicesDetail.total_amount;
      }
      GenericPostResource("/purchaseinvoicesdetails", myPurchaseInvoicesDetail)
        .then((response) => {
          setRows((oldRows) => [...oldRows, {
            purchase_invoice_detail_id: response.data.purchase_invoice_detail_id,
            item_code: myItem?.item_code,
            item_description: myItem?.item_description,
            quantity: myQuantity,
            price_by_unit: response.data.price_by_unit,
            unit_code: myItem?.unit_code,
            total_amount: response.data.total_amount,
            tax_name: myTax?.tax_name,
            tax_percent: myTax?.tax_percent,
            tax_code: myTax?.tax_code,
            retention_name: myRetention?.retention_name,
            retention_percent: myRetention?.retention_percent,
            discount: response.data.discount ?? 0,
          }]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [response.data.purchase_invoice_detail_id]: { mode: GridRowModes.View },
          }));
          changeTotalValue(myPurchaseInvoicesDetail.total_amount, 0, 0);
          setIsloading(false);
          setMyitem((prev) => null);
          setMyQuantity((prev) => undefined);
          setMyPrice((prev) => undefined);
          setMyDiscount((prev) => undefined);
        }).catch((error) => {
          showSnackBar(error.message, "error");
          setIsloading(false);
        });
    }
  }

  return (
    <GridToolbarContainer className='d-flex p-1 w-100'>
      {props.isEdit &&
        <>
          <Autocomplete
            value={myItem || null}
            size="small"
            sx={{ flex: 2 }}
            options={comboItems}
            getOptionLabel={(option) => `${option.item_code} - ${option.item_description}`}
            renderOption={(props, option: SupplierItems) => (
              <div key={option.supplier_item_id}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.item_code} - {option.item_description}
                </Box>
              </div>
            )}
            isOptionEqualToValue={(option, value) => option.supplier_item_id === value.supplier_item_id}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("items.title-view")}`}
                variant="filled"
              />
            )}
            disabled={isLoading}
          />
          {myItem &&
            <div className='w-75 d-flex'>
              <TextField
                inputRef={myQuantityRef}
                variant="filled"
                name="quantity"
                label={`${t("purchaseinvoicesdetails.fields.quantity")}`}
                value={myQuantity || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
                }}
                type="number"
                disabled={isLoading}
              />
              <TextField
                variant="filled"
                name="price"
                label={`${t("purchaseinvoicesdetails.fields.price")}`}
                value={myPrice || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
                }}
                type="number"
                disabled={isLoading}
              />
              <Autocomplete
                value={myTax || null}
                size="small"
                sx={{ flex: 1, paddingRight: 1 }}
                options={comboTaxes}
                getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}`}
                defaultValue={undefined}
                renderOption={(props, option: Tax) => (
                  <div key={option.tax_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.tax_name} - {option.tax_percent}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
                onChange={handleChangeTax}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("taxes.title-view")}`}
                    variant="filled"
                  />
                )}
                disabled={isLoading}
              />
              <Autocomplete
                value={myRetention || null}
                size="small"
                sx={{ flex: 1, paddingRight: 1 }}
                options={comboRetentions}
                getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}`}
                defaultValue={undefined}
                renderOption={(props, option: Retention) => (
                  <div key={option.retention_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.retention_name} - {option.retention_percent}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
                onChange={handleChangeRetention}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("retentions.title-view")}`}
                    variant="filled"
                  />
                )}
                disabled={isLoading}
              />
              <TextField
                variant="filled"
                name="discount"
                label={`${t("purchaseinvoicesdetails.fields.discount")}`}
                value={myDiscount || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
                }}
                type="number"
                disabled={isLoading}
              />
              <PrimaryButton disabled={isLoading} variant='outlined' sx={{ flex: 1, padding: 1 }} onClick={AddRecord}><AddIcon /></PrimaryButton>
            </div>
          }
        </>
      }
      <SnackbarComponent />
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function TablePurchaseInvoiceDetail({
  rows,
  columns,
  setColumns,
  nameOrderColumns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  setRows,
  setRowModesModel,
  changeTotalValue,
  isEdit,
  myLocalInvoiceDocType
}: EditableTableProps) {
  const [t] = useTranslation("global");


  function CustomColumnsItems({ ...props }) {
    return <div>
      <CustomColumnOrderSalesInvoice columns={columns} setColumns={setColumns} nameOrderColumns={nameOrderColumns} />
      <GridColumnMenuColumnsItem colDef={props.colDef} onClick={props.onClick} />
    </div>
  }

  function CustomColumnMenuComponent(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: CustomColumnsItems,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => row["purchase_invoice_detail_id"]}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          columnMenu: CustomColumnMenuComponent,
          toolbar: EditToolbar,
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, changeTotalValue, isEdit, myLocalInvoiceDocType },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  );
}