import { Control, Controller, FieldErrors, UseFormGetValues, } from "react-hook-form"
import { Company } from "../../../interfaces/Security/companies"
import { useTranslation } from "react-i18next"
import { DatePicker } from '@mui/x-date-pickers';
import { FormsErrors } from "../../../hooks/Forms/FormsErrors"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Autocomplete, Box, Button, Switch, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import { ButtonLoading } from "../../../theme/buttons";
import { PurchaseInvoice } from "../../../interfaces/Purchases/Invoices/purchaseInvoices"
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers"
import dayjs from "dayjs";
import { useDates } from "../../../hooks/useDates";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { Currency } from "../../../interfaces/Commons/currencies";

type InvoiceHeaderProps = {
  control: Control<PurchaseInvoice>,
  errors: FieldErrors<PurchaseInvoice>,
  purchaseInvoiceLocal: PurchaseInvoice | undefined,
  company: Company | undefined,
  comboSupplierLocations: SupplierLocation[],
  defaultSupplierLocation: SupplierLocation | undefined,
  defaultSupplierBankAccount: SupplierBankAccount | undefined,
  comboSupplierBankAccounts: SupplierBankAccount[],
  getValues: UseFormGetValues<PurchaseInvoice>,
  locationEdit: boolean,
  setLocationEdit: Dispatch<SetStateAction<boolean>>,
  isEdit?: boolean,
  isNotExchange?: boolean,
  setIsNotExchange: Dispatch<SetStateAction<boolean>>,
  loading: boolean,
  disableKeyboardEntry: (e: any) => void,
  defaultDate: Date,
  defaultDateSupplier: Date | undefined,
  setCurrencySupplier: Dispatch<SetStateAction<Currency | undefined>>
}

export const UpdatePurchaseInvoicesHeader = ({
  setCurrencySupplier,
  control,
  errors,
  purchaseInvoiceLocal,
  company,
  defaultSupplierBankAccount,
  comboSupplierBankAccounts,
  defaultSupplierLocation,
  comboSupplierLocations,
  getValues,
  locationEdit,
  setLocationEdit,
  isEdit,
  isNotExchange,
  setIsNotExchange,
  loading,
  disableKeyboardEntry,
  defaultDate,
  defaultDateSupplier
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();
  const { GetError } = FormsErrors();
  const { TimeConverter } = useDates();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);

  const editLocation = () => {
    (locationEdit == true) ? setLocationEdit(false) : setLocationEdit(true)
  }

  useEffect(() => {
    setIsNotExchange(false)
  }, [locationEdit, defaultSupplierLocation, isNotExchange]);

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <div className='d-flex w-50'>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{purchaseInvoiceLocal?.supplier_business_name}</Typography>
              <Typography>{purchaseInvoiceLocal?.supplier_rfc}</Typography>
              <Typography>{getValues("supplier_street_name")} {(getValues("supplier_city_name")) ? "," : ""} {getValues("supplier_city_name")} {getValues("supplier_state_name") ? "," : ""} {getValues("supplier_state_name")}{getValues("supplier_country_name") ? "," : ""} {getValues("supplier_country_name")}<Button size="small" sx={{ flex: 1 }} onClick={editLocation}><EditIcon /></Button></Typography>
            </div>
            <div className="d-flex">
              {locationEdit &&
                <Controller
                  name="supplier_location_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ maxWidth: "100%", minWidth: "80%" }}
                      options={comboSupplierLocations}
                      defaultValue={defaultSupplierLocation}
                      getOptionLabel={(option) => `${option.business_name}, ${option.supplier_rfc} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                      renderOption={(props, option: SupplierLocation) => (
                        <div key={option.supplier_location_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.supplier_location_id === value.supplier_location_id}
                      onChange={(_, values) => {
                        field.onChange(values?.supplier_location_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("purchaseinvoices.fields.address")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.supplier_location_id}
                          helperText={GetError(errors.supplier_location_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              }
            </div>
          </Box>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{company?.business_name}</Typography>
              <Typography>{company?.rfc}</Typography>
              <Typography>{company?.phone_number}</Typography>
              <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
            </div>
          </Box>
        </div>
        {/* Right Box*/}
        <div className='d-flex flex-column w-50'>
          {/* first Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>
              {!isNotExchange &&
                <Controller
                  name="purchase_invoice_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      label={`${t("purchaseinvoices.fields.purchase_invoice_date")}`}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          sx: { paddingRight: 2 },
                          error: !!errors.purchase_invoice_date,
                          helperText: GetError(errors.purchase_invoice_date?.type),
                        }
                      }}
                      defaultValue={dayjs(TimeConverter(defaultDate.toString())) ?? dayjs()}
                      format="DD/MM/YYYY"
                      disableFuture
                      disabled={isEdit}
                    />
                  }
                />
              }
            </Box>
            <Box sx={{ flex: 9 }}>
              {!isNotExchange &&
                <Controller
                  name="supplier_sale_invoice_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker2}
                      onClose={() => setOpenDatePicker2(false)}
                      ref={field.ref}
                      label={`${t("purchaseinvoices.fields.supplier_sale_invoice_date")}`}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker2(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.supplier_sale_invoice_date,
                          helperText: GetError(errors.supplier_sale_invoice_date?.type),
                        }
                      }}
                      defaultValue={defaultDateSupplier ? dayjs(TimeConverter(defaultDateSupplier.toString())) : undefined}
                      format="DD/MM/YYYY"
                      disableFuture
                      disabled={isEdit}
                    />
                  }
                />
              }
            </Box>
            <Box sx={{ flex: 11 }} className="d-flex flex-column mx-2" >
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.tax_amount")}: {setFormatNumber({ value: getValues("tax_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.retention_amount")}: {setFormatNumber({ value: getValues("retention_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* second Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 18 }}>
              {!isNotExchange &&
                <Controller
                  name="supplier_bank_account_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ maxWidth: "100%", minWidth: "80%" }}
                      options={comboSupplierBankAccounts}
                      defaultValue={defaultSupplierBankAccount}
                      getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}, ${option.account_number} ${(option.swift_code) ? `, ${option.swift_code}` : ""}`}
                      renderOption={(props, option: SupplierBankAccount) => (
                        <div key={option.supplier_bank_account_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.account_alias} - {option.bank_name}, {option.account_number} {(option.swift_code) ? `, ${option.swift_code}` : ""}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => { return option.supplier_bank_account_id === value.supplier_bank_account_id }}
                      onChange={(_, values) => {
                        field.onChange(values?.supplier_bank_account_id || null)
                        let currency: Currency = {
                          currency_id: values?.currency_id ?? 0,
                          currency_description: values?.currency_description ?? "",
                          currency_code: values?.currency_code ?? ""
                        }
                        setCurrencySupplier(currency);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("purchaseinvoices.fields.supplier_bank_account_id")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.supplier_bank_account_id}
                          helperText={GetError(errors.supplier_bank_account_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              }
            </Box>
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.discount_amount")}:{setFormatNumber({ value: getValues("discount_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* Fourth row */}
          <div className="d-flex mt-2">
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("purchaseinvoices.fields.subtotal_amount")}: {setFormatNumber({ value: getValues("subtotal_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("purchaseinvoices.fields.total_amount")}: {setFormatNumber({ value: getValues("total_amount") ?? 0 })}</Typography>
            </Box>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-2">
        <div className="w-50">
          <ButtonLoading isLoading={loading} type="submit" size="small" className="d-flex align-self-end w-100" variant="outlined" disabled={isEdit}>{t("generic.buttons.save")}</ButtonLoading>
        </div>
        <div className="w-50"></div>
        <div className="w-25">
          <Controller
            name="is_accounted"
            control={control}
            render={({ field }) =>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                <Typography>{t("purchaseinvoices.fields.is_accounted")}</Typography>
                <Switch
                  ref={field.ref}
                  onChange={(_, e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  disabled={true}
                  checked={field.value}
                  defaultChecked={purchaseInvoiceLocal?.is_accounted} />
              </Box>
            }
          />
        </div>
      </div>
    </>
  )
}
