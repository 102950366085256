import { Route, Routes } from 'react-router-dom'
import { TableBankAcounts } from './table'
import { AddBankAccounts } from './add'
import { ViewBankAccounts } from './view'

export const BrowserBankAccounts = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableBankAcounts />} />
      <Route path={"/add"} element={<AddBankAccounts />} />
      <Route path={"/view"} element={<ViewBankAccounts />} />
    </Routes>
  )
}