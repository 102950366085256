import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ButtonLoading,  } from '../../../../theme/buttons';
import { Dispatch, SetStateAction } from 'react';

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onAcept: (e: any) => void,
  isLoading: boolean
}

export const DialogFiscalSignature = ({ open, setOpen, setIsLoading, onAcept, isLoading }: DialogProps) => {
  const [t] = useTranslation("global");

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {t("salesinvoices.dialog.fiscal.title")}
        </DialogTitle>
        <DialogActions>
          <Button variant="text" className="m-1" onClick={() => {setOpen(false); setIsLoading(false);}}>{t("generic.buttons.cancel")}</Button>
          <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="btn m-1" onClick={onAcept}>{t("generic.buttons.accept")}</ButtonLoading>
        </DialogActions>
      </Dialog>
    </>
  )
}
