import { Route, Routes } from 'react-router-dom'
import { TableChassis } from './table'
import { AddChassis } from './add'
import { ViewChassis } from './view'

export const BrowserChassis = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableChassis />} />
      <Route path={"/add"} element={<AddChassis />} />
      <Route path={"/view"} element={<ViewChassis />} />
    </Routes>
  )
}