import { Route, Routes } from 'react-router-dom'
import { TableProductServiceKeys } from './table'
import { AddProductServiceKeys } from './add'
import { ViewProductServiceKeys } from './view'

export const BrowserProductServiceKey = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableProductServiceKeys />} />
      <Route path={"/add"} element={<AddProductServiceKeys />} />
      <Route path={"/view"} element={<ViewProductServiceKeys />} />
    </Routes>
  )
}