import { useTranslation } from "react-i18next";
import { ReceiptDetail } from "../../../../interfaces/Sales/Receipts/receipts";
import { PaymentDetail } from "../../../../interfaces/Purchases/Payments/payments";
import { AccordionDetailStatuses } from "./components/accordionDetailStatuses";

type TabStatusesProps = {
  paymentsDetailsData: PaymentDetail[]
}

export const TabStatuses = ({
  paymentsDetailsData,
}: TabStatusesProps) => {
  const [t] = useTranslation("global");

  return (
    <>
      {paymentsDetailsData.map((paymentDetail, index) => {
        return <AccordionDetailStatuses key={index} title={paymentDetail.purchase_invoice_name ?? ""} payment_detail_id={paymentDetail.payment_detail_id ?? 0} />
      })}
    </>
  )
}
