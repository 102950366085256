import { Route, Routes } from 'react-router-dom'
import { TableWarehouses } from './table'
import { AddWarehouse } from './add'
import { ViewWarehouse } from './view'

export const BrowserWarehouses = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableWarehouses />} />
            <Route path={"/add"} element={<AddWarehouse />} />
            <Route path={"/view"} element={<ViewWarehouse />} />
        </Routes>
    )
}
