import { AlertColor, Grid } from "@mui/material"
import { ProjectExtraField } from "../../../interfaces/Projects/Catalogs/projectExtraFields"
import { ProjectExtraFieldDate, ProjectExtraFieldFormula, ProjectExtraFieldNumber, ProjectExtraFieldString } from "./components/ProjectExtraField";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Menuitem } from "../../../interfaces/Security/menu";

type ProjectExtraFieldsValuesProps = {
  projectExtraFieldsData: ProjectExtraField[];
  setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
  resourceScreen: Menuitem | undefined,
  isClosedProject: boolean,
  setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>,
  showSnackBar: (message: string, severity: AlertColor) => void,
}

export const ProjectExtraFieldsValues = ({
  projectExtraFieldsData,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
}: ProjectExtraFieldsValuesProps) => {

  useEffect(() => { }, [projectExtraFieldsData]);

  return (
    <Grid container spacing={1} wrap="wrap">
      {projectExtraFieldsData.map((extraField, index) => {
        switch (extraField.field_type_name?.toLowerCase()) {
          case 'date': {
            return <Grid item xs={12} sm={6} key={index}>
              <ProjectExtraFieldDate
                value={extraField}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceScreen={resourceScreen}
                isClosedProject={isClosedProject}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                showSnackBar={showSnackBar}
              />
            </Grid>
            break;
          }
          case 'string': {
            return <Grid item xs={12} sm={6} key={index}>
              <ProjectExtraFieldString
                value={extraField}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceScreen={resourceScreen}
                isClosedProject={isClosedProject}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                showSnackBar={showSnackBar}
              />
            </Grid>
            break;
          }
          case 'number': {
            return <Grid item xs={12} sm={6} key={index}>
              <ProjectExtraFieldNumber
                value={extraField}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceScreen={resourceScreen}
                isClosedProject={isClosedProject}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                showSnackBar={showSnackBar}
              />
            </Grid>
            break;
          }
          case 'formula': {
            return <Grid item xs={12} sm={6} key={index}>
              <ProjectExtraFieldFormula
                value={extraField}
                projectExtraFieldsData={projectExtraFieldsData}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceScreen={resourceScreen}
                isClosedProject={isClosedProject}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                showSnackBar={showSnackBar}
              />
            </Grid>
            break;
          }
          default:
            <Grid item xs={12} sm={6} key={index}>{"Not support"}</Grid>
            break;
        }
        return <Grid item xs={12} sm={6} key={index}>{"Not support"}</Grid>
      })}
    </Grid>
  )
}
