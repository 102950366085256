import { useEffect, useState } from "react";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { Warehouse } from "../../../interfaces/Inventory/warehouses";
import GenericPromises from "../../../api/GenericPromises";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { WarehouseItem } from "../../../interfaces/Inventory/warehouseItems";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";

export const AddItemWarehouses = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboWarehouses, setComboWarehouses] = useState<Warehouse[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<WarehouseItem>({
    defaultValues: {
      warehouse_id: undefined,
      stock: undefined,
    }
  });
  const onSubmit: SubmitHandler<WarehouseItem> = (data) => onPost(data);

  const onPost = (data: WarehouseItem) => {
    let myData = {
      item_id: props.item_id,
      warehouse_id: data.warehouse_id,
      stock: data.stock
    }
    setLoadingPost(true);
    GenericPostResource("/warehouseitems", myData)
      .then((response) => {
        let myWarehouse = comboWarehouses.find((warehouse: Warehouse) => warehouse.warehouse_id === myData.warehouse_id);
        let myNewRow = {
          ...response.data,
          warehouse_code: myWarehouse?.warehouse_code,
          warehouse_description: myWarehouse?.warehouse_description,
          item_code: props.item_code,
          item_description: props.item_description
        }
        props.AddNewRow(myNewRow);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/warehouses")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboWarehouses(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <TextField
                variant="filled"
                label={`${t("items.title-view")}`}
                value={`${props.item_code} - ${props.item_description}`}
                size="small"
                style={{ width: "100%" }}
                disabled={true}
              />
            </div>

            <Divider />

            <div className='mt-3 d-flex'>
              <div className='w-50'>
                <Controller
                  name="warehouse_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboWarehouses}
                      getOptionLabel={(option) => `${option.warehouse_code} - ${option.warehouse_description}`}
                      renderOption={(props, option: Warehouse) => (
                        <div key={option.warehouse_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.warehouse_code} - {option.warehouse_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.warehouse_id === value.warehouse_id}
                      onChange={(_, values) => {
                        field.onChange(values?.warehouse_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("warehouses.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.warehouse_id}
                          helperText={GetError(errors.warehouse_id?.type)}
                          sx={{ paddingRight: 2 }}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="stock"
                  control={control}
                  rules={{ required: true, min: 0 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("warehouseitems.fields.stock")}`}
                      type='number'
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }
                      }}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.stock}
                      helperText={GetError(errors.stock?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
