import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDates } from "../../../../hooks/useDates";
import GenericPromises from "../../../../api/GenericPromises";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import MoneyIcon from '@mui/icons-material/Money';
import EmailIcon from '@mui/icons-material/Email';
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { PaymentDetail, PaymentStatus } from "../../../../interfaces/Purchases/Payments/payments";
import { DialogPayment } from "../DialogPayment";
import { ViewPaymentDetails } from "./view";
import { DialogSendEmailsTable } from "../helpers/dialogSendEmailTable";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const TablePaymentsDetails = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [hiddenHeaderCheckBox, setHiddenHeaderCheckBox] = useState(true);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [clickedButtonGoBack, setClickedButtonGoBack] = useState(false);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [dataEmails, setDataEmails] = useState<any>([]);
  const [paymentDetailPayload, setPaymentDetailPayload] = useState<PaymentDetail>({});
  const [selectedRows, setSelectedRows] = useState<GridRowsProp>([]);
  const [paymentsDetailsData, setPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [paymentsDetailsDataAll, setPaymentsDetailsDataAll] = useState<GridRowsProp>([]);
  const [mySupplierId, setMySupplierId] = useState(0);
  const [myBusinessName, setMyBusinessName] = useState("");
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourcePayments, setResourcePayments] = useState<Menuitem>();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("authorizationpayments.comboEnum.authorized-n-nopaid"), route: `/paymentsdetails?filter=${btoa(`payment_id=null&is_authorized=True`)}` },
    { enum_id: 2, option_name: t("authorizationpayments.comboEnum.all"), route: `/paymentsdetails` },
    { enum_id: 3, option_name: t("authorizationpayments.comboEnum.onlypaid"), route: `/paymentsdetails?filter=${btoa(`payment_id!=null`)}` },
    { enum_id: 4, option_name: t("authorizationpayments.comboEnum.onlynopaid"), route: `/paymentsdetails?filter=${btoa(`payment_id=null`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[0]);
  const [defaultEnum, setDefaultEnum] = useState(comboEnum[0]);
  const [columns, setColumns] = useState<GridColDef<PaymentDetail>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("paymentsdetails.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
          window.open(`/projects/view?${stringConverted}`, '_blank');
        }}>
          {params.row.project_name}
        </Button>
      }
    },
    { field: 'supplier_business_name', headerName: t("purchaseinvoices.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'customer_business_name', headerName: t("projects.fields.customer"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'shipping_company', headerName: t("projects.fields.shipping_company"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'eta', headerName: t("projects.fields.eta"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    { field: 'notes', headerName: t("purchaseinvoices.fields.notes"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("paymentsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'is_authorized', headerName: t("paymentsdetails.fields.is_authorized"), headerClassName: 'header-grid-table', flex: 1, type: "boolean",
      renderCell(params) {
        return (params.row.is_authorized) ? <CheckCircleIcon /> : <CancelIcon />
      }
    },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("paymentsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: 'left', headerAlign: 'left',
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("paymentsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'user_mail_send', headerName: t("payments.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("payments.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    {
      field: 'payment_date', headerName: t("paymentsdetails.fields.payment_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-paymentsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-paymentsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          if (element.field === 'payment_date') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          if (element.field === 'date_mail_send') {
            element.headerName = t(`payments.fields.${element.field}`);
          }
          else if (element.field === 'eta') {
            element.headerName = t(`projects.fields.${element.field}`);
          }
        }
        else {
          element.headerName = t(`paymentsdetails.fields.${element.field}`);
          if (element.field === ('amount_outstanding' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'customer_business_name') { element.headerName = t(`projects.fields.customer`); }
          else if (element.field === 'shipping_company') { element.headerName = t(`projects.fields.shipping_company`); }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'supplier_business_name') {
            element.headerName = t(`paymentsdetails.fields.supplier_bussines_name`);
          }
          else if (element.field === 'eta') {
            element.headerName = t(`projects.fields.eta`);
          }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
          }
          if (element.field === 'date_mail_send') {
            element.headerName = t(`payments.fields.date_mail_send`);
          }
          else if (element.field === 'related_currency_code') {
            element.headerName = t(`currencies.title-view`);
          }
          else if (element.field === 'total_company_currency_amount') {
            element.headerName = t(`companies.configuration.fields.total_company_currency_amount`);
          }
          else if (element.field === 'user_mail_send') {
            element.headerName = t(`payments.fields.user_mail_send`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`purchaseinvoices.fields.purchase_invoice_name`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`payments.fields.purchase_invoice_name`);
            element.renderCell = function (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
                window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.purchase_invoice_name}
              </Button>
            }
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onSendEmail = async () => {
    let myDataEmails: any = [];
    const filteredRows = selectedRows.filter(element => element.payment_id && element.uuid);
    let mySupplier: any;
    if (filteredRows.length > 0) {
      mySupplier = await GenericGetResource(`/suppliers/${filteredRows[0].supplier_id}`);
    }
    filteredRows.forEach((element: any) => {
      let data = {
        data: {
          ...element,
          supplier_email: (mySupplier?.data.supplier_email) ?? '',
          supplier_language: (mySupplier?.data.supplier_language) ?? '',
        },
        status: (mySupplier?.data.supplier_email) ? "Waiting" : "Error",
        status_description: (mySupplier?.data.supplier_email) ? "generic.send_email.waiting" : "generic.send_email.no_email"
      }
      myDataEmails.push(data);
    });
    setDataEmails((prev: any) => myDataEmails);
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    if (rowsIds.length === 1) {
      if (hiddenHeaderCheckBox) {
        setPaymentsDetailsDataAll(paymentsDetailsData);
      }
      let myRow = paymentsDetailsData.find(item => item.payment_detail_id === rowsIds[0])

      setMyBusinessName(myRow?.supplier_business_name ?? "");
      setMySupplierId(myRow?.supplier_id ?? 0)
      setPaymentsDetailsData((prev) => prev.filter((item) => item.supplier_id === myRow?.supplier_id &&
        item.supplier_business_name === myRow?.supplier_business_name &&
        item.supplier_bank_account_id === myRow?.supplier_bank_account_id));
      setDataEmails(paymentsDetailsData.filter(payment => rowsIds.includes(payment.payment_detail_id)));
      setHiddenHeaderCheckBox(false);
    }
    else if (rowsIds.length === 0) {
      setPaymentsDetailsData(paymentsDetailsDataAll);
      setDataEmails([]);
      setHiddenHeaderCheckBox(true);
    }
    setSelectedRows((prev) => {
      return paymentsDetailsData.filter(item => rowsIds.includes(item.payment_detail_id));
    });
  }

  useEffect(() => {
    setDataLoaded(false);
    GenericGetResource(comboEnum[0].route)
      .then(
        (response) => {
          setPaymentsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/payments`),
            GetResourceByUrl(`/paymentsdetails`),
            GetResourceByUrl(`/paymentsdetails/sendemail`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourcePayments((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              setResourceSendEmail((prev) => responses[2]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
  }, [clickedButtonGoBack]);

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then((response) => {
        setPaymentsDetailsData(response.data.data);
        setSelectedRows((prev) => []);
        setIsLoadingDataTable(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("paymentsdetails.title")}`;
    })

    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/paymentsdetails`)
      .then(
        (response) => {
          setPaymentsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/payments`),
            GetResourceByUrl(`/paymentsdetails`),
            GetResourceByUrl(`/paymentsdetails/sendemail`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourcePayments((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              setResourceSendEmail((prev) => responses[2]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [paymentsDetailsData]);

  useEffect(() => { }, [hiddenHeaderCheckBox]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read &&
        <div className='screen-container'>
          <Header
            title={t("paymentsdetails.title")}
            child={
              <div className="d-flex flex-row-reverse">
                <PrimaryButton size="small" variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={(selectedRows?.length === 0) || !resourcePayments?.create} onClick={() => setDialogPayment(true)}>
                  {t("paymentsdetails.buttons.apply-payment")}<MoneyIcon sx={{ marginLeft: 1 }} />
                </PrimaryButton>
                {resourceSendEmail?.create === true &&
                  <ButtonStyled size="small" variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={selectedRows.length === 0} onClick={async () => { await onSendEmail(); setOpenEmail(true); }}>
                    {t("generic.buttons.send_email")}<EmailIcon sx={{ marginLeft: 1 }} />
                  </ButtonStyled>
                }
                <Autocomplete
                  size="small"
                  options={comboEnum}
                  defaultValue={defaultEnum}
                  getOptionLabel={(option) => option.option_name}
                  renderOption={(props, option) => (
                    <div key={option.enum_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.option_name}
                      </Box>
                    </div>
                  )}
                  sx={{ width: "20%", paddingRight: 1 }}
                  isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                  onChange={(_, values) => {
                    setMyEnumValue(values ?? { enum_id: 1, option_name: t("authorizationpayments.comboEnum.authorized-n-nopaid"), route: `/paymentsdetails?filter=${btoa(`payment_id=null&is_authorized=True`)}` });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("generic.filter")}`}
                      variant="filled"
                    />
                  )}
                  clearOnEscape={false}
                  clearIcon={null}
                />
              </div>
            }
          />
          <div className="d-flex justify-content-center mt-2">
            {!isLoadingDataTable && <Spinner />}
            {isLoadingDataTable &&
              <DataTable
                columns={columns}
                setColumns={setColumns}
                data={paymentsDetailsData}
                entityId={"payment_detail_id"}
                entity={`PaymentsDetails`}
                preferences={myPreferences}
                namePreferences={"grid-paymentsdetails"}
                nameOrderColumns={"grid-paymentsdetails-columns"}
                selectRows
                hiddenHeaderCheckBox={hiddenHeaderCheckBox}
                onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
                isChildren={true}
                setOpenDialog={setOpenDialogView}
                setDataRow={setPaymentDetailPayload}
                hasDoubleClick
                conditionalOpenRow={`payment_id`}
                countColumns={["total_company_currency_amount"]}
              />
            }
          </div>
          <DialogEntity
            open={openDialogView}
            setOpen={setOpenDialogView}
            title={
              <></>
            }
            fullWidth
            content={
              <ViewPaymentDetails
                paymentDetailPayload={paymentDetailPayload}
                permissionsPaymentsDetails={resourceScreen}
                permissionsPayments={resourcePayments}
                setOpenDialogView={setOpenDialogView}
                setClickedButtonGoBack={setClickedButtonGoBack}
              />
            }
          />
          <DialogEntity
            open={dialogPayment}
            title={<></>}
            fullWidth={true}
            content={
              <DialogPayment
                setSelectedRows={setSelectedRows}
                rows={paymentsDetailsData.filter((item) => !item.payment_name)}
                supplier_id={mySupplierId}
                businessName={myBusinessName}
                selectedRows={selectedRows}
                setDialogPayment={setDialogPayment}
                setClickedButtonGoBack={setClickedButtonGoBack}
              />
            }
          />
          <DialogSendEmailsTable
            open={openEmail}
            setOpen={setOpenEmail}
            dataPaymentsDetailsSelected={dataEmails}
            showSnackBar={showSnackBar}
          />
          <SnackbarComponent />

        </div>
      }
    </>
  )
}
