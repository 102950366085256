import { Route, Routes } from 'react-router-dom'
import { TableVehicleConfigurations } from './table'
import { AddVehicleConfigurations } from './add'
import { ViewVehicleConfigurations } from './view'

export const BrowserVehicleConfigurations = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableVehicleConfigurations />} />
      <Route path={"/add"} element={<AddVehicleConfigurations />} />
      <Route path={"/view"} element={<ViewVehicleConfigurations />} />
    </Routes>
  )
}