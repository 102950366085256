import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AddPaymentModes } from './add'
import { TablePaymentModes } from './table'
import { ViewPaymentModes } from './view'

export const BrowserPaymentModes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePaymentModes />} />
      <Route path={"/add"} element={<AddPaymentModes />} />
      <Route path={"/view"} element={<ViewPaymentModes />} />
    </Routes>
  )
}
