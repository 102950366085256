import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Project } from '../../../interfaces/Projects/projects';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../components/Header';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Customer } from '../../../interfaces/Sales/Catalogs/Customers/customers';
import { ProjectType } from '../../../interfaces/Projects/Catalogs/projectTypes';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import { User } from '../../../interfaces/Security/users';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { usePermissions } from '../../../hooks/usePermissions';

export const AddProjects = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
    const { GetResourceByUrl } = usePermissions();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
    const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
    const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
    const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
    const [comboUsers, setComboUsers] = useState<User[]>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const [projectNameDisabled, setProjectNameDisabled] = useState(true);
    const [projectNamePrefix, setProjectNamePrefix] = useState("");

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        trigger,
        watch
    } = useForm<Project>({
        defaultValues: {
            project_type_id: undefined,
            customer_id: undefined,
            project_manager_id: undefined,
            project_name: undefined,
            project_description: undefined,
            project_comments:undefined,
            project_start_date: undefined,
            project_end_date: undefined,
            project_is_closed : undefined,
            project_user_closed : undefined,
            project_user_date_closed : undefined,
        }
    });

    const onSubmit: SubmitHandler<Project> = (data) => onPost(data);

    const onPost = (data: Project) => {
      trigger().then(async (responseTrigger) => {
        if (responseTrigger && data.project_type_id) {
          let myData = {
            project_type_id: data.project_type_id,
            customer_id: data.customer_id,
            project_manager_id: data.project_manager_id,
            project_name: data.project_name,
            project_description: data.project_description ?? null,
            project_comments:data.project_comments ?? null, 
            project_start_date: data.project_start_date,
            project_end_date: data.project_end_date,
            project_is_closed : data.project_is_closed ?? false,
            project_user_closed : data.project_user_closed ?? null,
            project_user_date_closed : data.project_user_date_closed ?? null,
          };
          setLoadingPost(true);
          GenericPostResource("/projects", myData)
            .then(async (response) => {
              await navigate("/projects/view", { state: { row: response.data } });
            })
            .catch((error) => {
              setLoadingPost(false);
              showSnackBar(error.message, "error");
            });
        } else {
          showSnackBar(t("salesinvoices.info.no-data"), "warning");
        }
      });
    };

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/projects", { state: {} });
    }


    const disableKeyboardEntry = (e: any) => {
        if (e?.preventDefault) {
            e?.preventDefault();
            e?.stopPropagation();
        }
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("projects.title")}`;
        })

        let myPromises = [
            GenericGetResource("/projecttypes"),
            GenericGetResource("/customers"),
            GenericGetResourceGeneric("/users", "/gusers"),
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboProjectTypes(responses[0].data.data);
                setComboCustomers(responses[1].data.data);
                setComboUsers(responses[2].data.data)

                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, "error");
            })
    }, []);

    const customerId = watch("customer_id");

    useEffect(() => {
        if (customerId) {
            GenericGetResource(`/projects/getprojectsnumberbycustomerid/${customerId}`)
            .then((response) => {
                const customer = comboCustomers.find(c => c.customer_id === customerId);
                if (customer) {
                    const currentYear = new Date().getFullYear();
    
                    const projectNumberRaw = response.data.res + 1; 
    
                    let formattedProjectNumber;
                    if (projectNumberRaw != null) {
                        const projectNumberStr = projectNumberRaw.toString();
    
                        if (projectNumberStr.length === 1) {
                            formattedProjectNumber = '00' + projectNumberStr; 
                        } else if (projectNumberStr.length === 2) {
                            formattedProjectNumber = '0' + projectNumberStr; 
                        } else {
                            formattedProjectNumber = projectNumberStr; 
                        }
                    } else {
                        formattedProjectNumber = '000'; 
                    }
    
                    const fullPrefix = `${customer.customer_identifier}-${formattedProjectNumber}${currentYear}`
                    setProjectNamePrefix(fullPrefix);
                    setValue("project_name", fullPrefix);
                    setProjectNameDisabled(false);
                } else {
                    setValue("project_name", ""); 
                    setProjectNameDisabled(true);
                }
    
                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, "error");
            });
        } else {
            setValue("project_name", ""); // Limpiar el nombre del proyecto si no hay cliente seleccionado
            setProjectNameDisabled(true);
        }
    }, [customerId, comboCustomers, setValue]);
    

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <>
                    <Header title={t("projects.title-view")} />
                    <div className='d-flex justify-content-center'>
                        <div className='custom-card-view w-100 mt-4'>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
                                <div className="custom-card-row d-flex w-100">
                                    <div className="w-100">
                                        <div className='d-flex w-100'>
                                            <div className='w-50'>
                                                <Controller
                                                    name="project_type_id"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            ref={field.ref}
                                                            size="small"
                                                            sx={{ width: "100%", paddingRight: 2 }}
                                                            options={comboProjectTypes}
                                                            getOptionLabel={(option) => `${option.project_type_name} - ${option.project_type_description}`}
                                                            renderOption={(props, option: ProjectType) => (
                                                                <div key={option.project_type_id}>
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.project_type_name} - {option.project_type_description}
                                                                    </Box>
                                                                </div>
                                                            )}
                                                            isOptionEqualToValue={(option, value) => option.project_type_id === value.project_type_id}
                                                            onChange={(_, values) => {
                                                                field.onChange(values?.project_type_id || null)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={`${t("projects.fields.project_type")}`}
                                                                    variant="filled"
                                                                    value={field.value}
                                                                    error={!!errors.project_type_id}
                                                                    helperText={GetError(errors.project_type_id?.type)}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='w-50'>
                                                <Controller
                                                    name="customer_id"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            ref={field.ref}
                                                            size="small"
                                                            sx={{ width: "100%" }}
                                                            options={comboCustomers}
                                                            getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                                                            renderOption={(props, option: Customer) => (
                                                                <div key={option.customer_id}>
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                                                                    </Box>
                                                                </div>
                                                            )}
                                                            isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                                                            onChange={(_, values) => {
                                                                field.onChange(values?.customer_id || null)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={`${t("projects.fields.customer")}`}
                                                                    variant="filled"
                                                                    value={field.value}
                                                                    error={!!errors.customer_id}
                                                                    helperText={GetError(errors.customer_id?.type)}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 pt-3'>

                                            <div className='w-50'>
                                                <Controller
                                                    name="project_manager_id"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            ref={field.ref}
                                                            size="small"
                                                            sx={{ width: "100%", paddingRight: 2 }}
                                                            options={comboUsers}
                                                            getOptionLabel={(option) => `${option.user_name}`}
                                                            renderOption={(props, option: User) => (
                                                                <div key={option.user_id}>
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.user_name}
                                                                    </Box>
                                                                </div>
                                                            )}
                                                            isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                                            onChange={(_, values) => {
                                                                field.onChange(values?.user_id || null)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={`${t("projects.fields.project_manager")}`}
                                                                    variant="filled"
                                                                    value={field.value}
                                                                    error={!!errors.project_manager_id}
                                                                    helperText={GetError(errors.project_manager_id?.type)}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className='w-50'>
                                                <Controller
                                                    name="project_name"
                                                    control={control}
                                                    rules={{ required: true, maxLength: 50 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("projects.fields.project_name")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.project_name}
                                                            helperText={GetError(errors.project_name?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            InputProps={{ readOnly: projectNameDisabled }}
                                                        />
                                                    }
                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 pt-3'>
                                            <div className='w-50'>
                                                <Controller
                                                    name="project_description"
                                                    control={control}
                                                    rules={{ maxLength: 200 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("projects.fields.project_description")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.project_description}
                                                            helperText={GetError(errors.project_description?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className='w-50'>
                                                <Controller
                                                    name="project_start_date"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) =>
                                                        <DatePicker
                                                            open={openStartDateDatePicker}
                                                            onClose={() => setOpenStartDateDatePicker(false)}
                                                            ref={field.ref}
                                                            label={`${t("projects.fields.project_start_date")}`}
                                                            value={startDate ? dayjs(startDate) : null}
                                                            onChange={(values) => {
                                                                field.onChange(values?.toDate());
                                                                setStartDate((prev) => values?.toDate());
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    variant: "filled",
                                                                    size: "small",
                                                                    fullWidth: true,
                                                                    onClick: () => setOpenStartDateDatePicker(true),
                                                                    onBeforeInput: disableKeyboardEntry,
                                                                    error: !!errors.project_start_date,
                                                                    helperText: GetError(errors.project_start_date?.type),
                                                                }
                                                            }}
                                                            format="DD/MM/YYYY"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    <div className='d-flex w-100 pt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="project_end_date"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) =>
                                                    <DatePicker
                                                        open={openEndDateDatePicker}
                                                        onClose={() => setOpenEndDateDatePicker(false)}
                                                        ref={field.ref}
                                                        label={`${t("projects.fields.project_end_date")}`}
                                                        onChange={(values) => {
                                                            field.onChange(values?.toDate());
                                                            setEndDate((prev) => values?.toDate());
                                                        }}
                                                        value={endDate ? dayjs(endDate) : null}
                                                        slotProps={{
                                                            textField: {
                                                                variant: "filled",
                                                                size: "small",
                                                                fullWidth: true,
                                                                onClick: () => setOpenEndDateDatePicker(true),
                                                                onBeforeInput: disableKeyboardEntry,
                                                                error: !!errors.project_end_date,
                                                                helperText: GetError(errors.project_end_date?.type),
                                                            }
                                                        }}
                                                        minDate={dayjs(startDate)}
                                                        format="DD/MM/YYYY"
                                                        disabled={!startDate ? true : false}
                                                        sx={{paddingRight:2}}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                        <div className='d-flex w-100 pt-3'>
                                            <div className='w-100'>
                                                    <Controller
                                                        name="project_comments"
                                                        control={control}
                                                        rules={{ maxLength: 1000 }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant="filled"
                                                                label={`${t("projects.fields.project_comments")}`}
                                                                ref={field.ref}
                                                                value={field.value}
                                                                multiline
                                                                minRows={5}
                                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                                error={!!errors.project_comments}
                                                                helperText={GetError(errors.project_comments?.type)}
                                                                size="small"
                                                                style={{ width: "100%" }}
                                                            />
                                                        }
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-row-reverse mt-1'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                                    <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                                </div>
                            </form>
                        </div>
                    </div>
                    <SnackbarComponent />
                </>
            }
        </>
    )
}