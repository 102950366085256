import { Route, Routes } from 'react-router-dom'
import { TableTransportations } from './table'
import { AddTransportations } from './add'
import { ViewTransportations } from './view'


export const BrowserTransportations = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableTransportations />} />
      <Route path={"/add"} element={<AddTransportations />} />
      <Route path={"/view"} element={<ViewTransportations />} />
    </Routes>
  )
}
