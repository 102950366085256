import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { CustomKeyCode } from '../../../../interfaces/Sales/Catalogs/customsKeyCodes';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../../theme/buttons';
import { TextField } from '@mui/material';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateCustomsKeyCodes = ({ ...props }) => {

  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { customsKeyCodesPayload } = props;
  const isEdit = !props.isEdit;

  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CustomKeyCode>({
    defaultValues: {
      customs_key_code: '',
      customs_key_code_description: '',
    }
  });

  const onSubmit: SubmitHandler<CustomKeyCode> = (data) => onPut(data);

  const onPut = (data: CustomKeyCode) => {
    let myData = {
      customs_key_code: data.customs_key_code,
      customs_key_code_description: data.customs_key_code_description
    }
    setLoadingPost(true);
    GenericPutResource(`/customskeycodes/${customsKeyCodesPayload.customs_key_code_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    reset({
      customs_key_code: customsKeyCodesPayload.customs_key_code,
      customs_key_code_description: customsKeyCodesPayload.customs_key_code_description,
    });

    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="customs_key_code"
                        control={control}
                        rules={{ required: true, maxLength: 5 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("customskeycodes.fields.customs_key_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.customs_key_code}
                            helperText={GetError(errors.customs_key_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className="w-50">
                      <Controller
                        name="customs_key_code_description"
                        control={control}
                        rules={{ maxLength: 100 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("customskeycodes.fields.customs_key_code_description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.customs_key_code_description}
                            helperText={GetError(errors.customs_key_code_description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
