import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      // main: "#9E2E20",
      main: "#78C008",
    },
    secondary: {
      main: "#f3f6f4",
    },
  },
});