import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Reminder } from "../../../../../interfaces/Projects/Catalogs/reminders";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { Autocomplete, Box, TextField } from "@mui/material";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";

export const AddProjectStagesTaskRemindersTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const [comboReminderTypes, setComboReminderTypes] = useState<ReminderType[]>(
    []
  );
  const [comboReminders, setComboReminders] = useState<Reminder[]>([]);
  const { GetError } = FormsErrors();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(
    null
  );
  const [inputReminderName, setInputReminderName] = useState<Reminder | null>();
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } =
    GenericPromises();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [reminderType, setReminderType] = useState<ReminderType | null>();


  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<Reminder>({
    defaultValues: {
      reminder_type_id: undefined,
      reminder_name: undefined,
      reminder_description: undefined,
    },
  });
  const onSubmit: SubmitHandler<Reminder> = (data) => onPost(data);

  const onPost = (data: Reminder) => {
    let myData = {
      reminder_type_id: data.reminder_type_id,
      reminder_name: data.reminder_name,
      reminder_description: data.reminder_description,
    };
    if (selectedReminder) {
      createProjectStageTaskReminderTemplate(selectedReminder);
    } else {
      createNewReminder(myData);
    }
  };

  const createNewReminder = (data: Reminder) => {
    const reminderData = {
      reminder_type_id: data.reminder_type_id,
      reminder_name: data.reminder_name,
      reminder_description: data.reminder_description,
    };
    setLoadingPost(true);
    GenericPostResource("/reminders", reminderData)
      .then((response) => {
        createProjectStageTaskReminderTemplate(response.data);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const createProjectStageTaskReminderTemplate = (reminder: Reminder) => {
    const projectStageTaskReminderTemplateData = {
      project_stage_task_template_id: props.project_stage_task_template_id,
      reminder_id: reminder.reminder_id,
      project_stage_task_reminder_template_name: reminder.reminder_name,
      project_stage_task_reminder_template_description:
        reminder.reminder_description,
    };

    GenericPostResource(
      "/projectstagestaskreminderstemplate",
      projectStageTaskReminderTemplateData
    )
      .then((response) => {
        props.handleAddReminder(response.data)
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    props.setOpenDialog(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/remindertypes"),
      GenericGetResource("/reminders"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboReminderTypes(responses[0].data.data);
        setComboReminders(responses[1].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  const reminderNameField = watch("reminder_name");

  useEffect(() => {
    if (selectedReminder) {
      setValue("reminder_type_id", selectedReminder.reminder_type_id);
      setValue("reminder_name", selectedReminder.reminder_name);
      setValue("reminder_description", selectedReminder.reminder_description);
      setReminderType(
        comboReminderTypes.find(
          (remiderType) =>
            remiderType.reminder_type_id === selectedReminder.reminder_type_id
        )
      );
    }
  }, [reminderNameField]);

  return (
    <>
    {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="reminder_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboReminders}
                      getOptionLabel={(option) => `${option.reminder_name}`}
                      renderOption={(props, option: Reminder) => (
                        <div key={option.reminder_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.reminder_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.reminder_id === value?.reminder_id
                      }
                      value={inputReminderName || null}
                      onChange={(_, value) => {
                        if (value) {
                          field.onChange(value.reminder_name);
                          setInputReminderName(value);
                          setSelectedReminder(value);
                        }
                      }}
                      onInputChange={(_, newInputValue, reason) => {
                        if (reason != "reset") {
                          const newReminder: Reminder = {
                            reminder_id: 0,
                            reminder_type_id: 0,
                            reminder_name: newInputValue,
                            reminder_description: "",
                          };

                          setInputReminderName(newReminder);
                          setSelectedReminder(null);

                          const existingReminderIndex =
                            comboReminders.findIndex(
                              (r) => r.reminder_id === 0
                            );

                          if (existingReminderIndex !== -1) {
                            const updatedReminders = [...comboReminders];
                            updatedReminders[existingReminderIndex] =
                              newReminder;
                            setComboReminders(updatedReminders);
                          } else {
                            setComboReminders([...comboReminders, newReminder]);
                          }

                          field.onChange(newInputValue || "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "reminders.fields.reminder_name"
                          )}`}
                          variant="filled"
                          value={inputReminderName?.reminder_name || ""}
                          error={!!errors.reminder_name}
                          helperText={GetError(errors.reminder_name?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="reminder_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboReminderTypes}
                      getOptionLabel={(option) =>
                        `${option.reminder_type_name}`
                      }
                      renderOption={(props, option: ReminderType) => (
                        <div key={option.reminder_type_name}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.reminder_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.reminder_type_id === value.reminder_type_id
                      }
                      value={reminderType || null}
                      onChange={(_, values) => {
                        field.onChange(values?.reminder_type_id || null);
                        setReminderType(values || null);
                        setSelectedReminder(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "reminders.fields.reminder_type"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.reminder_type_id}
                          helperText={GetError(errors.reminder_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="reminder_description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "reminders.fields.reminder_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value || ""}
                      onChange={(event) => {
                        field.onChange(event.target.value || null);
                        setSelectedReminder(null);
                      }}
                      error={!!errors.reminder_description}
                      helperText={GetError(errors.reminder_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton type="submit" variant="outlined" className="m-1">
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
