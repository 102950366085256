import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PackagingType } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/packagingTypes';
import GenericPromises from '../../../../../api/GenericPromises';
import { Menuitem } from '../../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../../components/Header';
import { ButtonStyled } from '../../../../../theme/buttons';
import { Button } from '@mui/material';
import { UpdatePackagingTypes } from './update';
import { GenericDialog } from '../../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const ViewPackagingTypes = () => {


  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [packagingTypesLocal, setPackagingTypesLocal] = useState<PackagingType>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const {showSnackBar,SnackbarComponent} = useSnackBar();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/packagingtypes/${packagingTypesLocal?.packaging_type_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/packagingtypes", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/packagingtypes", { state: {}, replace: true });
    GenericGetResource(`/packagingtypes/${location.state.row.packaging_type_id}`)
      .then(
        (response) => {
          setPackagingTypesLocal(response.data);

          GetResourceByUrl(`/packagingtypes`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message,"error");
            });
        }
      ).catch((error) => {
        showSnackBar(error.message,"error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <div>
          <Header
            title={t("packagingtypes.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/packagingtypes", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />

          <div>
            <UpdatePackagingTypes packagingTypesPayload={packagingTypesLocal} isEdit={resourceScreen?.update} />
          </div>

          <GenericDialog
            title={t("generic.dialog.delete")}
            content={t("packagingtypes.dialog.delete")}
            open={openDialog}
            setOpen={setOpenDialog}
            onAcept={onDelete}
          />
          <SnackbarComponent/>
        </div>
      }
    </>
  )
}
