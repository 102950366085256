import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { CompanyBankAccount } from '../../../../../interfaces/Security/companies';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { GenericDialog } from '../../../../../components/Dialogs/Dialog';
import { BankAccount } from '../../../../../interfaces/Commons/bankAccounts';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';

export const UpdateCompanyBankAccounts = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { companyBankAccountPayload, permissions } = props;
  const isEdit = !permissions.update;
  const [comboBankAccount, setComboBankAccount] = useState<BankAccount[]>([]);
  const [bankAccountSelected, setBankAccountSelected] = useState<BankAccount>();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CompanyBankAccount>({
    defaultValues: {
      bank_account_id: undefined
    }
  });
  const onSubmit: SubmitHandler<CompanyBankAccount> = (data) => onPut(data);

  const onPut = (data: CompanyBankAccount) => {
    let myData = {
      bank_account_id: data.bank_account_id,
      company_id: props.company_id,
    }
    let myBankAccount = comboBankAccount.find((item: BankAccount) => item.bank_account_id === data.bank_account_id);
    setLoadingPost(true);
    GenericPutResource(`/companybankaccounts/${companyBankAccountPayload.company_bank_account_id}`, myData)
      .then((response) => {
        let myUpdateData: CompanyBankAccount = {
          account_alias: myBankAccount?.account_alias,
          bank_name: myBankAccount?.bank_name,
          card_number: myBankAccount?.card_number,
          account_number: myBankAccount?.account_number,
          interbank_key: myBankAccount?.interbank_key,
          swift_code: myBankAccount?.swift_code,
          phone_number: myBankAccount?.phone_number,
          ...response.data
        };

        props.UpdateRow(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }



  const onDelete = () => {
    GenericDeleteResource(`/companybankaccounts/${companyBankAccountPayload?.company_bank_account_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(companyBankAccountPayload?.company_bank_account_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      })
  }

  const onChangeBankAccount = (bank_account_id: number | undefined) => {
    setBankAccountSelected((prev) => comboBankAccount.find((item: BankAccount) => item.bank_account_id === bank_account_id));
  }

  useEffect(() => {
    GenericGetResource("/bankaccounts")
      .then((responses) => {
        setComboBankAccount((prev) => responses.data.data);
        reset({
          bank_account_id: companyBankAccountPayload.bank_account_id ?? undefined,
        });
        setBankAccountSelected(responses.data.data.find((bankAccount: BankAccount) => bankAccount.bank_account_id === companyBankAccountPayload.bank_account_id));
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
              <div className='w-100'>
                <div className='w-100 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="bank_account_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboBankAccount}
                          defaultValue={bankAccountSelected}
                          getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                          renderOption={(props, option: BankAccount) => (
                            <div key={option.bank_account_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.account_alias} - {option.bank_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.bank_account_id === value.bank_account_id}
                          onChange={(_, values) => {
                            field.onChange(values?.bank_account_id || null)
                            if (values?.bank_account_id !== (null || undefined)) {
                              onChangeBankAccount(values?.bank_account_id);
                            } else {
                              setBankAccountSelected(undefined);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("bankaccounts.fields.account_alias")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.bank_account_id}
                              helperText={GetError(errors.bank_account_id?.type)}
                              sx={{ paddingRight: 2 }}
                              disabled={isEdit}
                            />
                          )}
                        />
                      }
                    />
                  </div>

                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.bank_name")}`}
                      value={`${bankAccountSelected?.bank_name || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.card_number")}`}
                      value={`${bankAccountSelected?.card_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>

                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.account_number")}`}
                      value={`${bankAccountSelected?.account_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.interbank_key")}`}
                      value={`${bankAccountSelected?.interbank_key || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>

                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.swift_code")}`}
                      value={`${bankAccountSelected?.swift_code || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.bank_references")}`}
                      value={`${bankAccountSelected?.bank_references || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.phone_number")}`}
                      value={`${bankAccountSelected?.phone_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("currencies.title-view")}`}
                      value={`${bankAccountSelected?.currency_code || ""} ${bankAccountSelected?.currency_description || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.balance")}`}
                      value={bankAccountSelected?.balance}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                  <div>
                    <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                    {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                  </div>
                  {permissions.delete ?
                    <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                  }
                </div>
              </div>
            </form>
          </Box>
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("companybankaccounts.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
