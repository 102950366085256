import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { Chassis } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/chassis';
import { TrailerType } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/trailerTypes';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateChassis = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [comboTrailers, setComboTrailers] = useState<TrailerType[]>([])
    const [defaultTrailer, setDefaultTrailer] = useState<TrailerType>()
    const { chassisPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Chassis>({
        defaultValues: {
            chassis_plate: '',
            chassis_description: '',
            trailer_type_id: undefined,
        }
    });
    const onSubmit: SubmitHandler<Chassis> = (data) => onPut(data);

    const onPut = (data: Chassis) => {
        let myData = {
            chassis_plate: data.chassis_plate,
            chassis_description: data.chassis_description,
            trailer_type_id: data.trailer_type_id
        }
        setLoadingPost(true);
        GenericPutResource(`/chassis/${chassisPayload.chassis_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    useEffect(() => {
        reset({
            chassis_plate: chassisPayload.chassis_plate,
            chassis_description: chassisPayload.chassis_description,
            trailer_type_id: chassisPayload.trailer_type_id,
        });
        GenericGetResource("/trailertypes")
        .then((response) => {
            setDefaultTrailer(response.data.data.find((trailer: TrailerType) => trailer.trailer_type_id === chassisPayload.trailer_type_id));
            setComboTrailers(response.data.data);
            setDataLoaded(true);
        }).catch((error) => {
            showSnackBar(error.message, 'error');
        })
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                    <div className='d-flex w-100'>
                                        <div className='w-50'>
                                            <Controller
                                                name="chassis_plate"
                                                control={control}
                                                rules={{ required: true, maxLength: 10 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("chassis.fields.chassis_plate")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.chassis_plate}
                                                        helperText={GetError(errors.chassis_plate?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="chassis_description"
                                                control={control}
                                                rules={{ maxLength: 100, required: true}}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("chassis.fields.chassis_description")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.chassis_description}
                                                        helperText={GetError(errors.chassis_description?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mt-3'>
                                            <div className='w-50'>
                                                <Controller
                                                    name="trailer_type_id"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            ref={field.ref}
                                                            size="small"
                                                            sx={{ width: "100%" }}
                                                            options={comboTrailers}
                                                            defaultValue={defaultTrailer}
                                                            getOptionLabel={(option) => `${option.trailer_type_code} - ${option.trailer_type_name}`}
                                                            renderOption={(props, option: TrailerType) => (
                                                                <div key={option.trailer_type_id}>
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {`${option.trailer_type_code} - ${option.trailer_type_name}`}
                                                                    </Box>
                                                                </div>
                                                            )}
                                                            isOptionEqualToValue={(option, value) => option.trailer_type_id === value.trailer_type_id}
                                                            onChange={(_, values) => {
                                                                field.onChange(values?.trailer_type_id || null)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={`${t("trailertypes.title-view")}`}
                                                                    variant="filled"
                                                                    value={field.value}
                                                                    error={!!errors.trailer_type_id || field.value === null}
                                                                    helperText={(errors.trailer_type_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                                                    sx={{ paddingRight: 2 }}
                                                                />
                                                            )}
                                                            disabled={isEdit}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
