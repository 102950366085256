import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { ProjectStagesTaskReminderTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTaskRemindersTemplate";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import { Reminder } from "../../../../../interfaces/Projects/Catalogs/reminders";

export const UpdateProjectStagesTaskRemindersTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboReminderTypes, setComboReminderTypes] = useState<ReminderType[]>(
    []
  );
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(
    null
  );

  const [selectedReminderType, setSelectedReminderType] = useState<ReminderType | null>(
    null
  );
  const [comboReminders, setComboReminders] = useState<Reminder[]>([]);
  const [defaultRemainder, setDefaultRemainder] = useState<Reminder>();
  const [defaultRemainderType, setDefaultRemainderType] =
    useState<ReminderType>();



  const { permissions, projectStagesTaskRemindersTemplatePayload } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProjectStagesTaskReminderTemplate>();

  const onSubmit: SubmitHandler<ProjectStagesTaskReminderTemplate> = (data) =>
    onPut(data);

  const onPut = (data: ProjectStagesTaskReminderTemplate) => {
    let myData = {
      project_stage_task_template_id: props.project_stage_task_template_id,
      reminder_id: data.reminder_id,
      project_stage_task_reminder_template_name:
        data.project_stage_task_reminder_template_name,
      project_stage_task_reminder_template_description:
        data.project_stage_task_reminder_template_description,
    };

    GenericPutResource(
      `/projectstagestaskreminderstemplate/${projectStagesTaskRemindersTemplatePayload.project_stage_task_reminder_template_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectStagesTaskReminderTemplate = response.data;
        props.handleUpdateReminder(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });

  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstagestaskreminderstemplate/${projectStagesTaskRemindersTemplatePayload.project_stage_task_reminder_template_id}`
    )
      .then(() => {
        setOpenDialog(false);
        props.handleDeleteReminder(
          projectStagesTaskRemindersTemplatePayload.project_stage_task_reminder_template_id
        );
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/reminders"),
      GenericGetResource("/remindertypes"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboReminders(responses[0].data.data);
        setComboReminderTypes(responses[1].data.data);

        reset({
          reminder_id: projectStagesTaskRemindersTemplatePayload.reminder_id,
          project_stage_task_reminder_template_name:
            projectStagesTaskRemindersTemplatePayload.project_stage_task_reminder_template_name,
          project_stage_task_reminder_template_description:
            projectStagesTaskRemindersTemplatePayload.project_stage_task_reminder_template_description,
        });


        let reminder = responses[0].data.data.find(
          (reminder: Reminder) =>
            reminder.reminder_id ==
            projectStagesTaskRemindersTemplatePayload.reminder_id
        );

        setDefaultRemainder(reminder);

        setDefaultRemainderType(
          responses[1].data.data.find(
            (reminderType: ReminderType) =>
              reminderType.reminder_type_id == reminder.reminder_type_id
          )
        );

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  const reminder = watch("reminder_id");

  useEffect(() => {
    if (selectedReminder) {
      // setValue(
      //   "project_stage_task_reminder_template_name",
      //   selectedReminder.reminder_name
      // );
      // setValue(
      //   "project_stage_task_reminder_template_description",
      //   selectedReminder.reminder_description
      // );
      setDefaultRemainderType(
        comboReminderTypes.find(
          (remiderType) =>
            remiderType.reminder_type_id === selectedReminder.reminder_type_id
        )
      );

    }
  }, [reminder]);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="reminder_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboReminders}
                      defaultValue={defaultRemainder}
                      getOptionLabel={(option) => `${option.reminder_name}`}
                      renderOption={(props, option: Reminder) => (
                        <div key={option.reminder_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.reminder_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.reminder_id === value.reminder_id
                      }
                      onChange={(_, values) => {
                        setSelectedReminder(values);
                        field.onChange(values?.reminder_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("reminders.fields.reminder_name")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.reminder_id}
                          helperText={GetError(errors.reminder_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <TextField
                  variant="filled"
                  label={`${t("reminders.fields.reminder_type")}`}
                  value={`${defaultRemainderType?.reminder_type_name || ""}`}
                  size="small"
                  style={{ width: "100%" }}
                  inputProps={{ readOnly: true }}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_task_reminder_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestaskreminderstemplate.fields.project_stage_task_reminder_template_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value || ""}
                      onChange={(event) => {
                        field.onChange(event.target.value || null);
                        setSelectedReminder(null);
                      }}
                      error={!!errors.project_stage_task_reminder_template_name}
                      helperText={GetError(
                        errors.project_stage_task_reminder_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_stage_task_reminder_template_description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "reminders.fields.reminder_description"
                      )}`}
                      ref={field.ref}
                      value={field.value || ""}
                      onChange={(event) => {
                        field.onChange(event.target.value || null);
                        setSelectedReminder(null);
                      }}
                      error={
                        !!errors.project_stage_task_reminder_template_description
                      }
                      helperText={GetError(
                        errors.project_stage_task_reminder_template_description
                          ?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => props.setOpenDialog(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions.delete ? (
                <PrimaryButton onClick={() => setOpenDialog(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("reminders.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
