import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { PurchaseInvoice } from '../../../../interfaces/Purchases/Invoices/purchaseInvoices';

type AnnotationsPurchaseInvoices = {
    control: Control<PurchaseInvoice>,
    errors: FieldErrors<PurchaseInvoice>,
    isEdit?: boolean,
}

export const AnnotationsPurchaseInvoices = ({
    control,
    errors,
    isEdit,
}: AnnotationsPurchaseInvoices) => {
    const { GetError } = FormsErrors();
    const [t] = useTranslation("global");

    return (
        <>
            <div className='w-100'>
                <Controller
                    name="notes"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                        <TextField
                            variant="filled"
                            label={`${t("purchaseinvoices.fields.notes")}`}
                            ref={field.ref}
                            value={field.value}
                            multiline
                            minRows={5}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.notes}
                            helperText={GetError(errors.notes?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={isEdit}
                        />
                    )}
                />
            </div>
        </>
    )
}