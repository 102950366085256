import { Route, Routes } from "react-router-dom"
import { TableCurrencies } from "./table"


export const BrowserCurrencies = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCurrencies />} />
    </Routes>
  )
}
