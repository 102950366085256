import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react'
import { PrimaryButton } from '../../../theme/buttons';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTranslation } from 'react-i18next';

export const CustomColumnOrder = ({ columns, setColumns, nameOrderColumns }) => {
    const [openDialogColumnOrder, setOpenDialogColumnOrder] = useState(false);
    const [myColumns, setMyColumns] = useState(columns);
    const [t] = useTranslation("global");

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(myColumns);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setMyColumns(items);
    }

    function setColumnsOrderLocalStorage() {
        localStorage.setItem(nameOrderColumns, JSON.stringify(myColumns));
    }

    return (
        <>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={() => { setOpenDialogColumnOrder(true); }}>
                    <ListItemIcon sx={{ marginInline: -1.5, marginLeft: -0.1 }}>
                        <LowPriorityIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ marginBlock: -1, marginLeft: -1 }}>
                        {t("generic.grid-table.column-orders")}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <Dialog
                open={openDialogColumnOrder}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>{t("generic.grid-table.column-orders")}</DialogTitle>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={'columns'}>
                        {(provided) => (
                            <DialogContent {...provided.droppableProps} ref={provided.innerRef}>
                                {myColumns.map((column, index) => {
                                    return (
                                        <Draggable key={column.field} draggableId={column.field.toString()} index={index}>
                                            {(provided) => (
                                                <ListItemButton
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{ marginBlock: 0.2, borderRadius: 1, boxShadow: 1, backgroundColor: 'white' }}>
                                                    <DragIndicatorIcon color='action' />{column.headerName}
                                                </ListItemButton>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </DialogContent>
                        )}
                    </Droppable>
                </DragDropContext>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setOpenDialogColumnOrder(false)}>{t("generic.buttons.cancel")}</Button>
                    <PrimaryButton variant="outlined" onClick={() => { setColumns(myColumns); setOpenDialogColumnOrder(false); setColumnsOrderLocalStorage(); }}>
                        {t("generic.buttons.accept")}
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
