import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import { Consecutive } from '../../../interfaces/Commons/consecutives';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { PrimaryButton } from '../../../theme/buttons';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { Header } from '../../../components/Header';
import { usePermissions } from '../../../hooks/usePermissions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';

export const TableConsecutive = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const location = useLocation();
    const { TimeConverter }= useDates();
    const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [unauthorized, setUnauthorized] = useState(true);
    const [ConsecutiveData, setConsecutiveData] = useState<GridRowsProp>([]);
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const [messageSnack, setMessageSnack] = useState("");
    const [myPreferences, setMyPreferences] = useState({});
    const { GetResourceByUrl } = usePermissions();
    const [columnsConsecutives, setColumnsConsecutives] = useState<GridColDef<Consecutive>[]>([
        { field: 'name', headerName: t("consecutives.fields.name"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'description', headerName: t("consecutives.fields.description"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'prefix', headerName: t("consecutives.fields.prefix"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'resource_name', headerName: t("consecutives.fields.resource_name"), headerClassName: 'header-grid-table', flex: 1, },
        {
            field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
        { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
    ]);


    const methodUsed = useCallback(() => {
        switch (location.state.method) {
            case "add":
                {
                    showSnackBar(t("generic.snackbar.add"), "success");
                    break;
                }
            case "delete":
                {
                    showSnackBar(t("generic.snackbar.delete"), "success");
                    break;
                }
        }

    }, [location.state, t]);

    const loadPreferences = async () => {
        let myPreferences = await localStorage.getItem("grid-consecutives");
        if (myPreferences != null) {
            setMyPreferences(JSON.parse(myPreferences));
        }
    }

    const loadColumnsOrder = async () => {
        let myOrderColumns = await localStorage.getItem("grid-consecutives-columns");
        if (myOrderColumns != null) {
            let myJson = JSON.parse(myOrderColumns);
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];
                if (element['type'] === 'date' && (element.field === 'start_date' || element.field === 'end_date')) {
                    element.headerName = t(`consecutives.fields.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<Consecutive, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                else if (element['type'] === 'date') {
                    element.headerName = t(`generic.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<Consecutive, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                if (element.field) {
                }
                else {
                    element.headerName = t(`consecutives.fields.${element.field}`);
                }
            }
            setColumnsConsecutives(myJson);
        }
    }



    useEffect(() => {

        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("consecutives.title")}`;
        })

        if (location.state !== null && location.state.method) methodUsed();
        GenericGetResource("/consecutives")
            .then(
                (response) => {
                    setConsecutiveData(response.data.data);

                    GetResourceByUrl(`/consecutives`)
                        .then((response1) => {
                            setResourceScreen((prev) => response1);
                            loadColumnsOrder();
                            loadPreferences();
                            setDataLoaded(true);
                        })
                        .catch((error) => {
                            setMessageSnack(error.message);
                            showSnackBar(error.message, 'error');
                            setUnauthorized(false);
                        });
                }
            ).catch((error) => {
                setMessageSnack(error.message);
                showSnackBar(error.message, 'error');
                setUnauthorized(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
            {unauthorized && !dataLoaded && <Spinner />}
            {dataLoaded && resourceScreen?.read && <div className='screen-container'>
                <Header
                    title={t("consecutives.title")}
                    child={
                        <div className='d-flex flex-row-reverse my-1'>
                            {resourceScreen?.create === true &&
                                <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
                            }
                        </div>
                    }
                />

                <div className="d-flex justify-content-center">
                    <DataTable
                        columns={columnsConsecutives}
                        setColumns={setColumnsConsecutives}
                        data={ConsecutiveData}
                        entityId={"consecutive_id"}
                        entity={`Consecutive`}
                        preferences={myPreferences}
                        namePreferences={"grid-consecutives"}
                        nameOrderColumns={"grid-consecutives-columns"}
                    />
                </div>
                <SnackbarComponent />
            </div>
            }
        </>
    )
}