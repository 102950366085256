import { User } from "../interfaces/Security/users";


export interface AuthState {
    status: 'checking' | 'ahutenticated' | 'not-authenticated';
    token: string | null;
    errorMessage: string;
    user: User | null;
    resources: Map<String, number> | null;
}

type AuthAction =
    | { type: 'signIn', payload: { token: string, user: User, resources: Map<String, number> | null } }
    | { type: 'addError', payload: string }
    | { type: 'addResources', payload: Map<String, number> }
    | { type: 'notAuthenticated' }
    | { type: 'logOut' }

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {

    switch (action.type) {
        case 'addError':
            return {
                ...state,
                user: null,
                status: 'not-authenticated',
                token: null,
                errorMessage: action.payload,
            }
        case 'addResources':
            return {
                ...state,
                resources: action.payload
            }
        case 'signIn':
            return {
                ...state,
                status: 'ahutenticated',
                token: action.payload.token,
                user: action.payload.user,
                resources: action.payload.resources,
            }
        case 'logOut':
        case 'notAuthenticated':
            return {
                ...state,
                status: 'not-authenticated',
                token: null,
                user: null,
                resources: null,
            }
        default:
            return state;
    }

}