import { useTranslation } from "react-i18next"
import { Box, TextField } from "@mui/material";
import { CancelledTransportationDocument } from "../../../../interfaces/Sales/Invoices/Cancellations/cancelledTransportationDocuments";

type GeneralProps = {
    transportationdocument: CancelledTransportationDocument | undefined;
}

export const General = ({
    transportationdocument
}: GeneralProps) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className='d-flex flex-column'>
        <div className="mt-2 d-flex">
          <div className="w-50">
              <TextField
                label={`${t("drivers.title-view")}`}
                variant="filled"
                value={transportationdocument?.driver_name}
                size="small"
                sx={ {width: "100%", paddingRight: 2} }
                InputProps={{
                  readOnly: true,
                  title: transportationdocument?.driver_name
                }}
              />
          </div>
          <div className="w-50">
              <TextField
                label={`${t("transportations.title-view")}`}
                variant="filled"
                value={`${transportationdocument?.transportation_code} - ${transportationdocument?.plate_number}`}
                size="small"  
                sx={ {width: "100%", paddingRight: 2} }
                InputProps={{
                  readOnly: true
                }}
              />
          </div>
        </div>
      </div>
    </>
  )
}
