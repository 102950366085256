import { Route, Routes } from 'react-router-dom'
import { TableItems } from './table'
import { AddItems } from './add'
import { ViewItems } from './view'

export const BrowserItems = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableItems />} />
            <Route path={"/add"} element={<AddItems />} />
            <Route path={"/view"} element={<ViewItems />} />
        </Routes>
    )
}