import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../../components/Header';
import { Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';
import { HazardousMaterialKey } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/hazardousMaterialsKeys';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const AddHazardousMaterialsKeys = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<HazardousMaterialKey>({
        defaultValues: {
            hazardous_material_key: '',
            hazardous_material_description: '',
        }
    });
    const onSubmit: SubmitHandler<HazardousMaterialKey> = (data) => onPost(data);

    const onPost = (data: HazardousMaterialKey) => {
        let myData = {
            hazardous_material_key: data.hazardous_material_key,
            hazardous_material_description: data.hazardous_material_description,
        }
        setLoadingPost(true);
        GenericPostResource("/hazardousmaterialskeys", myData)
            .then((response) => {
                navigate("/hazardousmaterialskeys", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/hazardousmaterialskeys", { state: {} });
    }
    
    useEffect(()=>{
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("hazardousmaterialskeys.title")}`;
        })
    },[])

    return (
        <>
            <Header title={t("hazardousmaterialskeys.title-view")} />
            <div className='d-flex justify-content-center'>

                <div className='custom-card-view w-75'>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                        <div className="custom-card-row d-flex justify-content-between">
                            <div className="w-100">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="hazardous_material_key"
                                            control={control}
                                            rules={{ required: true, maxLength: 4 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("hazardousmaterialskeys.fields.hazardous_material_key")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.hazardous_material_key}
                                                    helperText={GetError(errors.hazardous_material_key?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="hazardous_material_description"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("hazardousmaterialskeys.fields.hazardous_material_description")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.hazardous_material_description}
                                                    helperText={GetError(errors.hazardous_material_description?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                    <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

            </form>

        </div >

      </div >
    <SnackbarComponent />
    </>
  )
}
