import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../components/Header';
import { Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import { SnackbarComponent } from '../../../components/Commons/SnackBar';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Language } from '../../../interfaces/Commons/languages';

export const AddLanguages = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Language>({
        defaultValues: {
            language_code: '',
            language_description: '',
        }
    });
    const onSubmit: SubmitHandler<Language> = (data) => onPost(data);

    const onPost = (data: Language) => {
        let myData = {
            language_code: data.language_code,
            language_description: data.language_description
        }
        setLoadingPost(true);
        GenericPostResource("/languages", myData)
            .then((response) => {
                navigate("/languages", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/languages", { state: {} });
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("languages.title")}`;
        })
    }, [])

    return (
        <>
            <Header title={t("languages.title-view")} />
            <div className='d-flex justify-content-center'>

                <div className='custom-card-view w-75'>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                        <div className="custom-card-row d-flex justify-content-between">
                            <div className="w-100">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="language_code"
                                            control={control}
                                            rules={{ required: true, maxLength: 5 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("languages.fields.language_code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.language_code}
                                                    helperText={GetError(errors.language_code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="language_description"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("languages.fields.language_description")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.language_description}
                                                    helperText={GetError(errors.language_description?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>

                    </form>

                </div >

            </div >
            <SnackbarComponent />
        </>
    )
}
