import { Route, Routes } from 'react-router-dom'
import { TableSuppliers } from './table'
import { AddSupplier } from './add'
import { ViewSupplier } from './view'

export const BrowserSuppliers = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableSuppliers />} />
            <Route path={"/add"} element={<AddSupplier />} />
            <Route path={"/view"} element={<ViewSupplier />} />
        </Routes>
    )
}