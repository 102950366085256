import { useEffect, useState } from "react"
import { TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from "../../../api/GenericPromises";
import { PrimaryButton } from "../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { ItemFamily } from '../../../interfaces/Commons/items';
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const UpdateItemFamilies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { itemFamilyPayload } = props;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ItemFamily>({
    defaultValues: {
      item_family_name: "",
      item_family_description: "",
      item_family_code: undefined
    }
  });
  const onSubmit: SubmitHandler<ItemFamily> = (data) => onPut(data);

  const onPut = (data: ItemFamily) => {
    let myData = {
      item_family_name: data.item_family_name,
      item_family_code: data.item_family_code,
      item_family_description: data.item_family_description ?? null
    }
    setLoadingPost(true);
    GenericPutResource(`/itemfamilies/${data.item_family_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    reset({
      item_family_id: itemFamilyPayload.item_family_id,
      item_family_name: itemFamilyPayload.item_family_name,
      item_family_code: itemFamilyPayload.item_family_code,
      item_family_description: itemFamilyPayload.item_family_description,
    });

    setDataLoaded(true);
  }, [])

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>

          <div className='custom-card-view w-50'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row">
                <Controller
                  name="item_family_name"
                  control={control}
                  rules={{ required: true, maxLength: 20 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.item_family_name}
                      label={t("item-families.fields.item_family_name")}
                      helperText={GetError(errors.item_family_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className="custom-card-row">
                <Controller
                  name="item_family_code"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      label={t("item-families.fields.item_family_code")}
                      error={!!errors.item_family_code}
                      helperText={GetError(errors.item_family_code?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className="custom-card-row">
                <Controller
                  name="item_family_description"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      label={t("item-families.fields.item_family_description")}
                      error={!!errors.item_family_description}
                      helperText={GetError(errors.item_family_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className='d-flex flex-row-reverse mt-4'>
                <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
              </div>

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
