import { Route, Routes } from 'react-router-dom'
import { TableTaxes } from './table'
import { AddTaxes } from './add'
import { ViewTaxes } from './view'

export const BrowserTaxes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableTaxes />} />
      <Route path={"/add"} element={<AddTaxes />} />
      <Route path={"/view"} element={<ViewTaxes />} />
    </Routes>
  )
}
