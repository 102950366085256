import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { BankAccount } from "../../../interfaces/Commons/bankAccounts";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import { Currency } from "../../../interfaces/Commons/currencies";

export const AddSupplierBankAccounts = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboBankAccount, setComboBankAccount] = useState<BankAccount[]>([]);
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [bankAccountSelected, setBankAccountSelected] = useState<BankAccount>();
  const [itsNewBankAccount, setItsNewBankAccount] = useState(false)

  const [inputBankAccount, setInputBankAccount] = useState('');
  const [bankAccountCreated, setBankAccountCreated] = useState<BankAccount | null>(null);

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
    getValues,
  } = useForm<SupplierBankAccount>({
    defaultValues: {
      bank_account_id: undefined
    }
  });
  const onSubmit: SubmitHandler<SupplierBankAccount> = (data) => onPost(data);

  const onPost = (data: SupplierBankAccount) => {
    trigger().then(async (triggerData) => {
      if (!props.add) {
        let myComboBankAccounts = comboBankAccount;
        let bankAccountId: number | undefined = undefined;
        if (itsNewBankAccount) {
          if (!triggerData) {
            return;
          }
          setLoadingPost(true);
          let myDataBankAccount = {
            account_alias: bankAccountCreated?.account_alias ?? null,
            bank_name: data.bank_name,
            card_number: data.card_number ?? null,
            account_number: data.account_number ?? null,
            interbank_key: data.interbank_key ?? null,
            swift_code: data.swift_code ?? null,
            phone_number: data.phone_number ?? null,
            currency_id: data.currency_id ?? null,
            bank_references: data.bank_references ?? null,
            balance: data.balance ?? 0,
          }
          await GenericPostResource("/bankaccounts", myDataBankAccount).then((response) => {
            bankAccountId = response.data.bank_account_id;
            myComboBankAccounts = myComboBankAccounts.map(item =>
              item.bank_account_id === 0 ? { ...response.data } : item
            );
          }).catch((error) => {
            showSnackBar(error.message, "error");
            setLoadingPost(false);
            return;
          });
        }
        let myData = {
          bank_account_id: bankAccountId ?? data.bank_account_id,
          supplier_id: props.supplier_id,
        }
        let myBankAccount = myComboBankAccounts.find((item: BankAccount) => item.bank_account_id === bankAccountId ?? data.bank_account_id);
        GenericPostResource("/supplierbankaccounts", myData)
          .then((response) => {
            let myNewData: SupplierBankAccount = {
              account_alias: myBankAccount?.account_alias,
              bank_name: myBankAccount?.bank_name,
              card_number: myBankAccount?.card_number,
              account_number: myBankAccount?.account_number,
              interbank_key: myBankAccount?.interbank_key,
              swift_code: myBankAccount?.swift_code,
              phone_number: myBankAccount?.phone_number,
              ...response.data
            };
            props.AddNewRow(myNewData);
            props.setOpenDialog(false);
            showSnackBar(t("generic.snackbar.add"), "success")
            setLoadingPost(false);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setLoadingPost(false);
          });
      }
      else {
        if (!triggerData) {
          return;
        }
        let myDataBankAccount: SupplierBankAccount | undefined;
        if (itsNewBankAccount) {
          let myCurrency = comboCurrencies.find((element) => element.currency_id === data.currency_id);
          myDataBankAccount = {
            supplier_bank_account_id: props.numTemp,
            bank_account_id: 0,
            account_alias: bankAccountCreated?.account_alias,
            bank_name: data.bank_name,
            card_number: data.card_number,
            account_number: data.account_number,
            interbank_key: data.interbank_key,
            swift_code: data.swift_code,
            phone_number: data.phone_number,
            currency_id: data.currency_id,
            currency_code: myCurrency?.currency_code,
            currency_description: myCurrency?.currency_description,
            currency_symbol: data.currency_symbol,
            bank_references: data.bank_references,
            balance: data.balance ?? 0,
          }
        }
        else
          myDataBankAccount = comboBankAccount.find((element: BankAccount) => element.bank_account_id === data.bank_account_id)
        if (myDataBankAccount) {
          myDataBankAccount.supplier_bank_account_id = props.numTemp;
          props.AddNewRow(myDataBankAccount);
          props.setOpenDialog(false);
          showSnackBar(t("generic.snackbar.add"), "success")
          setLoadingPost(false);
        }
      }
    })
    setLoadingPost(false);
  }

  const handleAddTemporalBank = (account_alias: string) => {
    if (account_alias.length >= 50) {
      showSnackBar(t("bankaccounts.error.maxLength"), "error");
      return;
    }
    const existingTemporalBank = comboBankAccount.find(bank => bank.account_alias === account_alias);
    if (existingTemporalBank) return existingTemporalBank;

    const filteredBankAccounts = comboBankAccount.filter(element => element.bank_account_id !== 0);
    let myBankAccount: BankAccount = {
      bank_account_id: 0,
      account_alias: account_alias
    };
    setComboBankAccount([...filteredBankAccounts, myBankAccount]);
    setBankAccountCreated(myBankAccount);

    return myBankAccount;
  };

  const onCancel = () => {
    props.setOpenDialog(false);
  }

  const onChangeBankAccount = (bank_account_id: number | undefined) => {
    setBankAccountSelected((prev) => comboBankAccount.find((item: BankAccount) => item.bank_account_id === bank_account_id));
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/bankaccounts"),
      GenericGetResource("/currencies")
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboBankAccount((prev) => responses[0].data.data);
        setComboCurrencies((prev) => responses[1].data.data)
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <div className='w-100'>
              <div className='w-100 d-flex'>
                <div className='w-50'>
                  <Controller
                    name="bank_account_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboBankAccount}
                        value={comboBankAccount.find(option => option.bank_account_id === field.value) || null}
                        getOptionLabel={(option) => `${option.account_alias}${(option.bank_name) ? " - " + option.bank_name : ""}`}
                        renderOption={(props, option: BankAccount) => (
                          <div key={option.bank_account_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.account_alias}{(option.bank_name) ? " - " + option.bank_name : ""}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.bank_account_id === value?.bank_account_id}
                        onInputChange={(_, newInputValue) => {
                          setInputBankAccount(newInputValue);
                        }}
                        noOptionsText={
                          <Button onMouseDown={() => {
                            if (inputBankAccount.trim()) {
                              const newBankAccount = handleAddTemporalBank(inputBankAccount);
                              if (newBankAccount && newBankAccount.bank_account_id) {
                                field.onChange(newBankAccount.bank_account_id);
                                setValue("bank_account_id", newBankAccount.bank_account_id);
                                setItsNewBankAccount(true);
                              }
                            }
                          }}>
                            {t("bankaccounts.actions.create")}
                          </Button>
                        }
                        onChange={(_, values) => {
                          if (values) {
                            field.onChange(values.bank_account_id);
                            if (values.bank_account_id === 0) {
                              setItsNewBankAccount(true);
                            } else {
                              onChangeBankAccount(values.bank_account_id);
                              setBankAccountCreated(null);
                              setItsNewBankAccount(false);
                            }
                          } else {
                            setBankAccountSelected(undefined);
                          }
                        }}
                        onBlur={() => {
                          const selected = comboBankAccount.find(option => option.bank_account_id === field.value);
                          if (!selected) {
                            setValue("bank_account_id", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("bankaccounts.fields.account_alias")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.bank_account_id}
                            helperText={GetError(errors.bank_account_id?.type)}
                            sx={{ paddingRight: 2 }}
                          />
                        )}
                      />
                    }
                  />
                </div>
                <div className="w-50">
                  {itsNewBankAccount ?
                    <Controller
                      name="bank_name"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_name}
                          helperText={GetError(
                            errors.bank_name?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.bank_name")}`}
                      value={`${bankAccountSelected?.bank_name || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
              </div>

              <div className='w-100 d-flex mt-3'>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="card_number"
                      control={control}
                      rules={{ maxLength: 19 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.card_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.card_number}
                          helperText={GetError(
                            errors.card_number?.type
                          )}
                          size="small"
                          sx={{ paddingRight: 2 }}
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.card_number")}`}
                      value={`${bankAccountSelected?.card_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>

                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="account_number"
                      control={control}
                      rules={{ maxLength: 24 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.account_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.account_number}
                          helperText={GetError(
                            errors.account_number?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.account_number")}`}
                      value={`${bankAccountSelected?.account_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
              </div>

              <div className='w-100 d-flex mt-3'>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="interbank_key"
                      control={control}
                      rules={{ maxLength: 18 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          sx={{ paddingRight: 2 }}
                          label={`${t("bankaccounts.fields.interbank_key")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.interbank_key}
                          helperText={GetError(
                            errors.interbank_key?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.interbank_key")}`}
                      value={`${bankAccountSelected?.interbank_key || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>

                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="swift_code"
                      control={control}
                      rules={{ maxLength: 11 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.swift_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.swift_code}
                          helperText={GetError(
                            errors.swift_code?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.swift_code")}`}
                      value={`${bankAccountSelected?.swift_code || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
              </div>

              <div className='w-100 d-flex mt-3'>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="bank_references"
                      control={control}
                      rules={{ maxLength: 30 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_references")}`}
                          ref={field.ref}
                          sx={{ paddingRight: 2 }}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_references}
                          helperText={GetError(
                            errors.bank_references?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.bank_references")}`}
                      value={`${bankAccountSelected?.bank_references || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ maxLength: 20 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.phone_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.phone_number}
                          helperText={GetError(
                            errors.phone_number?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.phone_number")}`}
                      value={`${bankAccountSelected?.phone_number || ""}`}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
              </div>

              <div className='w-100 d-flex mt-3'>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="currency_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          options={comboCurrencies}
                          getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                          renderOption={(props, option: Currency) => (
                            <div key={option.currency_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.currency_code} - {option.currency_description}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                          onChange={(_, values) => {
                            field.onChange(values?.currency_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("currencies.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.currency_id}
                              helperText={GetError(errors.currency_id?.type)}
                            />
                          )}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("currencies.title-view")}`}
                      value={bankAccountSelected?.currency_code ? (`${bankAccountSelected?.currency_code} - ${bankAccountSelected?.currency_description}`) : ""}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      inputProps={{ readOnly: true, }}
                    />
                  }
                </div>
                <div className='w-50'>
                  {itsNewBankAccount ?
                    <Controller
                      name="balance"
                      control={control}
                      rules={{ required: true, min: 0 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.balance")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.balance}
                          helperText={GetError(
                            errors.balance?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                          type='number'
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", },
                          }}
                        />
                      )}
                    />
                    :
                    <TextField
                      variant="filled"
                      label={`${t("bankaccounts.fields.balance")}`}
                      value={bankAccountSelected?.balance}
                      size="small"
                      style={{ width: "100%" }}
                      inputProps={{ readOnly: true, }}
                      InputLabelProps={{ shrink: true }}
                    />
                  }
                </div>
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton onClick={() => { onPost(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>
          </form>
        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
