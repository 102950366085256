
export const useBase64 = () => {

  const utf8ToBase64 = (str: string): string => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  }

  return {
    utf8ToBase64,
  }
}
