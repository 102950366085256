import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../../theme/buttons";
import { PaymentCondition } from "../../../../interfaces/Sales/Catalogs/paymentConditions";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const UpdatePaymentConditions = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { paymentConditionLocal } = props;
    const isEdit = !props.isEdit;

    const {showSnackBar,SnackbarComponent}= useSnackBar();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<PaymentCondition>({
        defaultValues: {
            payment_condition_days: undefined,
            payment_condition_name: '',
            payment_condition_description: '',
        }
    });
    const onSubmit: SubmitHandler<PaymentCondition> = (data) => onPut(data);

    const onPut = (data: PaymentCondition) => {
        let myData = {
            payment_condition_days: data.payment_condition_days,
            payment_condition_name: data.payment_condition_name,
            payment_condition_description: data.payment_condition_description ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/paymentconditions/${paymentConditionLocal.payment_condition_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"),"success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message,"error");
            });
    }

    useEffect(() => {
        reset({
            payment_condition_days: paymentConditionLocal.payment_condition_days ?? undefined,
            payment_condition_name: paymentConditionLocal.payment_condition_name ?? undefined,
            payment_condition_description: paymentConditionLocal.payment_condition_description ?? undefined,
        });
        setDataLoaded(true);
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="payment_condition_days"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("paymentconditions.fields.payment_condition_days")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.payment_condition_days}
                                                    helperText={GetError(errors.payment_condition_days?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                                    }}
                                                    disabled={isEdit}
                                                    type="number"
                                                />
                                            }
                                        />
                                    </div>

                                    <div className='w-50'>
                                        <Controller
                                            name="payment_condition_name"
                                            control={control}
                                            rules={{ required: true, maxLength: 30 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("paymentconditions.fields.payment_condition_name")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.payment_condition_name}
                                                    helperText={GetError(errors.payment_condition_name?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="w-50">
                                        <Controller
                                            name="payment_condition_description"
                                            control={control}
                                            rules={{  maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    ref={field.ref}
                                                    variant="filled"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    label={t("paymentconditions.fields.payment_condition_description")}
                                                    error={!!errors.payment_condition_description}
                                                    helperText={GetError(errors.payment_condition_description?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>
                                </div>

                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent/>
        </>
    )
}