import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { Box, Button, TextField } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { useFiles } from '../../../../hooks/useFiles';
import { ImageDataComponent } from '../../../../components/Images/ImageData';
import { File } from '../../../../interfaces/Commons/files';
import DownloadIcon from '@mui/icons-material/Download';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import { Divider } from '../../../../components/Commons/Divider';
import { useDates } from '../../../../hooks/useDates';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const CancelledInvoicing = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { GetFiles } = useFiles();
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [salesInvoiceData, setSalesInvoiceData] = useState<CancelledSalesInvoice>();
  const { salesInvoiceLocal } = props;
  const [fiscalQr, setFiscalQr] = useState<File>({});
  const [fiscalXML, setFiscalXML] = useState<File>({ dataURL: '' });
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const onDownloadXML = () => {
    const downloadLink = document.createElement('a');
    const fileName = `${fiscalXML.file_name}.xml`;
    downloadLink.href = fiscalXML.dataURL ?? '';
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/cancelledsalesinvoices/${salesInvoiceLocal.cancelled_sales_invoice_id}`)
    ]
    Promise.all(myPromises)
      .then(async (responses) => {
        setSalesInvoiceData(responses[0].data);
        await GetFiles(salesInvoiceLocal.cancelled_sales_invoice_id, "CancelledSalesInvoices").then((responsefile) => {
          if (responsefile && responsefile.length > 0) {
            for (let i = 0; i < responsefile.length; i++) {
              const element: File = responsefile[i];
              if (element.file_id===salesInvoiceLocal.sales_invoice_QR) {
                setFiscalQr((prev) => element);
              }
              if (element.file_id===salesInvoiceLocal.cancelled_sales_invoice_XML) {
                setFiscalXML((prev) => element);
              }
            }
          }
          setDataLoaded(true);
        })
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-100'>
            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={4}
                      label={`${t("salesinvoices.fields.original_string_SAT")}`}
                      value={salesInvoiceData?.original_string_SAT}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.certificate_number_SAT")}`}
                      value={salesInvoiceData?.certificate_number_SAT}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.certificate_number_CFDI")}`}
                      value={salesInvoiceData?.certificate_number_CFDI}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.uuid")}`}
                      value={salesInvoiceData?.uuid}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.signing_date")}`}
                      value={(salesInvoiceData?.signing_date) ? `${TimeConverter(salesInvoiceData?.signing_date.toString())?.toLocaleDateString()} ${TimeConverter(salesInvoiceData?.signing_date.toString())?.toTimeString()}` : ''}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={3}
                      label={`${t("salesinvoices.fields.seal_SAT")}`}
                      value={salesInvoiceData?.seal_SAT}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={3}
                      label={`${t("salesinvoices.fields.seal_CFDI")}`}
                      value={salesInvoiceData?.seal_CFDI}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <Divider />
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_date")}`}
                      value={(salesInvoiceData?.cancellation_date) ? `${TimeConverter(salesInvoiceData?.cancellation_date.toString())?.toLocaleDateString()} ${TimeConverter(salesInvoiceData?.cancellation_date.toString())?.toTimeString()}` : ''}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_reason")}`}
                      value={salesInvoiceData?.cancellation_reason_description}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_response_code")}`}
                      value={salesInvoiceData?.cancellation_response_code}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      sx={{ paddingRight: 2 }}
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ marginLeft: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ImageDataComponent url={fiscalQr.dataURL} />
                <Button onClick={onDownloadXML} variant='contained' sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }} disabled={fiscalXML.dataURL === '' ?? true}>
                  <DownloadIcon />
                  {t("salesinvoices.buttons.xml")}
                </Button>
              </Box>
            </div>
          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
