import { GridColDef, GridInputRowSelectionModel, GridRenderCellParams, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValidRowModel, GridValueGetterParams } from "@mui/x-data-grid";
import { Payment, PaymentDetail } from "../../../../interfaces/Purchases/Payments/payments";
import { UseFormGetValues } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDates } from "../../../../hooks/useDates";
import GenericPromises from "../../../../api/GenericPromises";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";

type TableApplyPaymentProps = {
  rows: readonly GridValidRowModel[],
  getValues: UseFormGetValues<Payment>,
  setSelectedRows: Dispatch<SetStateAction<readonly GridValidRowModel[]>>,
  onChangeTotals: (myRows: readonly GridValidRowModel[]) => void,
  preSelectedRows: GridInputRowSelectionModel | undefined,
  setPreSelectedRows: Dispatch<SetStateAction<GridInputRowSelectionModel | undefined>>,
  loadingPost: boolean,
  isEdit: boolean
  hasErrorPost: boolean,
  isLoadingCurrenciesValues: boolean,
}

export const TableApplyPayment = ({
  rows,
  getValues,
  setSelectedRows,
  onChangeTotals,
  preSelectedRows,
  setPreSelectedRows,
  loadingPost,
  isEdit,
  hasErrorPost,
  isLoadingCurrenciesValues,
}: TableApplyPaymentProps) => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [paymentsDetailsData, setPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [auxPaymentsDetailsData, setAuxPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [myRowsIdsSelected, setMyRowsIdsSelected] = useState<GridRowSelectionModel>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const [columns, setColumns] = useState<GridColDef<PaymentDetail>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("paymentsdetails.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    { field: 'supplier_business_name', headerName: t("purchaseinvoices.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'notes', headerName: t("paymentsdetails.fields.notes"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("paymentsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("paymentsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("paymentsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value ?? null);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = localStorage.getItem("grid-paymentsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-paymentsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          if (element.field === 'payment_date') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          if (element.field === 'date_mail_send') {
            element.headerName = t(`payments.fields.${element.field}`);
          }
          else if (element.field === 'eta') {
            element.headerName = t(`projects.fields.${element.field}`);
          }
        }
        else {
          element.headerName = t(`paymentsdetails.fields.${element.field}`);
          if (element.field === ('amount_outstanding' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'customer_business_name') { element.headerName = t(`projects.fields.customer`); }
          else if (element.field === 'shipping_company') { element.headerName = t(`projects.fields.shipping_company`); }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'supplier_business_name') {
            element.headerName = t(`paymentsdetails.fields.supplier_bussines_name`);
          }
          else if (element.field === 'eta') {
            element.headerName = t(`projects.fields.eta`);
          }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
          }
          if (element.field === 'date_mail_send') {
            element.headerName = t(`payments.fields.date_mail_send`);
          }
          else if (element.field === 'related_currency_code') {
            element.headerName = t(`currencies.title-view`);
          }
          else if (element.field === 'total_company_currency_amount') {
            element.headerName = t(`companies.configuration.fields.total_company_currency_amount`);
          }
          else if (element.field === 'user_mail_send') {
            element.headerName = t(`payments.fields.user_mail_send`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`purchaseinvoices.fields.purchase_invoice_name`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`payments.fields.purchase_invoice_name`);
            element.renderCell = function (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
                window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.purchase_invoice_name}
              </Button>
            }
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    let myNewSelectedRows = paymentsDetailsData.filter(item => rowsIds.includes(item.payment_detail_id));
    setSelectedRows((prev) => myNewSelectedRows);
    setPreSelectedRows(rowsIds);
    setMyRowsIdsSelected(rowsIds);
    onChangeTotals(myNewSelectedRows);
  }

  useEffect(() => {
    if (loadingPost || (!loadingPost && getValues("payment_id"))) {
      setAuxPaymentsDetailsData((prev) => paymentsDetailsData);
      setPaymentsDetailsData((prev) => prev.filter((item) => myRowsIdsSelected.includes(item.payment_detail_id)));
      if (!hasErrorPost && getValues("payment_id")) {
        GenericGetResource(`/paymentsdetails/bypaymentid/${getValues("payment_id")}`)
          .then((responseDetails) => {
            setPaymentsDetailsData(responseDetails.data.data);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          })
      }
    }
    else if (!loadingPost && hasErrorPost) {
      setPaymentsDetailsData((prev) => auxPaymentsDetailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPost]);

  useEffect(() => {
    setDataLoaded(false);
    loadPreferences();
    loadColumnsOrder();
    const formattedRows: GridRowSelectionModel = preSelectedRows?.toString().split(',').map(id => Number(id)) as GridRowSelectionModel;
    if (getValues("uuid")) {
      setPaymentsDetailsData(rows.filter((item) => formattedRows.includes(item.payment_detail_id)));
      GenericGetResource(`/paymentsdetails/bypaymentid/${getValues("payment_id")}`)
        .then(async (responseDetails) => {
          setPaymentsDetailsData(responseDetails.data.data);
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    }
    else {
      setPaymentsDetailsData(rows);
      preSelectedRows && setMyRowsIdsSelected(formattedRows);
      setDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingCurrenciesValues) {
      setOpenBackDrop(true);
    }
    else {
      setOpenBackDrop(false);
    }
  }, [isLoadingCurrenciesValues])

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && <>
        <Box className='screen-container'>
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={paymentsDetailsData}
              entityId={"payment_detail_id"}
              entity={`PaymentsDetails`}
              preferences={myPreferences}
              namePreferences={"grid-paymentsdetails"}
              nameOrderColumns={"grid-paymentsdetails-columns"}
              selectRows={!isEdit}
              onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
              isChildren={true}
              preSelectedRows={!isEdit ? preSelectedRows : []}
              countColumns={["total_company_currency_amount"]}
            />
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <SnackbarComponent />
        </Box>
      </>
      }
    </>
  )
};
