import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MeasureUnitConversion } from '../../../interfaces/Commons/measureUnitConversions';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Unit } from '../../../interfaces/Commons/units';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const UpdateMeasureUnitConversions = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboUnits, setComboUnits] = useState<Unit[]>([])
  const [defaultUnitSource, setDefaultUnitSource] = useState<Unit>()
  const [defaultUnitTarget, setDefaultUnitTarget] = useState<Unit>()
  const { measureUnitConversionPayload } = props;

  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<MeasureUnitConversion>({
    defaultValues: {
      unit_source_id: undefined,
      unit_target_id: undefined,
      conversion_rate: undefined,
    }
  });
  const onSubmit: SubmitHandler<MeasureUnitConversion> = (data) => onPut(data);

  const onPut = (data: MeasureUnitConversion) => {
    let myData = {
      unit_source_id: data.unit_source_id,
      unit_target_id: data.unit_target_id,
      conversion_rate: data.conversion_rate,
    }
    setLoadingPost(true);
    GenericPutResource(`/measureunitconversions/${measureUnitConversionPayload.measure_unit_conversion_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }


  useEffect(() => {

    GenericGetResource("/units")
      .then((response) => {
        setComboUnits(response.data.data);

        reset({
          unit_source_id: measureUnitConversionPayload.unit_source_id,
          unit_target_id: measureUnitConversionPayload.unit_target_id,
          conversion_rate: measureUnitConversionPayload.conversion_rate,
        });

        setDefaultUnitSource(response.data.data.find((unit: Unit) => unit.unit_id === measureUnitConversionPayload.unit_source_id));
        setDefaultUnitTarget(response.data.data.find((unit: Unit) => unit.unit_id === measureUnitConversionPayload.unit_target_id));

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="unit_source_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboUnits}
                            defaultValue={defaultUnitSource}
                            getOptionLabel={(option) => `${option.unit_code} - ${option.unit_description}`}
                            renderOption={(props, option: Unit) => (
                              <div key={option.unit_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.unit_code} - {option.unit_description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                            onChange={(_, values) => {
                              field.onChange(values?.unit_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("measureunitconversions.fields.unit_source")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.unit_source_id}
                                helperText={GetError(errors.unit_source_id?.type)}
                                disabled={isEdit}

                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="unit_target_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboUnits}
                            defaultValue={defaultUnitTarget}
                            getOptionLabel={(option) => `${option.unit_code} - ${option.unit_description}`}
                            renderOption={(props, option: Unit) => (
                              <div key={option.unit_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.unit_code} - {option.unit_description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                            onChange={(_, values) => {
                              field.onChange(values?.unit_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("measureunitconversions.fields.unit_target")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.unit_target_id}
                                helperText={GetError(errors.unit_target_id?.type)}
                                disabled={isEdit}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 mt-3'>
                    <div className='w-100'>
                      <Controller
                        name="conversion_rate"
                        control={control}
                        rules={{ required: true, maxLength: 23 }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            variant="filled"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                              paddingRight: 2
                            }}
                            type="number"
                            value={field.value}
                            onChange={field.onChange}
                            label={t("measureunitconversions.fields.conversion_rate")}
                            error={!!errors.conversion_rate}
                            helperText={GetError(errors.conversion_rate?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
