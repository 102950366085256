import { useNavigate } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ResourceEntities from './resourceEntities';
import { prefix } from '@fortawesome/free-solid-svg-icons';


interface AccordionItemInterface {
    title: string;
    resource_name?: string | null;
    resource_id?: number | null;
    itemName?: string | null;
    open?: boolean;
    level?: number;
}

export const AccordionItemMenu = ({ title, resource_name, itemName, open, level }: AccordionItemInterface) => {
    const navigate = useNavigate();
    const GetResource = ResourceEntities();

    const startsWithEnty = (str: string): boolean => {
        const lowerCaseStr = str.toLowerCase();
        if (lowerCaseStr.startsWith('enty'))
            return true;
        return false
    };

    if (resource_name) {
        return (
            <div className='open'>
                <ListItem disablePadding sx={
                    (startsWithEnty(resource_name) && level === 3) ? { display: 'block', width: '115%', backgroundColor: 'secondary.main' } :
                        (level === 1) ? { display: 'block', width: '115%', backgroundColor: 'none' } : { display: 'block', width: '115%', backgroundColor: 'white' }}
                    onClick={() => {
                        navigate(`${GetResource(resource_name!)?.component}`)
                    }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 3,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 4 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={GetResource(resource_name)?.translation} placement="right-start" disableFocusListener disableTouchListener disableHoverListener={open}>
                                <div>
                                    {GetResource(resource_name)?.icon}
                                </div>
                            </Tooltip>
                        </ListItemIcon>

                        <ListItemText primary={GetResource(resource_name)?.translation} sx={{ opacity: open ? 1 : 0 }} />

                    </ListItemButton>
                </ListItem>
            </div >
        )
    }
    else if (itemName !== null && itemName !== undefined) {
        return (
            <div className='open'>
                <ListItem disablePadding sx={{ display: 'block', width: '115%', backgroundColor: 'secondary.main' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: !open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 4 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={GetResource(title)?.translation} placement="right-start" disableFocusListener disableTouchListener disableHoverListener={open}>
                                <div>
                                    {GetResource(itemName)?.icon}
                                </div>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={GetResource(title)?.translation} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </div>
        )
    }
    else {
        return <></>
    }
}
