import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Language } from '../../../interfaces/Commons/languages';

export const UpdateLanguage = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { languagePayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Language>({
        defaultValues: {
            language_code: '',
            language_description: '',
        }
    });
    const onSubmit: SubmitHandler<Language> = (data) => onPut(data);

    const onPut = (data: Language) => {
        let myData = {
            language_code: data.language_code,
            language_description: data.language_description
        }
        setLoadingPost(true);
        GenericPutResource(`/languages/${languagePayload.language_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    useEffect(() => {
        reset({
            language_code: languagePayload.language_code,
            language_description: languagePayload.language_description,
        });
        setDataLoaded(true);
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                    <div className='d-flex w-100'>
                                        <div className='w-50'>
                                            <Controller
                                                name="language_code"
                                                control={control}
                                                rules={{ required: true, maxLength: 5 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("languages.fields.language_code")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.language_code}
                                                        helperText={GetError(errors.language_code?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="language_description"
                                                control={control}
                                                rules={{ maxLength: 100 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("languages.fields.language_description")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.language_description}
                                                        helperText={GetError(errors.language_description?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
