import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Consecutive } from '../../../interfaces/Commons/consecutives';
import { MessageMails } from '../../../interfaces/Commons/messagesMails';
import { Language } from '../../../interfaces/Commons/languages';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';

export const UpdateMessageMails = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
    const [comboConsecutives, setComboConsecutives] = useState<Consecutive[]>([]);
    const [defaultLanguage, setDefaultLanguage] = useState<Language>();
    const [defaultMain, setDefaultMain] = useState(false);
    const [defaultConsecutive, setDefaultConsecutive] = useState<Consecutive>();
    const { messageMailsPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<MessageMails>({
        defaultValues: {
            consecutive_id: undefined,
            language_id: undefined,
            subject: undefined,
            body: undefined,
            main: undefined,
        }
    });
    const onSubmit: SubmitHandler<MessageMails> = (data) => onPut(data);

    const onPut = async (data: MessageMails) => {
        setLoadingPost(true);
        let myData = {
            consecutive_id: data.consecutive_id,
            language_id: data.language_id,
            subject: data.subject,
            body: data.body,
            main: data.main ?? false
        }
        await GenericGetResource('/messagesmails').then(async (response1) => {
            if (messageMailsPayload.main === true && myData.main != true) {
                showSnackBar(t("messagesmails.dialog.no_main"), 'warning');
                setLoadingPost(false);
                return;
            }
            await GenericPutResource(`/messagesmails/${messageMailsPayload.messages_mail_id}`, myData)
                .then((response) => {
                    if (data.main == true) {
                        response1.data.data.forEach(async (element: MessageMails) => {
                            if (element.consecutive_id === data.consecutive_id && response.data.messages_mail_id != element.messages_mail_id) {
                                await GenericPutResource(`/messagesmails/${element.messages_mail_id}`, { main: false });
                            }
                        });
                    }
                    showSnackBar(t("generic.snackbar.update"), "success");
                    setLoadingPost(false);
                })
                .catch((error) => {
                    setLoadingPost(false);
                    showSnackBar(error.message, 'error');
                });
        }).catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, 'error');
        });
    }

    useEffect(() => {
        reset({
            consecutive_id: messageMailsPayload.consecutive_id,
            language_id: messageMailsPayload.language_id,
            subject: messageMailsPayload.subject,
            body: messageMailsPayload.body,
            main: messageMailsPayload.main
        });
        let myPromises = [
            GenericGetResource("/languages"),
            GenericGetResource("/consecutives"),
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboLanguages(responses[0].data.data);
                setDefaultLanguage(responses[0].data.data.find((element: Language) => element.language_id === messageMailsPayload.language_id));
                setComboConsecutives(responses[1].data.data);
                setDefaultConsecutive(responses[1].data.data.find((element: Consecutive) => element.consecutive_id === messageMailsPayload.consecutive_id));
                setDefaultMain(messageMailsPayload.main);
                setDataLoaded(true);
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="d-flex justify-content-between">

                                <div className=" d-flex w-100">

                                    <div className='w-50'>
                                        <Controller
                                            name="language_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    sx={{ width: "100%", paddingRight: 2 }}
                                                    options={comboLanguages}
                                                    defaultValue={defaultLanguage}
                                                    getOptionLabel={(option) => `${option.language_description} - ${option.language_code}`}
                                                    renderOption={(props, option: Language) => (
                                                        <div key={option.language_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.language_description} - {option.language_code}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.language_id === value.language_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.language_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("languages.title-view")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.language_id}
                                                            helperText={GetError(errors.language_id?.type)}
                                                        />
                                                    )}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="consecutive_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    sx={{ width: "100%" }}
                                                    options={comboConsecutives}
                                                    defaultValue={defaultConsecutive}
                                                    getOptionLabel={(option) => `${option.prefix} - ${option.name}`}
                                                    renderOption={(props, option: Consecutive) => (
                                                        <div key={option.consecutive_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.prefix} - {option.name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.consecutive_id === value.consecutive_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.consecutive_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("consecutives.title-view")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.language_id}
                                                            helperText={GetError(errors.language_id?.type)}
                                                        />
                                                    )}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex w-100 mt-3'>
                                <Controller
                                    name="subject"
                                    control={control}
                                    rules={{ maxLength: 255, required: true }}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            variant="filled"
                                            label={`${t("messagesmails.fields.subject")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.subject}
                                            helperText={GetError(errors.subject?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>
                            <div className='d-flex w-100 mt-3'>
                                <Controller
                                    name="body"
                                    control={control}
                                    rules={{ maxLength: 1000, required: true }}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            variant="filled"
                                            label={`${t("messagesmails.fields.body")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.body}
                                            multiline
                                            minRows={7}
                                            helperText={GetError(errors.body?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>
                            <div className='d-flex w-100 mt-3'>
                                <Controller
                                    name="main"
                                    control={control}
                                    render={({ field }) =>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                            <Typography sx={{ ml: 2 }}>{t("messagesmails.fields.main")}</Typography>
                                            <Switch ref={field.ref} onChange={field.onChange} defaultChecked={defaultMain} disabled={isEdit} value={field.value} />
                                        </Box>
                                    }
                                />
                            </div>
                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form >

                    </div >
                </div >
            }
            <SnackbarComponent />
        </>
    )
}
