import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../api/GenericPromises";
import { ProjectTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectsTemplate";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../../theme/buttons";
import { Button } from "@mui/material";
import { Header } from "../../../../../components/Header";
import { TableProjectStagesTemplate } from "../ProjectStagesTemplate/table";

export const ViewProjectsTemplate = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [projectsTemplateLocal, setProjectsTemplateLocal] =
    useState<ProjectTemplate>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const onDelete = async () => {
    GenericDeleteResource(
      `/projectstemplate/${projectsTemplateLocal?.project_template_id}`
    )
      .then(() => {
        setOpenDialog(false);
        navigate("/projectstemplate", {
          state: { method: "delete" },
          replace: true,
        });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  useEffect(() => {
    if (location.state === null)
      return navigate("/projectstemplate", { state: {}, replace: true });
    GenericGetResource(
      `/projectstemplate/${location.state.row.project_template_id}`
    )
      .then(async (response) => {
        setProjectsTemplateLocal(response.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && (
        <>
          <Header
            title={t("projectstemplate.title-view")}
            child={
              <div className="d-flex flex-row-reverse m-1">
                {resourceScreen?.delete === true && (
                  <ButtonStyled
                    variant="contained"
                    className="my-1"
                    onClick={() => setOpenDialog(true)}
                  >
                    {t("generic.buttons.delete")}
                  </ButtonStyled>
                )}
                <Button
                  variant="text"
                  onClick={() => {
                    navigate("/projectstemplate", { replace: true });
                  }}
                >
                  {t("generic.buttons.goback")}
                </Button>
              </div>
            }
          />
          <TableProjectStagesTemplate
            project_template_id={projectsTemplateLocal?.project_template_id}
          />
        </>
      )}

      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
