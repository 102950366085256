import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../interfaces/Security/companies';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { Schema } from '../../../../interfaces/Security/schemas';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';


export const AddUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCompanies, setComboCompanies] = useState<Company[]>([]);
  const {showSnackBar,SnackbarComponent}= useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      user_id: undefined,
      company_id: undefined
    }
  });
  const onSubmit: SubmitHandler<UserCompanies> = (data) => onPost(data);

  const onPost = (data: UserCompanies) => {
    let myPromises = [
      GenericGetResource("/schemas"),
      GenericGetResource(`/companies/${data.company_id}`)
    ];
    Promise.all(myPromises).then((responses) => {
      let schema = responses[0].data.data.find((schema: Schema) => schema.schema_description === responses[1].data.business_name)?.schema_id;
      let myData = {
        user_id: props.user_id.user_id,
        company_id: data.company_id,
        schema_id: schema
      }
      GenericPostResource("/usercompanies", myData)
        .then((response) => {
          let myNewData: UserCompanies = response.data;
          myNewData.business_name = comboCompanies.find((company: Company) => company.company_id === response.data.company_id)?.business_name;
          myNewData.schema_name = responses[0].data.data.find((schema: Schema) => schema.schema_description === responses[1].data.business_name)?.schema_name;
          props.AddNewRow(myNewData);
          props.setOpenDialog(false);
          setLoadingPost(true);
        })
        .catch((error) => {
          setLoadingPost(false);
          if (error.message === 'Network Error') showSnackBar(t("userCompanies.fields.duplicate"),"error");
          else showSnackBar(error.message,"error");
        });
    }).catch((error) => {
      setLoadingPost(false);
      showSnackBar(error.message,"error");
    });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/companies"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCompanies(responses[0].data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-100'>
                <Controller
                  name="company_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboCompanies}
                      getOptionLabel={(option) => `${option.business_name}`}
                      isOptionEqualToValue={(option, value) => option.company_id === value.company_id}
                      onChange={(_, values) => {
                        field.onChange(values?.company_id || null)
                      }}
                      renderOption={(props, option: Company) => (
                        <div key={option.company_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.business_name}
                          </Box>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("userCompanies.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.user_id}
                          helperText={GetError(errors.user_id?.type)}
                          sx={{ paddingRight: 2 }}
                        />
                      )}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent/>
    </>
  )
}
