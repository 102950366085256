import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PackagingType } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/packagingTypes';
import { Header } from '../../../../../components/Header';
import { Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const AddPackagingTypes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PackagingType>({
    defaultValues: {
      packaging_type_code: '',
      packaging_type_description: ''
    }
  });

  const onSubmit: SubmitHandler<PackagingType> = (data) => onPost(data);

  const onPost = (data: PackagingType) => {
    let myData = {
      packaging_type_code: data.packaging_type_code,
      packaging_type_description: data.packaging_type_description ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/packagingtypes", myData)
        .then((response) => {
            navigate("/packagingtypes", { state: { method: "add" } });
        })
        .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message,"error");
        });
}

const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/packagingtypes", { state: {} });
}

useEffect(() => {
  GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("packagingtypes.title")}`;
  })
}, [])

return (
  <>
      <Header title={t("packagingtypes.title-view")} />
      <div className='d-flex justify-content-center'>

          <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                  <div className="custom-card-row d-flex justify-content-between">
                      <div className="w-100">
                          <div className='d-flex w-100'>
                              <div className='w-50'>
                                  <Controller
                                      name="packaging_type_code"
                                      control={control}
                                      rules={{ required: true, maxLength: 5 }}
                                      render={({ field }) =>
                                          <TextField
                                              variant="filled"
                                              label={`${t("packagingtypes.fields.packaging_type_code")}`}
                                              ref={field.ref}
                                              value={field.value}
                                              onChange={(event) => { field.onChange(event.target.value) }}
                                              error={!!errors.packaging_type_code}
                                              helperText={GetError(errors.packaging_type_code?.type)}
                                              size="small"
                                              style={{ width: "100%" }}
                                              sx={{ paddingRight: 2 }}
                                          />
                                      }
                                  />
                              </div>

                              <div className='w-50'>
                              <Controller
                                      name="packaging_type_description"
                                      control={control}
                                      rules={{  maxLength: 150 }}
                                      render={({ field }) =>
                                          <TextField
                                              variant="filled"
                                              label={`${t("packagingtypes.fields.packaging_type_description")}`}
                                              ref={field.ref}
                                              value={field.value}
                                              onChange={(event) => { field.onChange(event.target.value) }}
                                              error={!!errors.packaging_type_description}
                                              helperText={GetError(errors.packaging_type_description?.type)}
                                              size="small"
                                              style={{ width: "100%" }}
                                              sx={{ paddingRight: 2 }}
                                          />
                                      }
                                  />
                              </div>
                          </div>


                      </div>

                  </div>

                  <div className='d-flex flex-row-reverse mt-1'>
                      <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                      <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                  </div>

              </form>

          </div>

      </div>
      <SnackbarComponent/>
  </>
)
}
