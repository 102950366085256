import { Route, Routes } from 'react-router-dom'
import { TableConsecutive } from './table'
import { AddConsecutives } from './add'
import { ViewConsecutives } from './view'

export const BrowserConsecutives = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableConsecutive />} />
            <Route path={"/add"} element={<AddConsecutives />} />
            <Route path={"/view"} element={<ViewConsecutives />} />
        </Routes>
    )
}
