import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ProjectType } from '../../../../interfaces/Projects/Catalogs/projectTypes';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';

export const UpdateProjectTypes = ({ ...props }) => {

    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { projectTypePayload } = props;
    const isEdit = !props.isEdit;

    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ProjectType>({
        defaultValues: {
            project_type_name: undefined,
            project_type_description: undefined,
            MS_planner_id:undefined
        }
    });

    const onSubmit: SubmitHandler<ProjectType> =(data) => onPut(data);

    const onPut = (data: ProjectType) => {
        let myData = {
            project_type_name: data.project_type_name,
            project_type_description: data.project_type_description ?? null,
            MS_planner_id: data.MS_planner_id ?? null
        }
        setLoadingPost(true);
        GenericPutResource(`/projecttypes/${projectTypePayload.project_type_id}`, myData)
          .then((response) => {
            showSnackBar(t("generic.snackbar.update"),"success");
            setLoadingPost(false);
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message,"error");
            setLoadingPost(false);
          });
      }

      useEffect(() => {
        reset({
            project_type_name: projectTypePayload.project_type_name,
            project_type_description: projectTypePayload.project_type_description ?? undefined,
            MS_planner_id: projectTypePayload.MS_planner_id ?? undefined
        });
        setDataLoaded(true);
      }, []);


    return (
        <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75 mt-4'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="project_type_name"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("projecttypes.fields.project_type_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.project_type_name}
                            helperText={GetError(errors.project_type_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="project_type_description"
                        control={control}
                        rules={{ maxLength: 100 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("projecttypes.fields.project_type_description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.project_type_description}
                            helperText={GetError(errors.project_type_description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100 pt-3">
                  <div className="w-50">
                    <Controller
                      name="MS_planner_id"
                      control={control}
                      rules={{minLength: 28, maxLength: 28}}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("projecttypes.fields.MS_planner_id")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.MS_planner_id}
                          helperText={GetError(errors.MS_planner_id?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>
                </div>

                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
    )
}



