import { Route, Routes } from 'react-router-dom'
import { AddCity } from './add'
import { ViewCity } from './view'
import { TableCities } from './table'

export const BrowserCities = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableCities />} />
            <Route path={"/add"} element={<AddCity />} />
            <Route path={"/view"} element={<ViewCity />} />
        </Routes>
    )
}