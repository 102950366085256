import { useTranslation } from "react-i18next";
import { CancelledForeignTrade, CancelledForeignTradeMerchandise } from "../../../../interfaces/Sales/Invoices/Cancellations/cancelledForeignTrades"
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";
import ComponentTableForeignTradeMerchandise from "../../../../components/Tables/TableForeignTradeMerchandise";

type TableCancelledForeignTradeMerchandisesProps = {
  foreignTradeLocal?: CancelledForeignTrade,
  foreignTradeMerchandises: readonly GridValidRowModel[],
}

export const TableCancelledForeignTradeMerchandises = ({
  foreignTradeLocal,
  foreignTradeMerchandises,
}: TableCancelledForeignTradeMerchandisesProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();

  let columns: GridColDef[] =
    [
      {
        field: 'customs_quantity',
        headerName: t("foreigntrademerchandises.fields.customs_quantity"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_id',
        headerName: t("tarifffractions.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <Tooltip title={params.row.tariff_fraction_code !== null ? `${params.row.tariff_fraction_code} - ${params.row.tariff_fraction_general_description ?? ''} - ${params.row.tariff_fraction_specific_description ?? ''}` : ''}>
            <span>
              {params.row.tariff_fraction_code ?? ''} - {params.row.tariff_fraction_general_description ?? ''} - {params.row.tariff_fraction_getariff_fraction_specific_descriptionneral_description ?? ''}
            </span>
          </Tooltip>
        ),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_international_unit_symbol',
        headerName: t("foreigntrademerchandises.fields.tariff_fraction_international_unit_symbol"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_unit_code',
        headerName: t("foreigntrademerchandises.fields.tariff_fraction_unit_code"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'identification_number',
        headerName: t("foreigntrademerchandises.fields.identification_number"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'customs_unit_value',
        headerName: t("foreigntrademerchandises.fields.customs_unit_value"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'dolars_total_value',
        headerName: t("foreigntrademerchandises.fields.dolars_total_value"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell",
      },
    ];

  return (
    <>
      <ComponentTableForeignTradeMerchandise
        rows={foreignTradeMerchandises}
        foreignTrade={foreignTradeLocal?.cancelled_foreign_trade_id ?? 0}
        columns={columns}
        rowModesModel={undefined}
        handleRowModesModelChange={() => { }}
        handleRowEditStop={() => { }}
        processRowUpdate={(oldRow) => { return oldRow }}
        setRows={() => { }}
        setRowModesModel={() => { }}
        isEdit={true}
        ChangeTotalForeignTrade={() => { }}
      />
      <div className='d-flex flex-row-reverse m-2'>
        <div className='d-flex flex-column'>
          <span>
            {t("foreigntrades.fields.total_USD")}: {setFormatNumber({ value: foreignTradeLocal?.total_USD ?? 0 })}
          </span>
        </div>
      </div>
    </>
  )
}
