import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Retention } from '../../../../interfaces/Sales/Catalogs/retentions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddRetention = () => {

    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Retention>({
        defaultValues: {
            retention_code: '',
            retention_name: '',
            retention_percent: undefined,
        }
    });


    const onSubmit: SubmitHandler<Retention> = (data) => onPost(data);


    const onPost = (data: Retention) => {
        let myData = {
            retention_code: data.retention_code,
            retention_name: data.retention_name ?? null,
            retention_percent: data.retention_percent,
        }
        setLoadingPost(true);
        GenericPostResource("/retentions", myData)
            .then((response) => {
                navigate("/retentions", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/retentions", { state: {} });
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("retentions.title")}`;
        })
    }, [])

    return (
        <>
            <Header title={t("retentions.title-view")} />
            <div className='d-flex justify-content-center'>

                <div className='custom-card-view w-75'>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                        <div className="custom-card-row d-flex justify-content-between">
                            <div className="w-100">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="retention_code"
                                            control={control}
                                            rules={{ required: true, maxLength: 5 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("retentions.fields.retention_code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.retention_code}
                                                    helperText={GetError(errors.retention_code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className='w-50'>
                                        <Controller
                                            name="retention_percent"
                                            control={control}
                                            rules={{ required: true, maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    ref={field.ref}
                                                    variant="filled"
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                        "& input[type=number]": { MozAppearance: "textfield", }
                                                    }}
                                                    type="number"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    label={t("retentions.fields.retention_percent")}
                                                    error={!!errors.retention_percent}
                                                    helperText={GetError(errors.retention_percent?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>



                                <div className="w-100 mt-3">
                                    <Controller
                                        name="retention_name"
                                        control={control}
                                        rules={{ maxLength: 30 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("retentions.fields.retention_name")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.retention_name}
                                                helperText={GetError(errors.retention_name?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                            />
                                        }
                                    />
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>

                    </form>

                </div>

            </div>
            <SnackbarComponent />
        </>
    )
}
