import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { CancelledForeignTrade, CancelledForeignTradeMerchandise } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledForeignTrades';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import { TextField } from '@mui/material';
import { TableForeignTrade } from '../../SalesInvoices/ForeignTrade/table';
import { TableCancelledForeignTradeMerchandises } from './table';
import { ForeignTradeMerchandise } from '../../../../interfaces/Sales/Catalogs/ForeignTrade/foreignTrade';

type IndexCancelledForeignTradeProps = {
  salesInvoicePayload?: CancelledSalesInvoice;
}

export const IndexCancelledForeignTrade = ({
  salesInvoicePayload,
}: IndexCancelledForeignTradeProps) => {
  const [t] = useTranslation("global");

  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [foreignTradeLocal, setForeignTradeLocal] = useState<CancelledForeignTrade>();
  const [foreignTradeMerchandises, setForeignTradeMerchandises] = useState<CancelledForeignTradeMerchandise[]>([]);

  useEffect(() => {
    GenericGetResource(`/cancelledforeigntrades/bycancelledsalesinvoiceid/${salesInvoicePayload?.cancelled_sales_invoice_id}`)
      .then(async (responses) => {
        setForeignTradeLocal(responses.data.data[0]);
        await GenericGetResource(`/cancelledforeigntrademerchandises/bycancelledforeigntradeid/${responses.data.data[0].cancelled_foreign_trade_id}`)
          .then((response) => {
            setForeignTradeMerchandises(response.data.data);
            setDataLoaded(true);
          }).catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className="d-flex flex-row-reverse mb-2 w-100">
          </div>
          <div className='custom-card-view mb-2 d-flex'>
            <div className="w-50">
              <TextField
                variant="filled"
                label={`${t("customskeycodes.title-view")}`}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                }}
                value={`${foreignTradeLocal?.customs_key_code} - ${foreignTradeLocal?.customs_key_code_description}`}
                size="small"
                style={{ width: "100%" }}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            </div>

            <div className="w-50">
              <TextField
                variant="filled"
                label={`${t("incoterms.title-view")}`}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }
                }}
                value={`${foreignTradeLocal?.incoterm_code} - ${foreignTradeLocal?.incoterm_description}`}
                size="small"
                style={{ width: "100%" }}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            </div>
          </div>

          <div className='custom-card-view mt-2'>
            <div className='d-flex flex-row-reverse my-1'>
              <div className='d-flex flex-row-reverse'>
                <TextField
                  variant="filled"
                  label={`${t("foreigntrades.fields.exchange_rate_USD")}`}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                    "& input[type=number]": { MozAppearance: "textfield", }
                  }}
                  type="number"
                  value={foreignTradeLocal?.exchange_rate_USD}
                  size="small"
                  style={{ width: "100%" }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                />
              </div>
            </div>
            <TableCancelledForeignTradeMerchandises
              foreignTradeLocal={foreignTradeLocal}
              foreignTradeMerchandises={foreignTradeMerchandises}
            />
          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
};
