import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import { useCallback, useContext, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { PrimaryButton } from "../../../../theme/buttons";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { usePermissions } from "../../../../hooks/usePermissions";
import { PaymentCondition } from "../../../../interfaces/Sales/Catalogs/paymentConditions";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../../hooks/useDates";

export const TablePaymentConditions = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const location = useLocation();
    const { GenericGetResource } = GenericPromises();
    const { TimeConverter } = useDates();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [unauthorized, setUnauthorized] = useState(true);
    const [paymentConditionsData, setPaymentConditionsData] = useState<GridRowsProp>([]);
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const [messageSnack, setMessageSnack] = useState("");
    const [myPreferences, setMyPreferences] = useState({});
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetResourceByUrl } = usePermissions();
    const [columns, setColumns] = useState<GridColDef<PaymentCondition>[]>([
        { field: 'payment_condition_days', headerName: t("paymentconditions.fields.payment_condition_days"), headerClassName: 'header-grid-table', flex: 1 },
        { field: 'payment_condition_name', headerName: t("paymentconditions.fields.payment_condition_name"), headerClassName: 'header-grid-table', flex: 1 },
        { field: 'payment_condition_description', headerName: t("paymentconditions.fields.payment_condition_description"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
        { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
    ]);


    const methodUsed = useCallback(() => {
        switch (location.state.method) {
            case "add":
                {
                    showSnackBar(t("generic.snackbar.add"), "success");
                    break;
                }
            case "delete":
                {
                    showSnackBar(t("generic.snackbar.delete"), "success");
                    break;
                }
        }

    }, [location.state]);

    const loadPreferences = async () => {
        let myPreferences = await localStorage.getItem("grid-paymentconditions");
        if (myPreferences != null) {
            setMyPreferences(JSON.parse(myPreferences));
        }
    }

    const loadColumnsOrder = async () => {
        let myOrderColumns = await localStorage.getItem("grid-paymentconditions-columns");
        if (myOrderColumns != null) {
            let myJson = JSON.parse(myOrderColumns);
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];
                if (element['type'] == 'date') {
                    element.headerName = t(`generic.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<PaymentCondition, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                if (element.field) { }
                else {
                    element.headerName = t(`paymentconditions.fields.${element.field}`);
                }
            }
            setColumns(myJson);
        }
    }



    useEffect(() => {
        if (location.state !== null && location.state.method) methodUsed();
        GenericGetResource(`/paymentconditions`)
            .then(
                (response) => {
                    setPaymentConditionsData(response.data.data);

                    GetResourceByUrl(`/paymentconditions`)
                        .then((response1) => {
                            setResourceScreen((prev) => response1);
                            loadColumnsOrder();
                            loadPreferences();
                            setDataLoaded(true);
                        })
                        .catch((error) => {
                            setMessageSnack(error.message);
                            showSnackBar(error.message, 'error');
                            setUnauthorized(false);
                        });
                }
            ).catch((error) => {
                setMessageSnack(error.message);
                showSnackBar(error.message, 'error');
                setUnauthorized(false);
            });
    }, []);


    return (
        <>
            {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
            {unauthorized && !dataLoaded && <Spinner />}
            {dataLoaded && resourceScreen?.read && <div className='screen-container'>
                <Header title={t("paymentconditions.title")} />
                {resourceScreen?.create === true &&
                    <div className='d-flex flex-row-reverse my-1'>
                        <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
                    </div>
                }
                <div className="d-flex justify-content-center">
                    <DataTable columns={columns} setColumns={setColumns} data={paymentConditionsData} entityId={"payment_condition_id"} entity={`EntyPaymentConditions`} preferences={myPreferences} namePreferences={"grid-paymentconditions"} nameOrderColumns={"grid-paymentconditions-columns"} />
                </div>
                <SnackbarComponent />

            </div>
            }
        </>
    )
}