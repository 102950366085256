import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ExchangeRateHistory } from "../../../interfaces/Commons/currencies";
import { Box, Button, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs'
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../hooks/useDates";

export const AddExchangeRateHistory = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { ReverseTimeConverter } = useDates();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ExchangeRateHistory>({
    defaultValues: {
      rate: undefined,
    }
  });
  const onSubmit: SubmitHandler<ExchangeRateHistory> = (data) => onPost(data);

  const onPost = (data: ExchangeRateHistory) => {
    let myData = {
      rate_date: data.rate_date,
      rate: data.rate,
      currency_id: props.currency_id,
    }
    setLoadingPost(true);
    GenericPostResource("/exchangeratehistory", myData)
      .then((response) => {
        let myNewData: ExchangeRateHistory = response.data;
        if(myNewData.rate_date){
          myNewData.rate_date= ReverseTimeConverter(myNewData.rate_date)?.toString()
        }
        
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  useEffect(() => {
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <div className='d-flex mt-1'>
              <div className='w-50'>
                <Controller
                  name="rate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("exchangeratehistory.fields.rate")}`}
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                      }}
                      type="number"
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.rate}
                      helperText={GetError(errors.rate?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
              <div className='w-50'>
                <Controller
                  name="rate_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      label={`${t("exchangeratehistory.fields.rate_date")}`}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.rate_date,
                          helperText: GetError(errors.rate_date?.type),
                        }
                      }}
                      format="DD/MM/YYYY"
                      minDate={dayjs(new Date("2023-01-02"))}
                      maxDate={dayjs()}
                      disableFuture
                    />
                  }
                />
              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-3'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>
        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
