import { Route, Routes } from "react-router-dom"
import { TableCompanyBankAccounts } from "./table"


export const BrowserCompanyBankAccounts = ({ ...props }) => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCompanyBankAccounts company_id={props.company_id} />} />
    </Routes>
  )
}
