import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Currency } from '../../../interfaces/Commons/currencies';
import { Box, Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddCurrency = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Currency>({
    defaultValues: {
      currency_code: undefined,
      currency_description: undefined,
      currency_symbol: undefined,
    }
  });
  const onSubmit: SubmitHandler<Currency> = (data) => onPost(data);

  const onPost = (data: Currency) => {
    let myData = {
      currency_code: data.currency_code,
      currency_description: data.currency_description ?? null,
      currency_symbol: data.currency_symbol ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/currencies", myData)
      .then((response) => {
        let myNewData: Currency = response.data;
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("currencies.title")}`;
    })
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <div className='d-flex'>
              <div className='w-50 d-flex'>
                <div className='w-50'>
                  <Controller
                    name="currency_symbol"
                    control={control}
                    rules={{ maxLength: 1 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("currencies.fields.currency_symbol")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.currency_symbol}
                        helperText={GetError(errors.currency_symbol?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 1 }}
                      />
                    }
                  />
                </div>
                <Controller
                  name="currency_code"
                  control={control}
                  rules={{ required: true, maxLength: 3 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("currencies.fields.currency_code")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.currency_code}
                      helperText={GetError(errors.currency_code?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="currency_description"
                  control={control}
                  rules={{ maxLength: 30 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("currencies.fields.currency_description")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.currency_description}
                      helperText={GetError(errors.currency_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
