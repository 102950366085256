import { Dispatch, SetStateAction, useState } from 'react'
import { InvoiceDocumentType, SalesInvoice } from '../../../interfaces/Sales/Invoices/salesinvoices'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Currency } from '../../../interfaces/Commons/currencies'
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods'
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes'
import { Customer } from '../../../interfaces/Sales/Catalogs/Customers/customers'
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material'
import { FormsErrors } from '../../../hooks/Forms/FormsErrors'
import { useTranslation } from 'react-i18next'
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Company } from '../../../interfaces/Security/companies'
import GenericPromises from '../../../api/GenericPromises'

type InvoiceHeaderProps = {
  control: Control<SalesInvoice, any>,
  errors: FieldErrors<SalesInvoice>,
  isCustomerSelected?: boolean,
  onChangeCustomer?: (customer_id: number | undefined) => void,
  company: Company | undefined,
  defaultPaymentMethod: PaymentMethod | undefined,
  defaultPaymentMode: PaymentMode | undefined,
  defaultCurrency: Currency | undefined,
  defaultCFDI: CFDI | undefined,
  comboCurrencies: Currency[],
  comboPaymentMethods: PaymentMethod[],
  comboPaymentModes: PaymentMode[],
  comboCustomers: Customer[],
  comboCFDIs: CFDI[],
  comboInvoiceDocumentTypes: InvoiceDocumentType[],
  setMyConsecutive: Dispatch<SetStateAction<string>>,
  myConsecutive: string,
  isSelectedCustomerTextfield: boolean
  setIsSelectedCustomerTextfield: Dispatch<SetStateAction<boolean>>
}

export const InvoiceHeader = ({
  control,
  errors,
  isCustomerSelected,
  onChangeCustomer,
  company,
  defaultPaymentMethod,
  defaultPaymentMode,
  defaultCurrency,
  defaultCFDI,
  comboCurrencies,
  comboPaymentMethods,
  comboPaymentModes,
  comboCustomers,
  comboCFDIs,
  comboInvoiceDocumentTypes,
  setMyConsecutive,
  myConsecutive,
  isSelectedCustomerTextfield,
  setIsSelectedCustomerTextfield
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { GenericGetResource } = GenericPromises();

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
  }

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <Box className='custom-card-view w-25' sx={{ marginRight: 2 }}>
          <div>
            <Typography>{company?.business_name}</Typography>
            <Typography>{company?.rfc}</Typography>
            <Typography>{company?.phone_number}</Typography>
            <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
          </div>
          <div>
            <Controller
              name="customer_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboCustomers}
                  getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                  renderOption={(props, option: Customer) => (
                    <div key={option.customer_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                  onChange={(_, values) => {
                    field.onChange(values?.customer_id || null)
                    if (values?.customer_id !== null && values?.customer_id !== undefined) {
                      onChangeCustomer && onChangeCustomer(values?.customer_id);
                      setIsSelectedCustomerTextfield(true);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("customers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.customer_id}
                      helperText={GetError(errors.customer_id?.type)}
                    />
                  )}
                  disabled={isCustomerSelected || isSelectedCustomerTextfield}
                />
              )}
            />
          </div>
        </Box>
        {/* Right Box*/}
        <div className=' d-flex flex-column w-75'>
          {/* First row */}
          <div className='d-flex w-100'>

            <div className='w-50'>
              <Controller
                name="sales_invoice_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salesinvoices.fields.sales_invoice_name")}`}
                    ref={field.ref}
                    value={myConsecutive}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.sales_invoice_name}
                    helperText={GetError(errors.sales_invoice_name?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="invoice_doc_type_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    options={comboInvoiceDocumentTypes}
                    getOptionLabel={(option) => option.name}
                    disabled={isSelectedCustomerTextfield}
                    defaultValue={comboInvoiceDocumentTypes[0]}
                    renderOption={(props, option: InvoiceDocumentType) => (
                      <div key={option.invoice_doc_type_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
                    onChange={async (_, values) => {
                      await GetAndSetConsecutive(values?.consecutive_id);
                      field.onChange(values?.invoice_doc_type_id || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("invoicedocumenttypes.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.invoice_doc_type_id}
                        helperText={GetError(errors.invoice_doc_type_id?.type)}
                      />
                    )}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="sales_invoice_date"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    ref={field.ref}
                    label={`${t("salesinvoices.fields.sales_invoice_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.sales_invoice_date,
                        helperText: GetError(errors.sales_invoice_date?.type),
                      }
                    }}
                    defaultValue={dayjs()}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedCustomerTextfield}
                  />
                }
              />
            </div>

          </div>
          {/* Second Row */}
          <div className='d-flex w-100 mt-2'>
            <div className='w-50'>
              <Controller
                name="purchase_order"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salesinvoices.fields.purchase_order")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.purchase_order}
                    helperText={GetError(errors.purchase_order?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    disabled={!isCustomerSelected}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              {!isCustomerSelected &&
                <Controller
                  name="payment_method_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboPaymentMethods}
                      getOptionLabel={(option) => `${option.code} - ${option.name}` ?? ""}
                      renderOption={(props, option: PaymentMethod) => (
                        <div key={option.payment_method_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.payment_method_id === value.payment_method_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_method_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmethods.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_method_id}
                          helperText={GetError(errors.payment_method_id?.type)}
                        />
                      )}
                      disabled
                    />
                  )}
                />
              }
              {isCustomerSelected &&
                <Controller
                  name="payment_method_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboPaymentMethods}
                      getOptionLabel={(option) => `${option.code} - ${option.name}` ?? ""}
                      defaultValue={defaultPaymentMethod}
                      renderOption={(props, option: PaymentMethod) => (
                        <div key={option.payment_method_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.payment_method_id === value.payment_method_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_method_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmethods.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_method_id}
                          helperText={GetError(errors.payment_method_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              }
            </div>

            <div className='w-50'>
              {!isCustomerSelected &&
                <Controller
                  name="payment_mode_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboPaymentModes}
                      getOptionLabel={(option) => `${option.code} - ${option.description}`}
                      renderOption={(props, option: PaymentMode) => (
                        <div key={option.payment_mode_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.payment_mode_id === value.payment_mode_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_mode_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmodes.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_mode_id}
                          helperText={GetError(errors.payment_mode_id?.type)}
                          disabled={!isCustomerSelected}
                        />
                      )}
                      disabled
                    />
                  )}
                />
              }
              {isCustomerSelected &&
                <Controller
                  name="payment_mode_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboPaymentModes}
                      defaultValue={defaultPaymentMode}
                      getOptionLabel={(option) => `${option.code} - ${option.description}`}
                      renderOption={(props, option: PaymentMode) => (
                        <div key={option.payment_mode_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.payment_mode_id === value.payment_mode_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_mode_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmodes.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_mode_id}
                          helperText={GetError(errors.payment_mode_id?.type)}
                          disabled={!isCustomerSelected}
                        />
                      )}
                    />
                  )}
                />
              }
            </div>
          </div>
          {/* Third Row */}
          <div className='d-flex w-100 mt-2'>
            <div className='w-50'></div>

            <div className='w-50'>
              {!isCustomerSelected &&
                <Controller
                  name="currency_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboCurrencies}
                      defaultValue={defaultCurrency}
                      getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                      renderOption={(props, option: Currency) => (
                        <div key={option.currency_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.currency_code} - {option.currency_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                      onChange={(_, values) => {
                        field.onChange(values?.currency_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("currencies.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.currency_id}
                          helperText={GetError(errors.currency_id?.type)}
                        />
                      )}
                      disabled
                    />
                  )}
                />
              }
              {isCustomerSelected &&
                <Controller
                  name="currency_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboCurrencies}
                      defaultValue={defaultCurrency}
                      getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                      renderOption={(props, option: Currency) => (
                        <div key={option.currency_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.currency_code} - {option.currency_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                      onChange={(_, values) => {
                        field.onChange(values?.currency_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("currencies.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.currency_id}
                          helperText={GetError(errors.currency_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              }
            </div>

            <div className='d-flex flex-row-reverse justify-content-center align-items-center w-50'>
              {!isCustomerSelected &&
                <Controller
                  name="cfdi_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboCFDIs}
                      defaultValue={defaultCFDI}
                      getOptionLabel={(option) => `${option.code} - ${option.description}`}
                      renderOption={(props, option: CFDI) => (
                        <div key={option.cfdi_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.cfdi_id === value.cfdi_id}
                      onChange={(_, values) => {
                        field.onChange(values?.cfdi_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("salesinvoices.fields.cfdi")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.cfdi_id}
                          helperText={GetError(errors.cfdi_id?.type)}
                        />
                      )}
                      disabled
                    />
                  )}
                />
              }
              {isCustomerSelected &&
                <Controller
                  name="cfdi_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboCFDIs}
                      defaultValue={defaultCFDI}
                      getOptionLabel={(option) => `${option.code} - ${option.description}`}
                      renderOption={(props, option: CFDI) => (
                        <div key={option.cfdi_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.cfdi_id === value.cfdi_id}
                      onChange={(_, values) => {
                        field.onChange(values?.cfdi_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("salesinvoices.fields.cfdi")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.cfdi_id}
                          helperText={GetError(errors.cfdi_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              }
            </div>
          </div>
          {/* Fourth row */}
          <div className="d-flex flex-row-reverse mt-2">
            <div className="w-50">
              <Controller
                name="includes_exterior_complement"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <Typography sx={{ ml: 2 }}>{t("salesinvoices.fields.includes_exterior_complement")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} disabled={true} />
                  </Box>
                }
              />
            </div>
            <div className="w-50">

              <Controller
                name="includes_transportation_document"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                    <Typography sx={{ ml: 2 }}>{t("salesinvoices.fields.includes_transportation_document")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} disabled={true} />
                  </Box>
                }
              />
            </div>
            <div className="w-50"></div>
          </div>
        </div>
      </div>
    </>
  )
}
