
import { Box, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnMenu, GridColumnMenuColumnsItem, GridColumnMenuProps, GridColumnVisibilityModel, GridInputRowSelectionModel, GridPagination, GridRenderCellParams, GridRowParams, GridRowSelectionModel, GridToolbarContainer, GridToolbarExport, GridTreeNodeWithRender, GridValidRowModel, gridPageCountSelector, useGridApiContext, useGridApiRef, useGridSelector } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { CustomColumnOrder } from './CustomColumnOrder';
import { useTranslation } from 'react-i18next';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useFormatNumber } from '../../../hooks/useFormatNumber';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import "./DataTable.css";
import GenericPromises from '../../../api/GenericPromises';
import { Currency } from '../../../interfaces/Commons/currencies';
import { CompanyConfiguration } from '../../../interfaces/Security/companies';
import useSnackBar from '../../Commons/SnackBar/useSnackBar';
import { Spinner } from '../../Commons/Spinner/Spinner';

interface MyComponentProps {
    entity: string;
    namePreferences: string;
    nameOrderColumns: string;
    entityId: string;
    columns: GridColDef<any>[];
    setColumns: Dispatch<SetStateAction<GridColDef<any>[]>>;
    data: readonly GridValidRowModel[];
    preferences: any;
    isChildren?: boolean;
    setOpenDialog?: Dispatch<SetStateAction<boolean>>;
    setDataRow?: Dispatch<SetStateAction<{}>>;
    selectRows?: boolean;
    hiddenHeaderCheckBox?: boolean;
    onRowSelectionModelChange?: ((rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => void) | undefined;
    countColumns?: string[];
    preSelectedRows?: GridInputRowSelectionModel | undefined;
    hasDoubleClick?: boolean | undefined;
    conditionalOpenRow?: string | undefined;
    initFilter?: {
        columnfilter: string;
        value: string;
    }[];
}

export default function DataTable(
    {
        entity,
        namePreferences,
        nameOrderColumns,
        entityId,
        columns,
        setColumns,
        data,
        preferences,
        isChildren,
        setOpenDialog,
        setDataRow,
        selectRows,
        onRowSelectionModelChange,
        hiddenHeaderCheckBox,
        countColumns,
        preSelectedRows,
        hasDoubleClick = false,
        conditionalOpenRow,
        initFilter,
    }: MyComponentProps) {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dictionaryTotals, setDictionaryTotals] = useState<{ id: string; total: number }[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { setFormatNumberFromTable } = useFormatNumber();
    const [rowCountData, setRowCountData] = useState<any[]>([]);
    const [customGridColumnVisibilityModel, setCustomGridColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
    const apiRef1 = useGridApiRef();
    const apiRef2 = useGridApiRef();
    const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
    const [localPreference, setLocalPreference] = useState({})
    const { SnackbarComponent, showSnackBar } = useSnackBar();

    const onSelectedRow = (row: any) => {
        if (conditionalOpenRow && (row[conditionalOpenRow] !== null && row[conditionalOpenRow] !== undefined)) {
            if (isChildren) {
                if (setOpenDialog !== undefined && setDataRow !== undefined) {
                    setDataRow(row);
                    setOpenDialog(true);
                }
            } else {
                navigate("view", { state: { row: row } });
            }
        }
        else if (!conditionalOpenRow) {
            if (isChildren) {
                if (setOpenDialog !== undefined && setDataRow !== undefined) {
                    setDataRow(row);
                    setOpenDialog(true);
                }
            } else {
                navigate("view", { state: { row: row } });
            }
        }
    };

    function Pagination({
        page,
        onPageChange,
        className,
    }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                variant='outlined'
                sx={{
                    '& .MuiPaginationItem-root:hover': {
                        color: 'secondary',
                        backgroundColor: 'primary',
                        border: 'none',
                    }
                }}
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event as any, newPage - 1);
                }}
            />
        );
    }

    function CustomPagination(props: any) {
        const apiRef = useGridApiContext();
        const [myColumns, setMyColumns] = useState<GridColDef<any>[]>([]);
        const selectedRows = useGridSelector(apiRef, (state: GridStateCommunity) =>
            state.rowSelection
        );

        const SumRowsSelected = () => {
            let totals: { id: string, total: number }[] = [];
            if (countColumns && selectRows && data) {
                const totalsObj: { [key: string]: number } = {};
                countColumns.forEach(column => {
                    totalsObj[column] = 0;
                });

                selectedRows.forEach((rowIndex: any) => {
                    const rowData = data.find((element => element[entityId] === rowIndex));
                    if (rowData) {
                        countColumns.forEach(column => {
                            totalsObj[column] += rowData[column];
                        });
                    }
                });

                totals = Object.entries(totalsObj).map(([id, total]) => ({ id, total }));
            }
            return totals;
        }

        useEffect(() => {
            let myAuxColumns: GridColDef<any>[] = columns.map(column => ({ ...column }));

            for (let i = 0; i < myAuxColumns.length; i++) {
                const element = myAuxColumns[i];
                if (element.type === "boolean") {
                    element.renderCell = function (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
                        return null;
                    }
                }
                if (element.type === "number") {
                    element.renderCell = function (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
                        return (params.value > 0) ? setFormatNumberFromTable(params.value) : null;
                    }
                }
                if (element.type === "date") {
                    element.renderCell = function (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
                        return null;
                    }
                }
            }

            setMyColumns(myAuxColumns);
        }, [columns]);

        return <div className='d-flex w-100'>
            <Box display="flex" flexDirection="column" className='w-100'>
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiDataGrid-columnHeaders': {
                            display: 'none',
                        },
                        fontSize: "small"
                    }}
                >
                    {countColumns && countColumns.length > 0 && data.length > 0 &&
                        <>
                            <DataGrid
                                sx={{
                                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                        display: `none`,
                                    },
                                    '& .MuiDataGrid-checkboxInput': {
                                        display: 'none',
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        overflow: 'hidden !important',
                                    },
                                }}
                                rows={rowCountData}
                                columns={myColumns}
                                hideFooter
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                density="compact"
                                autoHeight
                                getRowId={(row) => row[entityId]}
                                checkboxSelection={selectRows}
                                apiRef={apiRef2}
                                columnVisibilityModel={customGridColumnVisibilityModel}
                                onColumnVisibilityModelChange={(e) => SetCustomPreferences(e)}
                                onRowSelectionModelChange={onRowSelectionModelChange}
                                onPaginationModelChange={handlePaginationModelChange}
                                onStateChange={() => onChargeGrid()}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: rowsPerPage } },
                                    columns: {
                                        columnVisibilityModel: localPreference,
                                    },
                                    sorting: {
                                        sortModel: [
                                            { field: 'rate_date', sort: 'desc' },
                                        ]
                                    }
                                }}
                                disableRowSelectionOnClick
                            />
                        </>
                    }
                </Box>
                <Box display="flex" flexDirection="row" className="w-100" paddingLeft={2}>
                    <Box flex={1} display="flex" alignItems="center" alignContent="center">
                        {selectedRows.length > 0 ?
                            <>
                                <Typography variant="subtitle1" justifyContent="flex-start" paddingX={1}>
                                    {t("generic.grid-table.count-rows")}: {selectedRows.length}
                                </Typography>
                                {countColumns && countColumns.length > 0 &&
                                    SumRowsSelected().map((row) => (
                                        <Typography key={row.id} paddingX={1} variant="subtitle1" justifyContent="flex-start">
                                            {row.id === "total_company_currency_amount"
                                                && `${t("companies.configuration.fields.total_company_currency_amount")}: ${setFormatNumberFromTable(row.total)}`
                                            }
                                            {row.id !== "total_company_currency_amount"
                                                && `${t(`${entity.toLocaleLowerCase()}.fields.${row.id}`)}: ${setFormatNumberFromTable(row.total)}`
                                            }

                                        </Typography>
                                    ))
                                }
                            </>
                            :
                            <></>
                        }
                    </Box>
                    <Box display="flex" alignItems="center" alignContent="center" justifyContent="flex-end">
                        <CustomToolbar />
                        <GridPagination
                            labelRowsPerPage={t("generic.grid-table.rows-per-page")}
                            ActionsComponent={Pagination}
                            {...props}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    }

    function CustomToolbar() {
        return (
            <>
                <GridToolbarContainer sx={{ margin: 1 }}>
                    <GridToolbarExport
                        printOptions={{ disableToolbarButton: true }}
                        csvOptions={{
                            fileName: `${entity}`,
                            utf8WithBom: true,
                        }}
                    />
                </GridToolbarContainer>
            </>
        );
    }

    function CustomColumnsItems({ ...props }) {
        return <div>
            <CustomColumnOrder columns={columns} setColumns={setColumns} nameOrderColumns={nameOrderColumns} />
            <GridColumnMenuColumnsItem colDef={props.colDef} onClick={props.onClick} />
        </div>
    }

    function CustomColumnMenuComponent(props: GridColumnMenuProps) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: CustomColumnsItems,
                }}
            />
        );
    }

    const SetCustomPreferences = (myColumns: any) => {
        localStorage.setItem(namePreferences, JSON.stringify(myColumns));
        setCustomGridColumnVisibilityModel(myColumns);
    };

    const SetRowCount = (dictonaryTotals: any) => {
        if (data.length > 0) {
            const newRow: { [key: string]: any } = {};
            data.forEach((item) => {
                for (const key in item) {
                    if (key === entityId) {
                        newRow[key] = data.length + 1;
                    } else {
                        const totalItem = dictonaryTotals.find((total: any) => total.id === key);
                        newRow[key] = totalItem ? totalItem.total : null;
                    }
                }
            });
            setRowCountData(() => [newRow]);
        }
    };

    useEffect(() => {
        if (preferences && typeof preferences === 'object' && Object.keys(preferences).length > 1) {
            setLocalPreference(preferences);
        } else {
            const defaultPreferences = {
                "last_update_user": false,
                "creation_date": false,
                "last_update_date": false
            };
            setLocalPreference(defaultPreferences);
        }
        let myNewColumns: GridColDef<any>[] = [];
        for (let i = 0; i < columns.length; i++) {
            const element = columns[i];
            element.minWidth = 200;
            if (element.type === "date") {
                element.renderCell = (params) => {
                    if (params.value) {
                        const date = params.value as Date;
                        return new Intl.DateTimeFormat('es-ES', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                        }).format(date);
                    }
                    return undefined

                }
            }
            myNewColumns.push(element);
        }
        if (countColumns && countColumns.length > 0) {
            // GET CURRENCIES AND COMPANY CONFIGURATION INFO  
            let myComboCurrencies: Currency[];
            let myCompanyConfiguration: CompanyConfiguration;
            let myDefaultCurrency: Currency | undefined;
            let myPromises = [
                GenericGetResource(`/currencies`),
                GenericGetResourceGeneric(`/companyconfigurations/bycompanyid/${1}`, "/gcompanyconfigurations"),
            ];
            Promise.all(myPromises)
                .then(async (responses) => {
                    // Match currencies target
                    myComboCurrencies = responses[0].data.data;
                    myCompanyConfiguration = responses[1].data.data[0];
                    myDefaultCurrency = myComboCurrencies.find((item) => item.currency_id === myCompanyConfiguration.company_currency_id);
                    // get columns to sum totals
                    let myColumnTotalAmount = "";
                    let myColumnCurrencyCode = "";
                    switch (entityId) {
                        case "sales_invoice_id":
                        case "purchase_invoice_id":
                        case "cancelled_sales_invoice_id":
                            {
                                myColumnTotalAmount = "total_amount";
                                myColumnCurrencyCode = "currency_code";
                                break;
                            }
                        case "receipt_detail_id":
                        case "payment_detail_id":
                            {
                                myColumnTotalAmount = "amount_outstanding";
                                myColumnCurrencyCode = "related_currency_code";
                                break;
                            }
                        default:
                            break;
                    }
                    // exchange rate today
                    // get currency id 
                    let myCurrencyUSD = myComboCurrencies.find((item) => item.currency_code.toUpperCase() === "USD");
                    let myExchangeRate = 1;
                    await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(myCurrencyUSD?.currency_id ?? 0)}`)
                        .then((responseExchangeRate) => {
                            // get currency from date today
                            const today = new Date().toISOString().split("T")[0];
                            myExchangeRate = responseExchangeRate.data.data.find((rate: any) => rate.rate_date.startsWith(today))?.rate || 1;
                        });

                    const processedTotals = countColumns.map(async (column) => {
                        let myTotals = 0;
                        if (column === "total_company_currency_amount") {
                            for (let i = 0; i < data.length; i++) {
                                const element = data[i];
                                // load currencies value with exchange rate if it's different currency_id
                                if (myDefaultCurrency?.currency_code === element[myColumnCurrencyCode.toString()]) {
                                    myTotals = myTotals + element[myColumnTotalAmount.toString()];
                                    element.total_company_currency_amount = element[myColumnTotalAmount.toString()];
                                }
                                // else add total amount to value
                                else {
                                    // si el currency de salesinvoices es MXN multiplicar
                                    if (element[myColumnCurrencyCode.toString()] === "MXN") {
                                        // dividir el monto entre el tipo de cambio
                                        myTotals = myTotals + (element[myColumnTotalAmount.toString()] / (myExchangeRate > 0 ? myExchangeRate : 1));
                                        element.total_company_currency_amount = (element[myColumnTotalAmount.toString()] / (myExchangeRate > 0 ? myExchangeRate : 1));
                                    }
                                    else if (element[myColumnCurrencyCode.toString()] === "USD") {
                                        // multiplicar el tippo de cambio
                                        myTotals = myTotals + (element[myColumnTotalAmount.toString()] * myExchangeRate);
                                        element.total_company_currency_amount = (element[myColumnTotalAmount.toString()] * myExchangeRate);
                                    }
                                }
                            }
                        }
                        else {
                            myTotals = data.reduce((acc, element) => acc + (element[column] || 0), 0);
                        }

                        return {
                            id: column,
                            total: myTotals,
                        }
                    });
                    Promise.all(processedTotals)
                        .then((resolvedTotals) => {
                            setDictionaryTotals(resolvedTotals);
                            SetRowCount(resolvedTotals);
                            setDataLoaded(true);
                        });
                })
                .catch((error) => {
                    showSnackBar(error.message, 'error');
                });
        }
        else {
            setDataLoaded(true);
        }
    }, [data]);

    const handlePaginationModelChange = (params: any) => {
        setRowsPerPage(params.pageSize);
        SetRowCount(dictionaryTotals);
    };

    const scrollerTables = (tableprincipal: MutableRefObject<GridApiCommunity>, tablesecondary: MutableRefObject<GridApiCommunity>) => {
        try {
            if (tableprincipal.current.getScrollPosition && tablesecondary.current.scroll != undefined) {
                let tablescroll = tableprincipal.current.getScrollPosition();
                tablesecondary.current.scroll(tablescroll);
            }
        }
        catch (e) { }
    }

    const [scrollEvent, setScrollEvent] = useState(false);

    const onChargeGrid = () => {
        const handleScroll = () => {
            scrollerTables(apiRef1, apiRef2);
        };

        const api = apiRef1.current;
        if (api.subscribeEvent !== undefined && scrollEvent !== true) {
            api.subscribeEvent('scrollPositionChange', handleScroll);
            setScrollEvent(true);
        }
    };

    const getRowClassName = (params: any) => {
        if (params.row.product_service_key === '84111506') {
            return 'yellowRow';
        }
        else if (params.row.is_accounted) {
            return 'textBold'
        }
        return '';
    };

    return (
        <Box
            sx={{
                width: '100%',
                '& .header-grid-table': {
                    backgroundColor: 'rgba(150, 150, 150, 0.27)',
                },
                '& .MuiDataGrid-root': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                },
                '& .MuiDataGrid-virtualScroller': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                },
                fontSize: "small"
            }}>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: `${hiddenHeaderCheckBox ? "none" : ""}`,
                        },
                        boxShadow: 10,
                        '& .MuiDataGrid-row:hover': {
                            color: 'primary.main',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            color: 'ActiveCaption',
                            outline: 'none',
                            border: 'none'
                        },
                        '.MuiDataGrid-columnHeaderCheckbox': {
                            backgroundColor: 'rgba(150, 150, 150, 0.27)'
                        },
                        '& .MuiDataGrid-columnHeader--showColumnBorder': {
                            outline: 'none',
                            bordere: 'none'
                        },
                        ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                            marginTop: ".5em",
                            marginBottom: ".5em",
                        },
                        '& .MuiDataGrid-selectedRowCount': {
                            display: 'none',
                        },
                    }}
                    localeText={{
                        toolbarExport: t("generic.grid-table.toolbar-export"),
                        toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
                        columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
                        columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
                        columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
                        columnMenuFilter: t("generic.grid-table.column-menu-filter"),
                        columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
                        columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
                        columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
                        filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
                        filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
                        filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
                        filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
                        filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
                        filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
                        filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
                    }}
                    density="compact"
                    getRowId={(row) => row[entityId]}
                    rows={data}
                    columns={columns}
                    checkboxSelection={selectRows}
                    onColumnVisibilityModelChange={(e) => SetCustomPreferences(e)}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    onRowClick={({ row }) => !hasDoubleClick && onSelectedRow(row)}
                    onRowDoubleClick={({ row }) => hasDoubleClick && onSelectedRow(row)}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 50 } },
                        columns: {
                            columnVisibilityModel: localPreference,
                        },
                        sorting: {
                            sortModel: [
                                { field: 'rate_date', sort: 'desc' },
                            ]
                        },
                        filter: {
                            filterModel: {
                                items: (initFilter && initFilter.length > 0) ?
                                    initFilter.map(element => {
                                        return { field: element.columnfilter, operator: 'equals', value: element.value };
                                    })
                                    : []
                            },
                        },
                    }}
                    rowSelectionModel={preSelectedRows}
                    slots={{ pagination: CustomPagination, columnMenu: CustomColumnMenuComponent, }}
                    pageSizeOptions={[50, 75, 100]}
                    autoHeight
                    onPaginationModelChange={handlePaginationModelChange}
                    apiRef={apiRef1}
                    disableRowSelectionOnClick
                    getRowClassName={getRowClassName}
                />
            }
            <SnackbarComponent />
        </Box>

    );
}