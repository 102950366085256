import { Route, Routes } from 'react-router-dom'
import { AddCountry } from './add'
import { ViewCountry } from './view'
import { TableCountries } from './table'

export const BrowserCountries = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableCountries />} />
            <Route path={"/add"} element={<AddCountry />} />
            <Route path={"/view"} element={<ViewCountry />} />
        </Routes>
    )
}