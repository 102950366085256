import { useState } from 'react'
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Menuitem } from '../../../interfaces/Security/menu';
import { useNavigate } from 'react-router-dom';

const SubLevelComp = ({ item, renderNestedLevels }: any) => {
    const [selected, setselected] = useState("active");

    const navigate = useNavigate();

    const toggle = () => {
        setselected(selected === "" ? "active" : "");
    };

    const hasChildren = (item: Menuitem) => {
        return Array.isArray(item.children) && item.children.length > 0;
    };

    return (
        <div>
            <div
                onClick={() => {
                    if (!item.resource_id) { toggle(); } 
                }}
                style={{ cursor: "pointer" }}
                className={'item-accordion'}
            >
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        {hasChildren(item) && <>{selected === "active" ? <FontAwesomeIcon icon={faAngleDown} style={{ paddingRight: "1rem" }} /> : <FontAwesomeIcon icon={faAngleRight} style={{ paddingRight: "1rem" }} />}</>}
                        {item.option_name}
                    </div>
                </div>
            </div>
            {selected && (
                <div className="open" style={{ marginLeft: "20px" }}>
                    {hasChildren(item) && renderNestedLevels(item.children)}
                </div>
            )}
        </div>
    );
};


export const AccordionExample = ({ accordionData }: any): any => {

    const renderNestedLevels = (data: Menuitem[]) => {
        return data.map((item: Menuitem, itemIndex: number) => (
            <SubLevelComp item={item} renderNestedLevels={renderNestedLevels} key={itemIndex} />
        ));
    };

    return renderNestedLevels(accordionData);
}

