import { useTranslation } from "react-i18next";
import { PaymentDetail } from "../../../../interfaces/Purchases/Payments/payments";
import { useDates } from "../../../../hooks/useDates";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";

type TabRelatedDocumentsProps = {
  paymentsDetailsData: PaymentDetail[],
}

export const TabRelatedDocuments = ({
  paymentsDetailsData,
}: TabRelatedDocumentsProps) => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const { setFormatNumberFromTable } = useFormatNumber();
  const [dataLoaded, setDataLoaded] = useState(false);

  const [myPreferences, setMyPreferences] = useState({});
  const [columns, setColumns] = useState<GridColDef<PaymentDetail>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("purchaseinvoices.fields.purchase_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    { field: 'supplier_business_name', headerName: t("paymentsdetails.fields.supplier_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'payment_name', headerName: t("payments.fields.payment_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("paymentsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'previous_amount', headerName: t("paymentsdetails.fields.previous_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value);
      },
    },
    {
      field: 'payment_amount', headerName: t("paymentsdetails.fields.payment_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value);
      },
    },
    {
      field: 'amount_outstanding', headerName: t("paymentsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value);
      },
    },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'payment_deadline', headerName: t("paymentsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-relatedpaymentsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-relatedpaymentsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
        else {
          if (element.field === 'amount_outstanding') {
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`purchaseinvoices.fields.purchase_invoice_name`);
          }
          else if (element.field === 'uuid') {
            element.headerName = t(`receipts.fields.uuid`);
            element.renderCell = function (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
                window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.purchase_invoice_name}
              </Button>
            }
          }
          else {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    loadPreferences();
    loadColumnsOrder();

    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='screen-container'>
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={paymentsDetailsData}
              entityId={"payment_detail_id"}
              entity={`PaymentsDetails`}
              preferences={myPreferences}
              namePreferences={"grid-paymentsdetails"}
              nameOrderColumns={"grid-paymentsdetails-columns"}
              isChildren={true}
            />
          </div>
        </Box>
      }
    </>
  )
}
