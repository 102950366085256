import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, useForm } from 'react-hook-form';
import {  Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericDeleteResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { userCompaniesPayload, permissions } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      company_id: undefined,
      business_name: "",
    }
  });

  const onDelete = () => {
    GenericDeleteResource(`/usercompanies/${userCompaniesPayload.user_company_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(userCompaniesPayload?.user_company_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        setMessageSnack(error.message);
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    reset({
      business_name: userCompaniesPayload.business_name ?? "",
    })
    setDataLoaded(true)
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form className='mt-4 w-100'>

            <div className='d-flex'>

            <div className='w-100'>
                <Controller
                  name="business_name"
                  control={control}
                  rules={{ required: true, maxLength: 60 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("userCompanies.title-view")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.business_name}
                      helperText={GetError(errors.business_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  }
                />
              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              {permissions.delete ?
                <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
              }
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("userCompanies.fields.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent/>
    </>
  )
}
