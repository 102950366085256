import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { PrimaryButton } from "../../../../../theme/buttons";
import { ProjectTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectsTemplate";
import { ProjectType } from "../../../../../interfaces/Projects/Catalogs/projectTypes";
import { Customer } from "../../../../../interfaces/Sales/Catalogs/Customers/customers";
import { User } from "../../../../../interfaces/Security/users";

export const UpdateProjectsTemplate = ({ ...props }) => {
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericGetResourceGeneric,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [comboUsers, setComboUsers] = useState<User[]>([]);
  const [defaultProjectType, setDefaultProjectType] = useState<ProjectType>();
  const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
  const [defaultUser, setDefaultUser] = useState<User>();
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [
    defaultProjectsTemplateStartedDate,
    setDefaultProjectsTemplateStartedDate,
  ] = useState<Date | null>();
  const [
    defaultProjectsTemplateEndDate,
    setDefaultProjectsTemplateEndDate,
  ] = useState<Date | null>();
  const [loadEndDateFirstTime, setLoadEndDateFirstTime] = useState(true);
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDialog, setOpenDialog] = useState(false);
  const { permissions, projectsTemplatePayload } = props;
  const [projectNameDisabled, setProjectNameDisabled] = useState(true);
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    resetField,
  } = useForm<ProjectTemplate>();

  const onSubmit: SubmitHandler<ProjectTemplate> = (data) => onPut(data);

  const onPut = (data: ProjectTemplate) => {
    let myData = {
      project_type_id: data.project_type_id,
      customer_id: data.customer_id,
      project_manager_id: data.project_manager_id,
      project_template_name: data.project_template_name,
      project_template_description: data.project_template_description,
      project_template_comments: data.project_template_comments,
      project_template_start_date: defaultProjectsTemplateStartedDate,
      project_template_end_date: defaultProjectsTemplateEndDate,
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projectstemplate/${projectsTemplatePayload.project_template_id}`,
      myData
    )
      .then((response) => {
        props.onUpdate(response.data);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      }) 
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstemplate/${projectsTemplatePayload.project_template_id}`
    )
      .then((response) => {
        props.onDeleteRow(projectsTemplatePayload)
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
        e?.preventDefault();
        e?.stopPropagation();
    }
  }


  useEffect(() => {
    if (!loadEndDateFirstTime) {
      setDefaultProjectsTemplateEndDate(null);
      resetField("project_template_end_date");
      setValue(
        "project_template_end_date",
        null
      );
      return;
    }
    setValue(
      "project_template_end_date",
      projectsTemplatePayload?.project_template_end_date
    );
  }, [defaultProjectsTemplateStartedDate]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t(
        "projectstemplate.title"
      )}`;
    });

    let myPromises = [
      GenericGetResource("/projecttypes"),
      GenericGetResource("/customers"),
      GenericGetResourceGeneric("/users", "/gusers"),
      GenericGetResource("/projectstemplate"),
    ];
    Promise.all(myPromises)
      .then((responses) => {

        const filteredProjectTypes = responses[0].data.data.filter(
          (projectType:ProjectType) => !props.projectTypeIds.includes(projectType.project_type_id) ||
          projectType.project_type_id === projectsTemplatePayload.project_type_id
        );
        setComboProjectTypes(filteredProjectTypes);
        setComboCustomers(responses[1].data.data);
        setComboUsers(responses[2].data.data);

        reset({
          project_type_id: projectsTemplatePayload.project_type_id,
          customer_id: projectsTemplatePayload.customer_id,
          project_manager_id: projectsTemplatePayload.project_manager_id,
          project_template_name: projectsTemplatePayload.project_template_name,
          project_template_description:
            projectsTemplatePayload.project_template_description,
          project_template_comments:
            projectsTemplatePayload.project_template_comments,
        });

        setDefaultProjectsTemplateStartedDate(
          projectsTemplatePayload?.project_template_start_date
        );

        setDefaultProjectsTemplateEndDate(
          projectsTemplatePayload?.project_template_end_date
        );

        setDefaultProjectType(
          responses[0].data.data.find(
            (projectType: ProjectType) =>
              projectType.project_type_id ===
              projectsTemplatePayload.project_type_id
          )
        );

        setDefaultCustomer(
          responses[1].data.data.find(
            (customer: Customer) =>
              customer.customer_id === projectsTemplatePayload.customer_id
          )
        );

        setDefaultUser(
          responses[2].data.data.find(
            (user: User) =>
              user.user_id === projectsTemplatePayload.project_manager_id
          )
        );

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="project_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboProjectTypes}
                      defaultValue={defaultProjectType}
                      getOptionLabel={(option) => `${option.project_type_name}`}
                      renderOption={(props, option: ProjectType) => (
                        <div key={option.project_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.project_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.project_type_id === value.project_type_id
                      }
                      onChange={(_, values) => {
                        field.onChange(values?.project_type_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstemplate.fields.project_type")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_type_id}
                          helperText={GetError(errors.project_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="customer_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboCustomers}
                      getOptionLabel={(option) =>
                        `${
                          option.customer_identifier
                            ? option.customer_identifier
                            : "XXX"
                        } - ${
                          option.comercial_name
                            ? option.comercial_name
                            : option.business_name
                        }`
                      }
                      defaultValue={defaultCustomer}
                      renderOption={(props, option: Customer) => (
                        <div key={option.customer_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.customer_identifier
                              ? option.customer_identifier
                              : "XXX"}{" "}
                            -{" "}
                            {option.comercial_name
                              ? option.comercial_name
                              : option.business_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.customer_id === value.customer_id
                      }
                      onChange={(_, values) => {
                        field.onChange(values?.customer_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstemplate.fields.customer")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.customer_id}
                          helperText={GetError(errors.customer_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_manager_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboUsers}
                      defaultValue={defaultUser}
                      getOptionLabel={(option) => `${option.user_name}`}
                      renderOption={(props, option: User) => (
                        <div key={option.user_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.user_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.user_id === value.user_id
                      }
                      onChange={(_, values) => {
                        field.onChange(values?.user_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstemplate.fields.project_manager"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_manager_id}
                          helperText={GetError(errors.project_manager_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_template_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstemplate.fields.project_template_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_template_name}
                      helperText={GetError(errors.project_template_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      InputProps={{ readOnly: projectNameDisabled }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex w-100 pt-3">
              <div className="w-50">
                <Controller
                  name="project_template_description"
                  control={control}
                  rules={{ maxLength: 200 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstemplate.fields.project_template_description"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_template_description}
                      helperText={GetError(
                        errors.project_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_template_start_date"
                  control={control}
                  rules={{  }}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstemplate.fields.project_template_start_date"
                      )}`}
                      value={defaultProjectsTemplateStartedDate ? dayjs(defaultProjectsTemplateStartedDate) : null}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setDefaultProjectsTemplateStartedDate((prev) => values?.toDate() || null);
                        setLoadEndDateFirstTime(false);
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_template_start_date,
                          helperText: GetError(
                            errors.project_template_start_date?.type
                          ),
                        },
                      }}
                      format="DD/MM/YYYY"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex w-100 pt-3">
              <div className="w-50">
                <Controller
                  name="project_template_end_date"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openEndDateDatePicker}
                      onClose={() => setOpenEndDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstemplate.fields.project_template_end_date"
                      )}`}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null); 
                        setDefaultProjectsTemplateEndDate((prev) =>values?.toDate() || null);
                      }}
                      value={defaultProjectsTemplateEndDate ? dayjs(defaultProjectsTemplateEndDate) : null}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenEndDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_template_end_date,
                          helperText: GetError(
                            errors.project_template_end_date?.type
                          ),
                        },
                      }}
                      minDate={dayjs(defaultProjectsTemplateStartedDate)}
                      format="DD/MM/YYYY"
                      disabled={!defaultProjectsTemplateStartedDate ? true : false}
                      sx={{ paddingRight: 2 }}
                      
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex w-100 pt-3">
              <div className="w-100">
                <Controller
                  name="project_template_comments"
                  control={control}
                  rules={{ maxLength: 1000 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstemplate.fields.project_template_comments"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      multiline
                      minRows={5}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_template_comments}
                      helperText={GetError(
                        errors.project_template_comments?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => props.setOpenDialog(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions.delete ? (
                <PrimaryButton onClick={() => setOpenDialog(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectstagestemplate.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
