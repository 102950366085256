import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CancellationReason } from "../../../../../interfaces/Sales/Invoices/Cancellations/cancellationReason";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonLoading } from "../../../../../theme/buttons";
import { uuidRegex } from "../../../../../constants/Regex";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";


const getUUIDFormat = (uuid: string, keyCode: string, setReplacementFiscalFolio: Dispatch<SetStateAction<string>>) => {
  let myUuid = uuid;
  if (keyCode !== 'Backspace' && keyCode !== '-') {
    switch (uuid.length) {
      case 8:
      case 13:
      case 18:
      case 23:
        myUuid = `${myUuid}-`
        break;
    }
    setReplacementFiscalFolio((prev) => myUuid);
  }
}

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onAcept: (e: any) => void,
  comboCancellationReasons: CancellationReason[],
  cancellationReasonId: number,
  setCancellationReasonId: Dispatch<SetStateAction<number>>,
  replacementFiscalFolio: string,
  setReplacementFiscalFolio: Dispatch<SetStateAction<string>>
}

export const DialogCancelFiscalSignatureReceipt = ({
  open,
  setOpen,
  setIsLoading,
  onAcept,
  comboCancellationReasons,
  isLoading,
  cancellationReasonId,
  setCancellationReasonId,
  replacementFiscalFolio,
  setReplacementFiscalFolio,
}: DialogProps) => {
  const [t] = useTranslation("global");
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const [hasError, setHasError] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hasCancelUuid, setHasCancelUuid] = useState(false);

  useEffect(() => {
    setHasError(false);
    setCancellationReasonId(0);
    setReplacementFiscalFolio("");
    setHasCancelUuid((prev) => false);
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
          fullWidth
        >
          <DialogTitle id="responsive-dialog-title">
            {t("salesinvoices.dialog.cancel.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className='mb-2'>
              {t("salesinvoices.dialog.cancel.content")}
            </DialogContentText>
            <Autocomplete
              size="small"
              sx={{ width: "100%", marginBottom: 2 }}
              options={comboCancellationReasons}
              getOptionLabel={(option) => option.description}
              renderOption={(props, option: CancellationReason) => (
                <div key={option.cancellation_reason_id}>
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.description}
                  </Box>
                </div>
              )}
              isOptionEqualToValue={(option, value) => option.cancellation_reason_id === value.cancellation_reason_id}
              onChange={(_, values) => {
                setCancellationReasonId(values?.cancellation_reason_id || 0);
                if (comboCancellationReasons.find((item) => item.cancellation_reason_id === values?.cancellation_reason_id)?.cancellation_reason_key === "01") {
                  setHasCancelUuid(true);
                }
                else {
                  setHasCancelUuid(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("salesinvoices.dialog.cancel.cancellation-reason")}`}
                  variant="filled"
                />
              )}
            />
            {hasCancelUuid &&
              <TextField
                variant="filled"
                label={`${t("relatedsalesinvoices.fields.related_sales_invoice_uuid")}`}
                value={replacementFiscalFolio}
                onChange={(event) => {
                  setReplacementFiscalFolio(event.target.value);
                }}
                onKeyDown={(event) => {
                  getUUIDFormat(replacementFiscalFolio, event.key, setReplacementFiscalFolio);
                }}
                error={hasError}
                helperText={hasError && t("generic.forms-errors.pattern")}
                size="small"
                style={{ width: "100%" }}
              />
            }
            {!hasCancelUuid && <></>}
          </DialogContent>
          <DialogActions>
            <Button variant="text" className="m-1" onClick={() => {
              setOpen(false);
              setIsLoading(false);
              setCancellationReasonId(0);
              setReplacementFiscalFolio("");
              setHasCancelUuid(false);
            }}>{t("generic.buttons.goback")}</Button>
            <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="m-1"
              onClick={(e: any) => {
                if (!cancellationReasonId) {
                  showSnackBar(t("generic.snackbar.select-option"), "error");
                }
                else {
                  if (hasCancelUuid) {
                    let myRegex: RegExp = new RegExp(`${uuidRegex.source.substring(1, uuidRegex.source.length - 1)}`);
                    let test = myRegex.test(replacementFiscalFolio);
                    if (test) {
                      setHasError(false);
                      onAcept(e);
                    }
                    else {
                      setHasError(true);
                    }
                  }
                  else {
                    onAcept(e);
                  }
                }
              }}
            >{t("generic.buttons.accept")}</ButtonLoading>
          </DialogActions>
        </Dialog>
      }
      <SnackbarComponent />
    </>
  )
}
