import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TaxRegime } from '../../../../interfaces/Sales/Catalogs/taxRegimes';
import { Header } from '../../../../components/Header';
import { Box, Button, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddTaxRegimes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TaxRegime>({
    defaultValues: {
      tax_regime_code: '',
      description: '',
      is_person_entity: true,
      is_legal_entity: false,
    }
  });
  const onSubmit: SubmitHandler<TaxRegime> = (data) => onPost(data);

  const onPost = (data: TaxRegime) => {
    let myData = {
      tax_regime_code: data.tax_regime_code,
      description: data.description ?? null,
      is_person_entity: data.is_person_entity ?? false,
      is_legal_entity: data.is_legal_entity ?? false
    }
    setLoadingPost(true);
    GenericPostResource("/taxregimes", myData)
      .then((response) => {
        navigate("/taxregimes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/taxregimes", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("taxregimes.title")}`;
    })
  }, [])

  return (
    <>
      <Header title={t("taxregimes.title-view")} />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row d-flex justify-content-between">

              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="tax_regime_code"
                      control={control}
                      rules={{ required: true, maxLength: 5 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("taxregimes.fields.tax_regime_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.tax_regime_code}
                          helperText={GetError(errors.tax_regime_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>

                  <div className="w-50">
                    <Controller
                      name="description"
                      control={control}
                      rules={{required: true, maxLength: 100 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("taxregimes.fields.description")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.description}
                          helperText={GetError(errors.description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className="d-flex flex-column mt-3">
                  <Controller
                    name="is_person_entity"
                    control={control}
                    render={({ field }) =>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                        <Typography sx={{ ml: 2 }}>{t("taxregimes.fields.is_person_entity")}</Typography>
                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked />
                      </Box>
                    }
                  />
                  <Controller
                    name="is_legal_entity"
                    control={control}
                    render={({ field }) =>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                        <Typography sx={{ ml: 2 }}>{t("taxregimes.fields.is_legal_entity")}</Typography>
                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                      </Box>
                    }
                  />
                </div>
              </div>

            </div>
            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>
          </form>

        </div>

      </div>
      <SnackbarComponent />
    </>
  )
}
