import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import GenericPromises from "../../../api/GenericPromises";
import { Button } from '@mui/material';
import { Menuitem } from "../../../interfaces/Security/menu";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { UpdateItems } from "./update";
import { useTranslation } from "react-i18next";
import { Item } from "../../../interfaces/Commons/items";
import ItemDetail from "./itemDetail";
import { TableItemCustomers } from "../ItemCustomers/table";
import { TableItemWarehouses } from "../ItemWarehouses/table";
import { usePermissions } from "../../../hooks/usePermissions";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../theme/buttons";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { TableItemSuppliers } from "../ItemSuppliers/table";

export const ViewItems = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [itemLocal, setItemLocal] = useState<Item>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetResourceByUrl } = usePermissions();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();

  const onDelete = () => {
    let myItem = {
      item_code: itemLocal?.item_code,
      item_description: itemLocal?.item_description,
      default_item_price: itemLocal?.default_item_price ?? null,
      weight: itemLocal?.weight ?? null,
      item_type_id: itemLocal?.item_type_id ?? null,
      item_family_id: itemLocal?.item_family_id ?? null,
      item_status_id: 2,
      unit_id: itemLocal?.unit_id,
      product_service_key_id: itemLocal?.product_service_key_id,
      tax_object_id: itemLocal?.tax_object_id,
      retention_id: itemLocal?.retention_id ?? null,
      tax_id: itemLocal?.tax_id ?? null,
    }
    GenericPutResource(`/items/${itemLocal?.item_id}`, myItem)
      .then(() => {
        setOpenDialog(false);
        navigate("/items", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }



  useEffect(() => {
    if (location.state === null) return navigate("/items", { state: {}, replace: true });
    GenericGetResource(`/items/${location.state.row.item_id}`)
      .then(
        (response) => {
          setItemLocal(response.data);

          GetResourceByUrl(`/items`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("items.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/items", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />

          <div>
            <UpdateItems
              itemPayload={itemLocal}
              isEdit={resourceScreen?.update}
            />
          </div>

          <div className="mt-4">
            <ItemDetail
              Customers={
                <TableItemCustomers item_id={itemLocal?.item_id} item_code={itemLocal?.item_code} item_description={itemLocal?.item_description} />
              }
              Suppliers={
                <TableItemSuppliers item_id={itemLocal?.item_id} item_code={itemLocal?.item_code} item_description={itemLocal?.item_description} />
              }
              Warehouses={
                <TableItemWarehouses item_id={itemLocal?.item_id} item_code={itemLocal?.item_code} item_description={itemLocal?.item_description} />
              }
            />
          </div>

        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("items.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
