import { Route, Routes } from 'react-router-dom'
import { TableHazardousMaterialsKeys } from './table'
import { AddHazardousMaterialsKeys } from './add'
import { ViewHazardousMaterialsKeys } from './view'

export const BrowserHazardousMaterialsKeys = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableHazardousMaterialsKeys />} />
      <Route path={"/add"} element={<AddHazardousMaterialsKeys />} />
      <Route path={"/view"} element={<ViewHazardousMaterialsKeys />} />
    </Routes>
  )
}