import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Payment, PaymentDetail } from "../../../../interfaces/Purchases/Payments/payments";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { useTranslation } from "react-i18next";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../api/GenericPromises";
import { useDates } from "../../../../hooks/useDates";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useFiles } from "../../../../hooks/useFiles";
import { File } from "../../../../interfaces/Commons/files";
import Swal from "sweetalert2";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { Box, Button, ListItemButton, Popover, TextField, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { StampingPayment } from "../commons/stamping";
import { DialogSendEmailPayment } from "../helpers/dialogSendEmailPayment";
import { TabStatuses } from "./tabStatuses";
import { TabRelatedDocuments } from "./tabRelatedPaymentsDetails";
import DialogPaymentDetail from "./tabPaymentDetail";
import { Divider } from "../../../../components/Commons/Divider";
import { DialogCancelPayment } from "./components/dialogCancelPayment";
import { useTheme } from '@mui/material/styles';

type ViewPaymentDetailsProps = {
  paymentDetailPayload: PaymentDetail;
  setOpenDialogView: Dispatch<SetStateAction<boolean>>;
  permissionsPaymentsDetails?: Menuitem;
  permissionsPayments?: Menuitem;
  setClickedButtonGoBack: Dispatch<SetStateAction<boolean>>;
}

export const ViewPaymentDetails = ({
  paymentDetailPayload,
  setOpenDialogView,
  permissionsPaymentsDetails,
  permissionsPayments,
  setClickedButtonGoBack,
}: ViewPaymentDetailsProps) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource, GenericPatchResource, GenericGetReport, GenericPutResource } = GenericPromises();
  const { TimeConverter } = useDates();
  const { setFormatNumber } = useFormatNumber();
  const { GetResourceByUrl } = usePermissions();
  const theme = useTheme();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadedStampingInfo, setLoadedStampingInfo] = useState(false);
  const [openDialogCancelPayment, setOpenDialogCancelPayment] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [resourceGeneratePdf, setResourceGeneratePdf] = useState<Menuitem>();
  const [resourceCancelPayment, setResourceCancelPayment] = useState<Menuitem>();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [paymentLocal, setPaymentLocal] = useState<Payment>();
  const [paymentsDetailsData, setPaymentsDetailsData] = useState<PaymentDetail[]>([]);
  const [dataPaymentsDetailsSelected, setDataPaymentsDetailsSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fiscalXML, setFiscalXML] = useState<File>();
  const [fiscalQr, setFiscalQr] = useState<File>();
  const { GetFileById, GetFileIfExists, GetFiles } = useFiles();

  // const { generatePDF } = PaymentsPDF();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const getDataAndGeneratePDF = async (paymentsId: number) => {
    try {
      const response = await GenericGetReport(`/payments/pdf/${paymentsId}`, false);

      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile = await  GenericGetResource(`/filessql/`+response.data.dataIssuingCompany.rpt_image);
        response.data.dataPaymentHeader.logoImage = rptImageFile?.data.data_file  ?? undefined;
      }

      if (response.data.dataPaymentHeader.qr_file) {
        const responseQR = await GetFileById(response.data.dataPaymentHeader.qr_file);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataPaymentHeader.fiscalQr = newBase64 ?? undefined;
      }

      // let pdfDoc = await generatePDF(response);
      // return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async () => {
    try {
      if (!paymentLocal?.report_file_id) {
        const pdfDoc = await getDataAndGeneratePDF(paymentLocal?.payment_id ?? 0);
        showSnackBar(t("purchaseinvoices.reports.generated-PDF"), 'success');
      } else {
        const pdfFile = await GetFileIfExists(paymentLocal?.report_file_id);
        const downloadLink = document.createElement('a');
        const fileName = `${paymentLocal?.payment_name}.pdf`;
        downloadLink.href = pdfFile?.dataURL ?? '';
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(pdfFile);
        showSnackBar(t("purchaseinvoices.reports.downloaded-PDF"), "success");
      }
    } catch (error: any) {
      showSnackBar('Error to generate pdf', "error");
    }
  };

  const onCancelPayment = async (e: any) => {
    setIsLoading(true);

    await GenericPatchResource(`/payments/cancel/${paymentLocal?.payment_id}`,)
      .then(() => {
        Swal.fire({
          customClass: { container: "swalfire" },
          title: `${t("purchaseinvoices.info.already-canceled")}`,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: theme.palette.primary.main,
        }).then((result) => {
          if (result.isConfirmed) {
            setClickedButtonGoBack((prev) => !prev)
          }
        });
        setPaymentLocal((prev) => ({
          ...paymentLocal,
        }));
        setLoadedStampingInfo((prev) => !prev);
        setIsLoading(false);
        setOpenDialogView(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setIsLoading(false);
      })
  }

  const onSendEmail = async () => {
    if (paymentLocal) {
      let paymentsDetailsData: any[] = []
      let mySupplier = await GenericGetResource(`/suppliers/${paymentLocal.supplier_id}`);
      paymentLocal.supplier_email = mySupplier.data.supplier_email;
      paymentsDetailsData.push({
        data: paymentLocal,
        status: (mySupplier.data.supplier_email) ? "Waiting" : "Error",
        status_description: (mySupplier.data.supplier_email) ? "generic.send_email.waiting" :
          "generic.send_email.no_email"
      })
      setDataPaymentsDetailsSelected((prev: any) => paymentsDetailsData);
      setOpenEmail(true);
    }
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/payments/${paymentDetailPayload.payment_id ?? 0}`),
      GenericGetResource(`/paymentsdetails/bypaymentid/${paymentDetailPayload.payment_id}`),
    ]
    Promise.all(myPromises)
      .then(async (responses) => {
        responses[0] && setPaymentLocal(responses[0].data);
        responses[1] && setPaymentsDetailsData(responses[1].data.data);

        let myPermissions = [
          GetResourceByUrl(`/payments/pdf`),
          GetResourceByUrl(`/paymentsdetails/sendemail`),
          GetResourceByUrl(`/payments/cancel`),
        ];

        Promise.all(myPermissions)
          .then((responsesPermissions) => {
            responsesPermissions[0] && setResourceGeneratePdf(responsesPermissions[0]);
            responsesPermissions[1] && setResourceSendEmail(responsesPermissions[1]);
            responsesPermissions[2] && setResourceCancelPayment(responsesPermissions[2]);
          });

        if (!responses[0].data.xml_file_id || !responses[0].data.qr_file) {
          try {
            const response = await GenericPatchResource(`/payments/fiscalsignature/${paymentDetailPayload.payment_id}`);

            if (response.data.XML !== undefined && response.data.QR !== undefined) {
              const files = {
                qr_file: response.data.QR,
                xml_file_id: response.data.XML
              };

              await GenericPutResource(`/payments/${paymentDetailPayload.payment_id}`, files);

              const responseFiles = await GetFiles(response.data.payment_id, "Payments");

              if (responseFiles && responseFiles.length > 0) {
                responseFiles.forEach(async (element) => {
                  if (element.file_id === (response.data.XML ?? 0)) {
                    await setFiscalXML(element);
                  }
                  if (element.file_id === (response.data.QR ?? 0)) {
                    await setFiscalQr(element);
                  }
                });
              }
            }
          } catch (error) {
            showSnackBar(t("generic.snackbar.try_to_save_files"), "warning");
          }
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Header
            title={t("payments.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button aria-describedby={id2} onClick={handleClick2}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { setOpenDialogCancelPayment(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceCancelPayment?.create ?? true}>
                      {t("payments.actions.cancel")}<CancelPresentationIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onSendEmail(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceSendEmail?.create}>
                      {t("purchaseinvoices.actions.sendemail")}<EmailIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='pt-1'>
                  <Button aria-describedby={id1} onClick={handleClick1}>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceGeneratePdf?.read ?? true}>
                      {t("purchaseinvoices.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <Button onClick={() => { setOpenDialogView(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />

          {permissionsPayments?.read &&
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-50">

                  <Typography style={{ fontWeight: "bold" }}>{t("suppliers.title-view")}:</Typography>
                  <Typography>{paymentLocal?.supplier_business_name}</Typography>
                  <Typography>{paymentLocal?.supplier_rfc}</Typography>
                  <Typography>{paymentLocal?.supplier_tax_regime_code} - {paymentLocal?.supplier_tax_regime_description}</Typography>
                  <Box sx={{ paddingRight: 1, marginBottom: 0.5 }}>
                    <Divider />
                  </Box>
                  <div className="d-flex w-100">
                    <TextField
                      variant="filled"
                      label={`${t("payments.fields.payment_name")}`}
                      value={paymentLocal?.payment_name}
                      size="small"
                      sx={{ width: "100%", paddingRight: 1 }}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column-reverse w-50">
                  <div className="d-flex">
                    <TextField
                      sx={{ width: "60%", paddingRight: 1 }}
                      variant="filled"
                      label={`${t("payments.fields.total_amount")}`}
                      value={setFormatNumber({ value: paymentLocal?.total_amount ?? 0 })}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                    <TextField
                      sx={{ width: "40%" }}
                      label={`${t("currencies.title-view")}`}
                      variant="filled"
                      value={`${paymentLocal?.currency_code ?? ''} - ${paymentLocal?.currency_description ?? ''}`}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                </div>
              </div>

              <Divider />

              <div className="d-flex">
                <TextField
                  label={`${t("paymentmethods.title-view")}`}
                  variant="filled"
                  value={`${paymentLocal?.payment_method_code ?? ''} - ${paymentLocal?.payment_method_description ?? ''}`}
                  sx={{ width: "50%", paddingRight: 1 }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
                <TextField
                  label={`${t("payments.fields.payment_date")}`}
                  variant="filled"
                  value={TimeConverter(paymentLocal?.payment_date?.toString())}
                  sx={{ width: "50%" }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
              </div>
              <div className="d-flex mt-2 mb-2">
                <TextField
                  label={`${t("payments.fields.companybankaccount")}`}
                  variant="filled"
                  value={`${paymentLocal?.company_bank_name ?? ''} - ${paymentLocal?.company_account_number ?? ''}`}
                  sx={{ width: "50%", paddingRight: 1 }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
                <TextField
                  label={`${t("payments.fields.supplierbankaccount")}`}
                  variant="filled"
                  value={`${paymentLocal?.supplier_bank_name ?? ''} - ${paymentLocal?.supplier_account_number ?? ''}`}
                  sx={{ width: "50%" }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
              </div>

              <Divider />
            </>
          }

          <DialogPaymentDetail
            Payment={
              permissionsPaymentsDetails?.read &&
              <TabRelatedDocuments
                paymentsDetailsData={paymentsDetailsData}
              />
            }
            Stamping={
              permissionsPayments?.read && (loadedStampingInfo || !loadedStampingInfo) &&
              <StampingPayment
                paymentLocal={paymentLocal}
              />
            }
            Statuses={
              <TabStatuses paymentsDetailsData={paymentsDetailsData} />
            }
          />
          <DialogCancelPayment
            open={openDialogCancelPayment}
            setOpen={setOpenDialogCancelPayment}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onAcept={onCancelPayment}
          />
          <DialogSendEmailPayment
            open={openEmail}
            setOpen={setOpenEmail}
            dataPaymentsDetailsSelected={dataPaymentsDetailsSelected}
            paymentLocal={paymentLocal}
            setPaymentLocal={setPaymentLocal}
          />
        </>
      }
      <SnackbarComponent />
    </>
  )
}
