import { useTranslation } from "react-i18next";
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { City } from "../../../interfaces/Commons/Locations/cities";
import { State } from "../../../interfaces/Commons/Locations/states";
import { Country } from "../../../interfaces/Commons/Locations/countries";
import { Autocomplete, Box, Switch, TextField, Typography } from "@mui/material";
import { Divider } from "../../../components/Commons/Divider";
import { emailRegex } from "../../../constants/Regex";


type AddGeneralProps = {
  control: Control<SupplierLocation, any>;
  errors: FieldErrors<SupplierLocation>;
  comboCountries: Country[];
  comboStates: State[];
  comboCities: City[];
  getCities: Function;
  setValue: UseFormSetValue<SupplierLocation>
}

export const AddGeneral = ({ setValue, control, errors, comboCountries, comboStates, comboCities, getCities }: AddGeneralProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>();

  const [city, setCity] = useState<City | null>(null)

  const changeCity = async (stateId: number | undefined) => {
    if (stateId !== undefined) {
      await getCities(stateId)
      setCity(null)
      setIsStateSelected(true);
    }
    else {
      setIsStateSelected(false);
    }
  }

  useEffect(() => {
    changeCity(stateId);
  }, [stateId])

  useEffect(() => {
    changeCity(stateId);
  }, [])

  return (
    <>
      <div className='d-flex'>
        <div className='w-50'>
          <Controller
            name="country_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboCountries}
                getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                renderOption={(props, option: Country) => (
                  <div key={option.country_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.country_code} - {option.country_name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                onChange={(_, values) => {
                  field.onChange(values?.country_id || null)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("supplierlocations.fields.country")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.country_id}
                    helperText={GetError(errors.country_id?.type)}
                    sx={{ paddingRight: 2 }}
                  />
                )}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="state_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboStates}
                getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                renderOption={(props, option: State) => (
                  <div key={option.state_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.state_code} - {option.state_name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                onChange={(_, values) => {
                  setIsStateSelected(false);
                  setValue("city_id", undefined);
                  field.onChange(values?.state_id || null)
                  setStateId(values?.state_id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("supplierlocations.fields.state")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.state_id}
                    helperText={GetError(errors.state_id?.type)}
                  />
                )}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          {!isStateSelected &&
            <Controller
              name="city_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  options={comboCities}
                  getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                  renderOption={(props, option: City) => (
                    <div key={option.city_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.city_code} - {option.city_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                  onChange={(_, values) => {
                    field.onChange(values?.city_id || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("supplierlocations.fields.city_name")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.city_id}
                      helperText={GetError(errors.city_id?.type)}
                      sx={{ paddingRight: 2 }}

                    />
                  )}
                  disabled
                />
              }
            />
          }
          {isStateSelected &&
            <Controller
              control={control}
              name="city_id"
              rules={{ required: true }}
              render={({ field }) =>
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  options={comboCities}
                  value={city}
                  getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                  renderOption={(props, option: City) => (
                    <div key={option.city_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.city_code} - {option.city_name}
                      </Box>
                    </div>
                  )}

                  isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                  onChange={(_, values) => {
                    setCity(values);
                    field.onChange(values?.city_id || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("supplierlocations.fields.city_name")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.city_id}
                      helperText={GetError(errors.city_id?.type)}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              }
            />
          }
        </div>

        <div className='w-50'>
          <Controller
            name="street"
            control={control}
            rules={{ required: true, maxLength: 60 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.street")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.street}
                helperText={GetError(errors.street?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="postal_code"
            control={control}
            rules={{ maxLength: 5, required: true }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.postal_code")}`}
                ref={field.ref}
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                }}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.postal_code}
                helperText={GetError(errors.postal_code?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>

      </div>

      <Divider />

      <div className='d-flex'>
        <div className="w-50">
          <Controller
            name="business_name"
            control={control}
            rules={{ maxLength: 50, required: true }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.business_name")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.business_name}
                helperText={GetError(errors.business_name?.type)}
                size="small"
                style={{ width: "100%" }}
                sx={{ paddingRight: 2 }}
              />
            }
          />
        </div>
        <div className='w-50'>
          <Controller
            name="supplier_rfc"
            control={control}
            rules={{ required: true, maxLength: 13 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.supplier_rfc")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.supplier_rfc}
                helperText={GetError(errors.supplier_rfc?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>
      </div>
      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="contact_name"
            control={control}
            rules={{ maxLength: 50 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.contact_name")}`}
                ref={field.ref}
                value={field.value}
                sx={{ paddingRight: 2 }}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.contact_name}
                helperText={GetError(errors.contact_name?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>
        <div className='w-50'>
          <Controller
            name="supplier_phone_number"
            control={control}
            rules={{ maxLength: 20 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.phone_number")}`}
                ref={field.ref}
                value={field.value}
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", },
                }}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.supplier_phone_number}
                helperText={GetError(errors.supplier_phone_number?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="supplier_email"
            control={control}
            rules={{ maxLength: 1000, pattern: emailRegex }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.email")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.supplier_email}
                helperText={GetError(errors.supplier_email?.type)}
                size="small"
                style={{ width: "100%" }}
                sx={{ paddingRight: 2 }}
              />
            }
          />
        </div>
        <div className='w-50'>
          <Controller
            name="supplier_web"
            control={control}
            rules={{ maxLength: 100}}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("supplierlocations.fields.supplier_web")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.supplier_web}
                helperText={GetError(errors.supplier_web?.type)}
                size="small"
                style={{ width: "100%" }}
              />
            }
          />
        </div>
      </div>

      <div className="d-flex flex-column mt-3">
        <Controller
          name="fiscal_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("supplierlocations.fields.fiscal_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
            </Box>
          }
        />
        <Controller
          name="supply_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("supplierlocations.fields.supply_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
            </Box>
          }
        />
        <Controller
          name="default_supply_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("supplierlocations.fields.default_supply_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
            </Box>
          }
        />
      </div>
    </>
  )
}
