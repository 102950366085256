import { Route, Routes } from "react-router-dom"
import { TableTaxRegimes } from "./table"
import { AddTaxRegimes } from "./add"
import { ViewTaxRegimes } from "./view"

export const BrowserTaxRegimes = () => {

  return (
    <Routes>
      <Route path={"/"} element={<TableTaxRegimes />} />
      <Route path={"/add"} element={<AddTaxRegimes />} />
      <Route path={"/view"} element={<ViewTaxRegimes />} />
    </Routes>
  )
}
