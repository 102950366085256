import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from "react";
import { ReceiptDetailStatus } from '../../../../../interfaces/Sales/Receipts/receipts';
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import DataTable from "../../../../../components/Tables/GridTableMaterialUI/DataTable";
import { useTranslation } from "react-i18next";
import { GridColDef, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { useDates } from "../../../../../hooks/useDates";

type AccordionDetailStatusesProps = {
  title: string,
  payment_detail_id: number;
}

export const AccordionDetailStatuses = ({
  title,
  payment_detail_id,
}: AccordionDetailStatusesProps) => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const { TimeConverter } = useDates();
  const [myPreferences, setMyPreferences] = useState({});
  const [paymentDetailStatuses, setPaymentDetailStatuses] = useState<ReceiptDetailStatus[]>([]);
  const [dataLodaded, setDataLodaded] = useState(false);
  const [columns, setColumns] = useState<GridColDef<ReceiptDetailStatus>[]>([
    { field: 'new_status_name', headerName: t("paymentdetailstatuses.fields.new_status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'previous_status_name', headerName: t("paymentdetailstatuses.fields.previous_status_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-paymentdetailstatus");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-paymentdetailstatus-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ReceiptDetailStatus, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          element.headerName = t(`paymentdetailstatuses.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    GenericGetResource(`/paymentdetailstatuses/bypaymentdetailid/${payment_detail_id}`)
      .then((response) => {
        setPaymentDetailStatuses(response.data.data);

        loadPreferences();
        loadColumnsOrder();

        setDataLodaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {!dataLodaded && <Spinner isBox={false} />}
      {dataLodaded &&
        <>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
            >
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                columns={columns}
                setColumns={setColumns}
                data={paymentDetailStatuses}
                entityId={"payment_detail_status_id"}
                entity={`PaymentDetailStatuses`}
                preferences={myPreferences}
                namePreferences={"grid-paymentdetailstatus"}
                nameOrderColumns={"grid-paymentdetailstatus-columns"}
                isChildren={true}
              />
            </AccordionDetails>
          </Accordion>
        </>
      }
      <SnackbarComponent />
    </>
  )
}