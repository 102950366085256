import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { Driver } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/drivers';
import { Country } from '../../../../../interfaces/Commons/Locations/countries';
import { DriverType } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/driverTypes';
import { State } from '../../../../../interfaces/Commons/Locations/states';
import { City } from '../../../../../interfaces/Commons/Locations/cities';
import { emailRegex, rfcRegex } from '../../../../../constants/Regex';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateDrivers = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [comboCountries, setComboCountries] = useState<Country[]>([]);
    const [comboStates, setComboStates] = useState<State[]>([]);
    const [comboCities, setComboCities] = useState<City[]>([]);
    const [comboDriverTypes, setComboDriverTypes] = useState<DriverType[]>([]);
    const [defaultState, setDefaultState] = useState<State>()
    const [defaultCountries, setDefaultCountries] = useState<Country>()
    const [defaultCity, setDefaultCity] = useState<City>()
    const [defaultDriverType, setDefaultDriverType] = useState<DriverType>()
    const { driverPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Driver>({
        defaultValues: {
            driver_type_id: undefined,
            driver_name: '',
            driver_curp: '',
            driver_rfc: '',
            driver_license: '',
            nss: '',
            country_id: undefined,
            state_id: undefined,
            city_id: undefined,
            postal_code: '',
            street: '',
            phone: '',
            email: '',
        }
    });
    const onSubmit: SubmitHandler<Driver> = (data) => onPut(data);

    const onPut = (data: Driver) => {
        let myData = {
            driver_type_id: data.driver_type_id,
            driver_name: data.driver_name,
            driver_curp: data.driver_curp ?? null,
            driver_rfc: data.driver_rfc,
            driver_license: data.driver_license,
            nss: data.nss ?? null,
            country_id: data.country_id,
            state_id: data.state_id,
            city_id: data.city_id,
            postal_code: data.postal_code ?? null,
            street: data.street ?? null,
            phone: data.phone ?? null,
            email: data.email ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/drivers/${driverPayload.driver_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/countries"),
            GenericGetResource("/states"),
            GenericGetResource("/cities"),
            GenericGetResource("/drivertypes")
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboCountries(responses[0].data.data);
                setComboStates(responses[1].data.data);
                setComboCities(responses[2].data.data);
                setComboDriverTypes(responses[3].data.data);
                setDefaultCountries(responses[0].data.data.find((country: Country) => country.country_id === driverPayload.country_id))
                setDefaultState(responses[1].data.data.find((state: State) => state.state_id === driverPayload.state_id))
                setDefaultCity(responses[2].data.data.find((city: City) => city.city_id === driverPayload.city_id))
                setDefaultDriverType(responses[3].data.data.find((drivertype: DriverType) => drivertype.driver_type_id === driverPayload.driver_type_id))
                reset({
                    driver_type_id: driverPayload.driver_type_id,
                    driver_name: driverPayload.driver_name,
                    driver_curp: driverPayload.driver_curp,
                    driver_rfc: driverPayload.driver_rfc,
                    driver_license: driverPayload.driver_license,
                    nss: driverPayload.nss,
                    country_id: driverPayload.country_id,
                    state_id: driverPayload.state_id,
                    city_id: driverPayload.city_id,
                    postal_code: driverPayload.postal_code,
                    street: driverPayload.street,
                    phone: driverPayload.phone,
                    email: driverPayload.email,
                });
                setDataLoaded(true);
            })
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="driver_type_id"
                                            control={control}
                                            rules={{ required: true, maxLength: 3 }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    sx={{ width: "100%", paddingRight: 2 }}
                                                    options={comboDriverTypes}
                                                    defaultValue={defaultDriverType}
                                                    getOptionLabel={(option) => `${option.driver_type_code} - ${option.driver_type_name}`}
                                                    renderOption={(props, option: DriverType) => (
                                                        <div key={option.driver_type_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.driver_type_code} - {option.driver_type_name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.driver_type_id === value.driver_type_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.driver_type_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("drivers.fields.driver_type_id")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.driver_type_id}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="driver_name"
                                            control={control}
                                            rules={{ maxLength: 100, required: true }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.driver_name")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.driver_name}
                                                    helperText={GetError(errors.driver_name?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="driver_curp"
                                            control={control}
                                            rules={{ maxLength: 18 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.driver_curp")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.driver_curp}
                                                    helperText={GetError(errors.driver_curp?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="driver_rfc"
                                            control={control}
                                            rules={{ maxLength: 13, required: true, pattern: rfcRegex }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.driver_rfc")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.driver_rfc}
                                                    helperText={GetError(errors.driver_rfc?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="driver_license"
                                            control={control}
                                            rules={{ required: true, maxLength: 10 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.driver_license")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.driver_license}
                                                    helperText={GetError(errors.driver_license?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="nss"
                                            control={control}
                                            rules={{ maxLength: 11 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.nss")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.nss}
                                                    helperText={GetError(errors.nss?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="country_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    options={comboCountries}
                                                    defaultValue={defaultCountries}
                                                    getOptionLabel={(option) => `${option.country_id} - ${option.country_name}`}
                                                    renderOption={(props, option: Country) => (
                                                        <div key={option.country_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.country_code} - {option.country_name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.country_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("drivers.fields.country_id")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.country_id}
                                                            helperText={GetError(errors.country_id?.type)}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="state_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    options={comboStates}
                                                    defaultValue={defaultState}
                                                    getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                                                    renderOption={(props, option: State) => (
                                                        <div key={option.state_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.state_code} - {option.state_name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.state_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("drivers.fields.state_id")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.state_id}
                                                            helperText={GetError(errors.state_id?.type)}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="city_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    options={comboCities}
                                                    defaultValue={defaultCity}
                                                    getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                                                    renderOption={(props, option: City) => (
                                                        <div key={option.city_id}>
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.city_code} - {option.city_name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                                                    onChange={(_, values) => {
                                                        field.onChange(values?.city_id || null)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("drivers.fields.city_id")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.country_id}
                                                            helperText={GetError(errors.country_id?.type)}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="street"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.street")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.street}
                                                    helperText={GetError(errors.street?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="postal_code"
                                            control={control}
                                            rules={{ maxLength: 5 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.postal_code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    type="number"
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.postal_code}
                                                    helperText={GetError(errors.postal_code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='w-50'>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{ maxLength: 20 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.phone")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    type="number"
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                        "& input[type=number]": { MozAppearance: "textfield", },
                                                    }}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.phone}
                                                    helperText={GetError(errors.phone?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>

                                </div>
                                <div className='d-flex w-100 mt-3'>
                                    <div className='w-50'>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{ maxLength: 50, pattern: emailRegex }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("drivers.fields.email")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.email}
                                                    helperText={GetError(errors.email?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
