import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Control, UseFormGetValues, UseFormReset, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { Grid } from '@mui/material';
import { InvoiceBody } from './invoiceBody';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { ButtonLoading } from '../../../theme/buttons';
import { PurchaseInvoice, PurchaseInvoiceDetail } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';

type UpdatePurchaseInvoicesProps = {
  control: Control<PurchaseInvoice>,
  reset: UseFormReset<PurchaseInvoice>,
  purchaseInvoicePayload: PurchaseInvoice | undefined,
  isEdit: boolean,
  onPut: (data: PurchaseInvoice) => Promise<void>,
  setIsChangedTotalValue: Dispatch<SetStateAction<boolean>>,
  setValue: UseFormSetValue<PurchaseInvoice>,
  trigger: UseFormTrigger<PurchaseInvoice>,
  getValues: UseFormGetValues<PurchaseInvoice>,
  dataLoaded: boolean
  myLocalInvoiceDocType: number,
  rows: PurchaseInvoiceDetail[],
  setRows: Dispatch<SetStateAction<PurchaseInvoiceDetail[]>>,
  loadingPut: boolean
}

export const UpdatePurchaseInvoices = ({
  reset,
  purchaseInvoicePayload,
  isEdit,
  onPut,
  setIsChangedTotalValue,
  setValue,
  trigger,
  getValues,
  dataLoaded,
  myLocalInvoiceDocType,
  rows,
  setRows,
  loadingPut,
}: UpdatePurchaseInvoicesProps) => {
  const [t] = useTranslation("global");

  const onSave = () => {
    trigger().then(async (response) => {
      if (response) {
        await onPut(getValues());
      }
    });
  }

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item sx={{ height: 'auto', marginTop: 1 }}>
              <InvoiceBody
                rows={rows}
                setRows={setRows}
                purchaseInvoicePayload={purchaseInvoicePayload}
                setValue={setValue}
                getValues={getValues}
                setIsChangedTotalValue={setIsChangedTotalValue}
                isEdit={isEdit}
                reset={reset}
                onPut={onPut}
                myLocalInvoiceDocType={myLocalInvoiceDocType}
                loadingPut={loadingPut}
              />
            </Grid>
            <div className='d-flex flex-row-reverse pt-3'>
              {!isEdit &&
                <ButtonLoading isLoading={loadingPut} onClick={onSave} className="d-flex align-self-end w-25" variant="outlined">{t("generic.buttons.save")}</ButtonLoading>
              }
            </div>
          </Grid>
        </>
      }
    </>
  )
}
