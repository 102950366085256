import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RelatedSalesInvoice } from "../../../../interfaces/Sales/Catalogs/RelatedSalesInvoices/relatedSalesInvoices";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { PrimaryButton } from "../../../../theme/buttons";
import { SalesInvoiceRelationshipType } from '../../../../interfaces/Sales/Catalogs/salesInvoiceRelationshipTypes';
import { uuidRegex } from "../../../../constants/Regex";
import { getUUIDFormat } from ".";

export const UpdateRelatedSalesInvoice = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [defaultSalesInvoiceRelationshipType, setDefaultSalesInvoiceRelationshipType] = useState<SalesInvoiceRelationshipType | undefined>();
  const { permissions, relatedSalesInvoicePayload } = props;
  const comboSalesInvoiceRelationshipTypes: SalesInvoiceRelationshipType[] = props.comboSalesInvoiceRelationshipTypes;
  const isEdit = !permissions.update || props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<RelatedSalesInvoice>({
    defaultValues: {
    }
  });
  const onSubmit: SubmitHandler<RelatedSalesInvoice> = (data) => onPut(data);

  const onPut = (data: RelatedSalesInvoice) => {
    let myData = {
      sales_invoice_id: props.salesInvoiceId ?? null,
      related_sales_invoice: data.related_sales_invoice ?? null,
      related_sales_invoice_uuid: data.related_sales_invoice_uuid ?? null,
      related_sales_invoice_name: data.related_sales_invoice_name ?? null,
      sales_invoice_relationship_type_id: data.sales_invoice_relationship_type_id ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/relatedsalesinvoices/${relatedSalesInvoicePayload.related_sales_invoice_id}`, myData)
      .then((response) => {
        let myUpdateData: RelatedSalesInvoice = response.data;
        props.UpdateRow(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/relatedsalesinvoices/${relatedSalesInvoicePayload.related_sales_invoice_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(relatedSalesInvoicePayload.related_sales_invoice_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    reset({ ...relatedSalesInvoicePayload });

    setDefaultSalesInvoiceRelationshipType(comboSalesInvoiceRelationshipTypes.find((item) => item.sales_invoice_relationship_type_id === relatedSalesInvoicePayload.sales_invoice_relationship_type_id));

    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <div className=' h-25'><Spinner isBox={false} /></div>}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <h4>{relatedSalesInvoicePayload.related_sales_invoice_name}</h4>

            <div className='d-flex mt-1'>
              <div className='w-50'>
                <Controller
                  name="sales_invoice_relationship_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboSalesInvoiceRelationshipTypes}
                      defaultValue={defaultSalesInvoiceRelationshipType}
                      getOptionLabel={(option) => `${option.sales_invoice_relationship_type_code} - ${option.sales_invoice_relationship_type_description}`}
                      renderOption={(props, option: SalesInvoiceRelationshipType) => (
                        <div key={option.sales_invoice_relationship_type_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.sales_invoice_relationship_type_code} - {option.sales_invoice_relationship_type_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.sales_invoice_relationship_type_id === value.sales_invoice_relationship_type_id}
                      onChange={(_, values) => {
                        field.onChange(values?.sales_invoice_relationship_type_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("salesinvoicerelationshiptypes.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.sales_invoice_relationship_type_id}
                          helperText={GetError(errors.sales_invoice_relationship_type_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
              <div className='w-50'>
                <Controller
                  name="related_sales_invoice_uuid"
                  control={control}
                  rules={{ required: true, minLength: 36, maxLength: 36, pattern: uuidRegex }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("relatedsalesinvoices.fields.related_sales_invoice_uuid")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      onKeyDown={(event) => {
                        getUUIDFormat(getValues("related_sales_invoice_uuid") ?? "", event.key, setValue);
                      }}
                      error={!!errors.related_sales_invoice_uuid}
                      helperText={GetError(errors.related_sales_invoice_uuid?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>

            </div>
            {!isEdit &&
              <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                <div>
                  <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                  {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                </div>
                {permissions.delete ? <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>}
              </div>
            }

          </form>
        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("relatedsalesinvoices.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
