import { Avatar } from '@mui/material';
import { CardViewProps } from '../../interfaces/Commons/appInterfaces'
import "./index.css"

export const GenericCardView = ({ rows, width, hasImages }: CardViewProps) => {
    return (
        <div className='d-flex justify-content-center'>
            <div className="card-view d-flex flex-column align-items-around" style={{ width: width }}>
                {hasImages &&
                    <div className='d-flex flex-column'>
                        <Avatar className='m-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 350, width: 350 }} variant='rounded' alt={""}>
                            <img alt='profile-photo' width={400} height={400} src={require("../../assets/img/profile.jpeg")} />
                        </Avatar>
                    </div>
                }
                {
                    rows.map((row, index) => {
                        return (
                            (row.value === undefined || row.value === null || row.value === "null" || row.value === "") ? <div key={index}></div> :
                                <div key={index} className='d-flex flex-column card-row'>
                                    <div className='w-50'>
                                        <strong>{row.field}:</strong>
                                    </div>
                                    <div>
                                        {(row.value === undefined || row.value === null || row.value === "null") ? "" : row.value}
                                    </div>
                                </div>
                        );
                    })
                }
            </div>
        </div>
    )
}
