import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { Navigator } from './navigator/Navigator';
import { ThemeProvider } from '@mui/material';
import { defaultTheme } from './theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "./theme/custom.scss";
import "./App.css";

const AppState = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  )
}

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <BrowserRouter>
          <AppState>
            <Navigator />
          </AppState>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App;
