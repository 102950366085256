import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Supplier, SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import GenericPromises from "../../../api/GenericPromises";
import { usePermissions } from "../../../hooks/usePermissions";
import { useFiles } from "../../../hooks/useFiles";
import { Menuitem } from "../../../interfaces/Security/menu";
import { File } from "../../../interfaces/Commons/files";
import { TaxRegime } from "../../../interfaces/Sales/Catalogs/taxRegimes";
import { Currency } from "../../../interfaces/Commons/currencies";
import { Tax } from "../../../interfaces/Sales/Catalogs/taxes";
import { PaymentMode } from "../../../interfaces/Sales/Catalogs/paymentModes";
import { PaymentMethod } from "../../../interfaces/Sales/Catalogs/paymentMethods";
import { CFDI } from "../../../interfaces/Sales/Catalogs/CFDIs";
import { Retention } from "../../../interfaces/Sales/Catalogs/retentions";
import { PaymentCondition } from "../../../interfaces/Sales/Catalogs/paymentConditions";
import { Language } from "../../../interfaces/Commons/languages";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { ButtonStyled } from "../../../theme/buttons";
import { Button } from "@mui/material";
import SupplierDetail from "./supplierDetail";
import { TableSupplierLocations } from "../SupplierLocations/table";
import { FinancialDataTab } from "./finalncialData";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { UpdateSupplier } from "./update";
import { SupplierItem } from "../../../interfaces/Purchases/Catalogs/supplierItems";
import { TableSupplierItems } from "../SupplierItems/table";
import { BrowserSupplierBankAccounts } from "../SupplierBankAccounts";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";

export const ViewSupplier = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [supplierLocal, setSupplierLocal] = useState<Supplier>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isNotMexican, setIsNotMexican] = useState(false);
  const { GenericGetResource, GenericPutResource, GenericDeleteResource, GenericGet } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const { GetContentURLImage, PostFiles } = useFiles();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [imageFile, setImageFile] = useState<File>({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [defaultTax, setDefaultTax] = useState<Tax>();
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [defaultRetention, setDefaultRetention] = useState<Retention>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>();
  const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<Language>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [defaultPrimaryBankAccount, setDefaultPrimaryBankAccount] = useState<SupplierBankAccount>();
  const [comboSupplierBankAccount, setComboSupplierBankAccount] = useState<SupplierBankAccount[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Supplier>({
    defaultValues: {
      business_name: "",
      comercial_name: "",
      supplier_rfc: "",
      supplier_email: "",
      supplier_phone_number: "",
      supplier_web: "",
      tax_regime_id: undefined,
      default_currency_id: undefined,
      default_tax_id: undefined,
      default_payment_mode_id: undefined,
      default_payment_method_id: undefined,
      primary_bank_account: undefined,
      default_cfdi_id: undefined,
      default_retention_id: undefined,
      group_by: undefined,
      group_purchaseorders_by: undefined,
      group_shippingdocuments_by: undefined,
      group_invoices_by: undefined,
      default_payment_condition_id: undefined,
      language_id: undefined
    }
  });
  const onSubmit: SubmitHandler<Supplier> = (data) => onPut(data);

  const onPut = (data: Supplier) => {
    setLoadingPost(true);
    let myData = {
      business_name: data.business_name,
      comercial_name: data.comercial_name ?? null,
      supplier_rfc: data.supplier_rfc,
      supplier_email: data.supplier_email ?? null,
      supplier_phone_number: data.supplier_phone_number ?? null,
      supplier_web: data.supplier_web ?? null,
      tax_regime_id: data.tax_regime_id ?? null,
      language_id: data.language_id,
      default_currency_id: defaultCurrency?.currency_id ?? data.default_currency_id ?? null,
      default_tax_id: data.default_tax_id ?? defaultTax?.tax_id ?? null,
      default_payment_mode_id: data.default_payment_mode_id ?? defaultPaymentMode?.payment_mode_id ?? null,
      default_payment_method_id: data.default_payment_method_id ?? defaultPaymentMethod?.payment_method_id ?? null,
      default_cfdi_id: data.default_cfdi_id ?? defaultCFDI?.cfdi_id ?? null,
      default_retention_id: data.default_retention_id ?? defaultRetention?.retention_id ?? null,
      default_payment_condition_id: data.default_payment_condition_id ?? defaultPaymentConditions?.payment_condition_id ?? null,
      group_by: data.group_by ?? 0,
      group_purchaseorders_by: data.group_purchaseorders_by ?? null,
      group_shippingdocuments_by: data.group_shippingdocuments_by ?? null,
      group_invoices_by: data.group_invoices_by ?? null,
      primary_bank_account: data.primary_bank_account ?? null,
    }
    GenericPutResource(`/suppliers/${supplierLocal?.supplier_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success")
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onPostImage = (image: any) => {
    let myFile = {
      entity: "Suppliers",
      recordId: supplierLocal?.supplier_id,
      fileName: image.file_name,
      typeOfFile: image.type_of_file,
      schema_name: localStorage.getItem("schemaName"),
      Content: GetContentURLImage(image.dataURL),
    }
    PostFiles(myFile)
      .then(() => {
        setImageFile((prev) => image);
        setImageLoaded(true);
        showSnackBar(t("generic.snackbar.update_photo"), "success")
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onDelete = () => {
    let myPromises = [
      GenericGetResource(`/supplieritems/bysupplierid/${supplierLocal?.supplier_id}`),
      GenericGetResource(`/supplierlocations/bysupplierid/${supplierLocal?.supplier_id}`),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        let myChildPromises: Promise<any>[] = [];
        await responses[0].data.data.forEach((element: SupplierItem) => {
          myChildPromises.push(GenericDeleteResource(`/supplieritems/${element.supplier_item_id}`));
        });
        await responses[1].data.data.forEach((element: SupplierLocation) => {
          myChildPromises.push(GenericDeleteResource(`/supplierlocations/${element.supplier_location_id}`));
        });
        Promise.all(myChildPromises)
          .then(() => {
            GenericDeleteResource(`/suppliers/${supplierLocal?.supplier_id}`)
              .then(() => {
                setOpenDialog(false);
                navigate("/suppliers", { state: { method: "delete" }, replace: true });
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
                setOpenDialog(false);
              });
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOpenDialog(false);
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  }

  useEffect(() => {
    if (location.state === null) return navigate("/suppliers", { state: {}, replace: true });

    GenericGetResource(`/suppliers/${location.state.row.supplier_id}`)
      .then((response) => {
        setSupplierLocal((prev) => response.data);
        GetResourceByUrl("/suppliers")
          .then((response1) => {
            setResourceScreen((prev) => response1);
            let myPromises = [
              GenericGetResource("/suppliers"),
              GenericGet(`/files/recordId/${response.data.supplier_id}/entity/Suppliers/schema/${localStorage.getItem("schemaName")}`),
              GenericGetResource("/currencies"),
              GenericGetResource("/taxes"),
              GenericGetResource("/paymentmodes"),
              GenericGetResource("/paymentmethods"),
              GenericGetResource("/cfdi"),
              GenericGetResource("/taxregimes"),
              GenericGetResource("/retentions"),
              GenericGetResource("/paymentconditions"),
              GenericGetResource("/languages"),
              GenericGetResource(`/supplierbankaccounts/bysupplierid/${response.data.supplier_id}`),
            ];

            Promise.all(myPromises)
              .then((responses) => {
                setComboSuppliers(responses[0].data.data);
                reset({
                  business_name: response.data.business_name,
                  comercial_name: response.data.comercial_name ?? undefined,
                  primary_bank_account: response.data.primary_bank_account ?? undefined,
                  supplier_rfc: response.data.supplier_rfc,
                  supplier_email: response.data.supplier_email ?? undefined,
                  supplier_phone_number: response.data.supplier_phone_number ?? undefined,
                  supplier_web: response.data.supplier_web ?? undefined,
                  tax_regime_id: response.data.tax_regime_id ?? undefined,
                  default_currency_id: response.data.default_currency_id ?? undefined,
                  default_tax_id: response.data.default_tax_id ?? undefined,
                  default_payment_mode_id: response.data.default_payment_mode_id ?? undefined,
                  default_payment_method_id: response.data.default_payment_method_id ?? undefined,
                  default_cfdi_id: response.data.default_cfdi_id ?? undefined,
                  default_retention_id: response.data.default_retention_id ?? undefined,
                  group_by: response.data.group_by ?? 0,
                  group_purchaseorders_by: response.data.group_purchaseorders_by ?? undefined,
                  group_shippingdocuments_by: response.data.group_shippingdocuments_by ?? undefined,
                  group_invoices_by: response.data.group_invoices_by ?? undefined,
                  default_incoterm_id: response.data.default_incoterm_id ?? undefined,
                  default_payment_condition_id: response.data.default_payment_condition_id ?? undefined,
                  language_id: response.data.language_id,
                });

                if (responses[1].data.data && responses[1].data.data.length > 0) {
                  GenericGet(`/files/${responses[1].data.data[0].object_id}`)
                    .then((response2) => {
                      let myDataUrl = `data:${responses[1].data.data[0].type_of_file};base64,${response2.data.content}`
                      setImageFile((prev) => ({ ...prev, dataURL: myDataUrl, file_id: responses[1].data.data[0].file_id }));
                      setImageLoaded(true);
                    });
                }

                setComboCurrencies((prev) => responses[2].data.data);
                setComboTaxes((prev) => responses[3].data.data);
                setComboPaymentModes((prev) => responses[4].data.data);
                setComboPaymentMethods((prev) => responses[5].data.data);
                setComboCFDIs((prev) => responses[6].data.data);
                setComboTaxRegimes((prev) => responses[7].data.data);
                setComboRetentions((prev) => responses[8].data.data);
                setComboPaymentConditions((prev) => responses[9].data.data);
                setComboLanguages((prev) => responses[10].data.data);

                setComboSupplierBankAccount(responses[11].data.data);
                let myDefaultBankAccount = responses[11].data.data.find((item: SupplierBankAccount) => item.supplier_bank_account_id === response.data.primary_bank_account);
                if (myDefaultBankAccount) {
                  setDefaultPrimaryBankAccount(myDefaultBankAccount);
                  let myDefaultCurrency: Currency = {
                    currency_id: myDefaultBankAccount.currency_id,
                    currency_code: myDefaultBankAccount.currency_code,
                    currency_description: myDefaultBankAccount.currency_description,
                    currency_symbol: myDefaultBankAccount.currency_symbol,
                  }
                  setDefaultCurrency(myDefaultCurrency)
                  setValue("primary_bank_account", response.data.primary_bank_account)
                }

                setDefaultTax(responses[3].data.data.find((tax: Tax) => tax.tax_id === response.data.default_tax_id));
                setDefaultPaymentMode(responses[4].data.data.find((paymentMode: PaymentMode) => paymentMode.payment_mode_id === response.data.default_payment_mode_id));
                setDefaultPaymentMethod(responses[5].data.data.find((paymentMethod: PaymentMethod) => paymentMethod.payment_method_id === response.data.default_payment_method_id));
                setDefaultCFDI(responses[6].data.data.find((cfdi: CFDI) => cfdi.cfdi_id === response.data.default_cfdi_id));
                setDefaultTaxRegime((prev) => responses[7].data.data.find((item: TaxRegime) => item.tax_regime_id === response.data.tax_regime_id));
                setDefaultRetention((prev) => responses[8].data.data.find((item: Retention) => item.retention_id === response.data.default_retention_id));
                setDefaultPaymentConditions((prev) => responses[9].data.data.find((item: PaymentCondition) => item.payment_condition_id === response.data.default_payment_condition_id));
                setDefaultLanguage((prev) => responses[10].data.data.find((item: Language) => item.language_id === response.data.language_id));

                setDataLoaded(true);
              }).catch((error) => {
                showSnackBar(error.message, "error");
              });
          }).catch((error) => {
            showSnackBar(error.message, "error");
            navigate("/suppliers");
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("suppliers.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/suppliers", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <UpdateSupplier
                    supplierPayload={supplierLocal}
                    control={control}
                    errors={errors}
                    isEdit={resourceScreen?.update}
                    onPostImage={onPostImage}
                    loadingPost={loadingPost}
                    imageFile={imageFile}
                    imageLoaded={imageLoaded}
                    comboSuppliers={comboSuppliers}
                    comboTaxRegimes={comboTaxRegimes}
                    defaultTaxRegime={defaultTaxRegime}
                    comboLanguages={comboLanguages}
                    defaultLanguage={defaultLanguage}
                    supplier={supplierLocal}
                    isNotMexican={isNotMexican}
                    defaultPrimaryBankAccount={defaultPrimaryBankAccount}
                    comboSupplierBankAccount={comboSupplierBankAccount}
                    setDefaultPrimaryBankAccount={setDefaultPrimaryBankAccount}
                    setComboSupplierBankAccount={setComboSupplierBankAccount}
                    setDefaultCurrency={setDefaultCurrency}
                  />
                </div>

                <div className="mt-4">
                  <SupplierDetail
                    Locations={<TableSupplierLocations supplier_id={supplierLocal?.supplier_id} getValues={getValues} setValue={setValue} reset={reset} setIsNotMexican={setIsNotMexican} isNotMexican={isNotMexican} />}
                    Items={<TableSupplierItems supplier_id={supplierLocal?.supplier_id} business_name={supplierLocal?.business_name} />}
                    Taxes={
                      <FinancialDataTab
                        control={control}
                        errors={errors}
                        comboCurrencies={comboCurrencies}
                        defaultCurrency={defaultCurrency}
                        setDefaultCurrency={setDefaultCurrency}
                        comboTaxes={comboTaxes}
                        defaultTax={defaultTax}
                        setDefaultTax={setDefaultTax}
                        comboPaymentModes={comboPaymentModes}
                        defaultPaymentMode={defaultPaymentMode}
                        setDefaultPaymentMode={setDefaultPaymentMode}
                        comboPaymentMethods={comboPaymentMethods}
                        defaultPaymentMethod={defaultPaymentMethod}
                        setDefaultPaymentMethod={setDefaultPaymentMethod}
                        comboCFDIs={comboCFDIs}
                        defaultCFDI={defaultCFDI}
                        setDefaultCFDI={setDefaultCFDI}
                        comboRetention={comboRetentions}
                        defaultRetention={defaultRetention}
                        setDefaultRetention={setDefaultRetention}
                        comboPaymentConditions={comboPaymentConditions}
                        defaultPaymentCondition={defaultPaymentConditions}
                        setDefaultPaymentCondition={setDefaultPaymentConditions}
                        setValue={setValue}
                      />
                    }
                    SupplierBankAccounts={
                      <BrowserSupplierBankAccounts supplier_id={supplierLocal?.supplier_id} />
                    }
                  />
                </div>
              </form>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("suppliers.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
