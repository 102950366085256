import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PaymentMethod } from "../../../../interfaces/Sales/Catalogs/paymentMethods";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../../theme/buttons";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const UpdatePaymentMethods = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { paymentMethodLocal } = props;
    const isEdit = !props.isEdit;
    const {showSnackBar,SnackbarComponent}= useSnackBar();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<PaymentMethod>({
        defaultValues: {
            code: undefined,
            name: undefined,
            description: undefined,
        }
    });
    const onSubmit: SubmitHandler<PaymentMethod> = (data) => onPut(data);

    const onPut = (data: PaymentMethod) => {
        let myData = {
            code: data.code ?? null,
            name: data.name ?? null,
            description: data.description ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/paymentmethods/${paymentMethodLocal.payment_method_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"),"success")
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message,"error")
            });
    }

    useEffect(() => {
        reset({
            code: paymentMethodLocal.code ?? undefined,
            name: paymentMethodLocal.name ?? undefined,
            description: paymentMethodLocal.description ?? undefined,
        });
        setDataLoaded(true);
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="code"
                                            control={control}
                                            rules={{ required: true, maxLength: 2 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("paymentmethods.fields.code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.code}
                                                    helperText={GetError(errors.code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className='w-50'>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{ required: true, maxLength: 50 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("paymentmethods.fields.name")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.name}
                                                    helperText={GetError(errors.name?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="w-50">
                                        <Controller
                                            name="description"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    ref={field.ref}
                                                    variant="filled"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    label={t("paymentmethods.fields.description")}
                                                    error={!!errors.description}
                                                    helperText={GetError(errors.description?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>
                                </div>

                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent/>
        </>
    )
}