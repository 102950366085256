

import { Route, Routes } from 'react-router-dom'
import { TableINCOTERMs } from './table'
import { AddINCOTERMs } from './add'
import { ViewINCOTERMs } from './view'

export const BrowserINCOTERMs = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableINCOTERMs/>}/>
            <Route path={"/add"} element={<AddINCOTERMs/>}/>
            <Route path={"/view"} element={<ViewINCOTERMs/>}/>
        </Routes>
    )
}
