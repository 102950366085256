import { Route, Routes } from "react-router-dom"
import { TableSupplierBankAccounts } from "./table"


export const BrowserSupplierBankAccounts = ({ ...props }) => {
  return (
    <Routes>
      {(props.supplier_id) ?
        <Route path={"/"} element={<TableSupplierBankAccounts supplier_id={props.supplier_id} />} /> :
        <Route path={"/"} element={<TableSupplierBankAccounts setValue={props.setValue} add={props.add} BankAccountsData={props.BankAccountsData} setBankAccountsData={props.setBankAccountsData} />} />
      }
    </Routes>
  )
}
