import sxNetCalaApi from "./sxNetCala"
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const secrete = "Bearer";

const GenericPromises = () => {
    const { user, resources } = useContext(AuthContext);

    const GenericGet = async (url: string): Promise<any> => {
        try {
            const response = await sxNetCalaApi.get(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericGetResource = async (url: string): Promise<any> => {
        try {
            let resourceId = (url.indexOf("/", 1) === -1) ? (url.indexOf("?") === -1) ? url : url.substring(0, url.indexOf('?', 1)) : url.substring(0, url.indexOf('/', 1));
            const response = await sxNetCalaApi.get(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPost = async (url: string, myData: any): Promise<any> => {
        try {
            const response = await sxNetCalaApi.post(url, myData, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPostResource = async (url: string, myData: any): Promise<any> => {
        let resourceId = (url.indexOf("/", 1) === -1) ? url : url.substring(0, url.indexOf('/', 1));
        try {
            const response = await sxNetCalaApi.post(url, myData, {
                headers: {
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPostAction = async (url: string, myData: any, takeAllUrl: boolean): Promise<any> => {
        let resourceId = takeAllUrl ? url : url.substring(0, url.lastIndexOf("/"));
        try {
            const response = await sxNetCalaApi.post(url, myData, {
                headers: {
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericGetReport = async (url: string, takeAllUrl: boolean): Promise<any> => {
        let resourceId = takeAllUrl ? url : url.substring(0, url.lastIndexOf("/"));
        try {
            const response = await sxNetCalaApi.get(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPostDocument = async (url: string, myData: any, takeAllUrl: boolean): Promise<any> => {
        let resourceId = takeAllUrl ? url : url.substring(0, url.lastIndexOf("/"));
        try {
            const response = await sxNetCalaApi.post(url, myData, {
                headers: {
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPut = async (url: string, myData: any): Promise<any> => {
        try {
            const response = await sxNetCalaApi.put(url, myData, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPutResource = async (url: string, myData: any): Promise<any> => {
        let resourceId = (url.indexOf("/", 1) === -1) ? url : url.substring(0, url.indexOf('/', 1));
        try {
            const response = await sxNetCalaApi.put(url, myData, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPutAction = async (url: string, myData: any, takeAllUrl: boolean): Promise<any> => {
        let resourceId = takeAllUrl ? url : url.substring(0, url.lastIndexOf("/"));
        try {
            const response = await sxNetCalaApi.put(url, myData, {
                headers: {
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericDelete = async (url: string): Promise<any> => {
        try {
            const response = await sxNetCalaApi.delete(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericDeleteResource = async (url: string): Promise<any> => {
        let resourceId = (url.indexOf("/", 1) === -1) ? url : url.substring(0, url.indexOf('/', 1));
        try {
            const response = await sxNetCalaApi.delete(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    // GenericPatchResource
    const GenericPatchResource = async (url: string, myData?: any): Promise<any> => {
        let resourceId = (url.indexOf("/", 1) === -1) ? url : url.substring(0, url.indexOf('/', 1));
        try {
            const response = await sxNetCalaApi.patch(url, myData ?? null, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(resourceId),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericGetResourceGeneric = async (url: string, routePermission: string): Promise<any> => {
        try {
            const response = await sxNetCalaApi.get(url, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(routePermission),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    const GenericPutResourceGeneric = async (url: string, routePermission: string, data: any): Promise<any> => {
        try {
            const response = await sxNetCalaApi.put(url, data, {
                headers: {
                    'UserId': user?.user_id,
                    'Password': user?.password,
                    'GroupId': user?.group_id,
                    'ResourceId': resources?.get(routePermission),
                    'Authorization': `${secrete} ${localStorage.getItem("token")}`,
                }
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    };

    return {
        GenericGet,
        GenericGetResource,
        GenericPost,
        GenericPostResource,
        GenericPostAction,
        GenericPut,
        GenericPutResource,
        GenericDelete,
        GenericDeleteResource,
        GenericPatchResource,
        GenericGetReport,
        GenericPostDocument,
        GenericPutAction,
        GenericGetResourceGeneric,
        GenericPutResourceGeneric,
    }
}

export default GenericPromises;