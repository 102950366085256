import { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ForeignTrade } from '../../../../interfaces/Sales/Catalogs/ForeignTrade/foreignTrade';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { SalesInvoice } from '../../../../interfaces/Sales/Invoices/salesinvoices';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { CustomKeyCode } from '../../../../interfaces/Sales/Catalogs/customsKeyCodes';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { INCOTERM } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { TableForeignTrade } from './table';
import { usePermissions } from '../../../../hooks/usePermissions';
import { ButtonLoading } from '../../../../theme/buttons';

type ForeignTradeProps = {
  isEdit: boolean;
  salesInvoicePayload?: SalesInvoice;
  setLoadingPut: Dispatch<SetStateAction<boolean>>,
  loadingPut: boolean
}

export const ForeignTradetab = forwardRef(({
  isEdit,
  salesInvoicePayload,
  loadingPut,
  setLoadingPut
}: ForeignTradeProps, ref) => {
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetResourceByUrl } = usePermissions();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [foreignTradeLocal, setForeignTradeLocal] = useState<ForeignTrade>();
  const [comboCustomsKeyCodes, setComboCustomsKeyCodes] = useState<CustomKeyCode[]>([]);
  const [defaultCustomKeyCode, setDefaultCustomKeyCode] = useState<CustomKeyCode>();
  const [comboIncoterms, setComboIncoterms] = useState<INCOTERM[]>([]);
  const [defaultIncoterm, setDefaultIncoterm] = useState<INCOTERM>();
  const [resourceScreenForeignTrade, setResourceScreenForeignTrade] = useState<Menuitem>();
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const refTable = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      trigger() {
        trigger();
      },
      async ChangeTotals(id: number) {
        await ChangeTotalForeignTrade(id);
      },
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset
  } = useForm<ForeignTrade>({
    defaultValues: {

    }
  });
  const onSubmit: SubmitHandler<ForeignTrade> = (data) => onPut(data);

  const onPut = (data: ForeignTrade, foreign_trade_id?: number) => {
    setLoadingPut(true);
    let myINCOTERM = comboIncoterms.find((item: INCOTERM) => item.incoterm_id === data.incoterm_id);
    let myCustomsKeyCode = comboCustomsKeyCodes.find((item: CustomKeyCode) => item.customs_key_code_id === data.customs_key_code_id);

    let myData = {
      certificate_origin: '0' ?? null,
      customs_key_code_id: myCustomsKeyCode?.customs_key_code_id ?? (data.customs_key_code_id ?? null),
      customs_key_code: myCustomsKeyCode?.customs_key_code ?? (data.customs_key_code ?? null),
      customs_key_code_description: myCustomsKeyCode?.customs_key_code_description ?? (data.customs_key_code_description ?? null),
      incoterm_id: myINCOTERM?.incoterm_id ?? (data.incoterm_id ?? null),
      incoterm_code: myINCOTERM?.incoterm_code ?? (data.incoterm_code ?? null),
      incoterm_description: myINCOTERM?.incoterm_description ?? (data.incoterm_description ?? null),
      exchange_rate_USD: data.exchange_rate_USD ?? null,
      total_USD: data.total_USD ?? null,
      sales_invoice_id: foreignTradeLocal?.sales_invoice_id ?? null,
    }
    GenericPutResource(`/foreigntrades/${foreignTradeLocal?.foreign_trade_id ?? foreign_trade_id}`, myData)
      .then(() => {
        showSnackBar(t("generic.snackbar.update"), "success");
        ChangeTotalForeignTrade();
        trigger();
        setLoadingPut(false);
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPut(false);
      });
  }

  const ChangeTotalForeignTrade = (foreign_trade_id?: number) => {
    let myPromises = [
      GenericGetResource(`/foreigntrades/${foreignTradeLocal?.foreign_trade_id ?? foreign_trade_id}`),
      GenericGetResource(`/foreigntrademerchandises/byforeigntradeid/${foreignTradeLocal?.foreign_trade_id ?? foreign_trade_id}`)
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        setDataLoaded((prev) => false);
        reset({ ...responses[0].data });
        setValue("total_USD", responses[0].data.total_USD);
        setValue("exchange_rate_USD", responses[0].data.exchange_rate_USD);
        await refTable.current.setNewRows(responses[1].data.data);
        setIsChangedTotalValue((prev) => !prev);
        setDataLoaded((prev) => true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setDataLoaded((prev) => true);
      });
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/customskeycodes`),
      GenericGetResource(`/incoterms`),
      GenericGetResource(`/foreigntrades/bysalesinvoiceid/${salesInvoicePayload?.sales_invoice_id}`),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        responses[0] && setComboCustomsKeyCodes(responses[0].data.data);
        responses[1] && setComboIncoterms(responses[1].data.data);
        responses[2] && setForeignTradeLocal(responses[2].data.data[0]);

        reset({ ...responses[2].data.data[0] });

        if (getValues("customs_key_code")) {
          let myCustomKeyCode: CustomKeyCode = {
            customs_key_code_id: 0,
            customs_key_code: getValues("customs_key_code") ?? undefined,
            customs_key_code_description: getValues("customs_key_code_description") ?? undefined
          }
          await reset({ customs_key_code_id: 0 }, { keepValues: true });
          setDefaultCustomKeyCode((prev) => myCustomKeyCode);
        }

        if (getValues("incoterm_code")) {
          let myINCOTERM: INCOTERM = {
            incoterm_id: 0,
            incoterm_code: getValues("incoterm_code") ?? undefined,
            incoterm_description: getValues("incoterm_description") ?? undefined
          }
          await reset({ incoterm_id: 0 }, { keepValues: true });
          setDefaultIncoterm((prev) => myINCOTERM);
        }

        await GetResourceByUrl(`/foreigntrades`)
          .then((resourceResponse) => { setResourceScreenForeignTrade(resourceResponse) })
          .catch(() => { showSnackBar(t("generic.snackbar.permissions-error"), "error") });

        setDataLoaded(true);
      })
      .catch((error: any) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  useEffect(() => { }, [dataLoaded]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className="d-flex flex-row-reverse mb-2 w-100">
            {!isEdit && resourceScreenForeignTrade?.update &&
              <ButtonLoading isLoading={loadingPut} onClick={() => onPut(getValues())} className='w-25' size="small" variant='outlined'>{t("generic.buttons.save")}</ButtonLoading>
            }
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='custom-card-view mb-2 d-flex'>
              <div className="w-50">
                <Controller
                  name="customs_key_code_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboCustomsKeyCodes}
                      defaultValue={defaultCustomKeyCode}
                      getOptionLabel={(option) => `${option.customs_key_code} - ${option.customs_key_code_description}`}
                      renderOption={(props, option: CustomKeyCode) => (
                        <div key={option.customs_key_code_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.customs_key_code} - {option.customs_key_code_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.customs_key_code_id === value.customs_key_code_id}
                      onChange={(_, values) => {
                        field.onChange(values?.customs_key_code_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("customskeycodes.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.customs_key_code_id}
                          helperText={GetError(errors.customs_key_code_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="incoterm_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={comboIncoterms}
                      defaultValue={defaultIncoterm}
                      getOptionLabel={(option) => `${option.incoterm_code} - ${option.incoterm_description}`}
                      renderOption={(props, option: INCOTERM) => (
                        <div key={option.incoterm_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.incoterm_code} - {option.incoterm_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.incoterm_id === value.incoterm_id}
                      onChange={(_, values) => {
                        field.onChange(values?.incoterm_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("incoterms.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.incoterm_id}
                          helperText={GetError(errors.incoterm_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>

            <div className='custom-card-view mt-2'>
              <div className='d-flex flex-row-reverse my-1'>
                <div className='d-flex flex-row-reverse'>
                  <Controller
                    name="exchange_rate_USD"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("foreigntrades.fields.exchange_rate_USD")}`}
                        ref={field.ref}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }
                        }}
                        type="number"
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.exchange_rate_USD}
                        helperText={GetError(errors.exchange_rate_USD?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{ readOnly: true, disableUnderline: true }}
                      />
                    }
                  />
                </div>
              </div>
              <TableForeignTrade
                isEdit={isEdit}
                foreignTradeLocal={foreignTradeLocal}
                ChangeTotalForeignTrade={ChangeTotalForeignTrade}
                getValues={getValues}
                ref={refTable}
              />
            </div>
            <div className="d-flex flex-row-reverse mt-2 w-100">
              {!isEdit && resourceScreenForeignTrade?.update &&
                <ButtonLoading isLoading={loadingPut} onClick={() => onPut(getValues())} className='w-25' size="small" variant='outlined'>{t("generic.buttons.save")}</ButtonLoading>
              }
            </div>
          </form>
        </>
      }
      <SnackbarComponent />
    </>
  );
})
