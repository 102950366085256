import { useContext } from 'react';
import { Routes, Route, Navigate } from "react-router-dom"
import { Home } from "../screens/Home"
import { Login } from "../screens/Security/Login"
import { Recovery } from "../screens/Security/Recovery"
import { Spinner } from '../components/Commons/Spinner/Spinner';
import { AuthContext } from '../context/AuthContext';
import { PrivateRoute } from './PrivateRoute';
import { BrowserUsers } from '../screens/Security/Admin/Users';
import SideMenuMaterialUI from '../components/Security/SideMenuMaterialUI/SideMenuMaterialUI';
import { Profile } from '../screens/Security/Profile';
import { BrowserItems } from '../screens/Commons/Items';
import { BrowserItemTypes } from '../screens/Commons/ItemTypes';
import { BrowserItemFamilies } from '../screens/Commons/ItemFamilies';
import { BrowserCustomers } from '../screens/Sales/Catalogs/Customer/Customers';
import { BrowserSuppliers } from '../screens/Purchases/Suppliers';
import { BrowserWarehouses } from '../screens/Inventory/Warehouses';
import { BrowserCountries } from '../screens/Commons/Countries';
import { BrowserStates } from '../screens/Commons/States';
import { BrowserCities } from '../screens/Commons/Cities';
import { BrowserCurrencies } from '../screens/Commons/Currencies';
import { BrowserTaxes } from '../screens/Sales/Catalogs/Taxes';
import { BrowserPaymentModes } from '../screens/Sales/Catalogs/PaymentModes';
import { BrowserPaymentMethods } from '../screens/Sales/Catalogs/PaymentMethods';
import { BrowserCFDIs } from '../screens/Sales/Catalogs/CFDIs';
import { BrowserGroups } from '../screens/Security/Admin/Groups';
import { BrowserCompanies } from '../screens/Security/Admin/Companies';
import { BrowserConsecutives } from '../screens/Commons/Consecutives';
import { BrowserSalesInvoices } from '../screens/Sales/SalesInvoices';
import { BrowserProductServiceKey } from '../screens/Sales/Catalogs/ProductServiceKeys';
import { BrowserUnits } from '../screens/Commons/Units';
import { BrowserTaxObjects } from '../screens/Sales/Catalogs/TaxObjects';
import { BrowserCancellationReasons } from '../screens/Sales/Cancellations/CancellationReasons';
import { BrowserTaxRegimes } from '../screens/Sales/Catalogs/TaxRegimes';
import { BrowserChassis } from '../screens/Sales/Catalogs/TransportationDocument/Chassis';
import { BrowserTrailerTypes } from '../screens/Sales/Catalogs/TransportationDocument/TrailerTypes';
import { BrowserCancelledSalesInvoices } from '../screens/Sales/Cancellations/CancelledSalesInvoices';
import { BrowserTransportations } from '../screens/Sales/Catalogs/TransportationDocument/Transportations';
import { BrowserVehicleConfigurations } from '../screens/Sales/Catalogs/TransportationDocument/VehicleConfigurations';
import { BrowserDriverTypes } from '../screens/Sales/Catalogs/TransportationDocument/DriverTypes';
import { BrowserDrivers } from '../screens/Sales/Catalogs/TransportationDocument/Drivers';
import { BrowserRetentions } from '../screens/Sales/Catalogs/Retentions';
import { BrowserINCOTERMs } from '../screens/Sales/Catalogs/INCOTERMs';
import { BrowserPaymentConditions } from '../screens/Sales/Catalogs/PaymentConditions';
import { BrowserTariffFractions } from '../screens/Sales/Catalogs/TariffFractions';
import { BrowserBankAccounts } from '../screens/Commons/BankAccounts';
import { BrowserCustomsKeyCodes } from '../screens/Sales/Catalogs/CustomsKeyCodes';
import { BrowserMeasureUnitConversions } from '../screens/Commons/MeasureUnitConversions';
import { BrowserPackagingTypes } from '../screens/Sales/SalesInvoices/TransportationDocument/PackagingTypes';
import { BrowserHazardousMaterialsKeys } from '../screens/Sales/Catalogs/TransportationDocument/HazardousMaterialsKey';
import { BrowserSalesInvoiceRelationshipTypes } from '../screens/Sales/Catalogs/SalesInvoiceRelationshipTypes';
import { BrowserReceiptDetails } from '../screens/Sales/Receipts/ReceiptsDetails';
import { BrowserLanguages } from '../screens/Commons/Languages';
import { BrowserMessagesMails } from '../screens/Commons/MessagesMails';
import { BrowserProjectTypes } from '../screens/Projects/Catalogs/ProjectTypes';
import { BrowserProjects } from '../screens/Projects/Projects';
import { BrowserPurchaseInvoices } from '../screens/Purchases/PurchaseInvoice';
import { BrowserPaymentsDetails } from '../screens/Purchases/Payments/PaymentsDetails';
import { BrowserAuthorizationPayments } from '../screens/Purchases/Payments/AuthorizationPayments';
import { BrowserProjectTemplate } from '../screens/Projects/Catalogs/Templates/ProjectsTemplate';

export const Navigator = () => {
    const { status, user } = useContext(AuthContext);

    if (status === 'checking') return <Spinner />
    return (
        <>
            {status === 'ahutenticated' ?
                <>
                    <SideMenuMaterialUI router={
                        <Routes>
                            <Route element={<PrivateRoute isAllowed={user} redirectTo={"/"} />}>
                                <Route path="/home" element={<Home />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/users/*" element={<BrowserUsers />} />
                                <Route path="/items/*" element={<BrowserItems />} />
                                <Route path="/itemtypes/*" element={<BrowserItemTypes />} />
                                <Route path="/itemFamilies/*" element={<BrowserItemFamilies />} />
                                <Route path="/customers/*" element={<BrowserCustomers />} />
                                <Route path="/suppliers/*" element={<BrowserSuppliers />} />
                                <Route path="/warehouses/*" element={<BrowserWarehouses />} />
                                <Route path="/countries/*" element={<BrowserCountries />} />
                                <Route path="/states/*" element={<BrowserStates />} />
                                <Route path="/cities/*" element={<BrowserCities />} />
                                <Route path="/currencies/*" element={<BrowserCurrencies />} />
                                <Route path="/taxes/*" element={<BrowserTaxes />} />
                                <Route path="/paymentconditions/*" element={<BrowserPaymentConditions />} />
                                <Route path="/paymentmodes/*" element={<BrowserPaymentModes />} />
                                <Route path="/paymentmethods/*" element={<BrowserPaymentMethods />} />
                                <Route path="/cfdi/*" element={<BrowserCFDIs />} />
                                <Route path="/groups/*" element={<BrowserGroups />} />
                                <Route path="/companies/*" element={<BrowserCompanies />} />
                                <Route path="/consecutives/*" element={<BrowserConsecutives />} />
                                <Route path='/salesinvoices/*' element={<BrowserSalesInvoices />} />
                                <Route path="/productservicekeys/*" element={<BrowserProductServiceKey />} />
                                <Route path='/units/*' element={<BrowserUnits />} />
                                <Route path='/taxobjects/*' element={<BrowserTaxObjects />} />
                                <Route path='/cancellationreasons/*' element={<BrowserCancellationReasons />} />
                                <Route path='/taxregimes/*' element={<BrowserTaxRegimes />} />
                                <Route path='/chassis/*' element={<BrowserChassis />} />
                                <Route path='/trailertypes/*' element={<BrowserTrailerTypes />} />
                                <Route path='/cancelledsalesinvoices/*' element={<BrowserCancelledSalesInvoices />} />
                                <Route path='/transportations/*' element={<BrowserTransportations />} />
                                <Route path='/vehicleconfigurations/*' element={<BrowserVehicleConfigurations />} />
                                <Route path='/drivertypes/*' element={<BrowserDriverTypes />} />
                                <Route path='/drivers/*' element={<BrowserDrivers />} />
                                <Route path='/retentions/*' element={<BrowserRetentions />} />
                                <Route path='/incoterms/*' element={<BrowserINCOTERMs />} />
                                <Route path='/tarifffractions/*' element={<BrowserTariffFractions />} />
                                <Route path='/bankaccounts/*' element={<BrowserBankAccounts />} />
                                <Route path='/customskeycodes/*' element={<BrowserCustomsKeyCodes />} />
                                <Route path='/measureunitconversions/*' element={<BrowserMeasureUnitConversions />} />
                                <Route path='/packagingtypes/*' element={<BrowserPackagingTypes />} />
                                <Route path='/hazardousmaterialskeys/*' element={<BrowserHazardousMaterialsKeys />} />
                                <Route path='/salesinvoicerelationshiptypes/*' element={<BrowserSalesInvoiceRelationshipTypes />} />
                                <Route path='/receiptsdetails/*' element={<BrowserReceiptDetails />} />
                                <Route path='/languages/*' element={<BrowserLanguages />} />
                                <Route path='/messagesmails/*' element={<BrowserMessagesMails />} />
                                <Route path='/projecttypes/*' element={<BrowserProjectTypes />} />
                                <Route path='/projects/*' element={<BrowserProjects />} />
                                <Route path='/purchaseinvoices/*' element={<BrowserPurchaseInvoices />} />
                                <Route path='/paymentsdetails/*' element={<BrowserPaymentsDetails />} />
                                <Route path='/authorizationpayments/*' element={<BrowserAuthorizationPayments />} />
                                <Route path='/projectstemplate/*' element={<BrowserProjectTemplate />} />
                                <Route path="*" element={<Navigate to="/home" />} />
                            </Route>
                        </Routes>
                    } />
                </>
                :
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/recovery" element={<Recovery />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            }
        </>
    )
}
