import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { AuthContext } from '../../../../context/AuthContext';
import { useDates } from '../../../../hooks/useDates';
import { GridColDef, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../hooks/usePermissions';
import { ProjectExtraField } from '../../../../interfaces/Projects/Catalogs/projectExtraFields';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { PrimaryButton } from '../../../../theme/buttons';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { AddProjectExtraFields } from './add';
import { Header } from '../../../../components/Header';
import { UpdateProjectExtraFields } from './update';
import { AlertColor } from '@mui/material';

type TableProjectExtraFieldsProps = {
    projectExtraFieldsData: ProjectExtraField[],
    setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
    project_id: number,
    isClosedProject: boolean,
    showSnackBar: (message: string, severity: AlertColor) => void,
}

export const TableProjectExtraFields = ({
    projectExtraFieldsData,
    setProjectExtraFieldsData,
    project_id,
    isClosedProject,
    showSnackBar,
}: TableProjectExtraFieldsProps) => {
    const [t] = useTranslation("global");
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { TimeConverter } = useDates();
    const [unauthorized, setUnauthorized] = useState(true);
    const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [projectExtraFieldPayload, setProjectExtraFieldPayload] = useState({});
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const [messageSnack, setMessageSnack] = useState("");
    const [myPreferences, setMyPreferences] = useState({});
    const { GetResourceByUrl } = usePermissions();
    const [columnsProjectExtraFields, setColumnsProjectExtraFields] = useState<GridColDef<ProjectExtraField>[]>([
        { field: 'project_extra_field_name', headerName: t("projectextrafields.fields.project_extra_field_name"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'field_type_name', headerName: t("projectextrafields.fields.field_type"), headerClassName: 'header-grid-table', flex: 1, },
        {
            field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
        { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
    ]);

    const methodUsed = useCallback(() => {
        switch (location.state.method) {
            case "add":
                {
                    showSnackBar(t("generic.snackbar.add"), "success");
                    break;
                }
            case "delete":
                {
                    showSnackBar(t("generic.snackbar.delete"), "success");
                    break;
                }
        }

    }, [location.state, t]);

    const loadPreferences = async () => {
        let myPreferences = await localStorage.getItem("grid-projectextrafields-items");
        if (myPreferences != null) {
            setMyPreferences(JSON.parse(myPreferences));
        }
    }

    const loadColumnsOrder = async () => {
        let myOrderColumns = await localStorage.getItem("grid-projectextrafields-columns");
        if (myOrderColumns != null) {
            let myJson = JSON.parse(myOrderColumns);
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];
                if (element['type'] === 'date') {
                    element.headerName = t(`generic.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<ProjectExtraField, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                if (element.field) {

                }
                else {
                    element.headerName = t(`projectextrafields.fields.${element.field}`);
                }
            }
            setColumnsProjectExtraFields(myJson);
        }
    }

    const handleAddRow = (newRow: ProjectExtraField) => {
        let myRow = {
            ...newRow,
            creation_date: new Date(),
            last_update_user: user?.user_email,
            last_update_date: new Date()
        }
        setProjectExtraFieldsData((prevState) => [...projectExtraFieldsData, myRow]);
    };

    const handleUpdateRow = (updateRow: ProjectExtraField) => {
        if (projectExtraFieldsData.length === 0) {
            return;
        }
        setProjectExtraFieldsData((prevRows) => {
            const rowToUpdateIndex = projectExtraFieldsData.findIndex(e => e.project_extra_field_id === updateRow.project_extra_field_id);

            return prevRows.map((row, index) =>
                index === rowToUpdateIndex ? updateRow : row,
            );
        });
    };

    const handleDeleteRow = (deleteRow: number) => {
        if (projectExtraFieldsData.length === 0) {
            return;
        }
        setProjectExtraFieldsData((prevRows) => {
            const rowToDeleteIndex = projectExtraFieldsData.findIndex(e => e.project_extra_field_id === deleteRow);
            return [
                ...projectExtraFieldsData.slice(0, rowToDeleteIndex),
                ...projectExtraFieldsData.slice(rowToDeleteIndex + 1),
            ];
        });
    };

    useEffect(() => {
        if (location.state !== null && location.state.method) methodUsed();

        GetResourceByUrl(`/projectextrafields`)
            .then((response1) => {
                setResourceScreen((prev) => response1);
                loadColumnsOrder();
                loadPreferences();
                setDataLoaded(true);
            })
            .catch((error) => {
                setMessageSnack(error.message);
                showSnackBar(error.message, 'error');
                setUnauthorized(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
            {unauthorized && !dataLoaded && <Spinner isBox={false} />}
            {dataLoaded && resourceScreen?.read &&
                <>
                    <div className='screen-container'>
                        {resourceScreen?.create === true &&
                            <div className='d-flex flex-row-reverse my-1'>
                                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }} disabled={isClosedProject}>{t("generic.buttons.add")}</PrimaryButton>
                            </div>
                        }
                        <div className="d-flex justify-content-center">
                            <DataTable
                                columns={columnsProjectExtraFields}
                                setColumns={setColumnsProjectExtraFields}
                                data={projectExtraFieldsData}
                                entityId={"project_extra_field_id"}
                                entity={`ProjectExtraFields`}
                                preferences={myPreferences}
                                namePreferences={"grid-projectextrafields"}
                                nameOrderColumns={"grid-projectextrafields-columns"}
                                isChildren={true}
                                setOpenDialog={setOpenDialogUpdate}
                                setDataRow={setProjectExtraFieldPayload}
                            />
                        </div>
                    </div>
                    <DialogEntity
                        content={<UpdateProjectExtraFields projectExtraFieldPayload={projectExtraFieldPayload} setOpenDialogUpdate={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} isClosedProject={isClosedProject} />}
                        open={openDialogUpdate}
                        setOpen={setOpenDialogUpdate}
                        title={<Header title={t("projectextrafields.title-view")} size='sm' />}
                    />
                    <DialogEntity
                        content={<AddProjectExtraFields setOpenDialog={setOpenDialogAdd} project_id={project_id} AddNewRow={handleAddRow} isClosedProject={isClosedProject} />}
                        open={openDialogAdd}
                        setOpen={setOpenDialogAdd}
                        title={<Header title={t("projectextrafields.title-view")} size='sm' />}
                    />
                </>
            }
        </>
    )
}
