import { Route, Routes } from 'react-router-dom'
import { TableSalesInvoices } from './table'
import { ViewSalesInvoices } from './view'
import { AddSalesInvoices } from './add'

export const BrowserSalesInvoices = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableSalesInvoices />} />
      <Route path={"/add"} element={<AddSalesInvoices/>} />
      <Route path={"/view"} element={<ViewSalesInvoices />} />
    </Routes>
  )
}