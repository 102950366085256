import { GridValidRowModel } from "@mui/x-data-grid";
import { Payment } from "../../../../interfaces/Purchases/Payments/payments";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { useSendEmails } from "../../../../hooks/useSendEmails";
import { AuthContext } from "../../../../context/AuthContext";
import { CompanyConfiguration } from "../../../../interfaces/Security/companies";
import { emailSeparatedByCommas } from "../../../../constants/Regex";
import { MessageMails } from "../../../../interfaces/Commons/messagesMails";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemAvatar, Paper, TextField, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { ButtonLoading } from "../../../../theme/buttons";

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  dataPaymentsDetailsSelected: readonly GridValidRowModel[],
  paymentLocal: Payment | undefined,
  setPaymentLocal: Dispatch<SetStateAction<Payment | undefined>>,
}

export const DialogSendEmailPayment = ({
  open,
  setOpen,
  dataPaymentsDetailsSelected,
  paymentLocal,
  setPaymentLocal,
}: DialogProps) => {
  const [t] = useTranslation("global");
  const { GenericPatchResource, GenericPutResource, GenericGetResource } = GenericPromises();
  const { GetCompanyConfiguration, ReplaceMessages } = useSendEmails();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const [subject, setSubject] = useState("");
  const [error, setError] = useState<string>('');
  const [body, setBody] = useState("");
  const [company, setCompany] = useState<CompanyConfiguration>();
  const [data, setData] = useState<readonly GridValidRowModel[]>(dataPaymentsDetailsSelected);

  const onChangeEmails = (event: any, payment_id: number) => {
    let email = event.target.value;

    let myCleanEmails = email.trim().replace(/\s*,\s*/g, ',');
    let myEmails = myCleanEmails.split(',');

    const emailRegex = emailSeparatedByCommas;

    let hasError = false;
    let hasValidEmail = false;

    for (let i = 0; i < myEmails.length; i++) {
      const e = myEmails[i];

      if (e === '') {
        // Si el email está vacío (resultado de ", ,"), continuamos con el siguiente
        continue;
      }

      if (emailRegex.test(e)) {
        hasValidEmail = true;
      } else {
        hasError = true;
        setError('generic.send_email.email_no_valid');
        const updatedData = data.map(item =>
          (item.data.payment_id === payment_id)
            ? {
              ...item,
              status: 'Error',
              status_description: 'generic.send_email.email_no_valid',
              data: {
                ...item.data,
                supplier_email: email
              }
            }
            : item
        );
        setData(updatedData);
        break;  // Salimos del bucle ya que encontramos un error
      }
    }

    if (!hasValidEmail) {
      hasError = true;
      setError('generic.send_email.email_no_valid');
      const updatedData = data.map(item =>
        (item.data.payment_id === payment_id)
          ? {
            ...item,
            status: 'Error',
            status_description: 'generic.send_email.email_no_valid',
            data: {
              ...item.data,
              supplier_email: email
            }
          }
          : item
      );
      setData(updatedData);
    }

    // Si no hay errores, actualizamos los datos con el estado "Waiting"
    if (!hasError) {
      setError('');
      const updatedData = data.map(item =>
        (item.data.payment_id === payment_id)
          ? {
            ...item,
            data: {
              ...item.data,
              supplier_email: email
            },
            status: "Waiting",
            status_description: "generic.send_email.waiting"
          }
          : item
      );
      setData(updatedData);
    }
  };

  const validateButtonSendEmail = (): boolean => {
    let myValidation = data.findIndex(
      (element) =>
        // si tiene error
        error.length !== 0 ||
        (element.status === 'Error') ||
        // esta en estado diferente de espera
        (element.status !== 'Waiting') ||
        // tiene valor en correos, cuerpo y asunto
        body.length === 0 ||
        subject.length === 0 ||
        element.data.supplier_email.length === 0
    );
    return myValidation === -1 ? false : true;
  }

  const onSend = async () => {
    setProgress(true);
    const updatedData = data.map(item => ({
      ...item,
      status: 'Process',
      status_description: 'generic.send_email.processing'
    }));
    setData(updatedData);

    for (let i = 0; i < updatedData.length; i++) {
      let files: number[] = [];
      if (typeof data[i].data.xml_file_id === "number") {
        files.push(data[i].data.xml_file_id);
      }
      else {
        const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_xml' };
        setData(prevData => {
          const newData = [...prevData];
          newData[i] = updatedItem;
          return newData;
        });
        setProgress(false);
        continue;
      }
      if (typeof data[i].data.report_file_id === "number") {
        files.push(data[i].data.report_file_id);
      }
      else {
        const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_pdf' };
        setData(prevData => {
          const newData = [...prevData];
          newData[i] = updatedItem;
          return newData;
        });
        setProgress(false);
        continue;
      }
      let dataMessage = {
        emails: data[i].data.supplier_email ?? null,
        subject: subject,
        body: body,
        files: files,
        company: {
          host_mail: company?.host_mail,
          port_mail: company?.port_mail,
          username_mail: company?.username_mail,
          password_mail: company?.password_mail,
        }
      }
      await GenericPatchResource("/payments/sendemail", dataMessage)
        .then(async () => {
          const updatedData = await Promise.all(
            data.map(async (item, index) => {
              if (index === i) {

                let myPutData = {
                  date_mail_send: new Date(),
                  user_mail_send: user?.user_email,
                }

                GenericPutResource(`/payments/${paymentLocal?.payment_id}`, myPutData)
                  .then((responsePut) => {
                    setPaymentLocal({
                      ...paymentLocal,
                      user_mail_send: responsePut.data.user_mail_send,
                      date_mail_send: responsePut.data.date_mail_send,
                    });
                  });

                return { ...item, status: 'Send', status_description: 'generic.send_email.sent' };
              } else {
                return item;
              }
            })
          );
          setProgress(false);
          setData(updatedData);
        })
        .catch((error) => {
          setData(prevData => prevData.map((item, index) =>
            index === i ? { ...item, status: 'Error', status_description: error.response.data.details.toString().length > 155 ? error.response.data.details.toString().substring(0, 154) : error.response.data.details } : item
          ));
          setProgress(false);
        });
    }
  }

  useEffect(() => {
    if (dataPaymentsDetailsSelected.length > 0 && open) {
      setError('');
      setBody('');
      setSubject('');
      setProgress(false);
      setData(dataPaymentsDetailsSelected);
      let myPromises = [
        GenericGetResource(`/messagesmails`),
        GetCompanyConfiguration(),
      ];
      Promise.all(myPromises)
        .then((responses) => {
          // traer el lenguaje del supplier
          let messages = responses[0].data.data.filter((element: MessageMails) => {
            if ((element.language_code.toLocaleUpperCase() === dataPaymentsDetailsSelected[0].data.supplier_language.toLocaleUpperCase()) && (element.consecutive_id === dataPaymentsDetailsSelected[0].data.consecutive_id))
              return element;
          });
          // si tiene
          if (messages && messages.length > 0) {
            if (messages[0].body != undefined || messages[0].body != "") {
              let bodyFormated = ReplaceMessages(messages[0].body, dataPaymentsDetailsSelected[0].data);
              setBody(bodyFormated);
            }
            if (messages[0].subject != undefined || messages[0].subject != "") {
              let subjectFormated = ReplaceMessages(messages[0].subject, dataPaymentsDetailsSelected[0].data);
              setSubject(subjectFormated);
            }
          }
          // si no tiene traer el principal
          else {
            let message = responses[0].data.data.find((element: MessageMails) => (element.main === true) && (element.consecutive_id === dataPaymentsDetailsSelected[0].data.consecutive_id))
            if (message.body != undefined || message.body != "") {
              let bodyFormated = ReplaceMessages(message.body, dataPaymentsDetailsSelected[0].data);
              setBody(bodyFormated);
            }
            if (message.subject != undefined || message.subject != "") {
              let subjectFormated = ReplaceMessages(message.subject, dataPaymentsDetailsSelected[0].data);
              setSubject(subjectFormated);
            }
          }
          setCompany(responses[1].data.data[0]);
          setDataLoaded(true);
        }).catch((error) => {
          setDataLoaded(true);
        })
    }
  }, [open]);

  useEffect(() => { validateButtonSendEmail(); }, [subject, body, data]);

  return (
    <>
      {dataLoaded &&
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          maxWidth={'lg'}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="responsive-dialog-title">
            {t("generic.send_email.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className='mb-2'>
              {t("generic.send_email.singular_content")}
            </DialogContentText>
            <List>
              {data.map(item => (
                <ListItem key={item.data.payment_id}>
                  <Box className='w-100 d-flex flex-column'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ListItemAvatar>
                          {item.status === 'Error' ? (
                            <ErrorOutlineRoundedIcon />
                          ) : item.status === 'Waiting' ? (
                            <HelpRoundedIcon />
                          ) : item.status === 'Process' ? (
                            <CircularProgress size={30} sx={{ color: 'green' }} />
                          ) : (
                            <CheckRoundedIcon />
                          )}
                        </ListItemAvatar>
                      </Box>
                      <Box>
                        <Typography variant="h6">
                          {item.data.payment_name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: `${item.status === 'Error'
                              ? 'error.main'
                              : item.status === 'Waiting'
                                ? '#0277bd'
                                : item.status === 'Process'
                                  ? '#00838f'
                                  : 'green'
                              }`,
                            maxWidth: 400
                          }}
                          variant="body2"
                          display="flex"
                          alignItems="center"
                        >
                          {t(item.status_description)}
                        </Typography>
                      </Box>
                    </Box>
                    {!dataLoaded && <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 2
                      }}
                    >
                      <CircularProgress size={35} />
                    </Box>}
                    {dataLoaded && item.status != 'Send' &&
                      <Box>
                        <Paper className='w-100 mt-3'>
                          <Box className='d-flex flex-column pt-1'>
                            <Box className='d-flex flex-row' sx={{ alignItems: 'center' }}>
                              <Box sx={{ width: '100px', marginRight: 1 }}>
                                <Typography variant="body1" sx={{ textAlign: 'right' }}>
                                  {t("generic.send_email.emails")}
                                </Typography>
                              </Box>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: '2px' }}
                              />
                              <Box className='w-100' sx={{ marginX: 1 }}>
                                <TextField
                                  value={item.data.supplier_email}
                                  InputProps={{ sx: { height: 40 } }}
                                  fullWidth
                                  disabled={progress}
                                  onChange={(e) => onChangeEmails(e, item.data.payment_id)}
                                  error={!!error}
                                  helperText={t(error)}
                                />
                              </Box>
                            </Box>
                            <Divider
                              flexItem
                              sx={{
                                height: '2px',
                                marginY: 0.5
                              }} />
                            <Box className='d-flex flex-row' sx={{ alignItems: 'center' }}>
                              <Box sx={{ width: '100px', marginRight: 1 }}>
                                <Typography variant="body1" sx={{ textAlign: 'right' }}>
                                  {t("generic.send_email.subject")}
                                </Typography>
                              </Box>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: '2px' }}
                              />
                              <Box className='w-100 pb-1' sx={{ marginX: 1 }}>
                                <TextField
                                  InputProps={{ sx: { height: 40 } }}
                                  inputProps={{ maxLength: 255 }}
                                  value={subject}
                                  fullWidth
                                  disabled={progress}
                                  onChange={(event) => { setSubject(event.target.value); }}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Divider
                            flexItem
                            sx={{
                              height: '2px'
                            }} />
                          <Box>
                            <Box className='w-100'>
                              <TextField
                                label={`${t("generic.send_email.content")}`}
                                inputProps={{ maxLength: 50000 }}
                                multiline
                                value={body}
                                minRows={7}
                                size="small"
                                sx={{ width: "100%", margin: 1, paddingRight: 2 }}
                                onChange={(event) => { setBody(event.target.value); }}
                                disabled={progress}
                              />
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    }
                  </Box>
                </ListItem>
              ))}
            </List>
          </DialogContent >
          <DialogActions>
            <Button variant="text" className="m-1" disabled={progress} onClick={() => { setOpen(false); }}>{t("generic.buttons.goback")}</Button>
            <ButtonLoading
              variant="outlined"
              type="button"
              onClick={() => {
                if (progress) {
                  setProgress(false);
                  setOpen(false);
                  setDataLoaded(false);
                }
                else {
                  onSend();
                }
              }}
              className="btn m-1"
              disabled={validateButtonSendEmail()}
            >
              {t("generic.buttons.accept")}
            </ButtonLoading>
          </DialogActions>
        </Dialog >
      }
    </>
  )
}
