import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TablePaymentConditions } from './table'
import { AddPaymentConditions } from './add'
import { ViewPaymentConditions } from './view'

export const BrowserPaymentConditions = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePaymentConditions />} />
      <Route path={"/add"} element={<AddPaymentConditions />} />
      <Route path={"/view"} element={<ViewPaymentConditions />} />
    </Routes>
  )
}
