import { Route, Routes } from 'react-router-dom'
import { TableRetentions } from './table'
import { AddRetention } from './add'
import { ViewRetentions } from './view'

export const BrowserRetentions = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableRetentions />} />
            <Route path={"/add"} element={<AddRetention/>} />
            <Route path={"/view"} element={<ViewRetentions/>}/>
        </Routes>
    )
}
