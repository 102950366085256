import { GridColDef, GridInputRowSelectionModel, GridRenderCellParams, GridRowId, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDates } from '../../../../hooks/useDates';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Receipt, ReceiptDetail } from '../../../../interfaces/Sales/Receipts/receipts';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { UseFormGetValues } from 'react-hook-form';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';

type TableApplyReceiptProps = {
  rows: readonly GridValidRowModel[],
  getValues: UseFormGetValues<Receipt>,
  setSelectedRows: Dispatch<SetStateAction<readonly GridValidRowModel[]>>,
  onChangeTotals: (myRows: readonly GridValidRowModel[]) => void,
  preSelectedRows: GridInputRowSelectionModel | undefined,
  setPreSelectedRows: Dispatch<SetStateAction<GridInputRowSelectionModel | undefined>>,
  loadingPost: boolean,
  isEdit: boolean
  hasErrorPost: boolean,
  isLoadingCurrenciesValues: boolean,
}

export const TableApplyReceipt = ({
  rows,
  getValues,
  setSelectedRows,
  onChangeTotals,
  preSelectedRows,
  setPreSelectedRows,
  loadingPost,
  isEdit,
  hasErrorPost,
  isLoadingCurrenciesValues,
}: TableApplyReceiptProps) => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [receiptsDetailsData, setReceiptsDetailsData] = useState<GridRowsProp>([]);
  const [auxReceiptsDetailsData, setAuxReceiptsDetailsData] = useState<GridRowsProp>([]);
  const [myRowsIdsSelected, setMyRowsIdsSelected] = useState<GridRowSelectionModel>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const [columns, setColumns] = useState<GridColDef<ReceiptDetail>[]>([
    {
      field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`sales_invoice_id=${params.row.sales_invoice_id}&customer_id=${params.row.customer_id}`);
          window.open(`/salesinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.sales_invoice_name}
        </Button>
      }
    },
    { field: 'customer_business_name', headerName: t("receiptsdetails.fields.customer_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'receipt_name', headerName: t("receipts.fields.receipt_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'uuid', headerName: t("receipts.fields.uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("receiptsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("receiptsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("receiptsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value ?? null);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-receiptsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-receiptsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ReceiptDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
        else {
          if (element.field === ('amount_outstanding' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<ReceiptDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'sales_invoice_name') {
            element.headerName = t(`salesinvoices.fields.sales_invoice_name`);
          }
          else if (element.field === 'uuid') {
            element.headerName = t(`receipts.fields.uuid`);
            element.renderCell = function (params: GridRenderCellParams<ReceiptDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`sales_invoice_id=${params.row.sales_invoice_id}&customer_id=${params.row.customer_id}`);
                window.open(`/salesinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.sales_invoice_name}
              </Button>
            }
          }
          else {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    let myNewSelectedRows = receiptsDetailsData.filter(item => rowsIds.includes(item.receipt_detail_id));
    setSelectedRows((prev) => myNewSelectedRows);
    setPreSelectedRows(rowsIds);
    setMyRowsIdsSelected(rowsIds);
    onChangeTotals(myNewSelectedRows);
  }

  useEffect(() => {
    if (loadingPost || (!loadingPost && (getValues("uuid") ?? false))) {
      setAuxReceiptsDetailsData((prev) => receiptsDetailsData);
      setReceiptsDetailsData((prev) => prev.filter((item) => myRowsIdsSelected.includes(item.receipt_detail_id)));
      if (!hasErrorPost && getValues("uuid")) {
        setDataLoaded(false);
        GenericGetResource(`/receiptsdetails/byreceiptid/${getValues("receipt_id")}`)
          .then((responseDetails) => {
            setReceiptsDetailsData(responseDetails.data.data);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
            setDataLoaded(true);
          })
      }
    }
    else if (!loadingPost && hasErrorPost) {
      setReceiptsDetailsData((prev) => auxReceiptsDetailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPost]);

  useEffect(() => {
    loadPreferences();
    loadColumnsOrder();
    const formattedRows: GridRowSelectionModel = preSelectedRows?.toString().split(',').map(id => Number(id)) as GridRowSelectionModel;
    if (getValues("uuid")) {
      setReceiptsDetailsData(rows.filter((item) => formattedRows.includes(item.receipt_detail_id)));
      GenericGetResource(`/receiptsdetails/byreceiptid/${getValues("receipt_id")}`)
        .then((responseDetails) => {
          setReceiptsDetailsData(responseDetails.data.data)
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    }
    else {
      setReceiptsDetailsData(rows);
      preSelectedRows && setMyRowsIdsSelected(formattedRows);
      setDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingCurrenciesValues) {
      setOpenBackDrop(true);
    }
    else {
      setOpenBackDrop(false);
    }
  }, [isLoadingCurrenciesValues])

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='screen-container'>
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={receiptsDetailsData}
              entityId={"receipt_detail_id"}
              entity={`ReceiptsDetails`}
              preferences={myPreferences}
              namePreferences={"grid-receiptsdetails"}
              nameOrderColumns={"grid-receiptsdetails-columns"}
              selectRows={!isEdit}
              onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
              isChildren={true}
              preSelectedRows={!isEdit ? preSelectedRows : []}
              countColumns={["total_company_currency_amount"]}
            />
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <SnackbarComponent />
        </Box>
      }
    </>
  )
};
