import { Route, Routes } from 'react-router-dom'
import { TableItemTypes } from './table'
import { AddItemTypes } from './add'
import { ViewItemTypes } from './view'

export const BrowserItemTypes = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableItemTypes />} />
            <Route path={"/add"} element={<AddItemTypes />} />
            <Route path={"/view"} element={<ViewItemTypes />} />
        </Routes>
    )
}