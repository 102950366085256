import {
  GridColDef,
  GridRowsProp,
  GridTreeNodeWithRender,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDates } from "../../../../../hooks/useDates";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../api/GenericPromises";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../../components/Header";
import { PrimaryButton } from "../../../../../theme/buttons";
import DataTable from "../../../../../components/Tables/GridTableMaterialUI/DataTable";
import { ProjectTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectsTemplate";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { UpdateProjectsTemplate } from "./update";
import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";

export const TableProjectsTemplate = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [projectsTemplateData, setProjectsTemplateData] =
    useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [projectTypeIds, setProjectTypeIds] = useState([]);
  const [openDialogUpdateProjectTemplate, setOpenDialogUpdateProjectTemplate] =
    useState(false);
  const [selectedProjectTemplate, setSelectedProjectTemplate] =
    useState<ProjectTemplate | null>(null); // Estado para almacenar la entidad seleccionada para editar

  const { GetResourceByUrl } = usePermissions();

  const [columns, setColumns] = useState<GridColDef<ProjectTemplate>[]>([
    {
      field: "project_template_name",
      headerName: t("projectstemplate.fields.project_template_name"),
      headerClassName: "header-grid-table",
      flex: 1,
    },
    {
      field: "project_type_name",
      headerName: t("projectstemplate.fields.project_type"),
      headerClassName: "header-grid-table",
      flex: 1,
    },
    {
      field: "creation_date",
      headerName: t("generic.creation_date"),
      headerClassName: "header-grid-table",
      type: "date",
      flex: 1,
      valueGetter: (params: any) => TimeConverter(params.value),
    },
    {
      field: "last_update_user",
      headerName: t("generic.last_update_user"),
      headerClassName: "header-grid-table",
      flex: 1,
    },
    {
      field: "last_update_date",
      headerName: t("generic.last_update_date"),
      headerClassName: "header-grid-table",
      type: "date",
      flex: 1,
      valueGetter: (params: any) => TimeConverter(params.value),
    },
    {
      field: "actions",
      headerName: t("projectstemplate.actions.edit"),
      headerClassName: "header-grid-table",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <>
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              handleEditClick(params.row);
            }}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </Button>
        </>
      ),
    },
  ]);

  const handleDeleteProjectTemplate = (deleteTemplate: ProjectTemplate) => {
    setProjectsTemplateData((prevData) =>
      prevData.filter((template) => template.project_template_id !== deleteTemplate.project_template_id)
    );
  };  


  const handleUpdateProjectTemplate = (updatedTemplate: ProjectTemplate) => {
    setProjectsTemplateData((prevData) =>
      prevData.map((template) =>
        template.project_template_id === updatedTemplate.project_template_id
          ? updatedTemplate
          : template
      )
    );
  };  

  const handleEditClick = (projectTemplate: ProjectTemplate) => {
    setSelectedProjectTemplate(projectTemplate);
    setOpenDialogUpdateProjectTemplate(true);
  };

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add": {
        setMessageSnack(t("generic.snackbar.add"));
        showSnackBar(t("generic.snackbar.add"), "success");
        break;
      }
      case "delete": {
        setMessageSnack(t("generic.snackbar.delete"));
        showSnackBar(t("generic.snackbar.delete"), "success");
        break;
      }
    }
  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-projectstemplate");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  };

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem(
      "grid-projectstemplate-columns"
    );
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element["type"] === "date") {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (
            params: GridValueGetterParams<
              ProjectTemplate,
              any,
              GridTreeNodeWithRender
            >
          ) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) {
        } else {
          element.headerName = t(`projectstemplate.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t(
        "projectstemplate.title"
      )}`;
    });
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource("/projectstemplate")
      .then((response) => {
        setProjectsTemplateData(response.data.data);

        const ids = response.data.data.map((item:ProjectTemplate) => item.project_type_id);
        setProjectTypeIds(ids);

        GetResourceByUrl(`/projectstemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      })
      .catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && (
        <div className="screen-container d-flex flex-column justify-content-center align-items-center">
          {" "}
          <img
            alt="ERROR"
            style={{ height: "20rem", width: "20rem" }}
            src={require("../../../../../assets/img/error.jpeg")}
          />{" "}
          <h2>{messageSnack}</h2>{" "}
        </div>
      )}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && (
        <div className="screen-container">
          <Header
            title={t("projectstemplate.title")}
            child={
              <div className="d-flex flex-row-reverse my-1">
                {resourceScreen?.create === true && (
                  <PrimaryButton
                    variant="outlined"
                    className="my-1"
                    onClick={() => {
                      navigate("add", { state: { ...location.state } });
                    }}
                  >
                    {t("generic.buttons.add")}
                  </PrimaryButton>
                )}
              </div>
            }
          />
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={projectsTemplateData}
              entityId={"project_template_id"}
              entity={`ProjectsTemplate`}
              preferences={myPreferences}
              namePreferences={"grid-projectstemplate"}
              nameOrderColumns={"grid-projectstemplate-columns"}
            />
          </div>
          <DialogEntity
            content={
              <UpdateProjectsTemplate
                projectsTemplatePayload={selectedProjectTemplate}
                permissions={resourceScreen}
                setOpenDialog={setOpenDialogUpdateProjectTemplate}
                projectTypeIds={projectTypeIds}
                onUpdate={handleUpdateProjectTemplate}
                onDeleteRow={handleDeleteProjectTemplate}
              />
            }
            open={openDialogUpdateProjectTemplate}
            setOpen={setOpenDialogUpdateProjectTemplate}
            title={
              <Header title={t("projectstagestemplate.title-view")} size="sm" />
            }
          />
          <SnackbarComponent />
        </div>
      )}
    </>
  );
};
