import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CFDI } from '../../../../interfaces/Sales/Catalogs/CFDIs';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateCFDIs = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { cfdiLocal } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CFDI>({
    defaultValues: {
      code: "",
      description: "",
      physical_person: true,
      moral_person: true
    }
  });
  const onSubmit: SubmitHandler<CFDI> = (data) => onPut(data);

  const onPut = (data: CFDI) => {
    let myData = {
      code: data.code ?? null,
      description: data.description ?? null,
      physical_person: data.physical_person ?? false,
      moral_person: data.moral_person ?? false,
    }
    setLoadingPost(true);
    GenericPutResource(`/cfdi/${cfdiLocal.cfdi_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    reset({
      code: cfdiLocal.code ?? undefined,
      description: cfdiLocal.description ?? undefined,
      physical_person: cfdiLocal.physical_person ?? false,
      moral_person: cfdiLocal.moral_person ?? false,
    });
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="code"
                      control={control}
                      rules={{ required: true, maxLength: 10 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("cfdis.fields.code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.code}
                          helperText={GetError(errors.code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>

                  <div className="w-50">
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true, maxLength: 100 }}
                      render={({ field }) =>
                        <TextField
                          ref={field.ref}
                          variant="filled"
                          value={field.value}
                          onChange={field.onChange}
                          label={t("cfdis.fields.description")}
                          error={!!errors.description}
                          helperText={GetError(errors.description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>

                </div>
              </div>

              <div className="d-flex flex-column mt-3">
                <Controller
                  name="physical_person"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("cfdis.fields.physical_person")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} disabled={isEdit} defaultChecked={cfdiLocal.physical_person} />
                    </Box>
                  }
                />
                <Controller
                  name="moral_person"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("cfdis.fields.moral_person")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} disabled={isEdit} defaultChecked={cfdiLocal.moral_person} />
                    </Box>
                  }
                />
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
