import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Group } from '../../../../interfaces/Security/groups';
import { Autocomplete, TextField, Box } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { OptionMenu } from '../../../../interfaces/Security/optionMenus';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateGroups = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboOptionMenu, setComboOptionMenu] = useState<OptionMenu[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [defaultOptionMenu, setDefaultOptionMenu] = useState<OptionMenu>();
  const {showSnackBar,SnackbarComponent}=useSnackBar();
  const { groupPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Group>({
    defaultValues: {
      group_name: '',
      option_menu_id: undefined,
      MS_group_id:undefined
    }
  });

  const onSubmit: SubmitHandler<Group> = (data) => onPut(data);

  const onPut = (data: Group) => {
    let myData = {
      group_name: data.group_name,
      option_menu_id: data.option_menu_id,
      MS_group_id:data.MS_group_id ?? null
    }
    setLoadingPost(true);
    GenericPutResource(`/groups/${groupPayload.group_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),"success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/optionmenu"),
    ]
    Promise.all(myPromises)
      .then((response) => {
        setComboOptionMenu(response[0].data.data);
        reset({
          group_name: groupPayload.group_name,
          option_menu_id: groupPayload.option_menu_id,
          MS_group_id: groupPayload.MS_group_id ?? undefined
        });
        setDefaultOptionMenu(response[0].data.data.find((optionMenu: OptionMenu) => optionMenu.option_menu_id === groupPayload.option_menu_id));
        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message,"error");
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="group_name"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("groups.fields.group_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.group_name}
                            helperText={GetError(errors.group_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="option_menu_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            defaultValue={defaultOptionMenu}
                            options={comboOptionMenu}
                            getOptionLabel={(option) => option.option_name}
                            renderOption={(props, option: OptionMenu) => (
                              <div key={option.option_menu_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.option_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.option_menu_id === value.option_menu_id}
                            onChange={(_, values) => {
                              field.onChange(values?.option_menu_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={t("groups.fields.option_name")}
                                value={field.value}
                                error={!!errors.group_id || field.value === null}
                                helperText={(errors.group_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                sx={{ paddingRight: 2 }}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>

                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type="submit" variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}