import { Route, Routes } from 'react-router-dom'
import { TableDrivers } from './table'
import { AddDrivers } from './add'
import { ViewDrivers } from './view'

export const BrowserDrivers = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableDrivers />} />
      <Route path={"/add"} element={<AddDrivers />} />
      <Route path={"/view"} element={<ViewDrivers />} />
    </Routes>
  )
}