import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import { GenericCardView } from "../../../components/GenericCardView";
import { RowCard } from '../../../interfaces/Commons/appInterfaces';
import GenericPromises from "../../../api/GenericPromises";
import { SnackbarComponent } from "../../../components/Commons/SnackBar";
import { Button } from '@mui/material';
import { Menuitem } from "../../../interfaces/Security/menu";
import { AuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { ItemType } from "../../../interfaces/Commons/items";
import { UpdateItemTypes } from "./update";
import { usePermissions } from "../../../hooks/usePermissions";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../theme/buttons";

export const ViewItemTypes = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [itemTypeLocal, setItemTypeLocal] = useState<ItemType>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [rowsData, setRowsData] = useState<RowCard[]>([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const [severitySnack, setSeveritySnack] = useState("error");
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/itemtypes/${itemTypeLocal?.item_type_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/itemtypes", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        setMessageSnack(error.message);
        setSeveritySnack("error");
        setOpenSnack(true);
        setOpenDialog(false);
      })
  }


  useEffect(() => {
    if (location.state === null) return navigate("/itemtypes", { state: {}, replace: true });
    GenericGetResource(`/itemtypes/${location.state.row.item_type_id}`)
      .then(
        (response) => {
          setItemTypeLocal(response.data);
          setRowsData([
            {
              field: `${t("item-types.fields.item_type_name")}`,
              value: `${itemTypeLocal?.item_type_name}`
            },
            {
              field: `${t("item-type.fields.item_type_description")}`,
              value: `${itemTypeLocal?.item_type_description}`
            },
          ]);
          GetResourceByUrl(`/itemtypes`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              setSeveritySnack("error");
              setMessageSnack(error.message);
              setOpenSnack(true);
            });
        }
      ).catch((error) => {
        setSeveritySnack("error");
        setMessageSnack(error.message);
        setOpenSnack(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <div>
          <Header
            title={t("item-types.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/itemtypes", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />


          <div>
            {resourceScreen?.update === true &&
              <UpdateItemTypes itemTypePayload={itemTypeLocal} setMessageSnack={setMessageSnack} setSeveritySnack={setSeveritySnack} />
            }
            {resourceScreen?.read && !resourceScreen?.update === true &&
              <GenericCardView rows={rowsData} width="50%" />
            }
          </div>

          <GenericDialog
            title={t("generic.dialog.delete")}
            content={t("item-types.dialog.delete")}
            open={openDialog}
            setOpen={setOpenDialog}
            onAcept={onDelete}
          />
          <SnackbarComponent
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            severity={severitySnack}
            message={messageSnack}
          />
        </div>
      }
    </>
  )
}
