import { Route, Routes } from 'react-router-dom'
import { TableCustomers } from './table'
import { AddCustomer } from './add'
import { ViewCustomer } from './view'

export const BrowserCustomers = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableCustomers />} />
            <Route path={"/add"} element={<AddCustomer />} />
            <Route path={"/view"} element={<ViewCustomer />} />
        </Routes>
    )
}