import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TableMeasureUnitConversions } from './table'
import { AddMeasureUnitConversions } from './add'
import { ViewMeasureUnitConversions } from './view'

export const BrowserMeasureUnitConversions = () => {
  return (
    <Routes>
        <Route path={"/"} element={<TableMeasureUnitConversions/>}/>
        <Route path={"/add"} element={<AddMeasureUnitConversions/>}/>
        <Route path={"/view"} element={<ViewMeasureUnitConversions/>}/>
    </Routes>
  )
}
