import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { TariffFraction } from '../../../../interfaces/Sales/Catalogs/tariffFractions';
import { Unit } from '../../../../interfaces/Commons/units';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddTariffFractions = () => {

    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [comboUnits, setComboUnits] = useState<Unit[]>([]);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<TariffFraction>({
        defaultValues: {
            tariff_fraction_code: '',
            tariff_fraction_general_description: '',
            tariff_fraction_specific_description: '',
            unit_id: undefined,
        }
    });

    const onSubmit: SubmitHandler<TariffFraction> = (data) => onPost(data);


    const onPost = (data: TariffFraction) => {
        let myData = {
            tariff_fraction_code: data.tariff_fraction_code,
            tariff_fraction_general_description: data.tariff_fraction_general_description ?? null,
            tariff_fraction_specific_description: data.tariff_fraction_specific_description ?? null,
            unit_id: data.unit_id ?? null,
        }
        setLoadingPost(true);
        GenericPostResource("/tarifffractions", myData)
            .then((response) => {
                navigate("/tarifffractions", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/tarifffractions", { state: {} });
    }

    useEffect(() => {
        GenericGetResource("/units").then((response) => {
            setComboUnits(response.data.data)
            setDataLoaded(true);
        }).catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, 'error');
        });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <>
                    <Header title={t("tarifffractions.title-view")} />
                    <div className='d-flex justify-content-center'>
                        <div className='custom-card-view w-75'>
                            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
                                <div className="custom-card-row d-flex justify-content-between">
                                    <div className="w-100">
                                        <div className='d-flex w-100'>
                                            <div className='w-50'>
                                                <Controller
                                                    name="tariff_fraction_code"
                                                    control={control}
                                                    rules={{ required: true, maxLength: 10, minLength: 8 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("tarifffractions.fields.tariff_fraction_code")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.tariff_fraction_code}
                                                            helperText={GetError(errors.tariff_fraction_code?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    }
                                                />
                                            </div>

                                            <div className='w-50'>
                                                <Controller
                                                    name="unit_id"
                                                    control={control}
                                                    rules={{}}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            ref={field.ref}
                                                            size="small"
                                                            sx={{ width: "100%", paddingRight: 2 }}
                                                            options={comboUnits}
                                                            getOptionLabel={(option) => `${option.unit_symbol} - ${option.unit_description}`}
                                                            renderOption={(props, option: Unit) => (
                                                                <div key={option.unit_id}>
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.unit_symbol} - {option.unit_description}
                                                                    </Box>
                                                                </div>
                                                            )}
                                                            isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                                                            onChange={(_, values) => {
                                                                field.onChange(values?.unit_id || null)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={`${t("units.title-view")}`}
                                                                    variant="filled"
                                                                    value={field.value}
                                                                    error={!!errors.unit_id}
                                                                    helperText={GetError(errors.unit_id?.type)}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </div>
                                        <div className='d-flex w-100 pt-3'>



                                            <div className="w-100">
                                                <Controller
                                                    name="tariff_fraction_specific_description"
                                                    control={control}
                                                    rules={{ maxLength: 100 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("tarifffractions.fields.tariff_fraction_specific_description")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.tariff_fraction_specific_description}
                                                            helperText={GetError(errors.tariff_fraction_specific_description?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    }
                                                />
                                            </div>

                                        </div>
                                        <div className='d-flex w-100 pt-3'>

                                            <div className="w-100">
                                                <Controller
                                                    name="tariff_fraction_general_description"
                                                    control={control}
                                                    rules={{ maxLength: 500 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("tarifffractions.fields.tariff_fraction_general_description")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            multiline
                                                            minRows={5}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.tariff_fraction_general_description}
                                                            helperText={GetError(errors.tariff_fraction_general_description?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            sx={{ paddingRight: 2 }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row-reverse mt-1'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                                    <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
            <SnackbarComponent />
        </>
    )
}
