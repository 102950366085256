import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { GridColDef, GridTreeNodeWithRender, GridValidRowModel, GridValueGetterParams } from "@mui/x-data-grid";
import { PurchaseInvoice } from "../../../../interfaces/Purchases/Invoices/purchaseInvoices";
import { useDates } from "../../../../hooks/useDates";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { SalesInvoice } from "../../../../interfaces/Sales/Invoices/salesinvoices";
import { Header } from "../../../../components/Header";
import { Button } from "@mui/material";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { ButtonLoading, ButtonStyled } from "../../../../theme/buttons";
import { Project } from "../../../../interfaces/Projects/projects";

type ProjectRelatedInvoicesProps = {
  projectPayload: Project;
  purchaseInvoicesData: readonly GridValidRowModel[],
  salesInvoicesData: readonly GridValidRowModel[],
  isClosedProject: boolean
}

export const ProjectRelatedInvoices = ({
  projectPayload,
  purchaseInvoicesData,
  salesInvoicesData,
  isClosedProject
}: ProjectRelatedInvoicesProps) => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [myPreferencesPurchaseInvoices, setMyPreferencesPurchaseInvoices] = useState({});
  const [myPreferencesSalesInvoices, setMyPreferencesSalesInvoices] = useState({});
  const [isLoadingSalesInvoice, setIsLoadingSalesInvoice] = useState(false);
  const [isLoadingPurchaseInvoice, setIsLoadingPurchaseInvoice] = useState(false);
  const [columnsPurchaseInvoice, setColumnsPurchaseInvoice] = useState<GridColDef<PurchaseInvoice>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("purchaseinvoices.fields.purchase_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view/?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    { field: 'supplier_business_name', headerName: t("purchaseinvoices.fields.supplier_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'notes', headerName: t("purchaseinvoices.fields.notes"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("purchaseinvoices.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'invoice_doc_status_name', headerName: t("purchaseinvoices.fields.invoice_doc_status_name"), headerClassName: 'header-grid-table', flex: 1 },
  ]);
  const [columnsSalesInvoices, setColumnsSalesInvoices] = useState<GridColDef<SalesInvoice>[]>([
    {
      field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`sales_invoice_id=${params.row.sales_invoice_id}&customer_id=${params.row.customer_id}`);
          window.open(`/salesinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.sales_invoice_name}
        </Button>
      }
    },
    { field: 'customer_business_name', headerName: t("receiptsdetails.fields.customer_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("salesinvoices.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'invoice_doc_status_name', headerName: t("salesinvoices.fields.invoice_doc_status_name"), headerClassName: 'header-grid-table', flex: 1 },
  ]);

  const loadPreferencesPurchaseInvoices = async () => {
    let myPreferences = await localStorage.getItem("grid-projectpurchaseinvoices");
    if (myPreferences != null) {
      setMyPreferencesPurchaseInvoices(JSON.parse(myPreferences));
    }
  }

  const AddSalesInvoice = async () => {
    setIsLoadingSalesInvoice(true);
    let stringConverted = ConvertParamsToBase64(`project_id=${projectPayload.project_id}`);
    window.open(`/salesinvoices/add?${stringConverted}`, '_blank');
    setIsLoadingSalesInvoice(false);
  }

  const AddPurchaseInvoice = async () => {
    setIsLoadingPurchaseInvoice(true);
    let stringConverted = ConvertParamsToBase64(`project_id=${projectPayload.project_id}`);
    window.open(`/purchaseinvoices/add?${stringConverted}`, '_blank');
    setIsLoadingPurchaseInvoice(false);
  }

  const loadColumnsOrderPurchaseInvoices = async () => {
    let myOrderColumns = await localStorage.getItem("grid-projectpurchaseinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'purchase_invoice_date' || element.field === 'date_mail_send') {
            element.headerName = t(`purchaseinvoices.fields.${element.field}`);
          }
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === ('total_amount' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'supplier_business_name') { element.headerName = t(`purchaseinvoices.fields.supplier_business_name`); }
          else {
            element.headerName = t(`purchaseinvoices.fields.${element.field}`);
          }
        }
      }
      setColumnsPurchaseInvoice(myJson);
    }
  }

  const loadPreferencesSalesInvoices = async () => {
    let myPreferences = await localStorage.getItem("grid-projectsalesinvoices");
    if (myPreferences != null) {
      setMyPreferencesSalesInvoices(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrderSalesInvoices = async () => {
    let myOrderColumns = await localStorage.getItem("grid-projectsalesinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'sales_invoice_date' || element.field === 'date_mail_send') {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
          }
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<SalesInvoice, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === ('total_amount' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<SalesInvoice, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'customer_business_name') { element.headerName = t(`salesinvoices.fields.customer_business_name`); }
          else {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
          }
        }
      }
      setColumnsSalesInvoices(myJson);
    }
  }

  useEffect(() => {
    loadColumnsOrderPurchaseInvoices();
    loadColumnsOrderSalesInvoices();
    loadPreferencesPurchaseInvoices();
    loadPreferencesSalesInvoices();
    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className="d-flex">
            <div className="w-50">
              <Header
                title={t("purchaseinvoices.title")}
                size="sm"
                child={
                  <>
                    <div className="d-flex justify-content-end">
                      <ButtonLoading
                        isLoading={isLoadingPurchaseInvoice}
                        variant="outlined"
                        className="my-1"
                        disabled={isClosedProject}
                        onClick={() => AddPurchaseInvoice()}>
                        {t("purchaseinvoices.actions.add")}
                      </ButtonLoading>
                    </div>
                  </>
                }
              />
              <DataTable
                columns={columnsPurchaseInvoice}
                setColumns={setColumnsPurchaseInvoice}
                data={purchaseInvoicesData}
                entityId={"purchase_invoice_id"}
                entity={`PurchaseInvoices`}
                preferences={myPreferencesPurchaseInvoices}
                namePreferences={"grid-projectpurchaseinvoices"}
                nameOrderColumns={"grid-projectpurchaseinvoices-columns"}
                selectRows={true}
                countColumns={["total_company_currency_amount"]}
                isChildren={true}
              />
            </div>
            <div className="d-flex flex-column mx-2" />
            <div className="w-50">
              <Header
                title={t("salesinvoices.title")}
                size="sm"
                child={
                  <>
                    <div className="d-flex justify-content-end">
                      <ButtonLoading isLoading={isLoadingSalesInvoice}
                        variant="outlined"
                        className="my-1"
                        onClick={() => AddSalesInvoice()}
                        disabled={isClosedProject}
                      >
                        {t("salesinvoices.actions.add")}
                      </ButtonLoading>
                    </div>
                  </>
                }
              />
              <DataTable
                columns={columnsSalesInvoices}
                setColumns={setColumnsSalesInvoices}
                data={salesInvoicesData}
                entityId={"sales_invoice_id"}
                entity={`SalesInvoices`}
                preferences={myPreferencesSalesInvoices}
                namePreferences={"grid-projectsalesinvoice"}
                nameOrderColumns={"grid-projectsalesinvoice-columns"}
                selectRows={true}
                countColumns={["total_company_currency_amount"]}
                isChildren={true}
              />
            </div>
          </div>
        </>
      }
    </>
  );
}