import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../api/GenericPromises';
import { ExchangeRateHistory } from '../../../interfaces/Commons/currencies';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, TextField, Typography } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const UpdateExchangeRateHistory = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { permissions, exchangeRateHistoryPayload } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ExchangeRateHistory>({
    defaultValues: {
      rate: undefined,
    }
  });
  const onSubmit: SubmitHandler<ExchangeRateHistory> = (data) => onPut(data);

  const onPut = (data: ExchangeRateHistory) => {
    let myData = {
      rate_date: data.rate_date,
      rate: data.rate,
      currency_id: data.currency_id,
    }
    setLoadingPost(true);
    GenericPutResource(`/exchangeratehistory/${exchangeRateHistoryPayload.exchange_rate_id}`, myData)
      .then((response) => {
        let myUpdateData: ExchangeRateHistory = response.data;
        props.UpdateRow(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/exchangeratehistory/${exchangeRateHistoryPayload.exchange_rate_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(exchangeRateHistoryPayload.exchange_rate_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    reset({
      currency_id: exchangeRateHistoryPayload.currency_id,
      rate: exchangeRateHistoryPayload.rate,
      rate_date: exchangeRateHistoryPayload.rate_date,
    });

    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <div className=' h-25'><Spinner /></div>}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <h4>{exchangeRateHistoryPayload.currency_code}</h4>

            <div className='d-flex mt-1'>
              <div className='w-50'>
                <Controller
                  name="rate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("exchangeratehistory.fields.rate")}`}
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                      }}
                      type="number"
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.rate}
                      helperText={GetError(errors.rate?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
              <div className='w-50'>
                <Controller
                  name="rate_date"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("exchangeratehistory.fields.rate_date")}`}
                      ref={field.ref}
                      value={new Date(field.value!).toUTCString().slice(5)}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.rate_date}
                      helperText={GetError(errors.rate_date?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  }
                />
              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions.delete ?
                <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
              }
            </div>

          </form>
        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("exchangeratehistory.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
