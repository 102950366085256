import { TableMerchandise } from './tableMerchandise';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelledMerchandiseDetail, CancelledTransportationDocument } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledTransportationDocuments';

type MerchandiseProps = {
    transportationDocumentPayload?: CancelledTransportationDocument
}

export const Merchandise = ({
    transportationDocumentPayload
}: MerchandiseProps) => {
    const [t] = useTranslation("global");

    return (
        <div className='d-flex flex-column'>
            <div>
                {/* Tabla de mercancías
        -- ClaveProductoServicio, descripcion, cantidad, clave unidad, peso en kg */}
                <TableMerchandise
                    transportationDocumentPayload={transportationDocumentPayload}
                />
            </div>
            <div className='d-flex flex-row-reverse mt-2'>
                {/* Totales
        -- Unidad de peso, Numero total de mercancias, peso bruto total */}
                <div className='d-flex flex-column'>
                    <Typography>{t("transportationdocuments.fields.weight_unit")}: {transportationDocumentPayload?.weight_unit ?? '0.00'}</Typography>
                    <Typography>{t("transportationdocuments.fields.total_merchandise")}: {transportationDocumentPayload?.total_merchandise ?? '0'}</Typography>
                    <Typography>{t("transportationdocuments.fields.total_gross_weight")}: {transportationDocumentPayload?.gross_total_weight ?? '0.00'}</Typography>
                </div>
            </div>
        </div>
    );
}
