import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ViewCFDIs } from './view'
import { TableCFDIs } from './table'
import { AddCFDIs } from './add'

export const BrowserCFDIs = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCFDIs />} />
      <Route path={"/add"} element={<AddCFDIs />} />
      <Route path={"/view"} element={<ViewCFDIs />} />
    </Routes>
  )
}