import { useLocation } from "react-router-dom";

export const useParamsFilter = () => {
  const location = useLocation();

  const GetParamsFromBase64 = () => {
    let params = new URLSearchParams(atob(location.search.split("?")[1]));
    return params;
  }

  const ConvertParamsToBase64 = (parameters: string) => {
    return btoa(parameters);
  }

  return {
    GetParamsFromBase64,
    ConvertParamsToBase64,
  }
}
