import GenericPromises from "../../../../../../api/GenericPromises";
import { useDates } from "../../../../../../hooks/useDates";
import { useFormulaEvaluator } from "../../../../../../hooks/useFormulaEvaluator";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Project } from "../../../../../../interfaces/Projects/projects";
import { Supplier } from "../../../../../../interfaces/Purchases/Catalogs/suppliers";
import { useRequestPurchaseInvoice } from "../hookRequestPurchaseInvoice/hookRequestPurchaseInvoice";
import { PurchaseInvoice } from '../../../../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { ProjectQueue } from "../../../table";
import { useCallback, useEffect, useRef } from "react";

export const useCalculateFormulasProjects = () => {
  const { EvaluateFormula } = useFormulaEvaluator();
  const { isDiferentDateToday } = useDates();
  const { onRequestPurchaseInvoice, PutPurchaseInvoiceDetails } = useRequestPurchaseInvoice();
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const itemDemurrageName = "Pago demoras";
  const itemStoragesName = "Pago de Almacenajes";
  const projectExtraFieldNameStorages = "ALMACENAJES";
  const projectExtraFieldNameDemurrages = "DEMORAS GENERADAS";
  const projectExtraFieldrelatedNameDemurrages = "DEMORAS";
  const projectExtraFieldNameConsignatory = "CONSIGNATARIO";
  const projectExtraFieldNameAA = "AA";
  const projectExtraFieldNameEnteredEmpty = "INGRESÓ VACÍO";
  const projectExtraFieldNameCustomsCleared = "DESADUANADO";
  const projectExtraFieldNameTerminal = "TERMINAL";
  const projectExtraFieldNameShipping = "NAVIERA";

  const isCancelLoadingRef = useRef(false);

  const CancelProcess = useCallback(() => {
    isCancelLoadingRef.current = true;
  }, []);

  const CheckAndValidateFormulas = async (
    myProjects: any[],
    setMyProjectsQueueToFunctions: React.Dispatch<React.SetStateAction<ProjectQueue[]>>,
    isForced: boolean = false,
    isCancelLoadingFunctions: boolean,
  ) => {
    isCancelLoadingRef.current = isCancelLoadingFunctions;
    for (let i = 0; i < myProjects.length; i++) {
      const element = myProjects[i];

      if (isCancelLoadingRef.current) {
        break;
      }
      // si el proyecto ya está cerrado no hacer nada
      if (!element.project_is_closed) {
        if ((!element.project_date_update || isDiferentDateToday(element?.project_date_update)) || isForced) {
          await GenericGetResource(`/projectextrafields/byprojectid/${element.project_id ?? 0}`)
            .then(async (responseExtraFields) => {
              try {
                setMyProjectsQueueToFunctions((prev) => [...prev, { status: 'inprogress', data: element }]);
                await CheckAndValidateFormulasPerProject(element, responseExtraFields.data.data, isForced)
                  .then(async () => {
                    let myPutData = {
                      project_date_update: new Date(),
                    }
                    await GenericPutResource(`/projects/${element.project_id}`, myPutData)
                      .then((responsePut) => {
                        element.project_date_update = responsePut.data.project_date_update;
                      });
                  });
                setMyProjectsQueueToFunctions((prev) =>
                  prev.map((project) =>
                    project.data.project_id === element.project_id
                      ? { ...project, status: 'done', data: element }
                      : project
                  )
                );
              }
              catch (error) {
                setMyProjectsQueueToFunctions((prev) =>
                  prev.map((project) =>
                    project.data.project_id === element.project_id
                      ? { ...project, status: 'error', data: element }
                      : project
                  )
                );
              }
            });
        }
      }
    }
  }

  const CheckAndValidateFormulasPerProject = async (
    projectRow: Project | undefined,
    projectExtrafieldResponseData: ProjectExtraField[],
    isForced: boolean = false,
    mySuppliersData?: Supplier[] | undefined,
  ) => {
    // si el proyecto ya está cerrado no hacer nada
    if (!projectRow?.project_is_closed) {
      // Check date update, si no tiene fecha o si no es igual a la fecha de hoy
      if ((!projectRow?.project_date_update || isForced || isDiferentDateToday(projectRow?.project_date_update))) {

        const now = new Date();
        const isoString = now.toISOString();
        const mySuppliers = (mySuppliersData) ? mySuppliersData : await GenericGetResource(`/suppliers`);

        // --- ALMACENAJES ---

        let myDaysStorage = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameStorages);
        // evaluar los días con almacenajes
        // Buscar agente aduanal y si no existe no hacer nada
        let myAA = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameAA);
        let myTerminal = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameTerminal);
        let mySupplierStorage = mySuppliers.data.data.find((item: any) => item.comercial_name === myAA?.project_extra_field_value);

        if (myDaysStorage?.project_extra_field_value && myDaysStorage?.project_extra_field_value.length > 0) {
          await EvaluateFormula(myDaysStorage?.project_extra_field_value, projectExtrafieldResponseData)
            .then(async (responseExtraField) => {
              if (responseExtraField > 0) {
                // que la validación tenga un id
                let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
                // si existen almacenajes y no existe factura de compra solicitar factura de compra
                if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                  if (mySupplierStorage) {
                    await onRequestPurchaseInvoice(myDaysStorage?.project_extra_field_name ?? '', responseExtraField, mySupplierStorage.supplier_id, myTerminal?.project_extra_field_value, projectRow?.project_id ?? 0);
                  }
                }
                // si ya existe una factura de compra actualizar el item que tenga los almacenajes con el rango seleccionado
                else {
                  await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myTerminal?.project_extra_field_value, myDaysStorage?.project_extra_field_name ?? '');
                }
              }
            })
            .catch(async (error) => {
              // calcula el valor con hoy
              await EvaluateFormula(`${myDaysStorage?.project_extra_field_value.replace(`{${projectExtraFieldNameCustomsCleared}}`, `new Date("${isoString}")`)}`, projectExtrafieldResponseData)
                .then(async (responseExtraField) => {
                  if (responseExtraField > 0) {
                    // update factura de compra con nuevo valor de almacenajes +1 día
                    let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
                    if ((myPurchaseInvoiceDetails.length < 1) && responseExtraField > 0) {
                      await onRequestPurchaseInvoice(myDaysStorage?.project_extra_field_name ?? '', responseExtraField, mySupplierStorage.supplier_id, myTerminal?.project_extra_field_value, projectRow?.project_id ?? 0);
                    }
                    else {
                      await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myTerminal?.project_extra_field_value, myDaysStorage?.project_extra_field_name ?? '');
                    }
                  }
                });
            });
        }
        else {
          let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
          if (myPurchaseInvoiceDetails.length > 0) {
            await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, 0, myTerminal?.project_extra_field_value, projectExtraFieldNameStorages ?? '');
          }
        }

        // --- DEMORAS ---

        let myDaysDemurrages = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameDemurrages);
        let myConsignatory = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameConsignatory);
        let myShipping = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameShipping);
        let mySupplierDemurrages = mySuppliers.data.data.find((item: any) => item.comercial_name === myConsignatory?.project_extra_field_value);

        if (myDaysDemurrages?.project_extra_field_value && myDaysDemurrages?.project_extra_field_value.length > 0) {

          await EvaluateFormula(myDaysDemurrages?.project_extra_field_value, projectExtrafieldResponseData)
            .then(async (responseExtraField) => {
              if (responseExtraField > 0) {
                let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
                if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                  if (mySupplierDemurrages) {
                    await onRequestPurchaseInvoice(projectExtraFieldrelatedNameDemurrages ?? '', responseExtraField, mySupplierDemurrages.supplier_id, myShipping?.project_extra_field_value, projectRow?.project_id ?? 0);
                  }
                }
                else {
                  await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '');
                }
              }
            })
            .catch(async (error) => {
              // calcula el valor con hoy
              await EvaluateFormula(`${myDaysDemurrages?.project_extra_field_value.replace(projectExtraFieldNameEnteredEmpty, `new Date("${isoString}")`)}`, projectExtrafieldResponseData)
                .then(async (responseExtraField) => {
                  if (responseExtraField > 0) {
                    let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
                    if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                      if (mySupplierDemurrages) {
                        await onRequestPurchaseInvoice(projectExtraFieldrelatedNameDemurrages ?? '', responseExtraField, mySupplierDemurrages.supplier_id, myShipping?.project_extra_field_value, projectRow?.project_id ?? 0);
                      }
                    }
                    else {
                      await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '');
                    }
                  }
                });
            });
        }
        else {
          let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
          if (myPurchaseInvoiceDetails.length > 0) {
            await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, 0, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '');
          }
        }

      }
    }
  }

  const ValidatePurchaseInvoiceDetail = async (project_id: number, detailOfPurchaseInvoice: string): Promise<PurchaseInvoice[]> => {
    let myInvoiceDetails = await GenericGetResource(`/purchaseinvoicesdetails/validatepurchaseinvoicedetail/${project_id}/${detailOfPurchaseInvoice}`);
    return myInvoiceDetails.data.result;
  }

  return {
    CheckAndValidateFormulas,
    CheckAndValidateFormulasPerProject,
    CancelProcess,
  }
}
