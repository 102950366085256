import { Theme } from '@emotion/react';
import { Autocomplete, Box, SxProps, TextField } from '@mui/material'
import React, { Ref, useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';


const comboLanguages = [{ id: 'es', name: "Español" }, { id: 'en', name: "English" }, { id: 'zh', name: "中文" }];

type ComboLanguageProps = {
    ref?: Ref<unknown> | undefined,
    defaultValue?: string | null | undefined,
    onChange: (values: { id: string; name: string; } | null) => void,
    value: String | undefined,
    errors?: FieldErrors<any>,
    sx?: SxProps<Theme> | undefined
}

const ComboLanguage = ({
    ref,
    defaultValue,
    onChange,
    value,
    errors,
    sx
}: ComboLanguageProps) => {

    const [t] = useTranslation("global");
    const [defaultLenguage, setDefaultLenguage] = useState<{ id: string; name: string; }>();
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        setDefaultLenguage((prev) => comboLanguages.find((item) => item.id === defaultValue));
        setDataLoaded(true);
    }, [])

    return (
        <>
            {dataLoaded &&
                <Autocomplete
                    ref={ref}
                    size="small"
                    sx={sx}
                    options={comboLanguages}
                    defaultValue={defaultLenguage}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <div key={option.id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                            </Box>
                        </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, values) => {
                        onChange(values)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            label={t("profile.choose-language")}
                            value={value}
                            error={!!errors?.language || value === null}
                            helperText={(errors?.language?.type || value === null) ? t("generic.forms-errors.required") : null}
                        />
                    )}

                />
            }
        </>
    )
}

export default ComboLanguage