import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { VehicleConfiguration } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/vehicleConfigurations';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateVehicleConfigurations = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { vehicleConfigurationPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<VehicleConfiguration>({
        defaultValues: {
            vehicle_config_code: '',
            vehicle_config_description: '',
        }
    });
    const onSubmit: SubmitHandler<VehicleConfiguration> = (data) => onPut(data);

    const onPut = (data: VehicleConfiguration) => {
        let myData = {
            vehicle_config_code: data.vehicle_config_code,
            vehicle_config_description: data.vehicle_config_description,
        }
        setLoadingPost(true);
        GenericPutResource(`/vehicleconfigurations/${vehicleConfigurationPayload.vehicle_config_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    useEffect(() => {
        reset({
            vehicle_config_code: vehicleConfigurationPayload.vehicle_config_code,
            vehicle_config_description: vehicleConfigurationPayload.vehicle_config_description,
        });
        setDataLoaded(true);
    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                    <div className='d-flex w-100'>
                                        <div className='w-50'>
                                            <Controller
                                                name="vehicle_config_code"
                                                control={control}
                                                rules={{ required: true, maxLength: 10 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("vehicleconfigurations.fields.vehicle_config_code")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.vehicle_config_code}
                                                        helperText={GetError(errors.vehicle_config_code?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="vehicle_config_description"
                                                control={control}
                                                rules={{ required: true, maxLength: 150 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("vehicleconfigurations.fields.vehicle_config_description")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.vehicle_config_description}
                                                        helperText={GetError(errors.vehicle_config_description?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
