import { Route, Routes } from 'react-router-dom'
import { TablePackagingTypes } from './table'
import { AddPackagingTypes } from './add'
import { ViewPackagingTypes } from './view'

export const BrowserPackagingTypes = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TablePackagingTypes />} />
            <Route path={"/add"} element={<AddPackagingTypes/>} />
            <Route path={"/view"} element={<ViewPackagingTypes/>}/>
        </Routes>
    )
}
