import { Route, Routes } from 'react-router-dom'
import { TableCompanies } from './table'
import { ViewCompanies } from './view'

export const BrowserCompanies= () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableCompanies />} />
            <Route path={"/view"} element={<ViewCompanies />} />
        </Routes>
    )
}