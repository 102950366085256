import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { PrimaryButton } from "../../../../../theme/buttons";
import { ProjectStagesTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTaskTemplate";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { ProjectGroupTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectGroupsTemplate";
import { TaskState } from "../../../../../interfaces/Projects/Catalogs/taskStates";
import { Group } from "../../../../../interfaces/Security/groups";

export const UpdateProjectStagesTaskTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericPostResource,
    GenericGetResourceGeneric,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadEndDateFirstTime, setLoadEndDateFirstTime] = useState(true);
  const [comboProjectTaskTemplate, setComboProjectTaskTemplate] = useState<
    ProjectTaskTemplate[]
  >([]);
  const [comboProjectGroupsTemplate, setComboProjectGroupsTemplate] = useState<
    ProjectGroupTemplate[]
  >([]);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [comboTaskStates, setComboTaskStates] = useState<TaskState[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [inputProjectTaskTemplate, setInputProjectTaskTemplate] = useState("");
  const [defaultProjectTaskTemplate, setDefaultProjectTaskTemplate] =
    useState<ProjectTaskTemplate>();
  const [defaultProjectGroupTemplate, setDefaultProjectGroupTemplate] =
    useState<ProjectGroupTemplate>();
  const [defaultGroup, setDefaultGroup] = useState<Group>()
  const [
    defaultProjectStagesTaskTemplateStartedAt,
    setDefaultProjectStagesTaskTemplateStartedAt,
  ] = useState<Date | null>();
  const [
    defaultProjectStagesTaskTemplateDueDate,
    setDefaultProjectStagesTaskTemplateDueDate,
  ] = useState<Date | null>();
  const { permissions, projectStagesTaskTemplatePayload } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm<ProjectStagesTaskTemplate>();

  const onSubmit: SubmitHandler<ProjectStagesTaskTemplate> = (data) =>
    onPut(data);

  const onPut = (data: ProjectStagesTaskTemplate) => {
    let myData = {
      project_stage_template_id: props.project_stage_template_id,
      project_task_template_id: data.project_task_template_id,
      project_group_template_id: data.project_group_template_id,
      project_stage_task_template_name: data.project_stage_task_template_name,
      project_stage_task_template_description:
        data.project_stage_task_template_description,
      project_stage_task_template_started_at:defaultProjectStagesTaskTemplateStartedAt || null,
      project_stage_task_template_due_date:defaultProjectStagesTaskTemplateDueDate || null,
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projectstagestaskstemplate/${projectStagesTaskTemplatePayload.project_stage_task_template_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectStagesTaskTemplate = response.data;
        props.handleUpdateTask(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstagestaskstemplate/${projectStagesTaskTemplatePayload.project_stage_task_template_id}`
    )
      .then(() => {
        setOpenDialog(false);
        props.handleDeleteTask(
          projectStagesTaskTemplatePayload.project_stage_task_template_id
        );
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddProjectTaskTemplate = (project_task_template_name: string) => {
    const existingProjectTaskTemplate = comboProjectTaskTemplate.find(
      (task) => task.project_task_template_name === project_task_template_name
    );
    if (existingProjectTaskTemplate) return existingProjectTaskTemplate;

    let myData = {
      current_task_state_template_id: comboTaskStates[0].task_state_id,
      project_task_template_name: project_task_template_name,
      project_task_template_description: null,
    };
    setLoadingPost(true);
    GenericPostResource("/projecttasktemplate", myData)
      .then((response) => {
        setComboProjectTaskTemplate([
          ...comboProjectTaskTemplate,
          response.data,
        ]);

        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const handleAddProjectGroupsTemplate = (group_id: number) => {
    const existingObject = comboProjectGroupsTemplate.find(
      (projectGroupTemplate) =>
        projectGroupTemplate.group_id === group_id &&
        projectGroupTemplate.project_template_id === props.project_template_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group_id,
        project_template_id: props.project_template_id,
      };

      setLoadingPost(true);
      GenericPostResource("/projectgroupstemplate", myData)
        .then((response) => {
          setComboProjectGroupsTemplate([
            ...comboProjectGroupsTemplate,
            response.data,
          ]);

          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    if (!loadEndDateFirstTime) {
      setDefaultProjectStagesTaskTemplateDueDate(undefined);
      setValue('project_stage_task_template_due_date', null);
      return;
    }
    setValue(
      "project_stage_task_template_due_date",
      projectStagesTaskTemplatePayload?.project_stage_task_template_due_date
    );
  }, [defaultProjectStagesTaskTemplateStartedAt]);


  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projecttasktemplate"),
      GenericGetResource("/projectgroupstemplate"),
      GenericGetResource("/taskstates"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectTaskTemplate(responses[0].data.data);
        setComboProjectGroupsTemplate(responses[1].data.data);
        setComboTaskStates(responses[2].data.data);
        setComboGroups(responses[3].data.data);

        reset({
          project_stage_template_id:
            projectStagesTaskTemplatePayload.project_stage_template_id,
          project_task_template_id:
            projectStagesTaskTemplatePayload.project_task_template_id,
          project_group_template_id:
            projectStagesTaskTemplatePayload.project_group_template_id,
          project_stage_task_template_name:
            projectStagesTaskTemplatePayload.project_stage_task_template_name,
          project_stage_task_template_description:
            projectStagesTaskTemplatePayload.project_stage_task_template_description,
        });

        setDefaultProjectStagesTaskTemplateStartedAt(
          projectStagesTaskTemplatePayload?.project_stage_task_template_started_at
        );

        setDefaultProjectStagesTaskTemplateDueDate(
          projectStagesTaskTemplatePayload?.project_stage_task_template_due_date
        );

        setDefaultProjectTaskTemplate(
          responses[0].data.data.find(
            (projectTaskTemplate: ProjectTaskTemplate) =>
              projectTaskTemplate.project_task_template_id ===
              projectStagesTaskTemplatePayload.project_task_template_id
          )
        );

        const projectGroupTemplate = responses[1].data.data.find(
          (projectGroupTemplate: ProjectGroupTemplate) =>
            projectGroupTemplate.project_group_template_id ===
            projectStagesTaskTemplatePayload.project_group_template_id
        );
  
        setDefaultProjectGroupTemplate(projectGroupTemplate);
  
        if (projectGroupTemplate) {
          const group = responses[3].data.data.find(
            (group: any) => group.group_id === projectGroupTemplate.group_id
          );
  
          if (group) {
            setDefaultGroup(group); 
          }
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false}/>
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="project_task_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboProjectTaskTemplate}
                      getOptionLabel={(option) =>
                        `${option.project_task_template_name}`
                      }
                      defaultValue={defaultProjectTaskTemplate}
                      renderOption={(props, option: ProjectTaskTemplate) => (
                        <div key={option.project_task_template_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.project_task_template_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.project_task_template_id ===
                        value.project_task_template_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputProjectTaskTemplate(newInputValue);
                      }}
                      onChange={(_, values) => {
                        field.onChange(
                          values?.project_task_template_id || null
                        );
                      }}
                      noOptionsText={
                        <Button
                          onMouseDown={() => {
                            if (inputProjectTaskTemplate.trim()) {
                              const newProjectTaskTemplate =
                                handleAddProjectTaskTemplate(
                                  inputProjectTaskTemplate
                                );
                              field.onChange(
                                newProjectTaskTemplate?.project_task_template_id
                              );
                            }
                          }}
                        >
                          {t("projectstagestaskstemplate.actions.create")}
                          
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestaskstemplate.fields.project_task_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_template_id}
                          helperText={GetError(
                            errors.project_task_template_id?.type
                          )}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_group_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      defaultValue={defaultGroup}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={(_, values) => {
                        if (values?.group_id) {
                          let projectGroup = handleAddProjectGroupsTemplate(
                            values?.group_id
                          );
                          field.onChange(
                            projectGroup?.project_group_template_id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestaskstemplate.fields.project_group_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_template_id}
                          helperText={GetError(
                            errors.project_group_template_id?.type
                          )}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
              <Controller
                  name="project_stage_task_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_task_template_name}
                      helperText={GetError(
                        errors.project_stage_task_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>

              <div className="w-50">
              <Controller
                  name="project_stage_task_template_description"
                  control={control}
                  rules={{ }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_task_template_description}
                      helperText={GetError(
                        errors.project_stage_task_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
              <Controller
                  name="project_stage_task_template_started_at"
                  control={control}
                  rules={{ }}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_started_at"
                      )}`}
                      defaultValue={defaultProjectStagesTaskTemplateStartedAt ? dayjs(defaultProjectStagesTaskTemplateStartedAt) : null}
                      value={defaultProjectStagesTaskTemplateStartedAt ? dayjs(defaultProjectStagesTaskTemplateStartedAt) : null}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setDefaultProjectStagesTaskTemplateStartedAt((prev) =>
                          values?.toDate() || null
                        );
                        setLoadEndDateFirstTime(false);
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error:
                            !!errors.project_stage_task_template_started_at,
                          helperText: GetError(
                            errors.project_stage_task_template_started_at?.type
                          ),
                        },
                      }}
                      format="DD/MM/YYYY"
                      sx={{ paddingRight: 2 }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>

              <div className="w-50">
              <Controller
                  name="project_stage_task_template_due_date"
                  control={control}
                  rules={{ }}
                  render={({ field }) => (
                    <DatePicker
                      open={openEndDateDatePicker}
                      onClose={() => setOpenEndDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_due_date"
                      )}`}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setDefaultProjectStagesTaskTemplateDueDate((prev) => values?.toDate() || null);
                      }}
                      defaultValue={defaultProjectStagesTaskTemplateDueDate ? dayjs(defaultProjectStagesTaskTemplateDueDate) : null}
                      value={defaultProjectStagesTaskTemplateDueDate ? dayjs(defaultProjectStagesTaskTemplateDueDate) : null}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenEndDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_task_template_due_date,
                          helperText: GetError(
                            errors.project_stage_task_template_due_date?.type
                          ),
                        },
                      }}
                      minDate={dayjs(defaultProjectStagesTaskTemplateStartedAt)}
                      format="DD/MM/YYYY"
                      disabled={!defaultProjectStagesTaskTemplateStartedAt || isEdit ?  true : false}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => props.setOpenDialog(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions.delete ? (
                <PrimaryButton onClick={() => setOpenDialog(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectstagestaskstemplate.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
