import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { useDates } from "../../../../../hooks/useDates";
import { ProjectStageTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { Stage } from "../../../../../interfaces/Projects/Catalogs/stages";
import { DatePicker } from "@mui/x-date-pickers";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";

export const AddProjectStagesTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboStages, setComboStages] = useState<Stage[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [inputStage, setInputStage] = useState('');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm<ProjectStageTemplate>({
    defaultValues: {
      project_template_id: undefined,
      stage_id: undefined,
      project_stage_template_name: undefined,
      project_stage_template_consecutive: undefined,
      project_stage_template_description: undefined,
      project_stage_template_started_at: undefined,
      project_stage_template_due_date: undefined,
    },
  });
  const onSubmit: SubmitHandler<ProjectStageTemplate> = (data) => onPost(data);

  const onPost = (data: ProjectStageTemplate) => {
    let myData = {
      project_template_id: props.project_template_id,
      stage_id: data.stage_id,
      project_stage_template_name: data.project_stage_template_name,
      project_stage_template_consecutive:
        data.project_stage_template_consecutive,
      project_stage_template_description:
        data.project_stage_template_description || null,
      project_stage_template_started_at: data.project_stage_template_started_at ||  null ,
      project_stage_template_due_date: data.project_stage_template_due_date || null,
    };
    setLoadingPost(true);
    GenericPostResource("/projectstagestemplate", myData)
      .then((response) => {
        props.handleAddStage(response.data)
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    props.setOpenDialog(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleAddStage = (stage_name:string) => {
    const existingStage = comboStages.find(stage => stage.stage_name === stage_name);
    if (existingStage) return existingStage;

    let myData = {
      stage_name: stage_name,
      stage_description:null
    };
    setLoadingPost(true);
    GenericPostResource("/stages", myData)
      .then((response) => {
        setComboStages([...comboStages, response.data]);
        setLoadingPost(false);
        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });

  };

  useEffect(() => {
    setEndDate(null);
      setValue(
        "project_stage_template_due_date",
        null
      );
  }, [startDate]);


  useEffect(() => {
    let myPromises = [GenericGetResource("/stages")];

    Promise.all(myPromises)
      .then((responses) => {
        setComboStages(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
    {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="stage_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboStages}
                      getOptionLabel={(option) => `${option.stage_name}`}
                      renderOption={(props, option: Stage) => (
                        <div key={option.stage_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.stage_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.stage_id === value.stage_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputStage(newInputValue); 
                      }}
                      onChange={(_, values) => {
                        field.onChange(values?.stage_id || null);
                      }}
                      noOptionsText={
                        <Button onMouseDown={() => {
                          if (inputStage.trim()) {
                            const newStage = handleAddStage(inputStage);
                            field.onChange(newStage?.stage_id || null);
                          }
                        }}>
                         {t("projectstagestemplate.actions.create")}
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstagestemplate.fields.stage")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.stage_id}
                          helperText={GetError(errors.stage_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_name}
                      helperText={GetError(
                        errors.project_stage_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_template_consecutive"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_consecutive"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_consecutive}
                      helperText={GetError(
                        errors.project_stage_template_consecutive?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_template_description"
                  control={control}
                  rules={{  }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_description}
                      helperText={GetError(
                        errors.project_stage_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_template_started_at"
                  control={control}
                  rules={{  }}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_started_at"
                      )}`}
                      value={startDate ? dayjs(startDate) : null}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setStartDate((prev) => values?.toDate() || null);
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_template_started_at,
                          helperText: GetError(
                            errors.project_stage_template_started_at?.type
                          ),
                        },
                      }}
                      format="DD/MM/YYYY"
                      sx={{paddingRight:2}}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_template_due_date"
                  control={control}
                  rules={{ }}
                  render={({ field }) => (
                    <DatePicker
                      open={openEndDateDatePicker}
                      onClose={() => setOpenEndDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_due_date"
                      )}`}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setEndDate((prev) => values?.toDate() || null);
                      }}
                      value={endDate ? dayjs(endDate) : null}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenEndDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_template_due_date,
                          helperText: GetError(
                            errors.project_stage_template_due_date?.type
                          ),
                        },
                      }}
                      minDate={dayjs(startDate)}
                      format="DD/MM/YYYY"
                      disabled={!startDate ? true : false}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton
                type="submit"
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
