import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { CompanyLocation } from '../../../../../interfaces/Security/companies';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Button, Switch, TextField, Typography } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { emailRegex } from '../../../../../constants/Regex';
import { Country } from '../../../../../interfaces/Commons/Locations/countries';
import { State } from '../../../../../interfaces/Commons/Locations/states';
import { City } from '../../../../../interfaces/Commons/Locations/cities';
import { GenericDialog } from '../../../../../components/Dialogs/Dialog';
import { Divider } from '../../../../../components/Commons/Divider';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateCompanyLocation = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Country>();
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [defaultState, setDefaultState] = useState<State>();
  const [comboCities, setComboCities] = useState<City[]>([]);
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { companyLocationPayload, permissions } = props;
  const isEdit = !permissions.update;

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>(defaultState?.state_id);
  const [newState, setNewState] = useState(false)

  const [city, setCity] = useState<City | null>()

  const getCities = (stateId: number) => {
    GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
      });
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm<CompanyLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: undefined,
      contact_name: undefined,
      phone_number: undefined,
      email: undefined,
      company_id: undefined,
      main_location: undefined,
    }
  });
  const onSubmit: SubmitHandler<CompanyLocation> = (data) => onPut(data);

  const onPut = (data: CompanyLocation) => {
    let myData = {
      country_id: data.country_id ?? null,
      state_id: data.state_id ?? null,
      city_id: data.city_id ?? null,
      postal_code: data.postal_code ?? null,
      street: data.street ?? null,
      contact_name: data.contact_name ?? null,
      phone_number: data.phone_number ?? null,
      email: data.email ?? null,
      company_id: props.company_id ?? null,
      main_location: data.main_location ?? false,
    }
    let myCountry = comboCountries.find((item: Country) => item.country_id === data.country_id);
    let myState = comboStates.find((item: State) => item.state_id === data.state_id);
    let myCity = comboCities.find((item: City) => item.city_id === data.city_id);
    setLoadingPost(true);
    GenericPutResource(`/companylocations/${companyLocationPayload.company_location_id}`, myData)
      .then((response) => {
        let myUpdateData: CompanyLocation = {
          ...response.data,
          country_name: myCountry?.country_name,
          state_name: myState?.state_name,
          city_name: myCity?.city_name,
        };
        if (response.data.main_location === true) {
          GenericGetResource(`/companylocations/bycompanyid/${props.company_id}`)
            .then(async (response1) => {
              for (let i = 0; i < response1.data.data.length; i++) {
                const element = response1.data.data[i];
                if (element.company_location_id !== response.data.company_location_id) {
                  let myData = {
                    state_id: element.state_id,
                    city_id: element.city_id,
                    postal_code: element.postal_code,
                    street: element.street,
                    contact_name: element.contact_name,
                    phone_number: element.phone_number,
                    email: element.email,
                    company_id: element.company_id,
                    main_location: false,
                  };
                  await GenericPutResource(`/companylocations/${element.company_location_id}`, myData)
                    .then((response2) => {
                      props.UpdateRow(response2.data);
                    })
                    .catch((error) => {
                      setLoadingPost(false);
                      showSnackBar(error.message,"error");
                    });
                }
              }
            })
            .catch((error) => {
              setLoadingPost(false);
              showSnackBar(error.message, 'error');
            });
        }
        props.UpdateRow(myUpdateData);
        showSnackBar(t("generic.snackbar.update"),"success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/companylocations/${companyLocationPayload?.company_location_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(companyLocationPayload?.company_location_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
    ]

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries((prev) => responses[0].data.data);
        setComboStates((prev) => responses[1].data.data);
        setComboCities((prev) => responses[2].data.data);

        reset({
          country_id: companyLocationPayload.country_id ?? undefined,
          state_id: companyLocationPayload.state_id ?? undefined,
          city_id: companyLocationPayload.city_id ?? undefined,
          postal_code: companyLocationPayload.postal_code ?? undefined,
          street: companyLocationPayload.street ?? undefined,
          contact_name: companyLocationPayload.contact_name ?? undefined,
          phone_number: companyLocationPayload.phone_number ?? undefined,
          email: companyLocationPayload.email ?? undefined,
          main_location: companyLocationPayload.main_location ?? false,
        });

        setDefaultCountry(responses[0].data.data.find((item: Country) => item.country_id === companyLocationPayload.country_id));
        setDefaultState(responses[1].data.data.find((item: State) => item.state_id === companyLocationPayload.state_id));
        setStateId(responses[1].data.data.find((state: State) => state.state_id === companyLocationPayload.state_id).state_id);
        setCity(responses[2].data.data.find((city: City) => city.city_id === companyLocationPayload.city_id));


        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (stateId !== undefined) {
      if (newState) {
        setCity(null);
      }
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId])

  return (
    <>
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

              <div className='d-flex'>
                <div className='w-100 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="country_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          defaultValue={defaultCountry}
                          options={comboCountries}
                          getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                          renderOption={(props, option: Country) => (
                            <div key={option.country_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.country_code} - {option.country_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                          onChange={(_, values) => {
                            field.onChange(values?.country_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("companylocations.fields.country_name")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.country_id}
                              helperText={GetError(errors.country_id?.type)}
                              sx={{ paddingRight: 2 }}
                            />
                          )}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="state_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboStates}
                          defaultValue={defaultState}
                          getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                          renderOption={(props, option: State) => (
                            <div key={option.state_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.state_code} - {option.state_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                          onChange={(_, values) => {
                            setValue("city_id", undefined);
                            field.onChange(values?.state_id || null);
                            setNewState(true);
                            setStateId(values?.state_id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("companylocations.fields.state_name")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.state_id}
                              helperText={GetError(errors.state_id?.type)}
                            />
                          )}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex w-100 mt-3'>
                <div className='w-50'>
                  {!isStateSelected &&
                    <Controller
                      name="city_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCities}
                          getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                          renderOption={(props, option: City) => (
                            <div key={option.city_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.city_code} - {option.city_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                          onChange={(_, values) => {
                            field.onChange(values?.city_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("customerlocations.fields.city_name")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.city_id}
                              helperText={GetError(errors.city_id?.type)}
                              sx={{ paddingRight: 2 }}

                            />
                          )}
                          disabled
                        />
                      }
                    />
                  }
                  {isStateSelected &&
                    <Controller
                      name="city_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCities}
                          value={city}
                          getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                          renderOption={(props, option: City) => (
                            <div key={option.city_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.city_code} - {option.city_name}
                              </Box>
                            </div>
                          )}

                          isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                          onChange={(_, values) => {
                            setCity(values);
                            field.onChange(values?.city_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("customerlocations.fields.city_name")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.city_id}
                              helperText={GetError(errors.city_id?.type)}
                              sx={{ paddingRight: 2 }}

                            />
                          )}

                        />
                      }
                    />
                  }
                </div>

                <div className='w-50'>
                  <Controller
                    name="street"
                    control={control}
                    rules={{ required: true, maxLength: 60 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("companylocations.fields.street_name")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.street}
                        helperText={GetError(errors.street?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>
              </div>

              <div className='d-flex mt-3'>
                <div className='w-50'>
                  <Controller
                    name="postal_code"
                    control={control}
                    rules={{ maxLength: 5 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("companylocations.fields.postal_code")}`}
                        ref={field.ref}
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                        }}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.postal_code}
                        helperText={GetError(errors.postal_code?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>

              </div>

              <Divider />

              <div className='d-flex w-100'>
                <div className='w-50'>
                  <Controller
                    name="contact_name"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("companylocations.fields.contact_name")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.contact_name}
                        helperText={GetError(errors.contact_name?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>
                <div className='w-50'>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{ maxLength: 20 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("companylocations.fields.phone_number")}`}
                        ref={field.ref}
                        value={field.value}
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.phone_number}
                        helperText={GetError(errors.phone_number?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>
              </div>

              <div className='d-flex mt-3'>
                <div className='w-50'>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ maxLength: 50, pattern: emailRegex }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("companylocations.fields.email")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.email}
                        helperText={GetError(errors.email?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>

              </div>

              <div className='w-50 mt-3'>
                <Controller
                  name="main_location"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2, justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("companylocations.fields.main_location")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} disabled={isEdit} defaultChecked={companyLocationPayload.main_location} />
                    </Box>
                  }
                />
              </div>

              <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                <div>
                  <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                  {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                </div>
                {permissions.delete ?
                  <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                }
              </div>

            </form>

          </Box>

        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("companylocations.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent/>
    </>
  )
}
