import { Route, Routes } from 'react-router-dom'
import { TableDriverTypes } from './table'
import { AddDriverTypes } from './add'
import { ViewDriverTypes } from './view'

export const BrowserDriverTypes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableDriverTypes />} />
      <Route path={"/add"} element={<AddDriverTypes />} />
      <Route path={"/view"} element={<ViewDriverTypes />} />
    </Routes>
  )
}