import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TableProjectTypes } from './table'
import { AddProjectTypes } from './add'
import { ViewProjectTypes } from './view'

export const BrowserProjectTypes = () => {
  return (
    <Routes>
        <Route path={'/'} element={<TableProjectTypes/>} />
        <Route path={"/add"} element={<AddProjectTypes/>}/>
        <Route path={"/view"} element={<ViewProjectTypes/>}/>
    </Routes>
  )
}
