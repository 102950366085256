import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Currency } from '../../../interfaces/Commons/currencies';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import GenericPromises from '../../../api/GenericPromises';
import { Box, Button, TextField } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import { TableExchangeRateHistory } from '../ExchangeRateHistory/table';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const UpdateCurrency = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { currencyPayload, permissions } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Currency>({
    defaultValues: {
      currency_code: undefined,
      currency_description: undefined,
      currency_symbol: undefined,
    }
  });
  const onSubmit: SubmitHandler<Currency> = (data) => onPut(data);

  const onPut = (data: Currency) => {
    let myData = {
      currency_code: data.currency_code,
      currency_description: data.currency_description ?? null,
      currency_symbol: data.currency_symbol ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/currencies/${currencyPayload.currency_id}`, myData)
      .then((response) => {
        let myUpdateData: Currency = response.data;
        props.UpdateRow(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    // delete rates
    GenericGetResource(`/exchangeratehistory/bycurrencyid/${currencyPayload?.currency_id}`)
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          GenericDeleteResource(`/exchangeratehistory/${element.exchange_rate_id}`)
            .catch((error) => {
              showSnackBar(error.message, 'error');
              setOpenDialog(false);
            })
        }
        // delete currency
        GenericDeleteResource(`/currencies/${currencyPayload?.currency_id}`)
          .then(() => {
            setOpenDialog(false);
            props.DeleteRow(currencyPayload?.currency_id);
            props.setOpenDialog(false);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
            setOpenDialog(false);
          })
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      });
  }

  useEffect(() => {

    reset({
      currency_id: currencyPayload.currency_id,
      currency_code: currencyPayload.currency_code,
      currency_description: currencyPayload.currency_description,
      currency_symbol: currencyPayload.currency_symbol,
    });

    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

              <div className='d-flex'>
                <div className='w-50 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="currency_symbol"
                      control={control}
                      rules={{ maxLength: 1 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("currencies.fields.currency_symbol")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.currency_symbol}
                          helperText={GetError(errors.currency_symbol?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 1 }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>
                  <Controller
                    name="currency_code"
                    control={control}
                    rules={{ required: true, maxLength: 3 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("currencies.fields.currency_code")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.currency_code}
                        helperText={GetError(errors.currency_code?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{ paddingRight: 2 }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>

                <div className='w-50'>
                  <Controller
                    name="currency_description"
                    control={control}
                    rules={{ maxLength: 30 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("currencies.fields.currency_description")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.currency_description}
                        helperText={GetError(errors.currency_description?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    }
                  />
                </div>
              </div>

              <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                <div>
                  <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                  {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                </div>
                {permissions.delete ?
                  <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                }
              </div>

            </form>

          </Box>

          <TableExchangeRateHistory currency_id={currencyPayload.currency_id} handleUpdateValueRate={props.handleUpdateValueRate} />
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("currencies.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
