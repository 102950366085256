import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { INCOTERM } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateINCOTERMs = ({ ...props }) => {

  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { incotermPayload } = props;
  const isEdit = !props.isEdit;

  const {showSnackBar,SnackbarComponent}= useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<INCOTERM>({
    defaultValues: {
      incoterm_code: '',
      incoterm_description: '',
    }
  });
  const onSubmit: SubmitHandler<INCOTERM> = (data) => onPut(data);

  const onPut = (data: INCOTERM) => {
    let myData = {
      incoterm_code: data.incoterm_code,
      incoterm_description: data.incoterm_description ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/incoterms/${incotermPayload.incoterm_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),"success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
        setLoadingPost(false);
      });
  }


  useEffect(() => {
    reset({
      incoterm_code: incotermPayload.incoterm_code,
      incoterm_description: incotermPayload.incoterm_description ?? undefined,
    });
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="incoterm_code"
                        control={control}
                        rules={{ required: true, maxLength: 5 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("incoterms.fields.incoterm_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.incoterm_code}
                            helperText={GetError(errors.incoterm_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="incoterm_description"
                        control={control}
                        rules={{ maxLength: 100 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("incoterms.fields.incoterm_description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.incoterm_description}
                            helperText={GetError(errors.incoterm_description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
