import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { CancelledSalesInvoice } from "../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useDates } from "../../../../hooks/useDates";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";

export const TableCancelledSalesInvoices = () => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [salesInvoiceData, setSalesInvoiceData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [columns, setColumns] = useState<GridColDef<CancelledSalesInvoice>[]>([
    { field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'customer_business_name', headerName: t("salesinvoices.fields.business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'purchase_order', headerName: t("salesinvoices.fields.purchase_order"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'invoice_doc_status_name', headerName: t("salesinvoices.fields.invoice_doc_status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("salesinvoices.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: "left", headerAlign: "left",
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-cancelledsalesinvoice");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-cancelledsalesinvoice-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'sales_invoice_date' || element.field === 'date_mail_send') {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
          }
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CancelledSalesInvoice, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === ('total_amount' || 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<CancelledSalesInvoice, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'customer_business_name') { element.headerName = t(`salesinvoices.fields.customer_business_name`); }
          else {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    GenericGetResource("/companies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("cancelledsalesinvoices.title")}`;
    })

    GenericGetResource("/cancelledsalesinvoices")
      .then(
        (response) => {
          setSalesInvoiceData(response.data.data);

          GetResourceByUrl(`/cancelledsalesinvoices`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
              setMessageSnack(error.message);
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setUnauthorized(false);
      });
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header title={t("cancelledsalesinvoices.title")} />
        <div className="d-flex justify-content-center">
          <DataTable columns={columns} setColumns={setColumns} data={salesInvoiceData} entityId={"cancelled_sales_invoice_id"} entity={`CancelledSalesInvoice`} preferences={myPreferences} namePreferences={"grid-salesinvoice"} nameOrderColumns={"grid-salesinvoice-columns"} countColumns={["total_company_currency_amount"]} />
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
