import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, TextField, Box } from "@mui/material";
import { PrimaryButton } from "../../../theme/buttons";
import { Consecutive } from "../../../interfaces/Commons/consecutives";
import { Resource } from "../../../interfaces/Security/resources";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const UpdateConsecutives = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboResources, setComboResources] = useState<Resource[]>([]);
  const [defaultResources, setDefaultResources] = useState<Resource>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { consecutivePayload } = props;
  const isEdit = !props.isEdit;


  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Consecutive>({
    defaultValues: {
      name: "",
      description: "",
      prefix: "",
      resource_id: undefined,
      consecutive: undefined,
    },
  });

  const onSubmit: SubmitHandler<Consecutive> = (data) => onPut(data);

  const onPut = (data: Consecutive) => {
    let myData = {
      name: data.name,
      description: data.description,
      prefix: data.prefix,
      resource_id: data.resource_id,
      consecutive: data.consecutive,
    };
    setLoadingPost(true);
    GenericPutResource(
      `/consecutives/${consecutivePayload.consecutive_id}`,
      myData
    )
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/resources"),
    ];
    Promise.all(myPromises)
      .then((response) => {
        setComboResources(response[0].data.data);
        setDataLoaded(true);
        setDefaultResources(
          response[0].data.data.find(
            (optionMenu: Resource) =>
              optionMenu.resource_id === consecutivePayload.resource_id
          ));
        setDataLoaded(true);
        reset({
          name: consecutivePayload.name,
          description: consecutivePayload.description,
          prefix: consecutivePayload.prefix,
          resource_id: consecutivePayload.resource_id,
          length: consecutivePayload.length,
          consecutive: consecutivePayload.consecutive
        });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {dataLoaded && (
        <div className="d-flex justify-content-center">
          <div className="custom-card-view w-75">
            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="custom-card-row d-flex justify-content-between">
                <div className="w-100">
                  <div className="d-flex w-100">
                    <div className="w-25">
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("consecutives.fields.name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                            }}
                            error={!!errors.name}
                            helperText={GetError(errors.name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>

                    <div className="w-25">
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("consecutives.fields.description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                            }}
                            error={!!errors.description}
                            helperText={GetError(errors.description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>

                    <div className="w-25">
                      <Controller
                        name="resource_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            defaultValue={defaultResources}
                            options={comboResources}
                            getOptionLabel={(option) => option.resource_name ?? ""}
                            renderOption={(props, option: Resource) => (
                              <div key={option.resource_id}>
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.resource_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.resource_id === value.resource_id
                            }
                            onChange={(_, values) => {
                              field.onChange(values?.resource_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={t("consecutives.fields.resource_id")}
                                value={field.value}
                                error={
                                  !!errors.resource_id || field.value === null
                                }
                                helperText={
                                  errors.resource_id?.type ||
                                    field.value === null
                                    ? t("generic.forms-errors.required")
                                    : null
                                }
                                sx={{ paddingRight: 2 }}
                              />
                            )}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="prefix"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("consecutives.fields.prefix")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                            }}
                            error={!!errors.prefix}
                            helperText={GetError(errors.prefix?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className="w-50">
                      <Controller
                        name="length"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("consecutives.fields.length")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                            }}
                            sx={{
                              paddingRight: 2,
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }
                            }}
                            error={!!errors.description}
                            helperText={GetError(errors.description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            type="number"
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-50">
                      <Controller
                        name="consecutive"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("consecutives.fields.consecutive")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                            }}
                            error={!!errors.consecutive}
                            helperText={GetError(errors.consecutive?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{
                              paddingRight: 2,
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }
                            }}
                            type="number"
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-50"></div>
                    <div className="w-50"></div>
                  </div>
                </div>
              </div>

              {!isEdit && (
                <div className="d-flex flex-row-reverse mt-4">
                  <PrimaryButton
                    variant="outlined"
                    className="m-1"
                    onClick={() => onPut(getValues())}
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
      <SnackbarComponent />
    </>
  );
};
