import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../theme/buttons';

export const ViewCancelledRelatedSalesInvoices = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { relatedSalesInvoicePayload } = props;

  return (
    <Box className='d-flex flex-column' sx={{ minWidth: 1000 }}>
      <div className='w-100'>

        <h4>{relatedSalesInvoicePayload.related_sales_invoice_name}</h4>

        <div className='d-flex mt-1 w-100'>
          <div className='w-100'>
            <TextField
              label={`${t("salesinvoicerelationshiptypes.title-view")}`}
              variant="filled"
              value={`${relatedSalesInvoicePayload.sales_invoice_relationship_type_code} - ${relatedSalesInvoicePayload.sales_invoice_relationship_type_description}`}
              style={{ width: "100%" }}
              size="small"
              sx={{ paddingRight: 2 }}
            />
          </div>
          <div className='w-100'>
            <TextField
              variant="filled"
              label={`${t("relatedsalesinvoices.fields.related_sales_invoice_uuid")}`}
              value={`${relatedSalesInvoicePayload.related_sales_invoice_uuid}`}
              size="small"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <Box className="d-flex flex-row-reverse mt-2">
        <PrimaryButton onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</PrimaryButton>
      </Box>

    </Box>
  )
}
