import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TablePaymentMethods } from './table'
import { AddPaymentMethods } from './add'
import { ViewPaymentMethods } from './view'

export const BrowserPaymentMethods = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePaymentMethods />} />
      <Route path={"/add"} element={<AddPaymentMethods />} />
      <Route path={"/view"} element={<ViewPaymentMethods />} />
    </Routes>
  )
}
