import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { ProjectStagesTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTaskTemplate";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { Group } from "../../../../../interfaces/Security/groups";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { ProjectGroupTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectGroupsTemplate";
import { TaskState } from "../../../../../interfaces/Projects/Catalogs/taskStates";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";

export const AddProjectStagesTaskTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } =
    GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboProjectTaskTemplate, setComboProjectTaskTemplate] = useState<
    ProjectTaskTemplate[]
  >([]);
  const [comboProjectGroupsTemplate, setComboProjectGroupsTemplate] = useState<
    ProjectGroupTemplate[]
  >([]);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [comboTaskStates, setComboTaskStates] = useState<TaskState[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [inputProjectTaskTemplate, setInputProjectTaskTemplate] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ProjectStagesTaskTemplate>({
    defaultValues: {
      project_stage_template_id: undefined,
      project_task_template_id: undefined,
      project_group_template_id: undefined,
      project_stage_task_template_name: undefined,
      project_stage_task_template_description: undefined,
      project_stage_task_template_started_at: undefined,
      project_stage_task_template_due_date: undefined,
    },
  });
  const onSubmit: SubmitHandler<ProjectStagesTaskTemplate> = (data) =>
    onPost(data);

  const onPost = (data: ProjectStagesTaskTemplate) => {
    let myData = {
      project_stage_template_id: props.project_stage_template_id,
      project_task_template_id: data.project_task_template_id,
      project_group_template_id: data.project_group_template_id,
      project_stage_task_template_name: data.project_stage_task_template_name,
      project_stage_task_template_description:
        data.project_stage_task_template_description,
      project_stage_task_template_started_at:
        data.project_stage_task_template_started_at || null,
      project_stage_task_template_due_date:
        data.project_stage_task_template_due_date || null,
    };
    setLoadingPost(true);
    GenericPostResource("/projectstagestaskstemplate", myData)
      .then((response) => {
        props.handleAddTask(response.data);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    props.setOpenDialog(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleAddProjectTaskTemplate = async (
    project_task_template_name: string
  ) => {
    const existingProjectTaskTemplate = comboProjectTaskTemplate.find(
      (stage) => stage.project_task_template_name === project_task_template_name
    );
    if (existingProjectTaskTemplate) return existingProjectTaskTemplate;

    let myData = {
      current_task_state_template_id: comboTaskStates[0].task_state_id,
      project_task_template_name: project_task_template_name,
      project_task_template_description: null,
    };
    setLoadingPost(true);
    await GenericPostResource("/projecttasktemplate", myData)
      .then((response) => {
        setComboProjectTaskTemplate([
          ...comboProjectTaskTemplate,
          response.data,
        ]);

        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const handleAddProjectGroupsTemplate = async (group: Group) => {
    const existingObject = comboProjectGroupsTemplate.find(
      (projectGroupTemplate) =>
        projectGroupTemplate.group_id === group.group_id &&
        projectGroupTemplate.project_template_id === props.project_template_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group.group_id,
        project_template_id: props.project_template_id,
        project_group_template_name: group.group_name,
      };

      return await GenericPostResource("/projectgroupstemplate", myData)
        .then((response) => {
          setComboProjectGroupsTemplate([
            ...comboProjectGroupsTemplate,
            response.data,
          ]);
          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  useEffect(() => {
    setEndDate(null);
    setValue("project_stage_task_template_due_date", null);
  }, [startDate]);

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projecttasktemplate"),
      GenericGetResource("/projectgroupstemplate"),
      GenericGetResource("/taskstates"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectTaskTemplate(responses[0].data.data);
        setComboProjectGroupsTemplate(responses[1].data.data);
        setComboTaskStates(responses[2].data.data);
        setComboGroups(responses[3].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboProjectTaskTemplate}
                      getOptionLabel={(option) =>
                        `${option.project_task_template_name}`
                      }
                      renderOption={(props, option: ProjectTaskTemplate) => (
                        <div key={option.project_task_template_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.project_task_template_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.project_task_template_id ===
                        value.project_task_template_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputProjectTaskTemplate(newInputValue);
                      }}
                      onChange={(_, values) => {
                        field.onChange(
                          values?.project_task_template_id || null
                        );
                      }}
                      noOptionsText={
                        <Button
                          onMouseDown={async () => {
                            if (inputProjectTaskTemplate.trim()) {
                              const newProjectTaskTemplate =
                                await handleAddProjectTaskTemplate(
                                  inputProjectTaskTemplate
                                );
                              field.onChange(
                                newProjectTaskTemplate?.project_task_template_id
                              );
                            }
                          }}
                        >
                          {t("projectstagestaskstemplate.actions.create")}
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestaskstemplate.fields.project_task_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_template_id}
                          helperText={GetError(
                            errors.project_task_template_id?.type
                          )}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_group_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={async (_, values) => {
                        if (values?.group_id) {
                          let projectGroup =
                            await handleAddProjectGroupsTemplate(values);
                          field.onChange(
                            projectGroup?.project_group_template_id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestaskstemplate.fields.project_group_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_template_id}
                          helperText={GetError(
                            errors.project_group_template_id?.type
                          )}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_task_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_task_template_name}
                      helperText={GetError(
                        errors.project_stage_task_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_stage_task_template_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_task_template_description}
                      helperText={GetError(
                        errors.project_stage_task_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_task_template_started_at"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_started_at"
                      )}`}
                      value={startDate ? dayjs(startDate) : null}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setStartDate((prev) => values?.toDate() || null);
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error:
                            !!errors.project_stage_task_template_started_at,
                          helperText: GetError(
                            errors.project_stage_task_template_started_at?.type
                          ),
                        },
                      }}
                      format="DD/MM/YYYY"
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_task_template_due_date"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openEndDateDatePicker}
                      onClose={() => setOpenEndDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstagestaskstemplate.fields.project_stage_task_template_due_date"
                      )}`}
                      onChange={(values) => {
                        field.onChange(values?.toDate() || null);
                        setEndDate((prev) => values?.toDate() || null);
                      }}
                      value={endDate ? dayjs(endDate) : null}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenEndDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_task_template_due_date,
                          helperText: GetError(
                            errors.project_stage_task_template_due_date?.type
                          ),
                        },
                      }}
                      minDate={dayjs(startDate)}
                      format="DD/MM/YYYY"
                      disabled={!startDate ? true : false}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton type="submit" variant="outlined" className="m-1">
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
