import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { TaxObject } from '../../../../interfaces/Sales/Catalogs/taxObjects';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateTaxObjects = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { taxObjectPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TaxObject>({
    defaultValues: {
        tax_object: '',
        description: ''
    }
  });
  const onSubmit: SubmitHandler<TaxObject> = (data) => onPut(data);

  const onPut = (data: TaxObject) => {
    let myData = {
        tax_object: data.tax_object,
        description: data.description ?? null
    }
    setLoadingPost(true);
    GenericPutResource(`/taxobjects/${taxObjectPayload.tax_object_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  useEffect(() => {
    reset({
      tax_object: taxObjectPayload.tax_object,
      description: taxObjectPayload.description ?? undefined
    });
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="tax_object"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("taxobjects.fields.tax_object")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.tax_object}
                            helperText={GetError(errors.tax_object?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            variant="filled"
                            value={field.value}
                            onChange={field.onChange}
                            label={t("taxobjects.fields.description")}
                            error={!!errors.description}
                            helperText={GetError(errors.description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
