import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../api/GenericPromises";
import { ProjectStageTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Box, Button, Grid } from "@mui/material";
import { Header } from "../../../../../components/Header";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { AddProjectStagesTemplate } from "./add";
import { AuthContext } from "../../../../../context/AuthContext";
import { TableProjectStagesTaskTemplate } from "../ProjectStagesTaskTemplate/table";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { UpdateProjectStagesTemplate } from "./update";
import "./index.css";
import { defaultTheme } from "../../../../../theme/theme";

export const TableProjectStagesTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [openDialogAddStage, setOpenDialogAddStage] = useState(false);
  const [openDialogUpdateStage, setOpenDialogUpdateStage] = useState(false);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>();
  const [projectStagesTemplateLocal, setProjectStagesTemplateLocal] = useState<
    ProjectStageTemplate[]
  >(props.projectStagesTemplateData);
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const [color, setColor] = useState<number[]>();

  const hexToRgb = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return [red, green, blue];
  };

  const onDelete = async () => {
    GenericDeleteResource(`/projectstemplate/${props?.project_template_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/projectstemplate", {
          state: { method: "delete" },
          replace: true,
        });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddStage = (newRow: ProjectStageTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesTemplateLocal((prevState) => {
      return sortByConsecutive([...projectStagesTemplateLocal, myRow]);
    });
  };

  const handleUpdateStage = (updateRow: ProjectStageTemplate) => {
    if (projectStagesTemplateLocal.length === 0) {
      return;
    }
    setProjectStagesTemplateLocal((prevRows) => {
      const rowToUpdateIndex = projectStagesTemplateLocal.findIndex(
        (e) =>
          e.project_stage_template_id === updateRow.project_stage_template_id
      );

      return sortByConsecutive(
        prevRows.map((row, index) =>
          index === rowToUpdateIndex ? updateRow : row
        )
      );
    });
  };

  const handleDeleteStage = (deleteRow: number) => {
    if (projectStagesTemplateLocal.length === 0) {
      return;
    }

    setProjectStagesTemplateLocal((prevRows) => {
      const rowToDeleteIndex = projectStagesTemplateLocal.findIndex(
        (e) => e.project_stage_template_id === deleteRow
      );

      const updatedData = [
        ...projectStagesTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectStagesTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return sortByConsecutive(updatedData);
    });
  };

  const compareOrderHint = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const sortByConsecutive = (data: ProjectStageTemplate[]) => {
    return data.sort((a: any, b: any) =>
      compareOrderHint(
        a.project_stage_template_consecutive,
        b.project_stage_template_consecutive
      )
    );
  };

  useEffect(() => {
    GenericGetResource(
      `/projectstagestemplate/byprojecttemplateid/${props.project_template_id}`
    )
      .then(async (response) => {
        setProjectStagesTemplateLocal(sortByConsecutive(response.data.data));

        setColor(hexToRgb(defaultTheme.palette.primary.main));

        GetResourceByUrl(`/projectstagestemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <div className="d-flex justify-content-center mt-4">
            <Grid
              container
              spacing={4}
              wrap="nowrap"
              className="stage-container"
            >
              {projectStagesTemplateLocal
                .sort(
                  (a, b) =>
                    Number(a.project_stage_template_consecutive) -
                    Number(b.project_stage_template_consecutive)
                )
                .map((stage) => (
                  <Grid
                    item
                    key={stage.project_stage_template_id}
                    xs={8}
                    md={5}
                    style={{ flexShrink: 0 }}
                  >
                    <Box
                      className="create-button-stage"
                      sx={{
                        color:'#ffffff',
                        backgroundColor: color
                          ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                              color[2]
                            },0.7)`
                          : "rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: color
                            ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                                color[2]
                              },0.3)`
                            : "rgba(0, 0, 0, 0.1)",
                        },
                      }}
                      onClick={() => {
                        setSelectedStageId(stage.project_stage_template_id);
                        setOpenDialogUpdateStage(true);
                      }}
                    >
                      {stage.project_stage_template_name}
                    </Box>
                    <TableProjectStagesTaskTemplate
                      project_stage_template_id={
                        stage.project_stage_template_id
                      }
                      project_template_id={stage.project_template_id}
                    />
                  </Grid>
                ))}
              {resourceScreen?.create == true && (
                <Grid item xs={8} md={5} style={{ flexShrink: 0 }}>
                  <Box
                    className="create-button-stage"
                    sx={{
                      "&:hover": {
                        backgroundColor: color
                          ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${
                              color[2]
                            },0.09)`
                          : "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    onClick={() => {
                      setOpenDialogAddStage(true);
                    }}
                  >
                    {t("projectstagestemplate.actions.create")}
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />

      <DialogEntity
        content={
          <AddProjectStagesTemplate
            handleAddStage={handleAddStage}
            setOpenDialog={setOpenDialogAddStage}
            project_template_id={props?.project_template_id}
          />
        }
        open={openDialogAddStage}
        setOpen={setOpenDialogAddStage}
        title={
          <Header title={t("projectstagestemplate.title-view")} size="sm" />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectStagesTemplate
            permissions={resourceScreen}
            setOpenDialog={setOpenDialogUpdateStage}
            handleUpdateStage={handleUpdateStage}
            handleDeleteStage={handleDeleteStage}
            projectStageTemplatePayload={
              projectStagesTemplateLocal?.find(
                (stage) => stage.project_stage_template_id === selectedStageId
              ) || {}
            }
          />
        }
        open={openDialogUpdateStage}
        setOpen={setOpenDialogUpdateStage}
        title={
          <Header title={t("projectstagestemplate.title-view")} size="sm" />
        }
      />
      <SnackbarComponent />
    </>
  );
};
