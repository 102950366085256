import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ButtonLoading } from "../../../../../theme/buttons";

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onAcept: (e: any) => void
}

export const DialogCancelPayment = ({
  open,
  setOpen,
  setIsLoading,
  isLoading,
  onAcept,
}: DialogProps) => {
  const [t] = useTranslation("global");

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {t("payments.dialog.cancel.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='mb-2'>
            {t("payments.dialog.cancel.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" className="m-1" onClick={() => {
            setOpen(false);
            setIsLoading(false);
          }}>{t("generic.buttons.goback")}</Button>
          <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="m-1"
            onClick={(e: any) => {
              onAcept(e);
            }}
          >{t("generic.buttons.accept")}</ButtonLoading>
        </DialogActions>
      </Dialog>
    </>
  )
}
