import { Route, Routes } from 'react-router-dom'
import { ViewPurchaseInvoices } from './view'
import { AddPurchaseInvoices } from './add'
import { TablePurchaseInvoices } from './table'

export const BrowserPurchaseInvoices = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePurchaseInvoices />} />
      <Route path={"/add"} element={<AddPurchaseInvoices/>} />
      <Route path={"/view"} element={<ViewPurchaseInvoices />} />
    </Routes>
  )
}