import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { CFDI } from '../../../../interfaces/Sales/Catalogs/CFDIs';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import { PrimaryButton } from '../../../../theme/buttons';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { usePermissions } from '../../../../hooks/usePermissions';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../../hooks/useDates';

export const TableCFDIs = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { TimeConverter } = useDates();
  const [unauthorized, setUnauthorized] = useState(true);
  const [CFDIsData, setCFDIsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [columns, setColumns] = useState<GridColDef<CFDI>[]>([
    { field: 'code', headerName: t("cfdis.fields.code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'description', headerName: t("cfdis.fields.description"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'physical_person', headerName: t("cfdis.fields.physical_person"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'moral_person', headerName: t("cfdis.fields.moral_person"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-cfdis");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-cfdis-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CFDI, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === "physical_person" || element.field === "moral_person") {
            element.valueGetter = function (params: GridValueGetterParams<CFDI, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field !== "last_update_user") {
            element.headerName = t(`cfdis.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("cfdis.title")}`;
    })
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource("/cfdi")
      .then(
        (response) => {
          setCFDIsData(response.data.data);

          GetResourceByUrl(`/cfdi`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
              setMessageSnack(error.message);
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("cfdis.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
            </div>
          }
        />

        <div className="d-flex justify-content-center">
          <DataTable columns={columns} setColumns={setColumns} data={CFDIsData} entityId={"cfdi_id"} entity={`CFDIsUsage`} preferences={myPreferences} namePreferences={"grid-cfdis"} nameOrderColumns={"grid-cfdis-columns"} />
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
