import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { Supplier } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { Currency } from "../../../interfaces/Commons/currencies";
import { Dispatch, SetStateAction } from "react";
import { Tax } from "../../../interfaces/Sales/Catalogs/taxes";
import { PaymentMode } from "../../../interfaces/Sales/Catalogs/paymentModes";
import { PaymentMethod } from "../../../interfaces/Sales/Catalogs/paymentMethods";
import { CFDI } from "../../../interfaces/Sales/Catalogs/CFDIs";
import { Retention } from "../../../interfaces/Sales/Catalogs/retentions";
import { PaymentCondition } from "../../../interfaces/Sales/Catalogs/paymentConditions";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";


type FinancialDataTabProps = {
  control: Control<Supplier, any>,
  errors: FieldErrors<Supplier>,
  comboCurrencies: Currency[],
  defaultCurrency: Currency | undefined,
  setDefaultCurrency: Dispatch<SetStateAction<Currency | undefined>>,
  comboTaxes: Tax[],
  defaultTax: Tax | undefined,
  setDefaultTax: Dispatch<SetStateAction<Tax | undefined>>,
  comboPaymentModes: PaymentMode[],
  defaultPaymentMode: PaymentMode | undefined,
  setDefaultPaymentMode: Dispatch<SetStateAction<PaymentMode | undefined>>,
  comboPaymentMethods: PaymentMethod[],
  defaultPaymentMethod: PaymentMethod | undefined,
  setDefaultPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>,
  comboCFDIs: CFDI[],
  defaultCFDI: CFDI | undefined,
  setDefaultCFDI: Dispatch<SetStateAction<CFDI | undefined>>,
  comboRetention: Retention[],
  defaultRetention: Retention | undefined,
  setDefaultRetention: Dispatch<SetStateAction<Retention | undefined>>,
  comboPaymentConditions: PaymentCondition[],
  defaultPaymentCondition: PaymentCondition | undefined,
  setDefaultPaymentCondition: Dispatch<SetStateAction<PaymentCondition | undefined>>,
  setValue: UseFormSetValue<Supplier>,
}

export const FinancialDataTab = ({
  control,
  errors,
  defaultCurrency,
  comboTaxes,
  defaultTax,
  comboPaymentModes,
  defaultPaymentMode,
  comboPaymentMethods,
  defaultPaymentMethod,
  comboCFDIs,
  defaultCFDI,
  comboRetention,
  defaultRetention,
  comboPaymentConditions,
  defaultPaymentCondition,
  setDefaultCFDI,
  setDefaultPaymentCondition,
  setDefaultPaymentMethod,
  setDefaultPaymentMode,
  setDefaultRetention,
  setDefaultTax
}: FinancialDataTabProps) => {
  const { GetError } = FormsErrors();
  const [t] = useTranslation("global");

  return (
    <>
      <div className='d-flex'>
        <div className='w-50'>
          <TextField
            sx={{ width: "100%", paddingRight: 2 }}
            disabled={true}
            size="small"
            label={`${t("currencies.title-view")}`}
            variant="filled"
            value={`${defaultCurrency?.currency_code ?? ""} - ${defaultCurrency?.currency_description ?? ""}`}
          />
        </div>

        <div className='w-50'>
          <Controller
            name="default_tax_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboTaxes}
                defaultValue={defaultTax}
                getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}`}
                renderOption={(props, option: Tax) => (
                  <div key={option.tax_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.tax_name} - {option.tax_percent}%
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
                onChange={(_, values) => {
                  field.onChange(values?.tax_id || null);
                  setDefaultTax(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("taxes.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_tax_id}
                    helperText={GetError(errors.default_tax_id?.type)}
                  />
                )}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="default_payment_mode_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboPaymentModes}
                defaultValue={defaultPaymentMode}
                getOptionLabel={(option) => `${option.code} - ${option.description}`}
                renderOption={(props, option: PaymentMode) => (
                  <div key={option.payment_mode_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.code} - {option.description}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.payment_mode_id === value.payment_mode_id}
                onChange={(_, values) => {
                  field.onChange(values?.payment_mode_id || null);
                  setDefaultPaymentMode(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("paymentmodes.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_payment_mode_id}
                    helperText={GetError(errors.default_payment_mode_id?.type)}
                    sx={{ paddingRight: 2 }}
                  />
                )}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="default_payment_method_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboPaymentMethods}
                defaultValue={defaultPaymentMethod}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                renderOption={(props, option: PaymentMethod) => (
                  <div key={option.payment_method_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.code} - {option.name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.payment_method_id === value.payment_method_id}
                onChange={(_, values) => {
                  field.onChange(values?.payment_method_id || null)
                  setDefaultPaymentMethod(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("paymentmethods.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_payment_method_id}
                    helperText={GetError(errors.default_payment_method_id?.type)}
                  />
                )}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="default_cfdi_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboCFDIs}
                defaultValue={defaultCFDI}
                getOptionLabel={(option) => `${option.code} - ${option.description}`}
                renderOption={(props, option: CFDI) => (
                  <div key={option.cfdi_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.code} - {option.description}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.cfdi_id === value.cfdi_id}
                onChange={(_, values) => {
                  field.onChange(values?.cfdi_id || null)
                  setDefaultCFDI(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("cfdis.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_cfdi_id}
                    helperText={GetError(errors.default_cfdi_id?.type)}
                    sx={{ paddingRight: 2 }}
                  />
                )}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="default_retention_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboRetention}
                defaultValue={defaultRetention}
                getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}`}
                renderOption={(props, option: Retention) => (
                  <div key={option.retention_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.retention_name} - {option.retention_percent}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
                onChange={(_, values) => {
                  field.onChange(values?.retention_id || null)
                  setDefaultRetention(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("retentions.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_retention_id}
                    helperText={GetError(errors.default_retention_id?.type)}
                  />
                )}
              />
            }
          />
        </div>
      </div>
      <div className="d-flex mt-3"> 
        <div className="w-50">
          <Controller
            name="default_payment_condition_id"
            control={control}
            rules={{}}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                sx={{ paddingRight: 2 }}
                size="small"
                options={comboPaymentConditions}
                defaultValue={defaultPaymentCondition}
                getOptionLabel={(option) => `${option.payment_condition_name}`}
                renderOption={(props, option: PaymentCondition) => (
                  <div key={option.payment_condition_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.payment_condition_name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.payment_condition_id === value.payment_condition_id}
                onChange={(_, values) => {
                  field.onChange(values?.payment_condition_id || null)
                  setDefaultPaymentCondition(values ?? undefined)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("paymentconditions.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.default_payment_condition_id}
                    helperText={GetError(errors.default_payment_condition_id?.type)}
                  />
                )}
              />
            }
          />
        </div>
      </div>
    </>
  )
}
