import { Box, Button, TextField, Typography } from '@mui/material'
import { ButtonLoading } from '../../../../theme/buttons'
import { Menuitem } from '../../../../interfaces/Security/menu'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Receipt } from '../../../../interfaces/Sales/Receipts/receipts'
import { UseFormGetValues } from 'react-hook-form'
import InfoIcon from '@mui/icons-material/Info';

type ConfirmDialogProps = {
  resourceFiscalSignature: Menuitem | undefined,
  isEdit: boolean,
  loadingPost: boolean,
  setDialogConfirmApplyReceipt: Dispatch<SetStateAction<boolean>>,
  isLoadingCurrenciesValues: boolean,
  onPost: (data: Receipt) => void,
  getValues: UseFormGetValues<Receipt>,
  hasPositiveDifference: boolean,
}

export const ConfirmDialog = ({
  resourceFiscalSignature,
  isEdit,
  loadingPost,
  setDialogConfirmApplyReceipt,
  isLoadingCurrenciesValues,
  onPost,
  getValues,
  hasPositiveDifference,
}: ConfirmDialogProps) => {
  const [t] = useTranslation("global");
  const [quantityField, setQuantityField] = useState<string | number>();
  const [isEqualQuantity, setEqualQuantity] = useState(false);

  const handleChange = (e: any) => {
    const value = parseFloat(e.target.value);
    setQuantityField(value.toString());
    if (getValues("total_amount")?.toString() === value.toString()) {
      setEqualQuantity((prev) => true);
    }
    else {
      setEqualQuantity(false);
    }
  }

  useEffect(() => {
    if (!hasPositiveDifference) {
      setEqualQuantity(true);
    }
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} width={"100%"}>
      {!hasPositiveDifference &&
        <>
          <Typography margin={1}>{t("receipts.info.confirm-payment")}</Typography>
        </>
      }
      {hasPositiveDifference &&
        <Box width={"100%"} marginBottom={4}>
          <Typography margin={1}>{t("receipts.info.confirm-payment")}</Typography>
          <Typography margin={1}><InfoIcon /> {t("receipts.info.create-advanced-sales-invoice")}</Typography>
          <TextField
            value={quantityField}
            variant='outlined'
            size='small'
            onChange={handleChange}
            label={t("receipts.fields.confirm_total_amount")}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
              "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1
            }}
            type="number"
            required
          />
        </Box>
      }
      <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
        <ButtonLoading disabled={!resourceFiscalSignature?.create || isEdit || !isEqualQuantity} isLoading={loadingPost} onClick={() => !isLoadingCurrenciesValues ? onPost(getValues()) : {}} className="d-flex align-self-end" variant="outlined">{t("receiptsdetails.buttons.apply-receipt")}</ButtonLoading>
        <Button onClick={() => { setDialogConfirmApplyReceipt(false) }} disabled={loadingPost}>{t("generic.buttons.goback")}</Button>
      </Box>
    </Box>
  )
}
