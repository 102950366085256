import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModesModel, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { ForeignTrade, ForeignTradeMerchandise } from '../../../../interfaces/Sales/Catalogs/ForeignTrade/foreignTrade';
import ComponentTableForeignTradeMerchandise from '../../../../components/Tables/TableForeignTradeMerchandise';
import GenericPromises from '../../../../api/GenericPromises';
import { UseFormGetValues } from 'react-hook-form';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { Tooltip } from '@mui/material';
import { User } from '../../../../interfaces/Security/users';

type TableForeignTradeProps = {
  isEdit: boolean;
  foreignTradeLocal: ForeignTrade | undefined;
  ChangeTotalForeignTrade: () => void,
  getValues: UseFormGetValues<ForeignTrade>,
}

export const TableForeignTrade = forwardRef(({
  isEdit,
  foreignTradeLocal,
  ChangeTotalForeignTrade,
  getValues
}: TableForeignTradeProps, ref) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource } = GenericPromises();
  const { setFormatNumber } = useFormatNumber();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isCanceledRow, setIsCanceledRow] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<ForeignTradeMerchandise[]>([]);

  useImperativeHandle(ref, () => {
    return {
      async setNewRows(myRows: any) {
        await setRows((prev) => myRows);
      },
    }
  }, []);


  let columns: GridColDef[] =
    [
      {
        field: 'customs_quantity',
        headerName: t("foreigntrademerchandises.fields.customs_quantity"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_id',
        headerName: t("tarifffractions.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <Tooltip title={params.row.tariff_fraction_code !== null ? `${params.row.tariff_fraction_code} - ${params.row.tariff_fraction_general_description ?? ''} - ${params.row.tariff_fraction_specific_description ?? ''}` : ''}>
            <span>
              {params.row.tariff_fraction_code ?? ''} - {params.row.tariff_fraction_general_description ?? ''} - {params.row.tariff_fraction_getariff_fraction_specific_descriptionneral_description ?? ''}
            </span>
          </Tooltip>
        ),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_international_unit_symbol',
        headerName: t("foreigntrademerchandises.fields.tariff_fraction_international_unit_symbol"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'tariff_fraction_unit_code',
        headerName: t("foreigntrademerchandises.fields.tariff_fraction_unit_code"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'identification_number',
        headerName: t("foreigntrademerchandises.fields.identification_number"),
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'customs_unit_value',
        headerName: t("foreigntrademerchandises.fields.customs_unit_value"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell",
      },
      {
        field: 'dolars_total_value',
        headerName: t("foreigntrademerchandises.fields.dolars_total_value"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell",
      },
    ];

  const [myColumns, setMyColumns] = useState<GridColDef[]>(columns);

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-foreigntrades-columns");
    if (myOrderColumns != null) {
      let myJson: any[] = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<User, any, GridTreeNodeWithRender>) {
            return new Date(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`foreigntrades.fields.${element.field}`);
        }


        if (element["field"] === "tariff_fraction_id") {
          element.renderCell = (params: GridRenderCellParams<any, Date>) => (
            <Tooltip title={params.row.tariff_fraction_code !== null ? `${params.row.tariff_fraction_code} - ${params.row.tariff_fraction_general_description ?? ''} - ${params.row.tariff_fraction_specific_description ?? ''}` : ''}>
              <span>
                {params.row.tariff_fraction_code ?? ''} - {params.row.tariff_fraction_general_description ?? ''} - {params.row.tariff_fraction_getariff_fraction_specific_descriptionneral_description ?? ''}
              </span>
            </Tooltip>
          )

        }
      }

      setMyColumns(myJson)
    }
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    return oldRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    GenericGetResource(`/foreigntrademerchandises/byforeigntradeid/${foreignTradeLocal?.foreign_trade_id}`)
      .then((response) => {
        setRows(response.data.data);
        loadColumnsOrder();
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [rows]);

  return (
    <div>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <ComponentTableForeignTradeMerchandise
            rows={rows}
            foreignTrade={foreignTradeLocal?.foreign_trade_id ?? 0}
            columns={myColumns}
            setColumns={setMyColumns}
            nameOrderColumns={"grid-foreigntrades-columns"}
            rowModesModel={rowModesModel}
            handleRowModesModelChange={handleRowModesModelChange}
            handleRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            isEdit={isEdit}
            ChangeTotalForeignTrade={ChangeTotalForeignTrade}
          />
          <div className='d-flex flex-row-reverse m-2'>
            <div className='d-flex flex-column'>
              <span>
                {t("foreigntrades.fields.total_USD")}: {setFormatNumber({ value: getValues("total_USD") ?? 0 })}
              </span>
            </div>
          </div>
        </>
      }
      <SnackbarComponent />
    </div>
  )
})
