import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from "react";

export const PrimaryButton = ({ ...props }) => {
  return (
    <Button {...props} sx={{
      ...props.sx,
      ':hover': {
        bgcolor: 'primary.main', // theme.palette.primary.main
        color: 'white',
      },
    }}>{props.children}</Button>
  )
}

export const ButtonStyled = ({ ...props }) => {
  return (
    <Button {...props} sx={{
      ...props.sx,
      ':hover': {
        bgcolor: 'primary.main', // theme.palette.primary.main
        color: 'white',
      },
      color: (props.variant == 'outlined') ? 'primary.main' : 'secondary.main'
    }}>{props.children}</Button>
  )
}

export const ButtonLoading = ({ ...props }) => {

  return (!props.isLoading) ?
    (
      <Button {...props} sx={{
        ...props.sx,
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
        },
        width: 'auto',
        color: 'primary.main'
      }}>{props.children}</Button>
    )
    :
    (
      <LoadingButton {...props} loading sx={{
        ...props.sx,
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
        },
        width: "auto",
        color: 'primary.main'
      }}>
      </LoadingButton>
    )
}