import { useState } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

const SnackbarComponent = ({ open, message, severity, handleClose }: any) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const useSnackBar = () => {
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const showSnackBar = (message: string, severity: AlertColor) => {
    setSnackBarProps({ open: true, message, severity });
  };

  const handleClose = () => {
    setSnackBarProps({ ...snackBarProps, open: false });
  };

  return { showSnackBar, SnackbarComponent: () => <SnackbarComponent {...snackBarProps} handleClose={handleClose} /> };
};

export default useSnackBar;
