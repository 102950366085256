import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GenericPromises from "../../../api/GenericPromises";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { ExchangeRateHistory } from "../../../interfaces/Commons/currencies";
import { PrimaryButton } from "../../../theme/buttons";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import { Header } from "../../../components/Header";
import { UpdateExchangeRateHistory } from "./update";
import { AddExchangeRateHistory } from "./add";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { usePermissions } from "../../../hooks/usePermissions";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../hooks/useDates";

export const TableExchangeRateHistory = ({ ...props }) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [exchangeRateHistoryData, setExchangeRateHistoryData] = useState<GridRowsProp>([]);
  const [exchangeRateHistoryPayload, setExchangeRateHistoryPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  // const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columnsExchangeRateHistory, setColumnsExchangeRateHistory] = useState<GridColDef<ExchangeRateHistory>[]>([
    { field: 'rate', headerName: t("exchangeratehistory.fields.rate"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'rate_date', headerName: t("exchangeratehistory.fields.rate_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);


  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-exchangeratehistory");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-exchangeratehistory-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date' && element.field !== 'rate_date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ExchangeRateHistory, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === 'rate_date') {
            element.valueGetter = function (params: GridValueGetterParams<ExchangeRateHistory, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
          else if (element.field !== 'last_update_user') {
            element.headerName = t(`exchangeratehistory.fields.${element.field}`);
          }
        }
      }
      setColumnsExchangeRateHistory(myJson);
    }
  }

  const handleAddRow = (newRow: ExchangeRateHistory) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    };
  
    const { rate_date } = myRow;
    if (rate_date) {
      if (new Date().toISOString().split('T')[0] === new Date(rate_date).toISOString().split('T')[0]) {
        props.handleUpdateValueRate(myRow.rate);
      }
    }
  
    setExchangeRateHistoryData((prevState) => {
      if (prevState.length === 0) {
        props.handleUpdateValueRate(myRow.rate);
      }
      return [...exchangeRateHistoryData, myRow];
    });
  };

  const handleUpdateRow = (updateRow: ExchangeRateHistory) => {
    if (exchangeRateHistoryData.length === 0) {
      return;
    }
    setExchangeRateHistoryData((prevRows) => {
      const rowToUpdateIndex = exchangeRateHistoryData.findIndex(e => e.exchange_rate_id === updateRow.exchange_rate_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (exchangeRateHistoryData.length === 0) {
      return;
    }

    setExchangeRateHistoryData((prevRows) => {
      const rowToDeleteIndex = exchangeRateHistoryData.findIndex(e => e.exchange_rate_id === deleteRow);

      const updatedData = [
        ...exchangeRateHistoryData.slice(0, rowToDeleteIndex),
        ...exchangeRateHistoryData.slice(rowToDeleteIndex + 1),
      ];

      if (updatedData.length > 0) {
        const today = new Date().toISOString().split('T')[0];

        const todayRecords = updatedData.filter(item =>
          new Date(item.rate_date).toISOString().split('T')[0] === today
        );

        let lastRecord;
        if (todayRecords.length > 0) {
          lastRecord = todayRecords.reduce((latest, item) =>
            new Date(item.rate_date) > new Date(latest.rate_date) ? item : latest
          );
        } else {
          lastRecord = updatedData.reduce((latest, item) =>
            new Date(item.rate_date) > new Date(latest.rate_date) ? item : latest
          );
        }

        if (lastRecord) {
          props.handleUpdateValueRate(lastRecord.rate);
        }
      }else{
        props.handleUpdateValueRate(null);
      }

      return updatedData;
    });
  };


  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/exchangeratehistory/bycurrencyid/${props.currency_id}`)
      .then(
        (response) => {
          setExchangeRateHistoryData(response.data.data);

          GetResourceByUrl(`/exchangeratehistory`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false}/>}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container mt-4'>
            <Header title={t("exchangeratehistory.title")} size='sm' />
            {resourceScreen?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsExchangeRateHistory}
                setColumns={setColumnsExchangeRateHistory}
                data={exchangeRateHistoryData}
                entityId={"exchange_rate_id"}
                entity={`ExchangeRateHistory`}
                preferences={myPreferences}
                namePreferences={"grid-exchangeratehistory"}
                nameOrderColumns={"grid-exchangeratehistory-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setExchangeRateHistoryPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateExchangeRateHistory exchangeRateHistoryPayload={exchangeRateHistoryPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("exchangeratehistory.title-view")} size='sm' />}
          />
          <DialogEntity
            content={<AddExchangeRateHistory setOpenDialog={setOpenDialogAdd} currency_id={props.currency_id} AddNewRow={handleAddRow} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("exchangeratehistory.title-view")} size='sm' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
