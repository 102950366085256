import React, { useContext, useState } from 'react'
import { Resource } from '../../interfaces/Security/resources';
import { AuthContext } from '../../context/AuthContext';
import { Menuitem } from '../../interfaces/Security/menu';
import GenericPromises from '../../api/GenericPromises';

export const usePermissions = () => {
    const { user, resources } = useContext(AuthContext);
    const { GenericGet } = GenericPromises();

    const getResourceScreen = async (resource: Menuitem, searchUrl: string): Promise<any> => {
        if (resource.children.length) {
            let myResource;
            for (let index = 0; index < resource.children.length; index++) {
                myResource = await getResourceScreen(resource.children[index], searchUrl);
                if (myResource !== undefined) { break; }
            }
            return myResource;
        }
        else {
            if (resource.resource_id === resources?.get(searchUrl)) {
                return resource;
            }
        }
    }

    const GetResourceByUrl = async (searchUrl: string) => {
        try {
            const response = await GenericGet(`/menu/${user?.group_id}`)
                .then(async (response) => {
                    let myResource;
                    for (let index = 0; index < response.data.children.length; index++) {
                        myResource = await getResourceScreen(response.data.children[index], searchUrl);
                        if (myResource !== undefined) { break; }
                    }
                    return myResource;
                });
            return response;
        }
        catch (error) {
            throw error;
        }
    }

    return {
        GetResourceByUrl
    }
}