import { Route, Routes } from 'react-router-dom'
import { AddState } from './add'
import { ViewState } from './view'
import { TableStates } from './table'

export const BrowserStates = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableStates />} />
            <Route path={"/add"} element={<AddState />} />
            <Route path={"/view"} element={<ViewState />} />
        </Routes>
    )
}