import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { GenericDialog } from '../../../../../components/Dialogs/Dialog';
import { BankAccount } from '../../../../../interfaces/Commons/bankAccounts';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { CustomerBankAccount } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Currency } from '../../../../../interfaces/Commons/currencies';

export const UpdateCustomerBankAccounts = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { customerBankAccountPayload, permissions } = props;
  const isEdit = !permissions.update;

  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([])
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm<CustomerBankAccount>({
    defaultValues: {
      bank_account_id: undefined
    }
  });
  const onSubmit: SubmitHandler<CustomerBankAccount> = (data) => onPut(data);

  const onPut = (data: CustomerBankAccount) => {
    if (!props.add) {
      let myData = {
        account_alias: data.account_alias ?? customerBankAccountPayload.account_alias,
        bank_name: data.bank_name ?? customerBankAccountPayload.bank_name,
        card_number: data.card_number ?? customerBankAccountPayload.card_number,
        account_number: data.account_number ?? customerBankAccountPayload.account_number,
        interbank_key: data.interbank_key ?? customerBankAccountPayload.interbank_key,
        swift_code: data.swift_code ?? customerBankAccountPayload.swift_code,
        phone_number: data.phone_number ?? customerBankAccountPayload.phone_number,
        currency_id: data.currency_id ?? customerBankAccountPayload.currency_id,
        bank_references: data.bank_references ?? customerBankAccountPayload.bank_references,
        balance: data.balance ?? customerBankAccountPayload.bank_references ?? 0,
      }
      setLoadingPost(true);
      GenericPutResource(`/bankaccounts/${customerBankAccountPayload.bank_account_id}`, myData)
        .then((response) => {
          let myUpdateData: CustomerBankAccount = {
            account_alias: response.data.account_alias,
            bank_name: response.data.bank_name,
            card_number: response.data.card_number,
            account_number: response.data.account_number,
            interbank_key: response.data.interbank_key,
            swift_code: response.data.swift_code,
            phone_number: response.data.phone_number,
            ...response.data
          };

          props.UpdateRow(myUpdateData);
          showSnackBar(t("generic.snackbar.update"), "success");
          setLoadingPost(false);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setLoadingPost(false);
        });
    } else {
      let myDataBankAccount: CustomerBankAccount | undefined;
      if (!myDataBankAccount)
        myDataBankAccount = {
          bank_account_id: customerBankAccountPayload.bank_account_id,
          account_alias: data.account_alias ?? customerBankAccountPayload.account_alias,
          bank_name: data.bank_name ?? customerBankAccountPayload.bank_name,
          card_number: data.card_number ?? customerBankAccountPayload.card_number,
          account_number: data.account_number ?? customerBankAccountPayload.account_number,
          interbank_key: data.interbank_key ?? customerBankAccountPayload.interbank_key,
          swift_code: data.swift_code ?? customerBankAccountPayload.swift_code,
          phone_number: data.phone_number ?? customerBankAccountPayload.phone_number,
          currency_id: data.currency_id ?? customerBankAccountPayload.currency_id,
          bank_references: data.bank_references ?? customerBankAccountPayload.bank_references,
          balance: data.balance ?? customerBankAccountPayload.bank_references ?? 0,
        }
      if (myDataBankAccount)
        myDataBankAccount.customer_bank_account_id = data.customer_bank_account_id ?? customerBankAccountPayload.customer_bank_account_id;
      props.UpdateRow(myDataBankAccount);
      showSnackBar(t("generic.snackbar.update"), "success");
      setLoadingPost(false);
      props.setOpenDialog(false);
    }
  }



  const onDelete = () => {
    if (!props.add) {
      GenericDeleteResource(`/customerbankaccounts/${customerBankAccountPayload?.customer_bank_account_id}`)
        .then(() => {
          setOpenDialog(false);
          props.DeleteRow(customerBankAccountPayload?.customer_bank_account_id);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setOpenDialog(false);
        })
    } else {
      setOpenDialog(false);
      props.DeleteRow(customerBankAccountPayload?.customer_bank_account_id);
      props.setOpenDialog(false);
    }
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/currencies")
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboCurrencies((prev) => responses[0].data.data);
        reset({
          bank_account_id: customerBankAccountPayload.bank_account_id ?? undefined,
          account_alias: customerBankAccountPayload.account_alias ?? undefined,
          bank_name: customerBankAccountPayload.bank_name ?? undefined,
          balance: customerBankAccountPayload.balance ?? undefined,
          bank_references: customerBankAccountPayload.bank_references ?? undefined,
          card_number: customerBankAccountPayload.card_number ?? undefined,
          currency_id: customerBankAccountPayload.currency_id ?? undefined,
          account_number: customerBankAccountPayload.account_number ?? undefined,
          interbank_key: customerBankAccountPayload.interbank_key ?? undefined,
          swift_code: customerBankAccountPayload.swift_code ?? undefined,
          phone_number: customerBankAccountPayload.phone_number ?? undefined,
        });
        setDefaultCurrency(() => responses[0].data.data.find((currency: Currency) => currency.currency_id === customerBankAccountPayload.currency_id))
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
              <div className='w-100'>
                <div className='w-100 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="account_alias"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.account_alias")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.account_alias}
                          helperText={GetError(
                            errors.account_alias?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="bank_name"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_name")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_name}
                          helperText={GetError(
                            errors.bank_name?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="card_number"
                      control={control}
                      rules={{ maxLength: 19 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.card_number")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.card_number}
                          helperText={GetError(
                            errors.card_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="account_number"
                      control={control}
                      rules={{ maxLength: 24 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.account_number")}`}
                          ref={field.ref}
                          value={field.value}
                          disabled={isEdit}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.account_number}
                          helperText={GetError(
                            errors.account_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="interbank_key"
                      control={control}
                      rules={{ maxLength: 18 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.interbank_key")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.interbank_key}
                          helperText={GetError(
                            errors.interbank_key?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="swift_code"
                      control={control}
                      rules={{ maxLength: 11 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.swift_code")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.swift_code}
                          helperText={GetError(
                            errors.swift_code?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="bank_references"
                      control={control}
                      rules={{ maxLength: 30 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_references")}`}
                          ref={field.ref}
                          value={field.value}
                          disabled={isEdit}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_references}
                          helperText={GetError(
                            errors.bank_references?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ maxLength: 20 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.phone_number")}`}
                          ref={field.ref}
                          value={field.value}
                          disabled={isEdit}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.phone_number}
                          helperText={GetError(
                            errors.phone_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="currency_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          options={comboCurrencies}
                          defaultValue={defaultCurrency}
                          getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                          renderOption={(props, option: Currency) => (
                            <div key={option.currency_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.currency_code} - {option.currency_description}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                          disabled={isEdit}
                          onChange={(_, values) => {
                            field.onChange(values?.currency_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("currencies.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.currency_id}
                              helperText={GetError(errors.currency_id?.type)}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="balance"
                      control={control}
                      rules={{ required: true, min: 0 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.balance")}`}
                          ref={field.ref}
                          disabled={isEdit}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.balance}
                          helperText={GetError(
                            errors.balance?.type
                          )}
                          size="small"
                          type='number'
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", }, width: "100%"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                  <div>
                    <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                    {permissions.update && <PrimaryButton onClick={() => { onPut(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                  </div>
                  {permissions.delete ?
                    <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                  }
                </div>
              </div>
            </form>
          </Box>
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("customerbankaccounts.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
