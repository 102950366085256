import { GridInputRowSelectionModel, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useFiles } from "../../../../hooks/useFiles";
import EditIcon from '@mui/icons-material/Edit';
import { usePermissions } from "../../../../hooks/usePermissions";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useCurrencies } from "../../../../hooks/useCurrencies";
import { Supplier, SupplierLocation } from '../../../../interfaces/Purchases/Catalogs/suppliers';
import { Company, CompanyBankAccount, CompanyLocation } from "../../../../interfaces/Security/companies";
import { SupplierBankAccount } from "../../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { PaymentMethod } from "../../../../interfaces/Sales/Catalogs/paymentMethods";
import { Currency } from "../../../../interfaces/Commons/currencies";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Payment } from "../../../../interfaces/Purchases/Payments/payments";
import Swal from "sweetalert2";
import { CFDI } from "../../../../interfaces/Sales/Catalogs/CFDIs";
import { File } from "../../../../interfaces/Commons/files";
import { Header } from "../../../../components/Header";
import { PrimaryButton } from "../../../../theme/buttons";
import { Autocomplete, Box, Button, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { numberWithTwoDecimalsRegex } from "../../../../constants/Regex";
import { Divider } from "../../../../components/Commons/Divider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { ConfrimDialogPayment } from './confirmDialog';
import { StampingPayment } from "../commons/stamping";
import DialogPayments from "./tabsDetail";
import { TableApplyPayment } from "./table";

export type DialogPaymentProps = {
  rows: readonly GridValidRowModel[],
  businessName: string,
  supplier_id: number,
  selectedRows?: GridRowsProp,
  setSelectedRows?: Dispatch<SetStateAction<readonly GridValidRowModel[]>>,
  setDialogPayment: Dispatch<SetStateAction<boolean>>,
  setClickedButtonGoBack: Dispatch<SetStateAction<boolean>>,
}

export const DialogPayment = ({
  rows,
  supplier_id,
  businessName,
  selectedRows,
  setSelectedRows,
  setDialogPayment,
  setClickedButtonGoBack,
}: DialogPaymentProps) => {
  const [t] = useTranslation("global");
  const myConsecutiveId = 5;
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric, GenericGetReport, GenericPost, GenericPutResource } = GenericPromises();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const { GetError } = FormsErrors();
  const { GetFiles } = useFiles();
  const { GetResourceByUrl } = usePermissions();
  const { setFormatNumber } = useFormatNumber();
  const { GetExchangeRateFromCurrencyCode, GetOperatorToGetDivisa } = useCurrencies();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const theme = useTheme();
  const [loadingPost, setLoadingPost] = useState(false);
  const [hasErrorPost, setHasErrorPost] = useState(false);
  const [hasPositiveDifference, setHasPositiveDifference] = useState(false);
  const [dialogConfirmApplyPayment, setDialogConfirmApplyPayment] = useState(false);
  const [hasAdvanceSalesInvoice, setHasAdvanceSalesInvoice] = useState(false);
  const [mySupplierLocal, setMySupplierLocal] = useState<Supplier>();
  const [myCompanyLocal, setMyCompanyLocal] = useState<Company>();
  const [preSelectedRows, setPreSelectedRows] = useState<GridInputRowSelectionModel>();
  const [defaultSupplierBankAccount, setDefaultSupplierBankAccount] = useState<SupplierBankAccount>();
  const [defaultCompanyBankAccount, setDefaultCompanyBankAccount] = useState<CompanyBankAccount>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [comboSupplierBankAccounts, setComboSupplierBankAccounts] = useState<SupplierBankAccount[]>([]);
  const [comboCompanyBankAccounts, setComboCompanyBankAccounts] = useState<CompanyBankAccount[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [mySelectedRows, setMySelectedRows] = useState<GridRowsProp>([]);
  const [comboSuppliersLocations, setComboSuppliersLocations] = useState<SupplierLocation[]>([])
  const [defaultSupplierLocation, setDefaultSupplierLocation] = useState<SupplierLocation>();
  const [resourceFiscalSignature, setResourceFiscalSignature] = useState<Menuitem>();
  const [fiscalXML, setFiscalXML] = useState<File>();
  const [fiscalQr, setFiscalQr] = useState<File>();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingCurrenciesValues, setIsLoadingCurrenciesValues] = useState(false);
  const [isSameCurrency, setIsSameCurrency] = useState(false);
  const [currencySupplier, setCurrencySupplier] = useState<Currency>()
  const [exchangeRate, setExchangeRate] = useState(1);
  const [focusTextField, setFocusTextField] = useState(false);
  const [companyBalance, setCompanyBalance] = useState({ balance: 0, currency_id: 0, currency_code: "" })
  const [formattedNumberCompany, setFormattedNumberCompany] = useState<number | null>(null);
  const [spinnerBalance, setSpinnerBalance] = useState(false);
  const { GetFileById } = useFiles();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm<Payment>({
    defaultValues: {
      total_amount: 0,
      selected_amount: 0,
      difference_amount: 0,
      currency_id: undefined
    }
  });
  const onSubmit: SubmitHandler<Payment> = (data) => onPost(data);

  const onPost = (data: Payment) => {
    trigger()
      .then(async (responseTrigger) => {
        let myPaymentsDetails = mySelectedRows.map(item => {
          if (!item.product_service_key) {
            return item.payment_detail_id;
          }
        }).filter(id => id !== undefined && id !== null);
        let myAdvanceInvoices = mySelectedRows.map(item => {
          if (item.product_service_key) {
            return item.payment_detail_id;
          }
        }).filter(id => id !== undefined && id !== null);
        if (responseTrigger) {
          setIsEdit(true);
          setLoadingPost(true);
          setHasErrorPost(false);

          const paymentDetailsMap = mySelectedRows.reduce((map, detail) => {
            map[detail.payment_detail_id] = detail.payment_deadline;
            return map;
          }, {} as { [key: number]: Date | null | undefined });

          const sortedPaymentsDetails = myPaymentsDetails.sort((a, b) => {
            const dateStrA = paymentDetailsMap[a];
            const dateStrB = paymentDetailsMap[b];

            if (dateStrA === null || dateStrA === undefined) {
              return 1;
            }
            if (dateStrB === null || dateStrB === undefined) {
              return -1;
            }

            const dateA = new Date(dateStrA);
            const dateB = new Date(dateStrB);

            return dateA.getTime() - dateB.getTime();
          });

          let myCurrency = comboCurrencies.find((item) => item.currency_code === currencySupplier?.currency_code);
          const responseSupplierLocations: SupplierLocation[] = await GenericGetResource(`/supplierlocations/bysupplierid/${mySupplierLocal?.supplier_id}`)
            .then(responseInterSupplier => {
              return responseInterSupplier.data.data;
            })
            .catch((error) => showSnackBar(error.message, 'error'));
          let mySupplierLocation;
          if (defaultSupplierLocation?.supplier_location_id && defaultSupplierLocation?.supplier_location_id > 0) {
            mySupplierLocation = defaultSupplierLocation;
          }
          else {
            mySupplierLocation = responseSupplierLocations.find(item => item.fiscal_address === true);
          }

          const responseCompanyLocations: CompanyLocation[] = await GenericGetResource(`/companylocations/bycompanyid/${myCompanyLocal?.company_id}`)
            .then(responseInterCompany => {
              return responseInterCompany.data.data;
            })
            .catch((error) => showSnackBar(error.message, 'error'));
          const myCompanyLocation = responseCompanyLocations.find(item => item.main_location === true);

          const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myConsecutiveId}`)
            .catch((error) => showSnackBar(error.message, 'error'));

          const responseCFDIs: CFDI[] = await GenericGetResource(`/cfdi`)
            .then((responseInterCFDIs) => responseInterCFDIs.data.data)
            .catch((error) => showSnackBar(error.message, 'error'));
          const myCFDI = await responseCFDIs.find((item) => item.code === "CP01")

          let mySupplierBankAccount = comboSupplierBankAccounts.find((item) => item.supplier_bank_account_id === data.supplier_bank_account_id);
          let myCompanyBankAccount = comboCompanyBankAccounts.find((item) => item.company_bank_account_id === data.company_bank_account_id);
          let myPaymentMethod = comboPaymentMethods.find((item) => item.payment_method_id === data.payment_method_id);

          let totalAmount = parseFloat(data.total_amount?.toString() ?? "0.00")
          if (focusTextField)
            totalAmount = totalAmount * exchangeRate

          totalAmount = parseFloat(totalAmount.toFixed(6));
          let myData = {
            payment_name: responseConsecutive.data.data[0].Consecutive ?? (data.payment_name ?? null),
            consecutive_id: myConsecutiveId ?? (data.consecutive_id ?? null),
            payment_date: ((typeof data.payment_date) === 'object' ? data.payment_date : data.payment_date?.toDateString()) ?? null,
            signing_date: data.signing_date ?? null,
            supplier_id: mySupplierLocal?.supplier_id ?? (data.supplier_id ?? null),
            supplier_business_name: mySupplierLocation?.business_name ?? (data.supplier_business_name ?? null),
            supplier_comercial_name: mySupplierLocal?.comercial_name ?? (data.supplier_comercial_name ?? null),
            supplier_rfc: mySupplierLocation?.supplier_rfc ?? (data.supplier_rfc ?? null),
            payment_method_id: myPaymentMethod?.payment_method_id ?? (data.payment_method_id ?? null),
            payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
            payment_method_description: myPaymentMethod?.description ?? (data.payment_method_description ?? null),
            supplier_tax_regime_id: mySupplierLocal?.tax_regime_id ?? (data.supplier_tax_regime_id ?? null),
            supplier_tax_regime_code: mySupplierLocal?.tax_regime_code ?? (data.supplier_tax_regime_code ?? null),
            supplier_tax_regime_description: mySupplierLocal?.tax_regime_description ?? (data.supplier_tax_regime_description ?? null),
            cfdi_id: myCFDI?.cfdi_id ?? (data.cfdi_id ?? null),
            cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
            cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
            supplier_bank_account_id: mySupplierBankAccount?.supplier_bank_account_id ?? (data.supplier_bank_account_id ?? null),
            supplier_account_alias: mySupplierBankAccount?.account_alias ?? (data.supplier_account_alias ?? null),
            supplier_bank_name: mySupplierBankAccount?.bank_name ?? (data.supplier_bank_name ?? null),
            supplier_bank_references: mySupplierBankAccount?.bank_references ?? (data.supplier_bank_references ?? null),
            supplier_account_number: mySupplierBankAccount?.account_number ?? (data.supplier_account_number ?? null),
            supplier_bank_phone_number: mySupplierBankAccount?.phone_number ?? (data.supplier_bank_phone_number ?? null),
            supplier_street: mySupplierLocation?.street ?? (data.supplier_street ?? null),
            supplier_city_id: mySupplierLocation?.city_id ?? (data.supplier_city_id ?? null),
            supplier_city_name: mySupplierLocation?.city_name ?? (data.supplier_city_name ?? null),
            supplier_state_id: mySupplierLocation?.state_id ?? (data.supplier_state_id ?? null),
            supplier_state_name: mySupplierLocation?.state_name ?? (data.supplier_state_name ?? null),
            supplier_state_abbr: mySupplierLocation?.state_abbr ?? (data.supplier_state_abbr ?? null),
            supplier_country_id: mySupplierLocation?.country_id ?? (data.supplier_country_id ?? null),
            supplier_country_code: mySupplierLocation?.country_code ?? (data.supplier_country_code ?? null),
            supplier_country_name: mySupplierLocation?.country_name ?? (data.supplier_country_name ?? null),
            supplier_postal_code: mySupplierLocation?.postal_code ?? (data.supplier_postal_code ?? null),
            supplier_language: mySupplierLocal?.language_code ?? "ES",
            company_bank_account_id: myCompanyBankAccount?.company_bank_account_id ?? (data.company_bank_account_id ?? null),
            company_account_alias: myCompanyBankAccount?.account_alias ?? (data.company_account_alias ?? null),
            company_bank_name: myCompanyBankAccount?.bank_name ?? (data.company_bank_name ?? null),
            company_bank_references: (data.company_bank_references ?? null),
            company_account_number: myCompanyBankAccount?.account_number ?? (data.company_account_number ?? null),
            company_bank_phone_number: myCompanyBankAccount?.phone_number ?? (data.company_bank_phone_number ?? null),
            company_street: myCompanyLocation?.street ?? (data.company_street ?? null),
            company_city_id: myCompanyLocation?.city_id ?? (data.company_city_id ?? null),
            company_city_name: myCompanyLocation?.city_name ?? (data.company_city_name ?? null),
            company_state_id: myCompanyLocation?.state_id ?? (data.company_state_id ?? null),
            company_state_name: myCompanyLocation?.state_name ?? (data.company_state_name ?? null),
            company_state_abbr: myCompanyLocation?.state_abbr ?? (data.company_state_abbr ?? null),
            company_country_id: myCompanyLocation?.country_id ?? (data.company_country_id ?? null),
            company_country_name: myCompanyLocation?.country_code ?? (data.company_country_name ?? null),
            company_postal_code: myCompanyLocation?.postal_code ?? (data.company_postal_code ?? null),
            company_rfc: myCompanyLocal?.rfc ?? (data.company_rfc ?? null),
            currency_id: myCurrency?.currency_id ?? (data.currency_id ?? null),
            currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
            currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
            subtotal_tax_amount: data.subtotal_tax_amount ?? 0,
            total_tax_amount: data.total_tax_amount ?? 0,
            subtotal_retention_amount: data.subtotal_retention_amount ?? 0,
            total_retention_amount: data.total_retention_amount ?? 0,
            subtotal_amount: data.subtotal_amount ?? 0,
            total_amount: totalAmount,
            original_string_SAT: data.original_string_SAT ?? null,
            certificate_number_SAT: data.certificate_number_SAT ?? null,
            certificate_number_CFDI: data.certificate_number_CFDI ?? null,
            uuid: data.uuid ?? null,
            seal_SAT: data.seal_SAT ?? null,
            seal_CFDI: data.seal_CFDI ?? null,
            xml_file_id: data.xml_file_id ?? null,
            qr_file: data.qr_file ?? null,
            report_file_id: data.report_file_id ?? null,
            cancel_xml_file_id: data.cancel_xml_file_id ?? null,
            date_mail_send: data.date_mail_send ?? null,
            user_mail_send: data.user_mail_send ?? null,
            cancellation_date: data.cancellation_date ?? null,
            cancellation_reason: data.cancellation_reason ?? null,
            cancellation_response_code: data.cancellation_response_code ?? null,
            cancellation_reason_description: data.cancellation_reason_description ?? null,
            paymentsdetails: sortedPaymentsDetails,
            exchange_rate: exchangeRate,
            advanceInvoices: myAdvanceInvoices,
          }
          GenericPostResource(`/payments`, myData)
            .then(async (response) => {
              if (response.data.error) {
                Swal.fire({
                  customClass: { container: "swalfire" },
                  icon: "error",
                  confirmButtonColor: theme.palette.primary.main,
                  title: "Error",
                  html: `${response.data.error}${(response.data.details) != null ? '<br> <br>' + response.data.details : ""}`,
                });
                if (!response.data.data) {
                  setHasErrorPost((prev) => true);
                  setIsEdit(false);
                  setLoadingPost(false);
                  setValue("total_amount", 0);
                  setDialogConfirmApplyPayment(false);
                }
              }
              else {
                if (response.data.XML !== undefined && response.data.QR !== undefined) {
                  setValue("xml_file_id", response.data.XML)
                  setValue("qr_file", response.data.QR)
                  await GetFiles(response.data.payment_id, "Payments").then((responsefiles) => {
                    if (responsefiles && responsefiles.length > 0) {
                      for (let i = 0; i < responsefiles.length; i++) {
                        const element: File = responsefiles[i];
                        if (element.file_id === (((getValues && getValues("qr_file")) ?? response.data.QR)) ?? 0) {
                          setFiscalQr(() => element);
                        }
                        if (element.file_id === (((getValues && getValues("xml_file_id")) ?? response.data.XML)) ?? 0) {
                          setFiscalXML(() => element);
                        }
                      }
                    }
                  });
                }
                if (response.data.data) {
                  setValue("payment_id", response.data.data.payment_id);
                }
                else if (response.data) {
                  setValue("payment_id", response.data.payment_id)
                  // await onGeneratePDF(response.data.payment_id);
                  await GenericPutResource(`/payments/${response.data.payment_id}`, { report_file_id: getValues("report_file_id") })
                }
                setLoadingPost(false);
                setDialogConfirmApplyPayment(false);
                Swal.fire({
                  customClass: { container: "swalfire" },
                  title: `${t("payments.info.stamped")}`,
                  confirmButtonColor: theme.palette.primary.main,
                  icon: "success",
                  allowOutsideClick: false
                });
              }
            })
            .catch(async (error) => {
              if (error.response.data.errorMessage) {
                await showSnackBar(t(error.response.data.errorMessage), "error");
              }
              else {
                showSnackBar(error.message, 'error');
              }
              setHasErrorPost((prev) => true);
              setIsEdit(false);
              setLoadingPost(false);
              setValue("total_amount", 0);
              setDialogConfirmApplyPayment(false)
            });
        }
      });
  }

  const getDataAndGeneratePDF = async (paymentId: number) => {
    try {
      const response = await GenericGetReport(`/payments/pdf/${paymentId}`, false);

      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile =  await GenericGetResource(`/filessql/`+response.data.dataIssuingCompany.rpt_image);
        response.data.dataPaymentHeader.logoImage = rptImageFile?.data.data_file  ?? undefined;
      }

      if (response.data.dataPaymentHeader.qr_file) {
        const responseQR = await GetFileById(response.data.dataPaymentHeader.qr_file);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataPaymentHeader.fiscalQr = newBase64 ?? undefined;
      }

      // let pdfDoc = await generatePDF(response);
      // return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const savePDFToDatabase = async (paymentId: number, pdfDoc: any) => {
    try {
      const startIndex = pdfDoc.indexOf(',') + 1;
      const pdfInBase64 = pdfDoc.substring(startIndex);
      const myFilePDF = {
        entity: "Payments",
        recordId: paymentId,
        fileName: getValues("payment_name"),
        typeOfFile: "application/pdf",
        schema_name: localStorage.getItem("schemaName"),
        Content: pdfInBase64
      };
      const responsePdf = await GenericPost(`/files`, myFilePDF);
      return responsePdf.file_id;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async (payment_id: number) => {
    try {
      const pdfDoc = await getDataAndGeneratePDF(payment_id);
      const fileId = await savePDFToDatabase(payment_id, pdfDoc);
      setValue("report_file_id", fileId);
      showSnackBar(t("purchaseinvoices.reports.generated-PDF"), "success");
    } catch (error: any) {
      showSnackBar('Error to generate pdf', "error");
    }
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const onChangeTotals = async (myRows: readonly GridValidRowModel[], currency?: string) => {
    trigger();
    setIsLoadingCurrenciesValues(true);
    let currencyCode = ''
    if (currency)
      currencyCode = currency
    else
      currencyCode = currencySupplier?.currency_code ?? ''
    if (currencyCode !== '') {
      let currencySame = myRows.find((element) => element.related_currency_code !== currencyCode) ?? false
      if (currencySame) {
        setIsSameCurrency(true)
      }
      else {
        setIsSameCurrency(false)
      }
      if (currency && currencyCode !== "MXN" ||
        currencyCode === "MXN" && !(mySelectedRows.some(element => element.related_currency_code !== "MXN"))) {
        let currencyId = comboCurrencies.find((element) => element.currency_code == currencyCode)?.currency_id ?? 0;
        let date = new Date(getValues("payment_date") ?? new Date());
        date?.setDate(date.getDate() + 1)
        let exchangeRate = await GetExchangeRateFromCurrencyCode(currencyId, date)
        if (exchangeRate?.rate && exchangeRate?.rate <= 0)
          setExchangeRate(1)
        else
          setExchangeRate(exchangeRate?.rate ?? 1)
      }
      let myTotalAmount = 0;
      myRows.forEach((element) => {
        (element.related_currency_code !== "MXN") ?
          myTotalAmount += element.amount_outstanding * ((exchangeRate) ? exchangeRate : 0) :
          myTotalAmount += element.amount_outstanding;
      });
      setValue("selected_amount", myTotalAmount);
      let myTotalPay = parseFloat(getValues("total_amount")?.toString() ?? "0")
      if (isNaN(myTotalPay))
        myTotalPay = 0
      let diference = 0
      if (focusTextField)
        myTotalPay = myTotalPay * ((exchangeRate) ? exchangeRate : 0)
      diference = myTotalAmount - myTotalPay
      if (diference <= 0) {
        if (focusTextField)
          myTotalAmount = myTotalAmount / ((exchangeRate) ? exchangeRate : 0)
        setValue("total_amount", myTotalAmount)
        diference = 0
      }
      setValue("difference_amount", diference)
    }
    setIsLoadingCurrenciesValues(false);
    spinnerBalance ? setSpinnerBalance(false) : setSpinnerBalance(true)
  }

  useEffect(() => {
    const calculateBalance = async () => {
      try {
        const totalAmount = getValues("total_amount") ?? 0;
        const balance = companyBalance.balance;

        let calculatedBalance = 0;
        if (focusTextField && companyBalance.currency_code === "MXN") {
          calculatedBalance = (balance - totalAmount) * exchangeRate;
        } else if (!focusTextField && companyBalance.currency_code !== "MXN") {
          calculatedBalance = (balance - totalAmount) / exchangeRate;
        } else {
          calculatedBalance = balance - totalAmount;
        }

        if (exchangeRate === -1) {
          Swal.fire({
            customClass: { container: "swalfire" },
            title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
            icon: "warning",
            confirmButtonColor: theme.palette.primary.main,
            allowOutsideClick: false
          });
          setFormattedNumberCompany(0);
        }
        else {
          setFormattedNumberCompany(calculatedBalance);
        }
        setSpinnerBalance(false);
      } catch (error) {
        console.error("Error calculating balance:", error);
        setSpinnerBalance(false);
      }
    };

    const GetExchangeRateForReceiptDate = async (currencyCode: number) => {
      const receiptDate = getValues('payment_date');
      let date = new Date(receiptDate ?? new Date());
      date.setDate(date.getDate() + 1);
      const exchangeRate = await GetExchangeRateFromCurrencyCode(currencyCode, date);
      return exchangeRate;
    };

    calculateBalance();
  }, [isLoadingCurrenciesValues, spinnerBalance, companyBalance]);

  useEffect(() => {

    let myPromises = [
      GenericGetResource(`/suppliers/${supplier_id}`),
      GenericGetResource(`/supplierbankaccounts/bysupplierid/${supplier_id}`),
      GenericGetResourceGeneric(`/companies/1`, "/gcompanies"),
      GenericGetResource(`/companybankaccounts/bycompanyid/1`),
      GenericGetResource(`/paymentmethods`),
      GenericGetResource(`/currencies`),
      GenericGetResource(`/consecutives/byConsecutiveId/${myConsecutiveId}`),
      GenericGetResource(`/supplierlocations/bysupplierid/${supplier_id}`),
    ];

    Promise.all(myPromises)
      .then(async (responses) => {
        responses[0] && setMySupplierLocal(responses[0].data);
        responses[1] && setComboSupplierBankAccounts(responses[1].data.data);
        responses[2] && setMyCompanyLocal(responses[2].data);
        responses[3] && setComboCompanyBankAccounts(responses[3].data.data);
        responses[4] && setComboPaymentMethods(responses[4].data.data);
        responses[5] && setComboCurrencies(responses[5].data.data);
        responses[6] && setValue("payment_name", responses[6].data.data[0].Consecutive);
        responses[7] && setComboSuppliersLocations(responses[7].data.data);
        setMySelectedRows(selectedRows ?? []);

        let myPermissions = [
          GetResourceByUrl(`/payments`),
        ];

        Promise.all(myPermissions)
          .then((responses) => {
            responses[0] && setResourceFiscalSignature(responses[0]);
          });

        let myDefaultCompanyBankAccount = responses[3].data.data.find((item: CompanyBankAccount) => item.company_bank_account_id === responses[2].data.primary_bank_account);
        setDefaultCompanyBankAccount(myDefaultCompanyBankAccount);
        setCompanyBalance({
          balance: myDefaultCompanyBankAccount?.balance ?? 0,
          currency_id: myDefaultCompanyBankAccount?.currency_id ?? 0,
          currency_code: myDefaultCompanyBankAccount?.currency_code ?? ""
        })


        let myDefaultSupplierBankAcoount: SupplierBankAccount = {};
        if (selectedRows && selectedRows.length > 0 && selectedRows[0].supplier_bank_account_id && selectedRows[0].supplier_bank_account_id > 0) {
          let myDefaultSupplierBankAcoount = responses[1].data.data.find((item: SupplierBankAccount) => item.supplier_bank_account_id === selectedRows[0].supplier_bank_account_id);
          setDefaultSupplierBankAccount(myDefaultSupplierBankAcoount);
          let myCurrency: Currency = {
            currency_id: 0,
            currency_code: myDefaultSupplierBankAcoount.currency_code ?? undefined,
            currency_description: myDefaultSupplierBankAcoount.currency_description ?? undefined,
            currency_symbol: myDefaultSupplierBankAcoount.currency_symbol ?? undefined
          }
          if (!currencySupplier?.currency_code)
            setCurrencySupplier(myCurrency);
        }

        let mySupplierLocations: SupplierLocation[] = responses[7].data.data;
        let myDefaultSupplierLocation;
        if (businessName)
          myDefaultSupplierLocation = mySupplierLocations.find((item) => item.business_name === businessName)
        else
          myDefaultSupplierLocation = mySupplierLocations.find((item) => item.fiscal_address === true)
        setDefaultSupplierLocation(myDefaultSupplierLocation);
        setValue("supplier_business_name", myDefaultSupplierLocation?.business_name);

        let myDefaultPaymentMethod = responses[4].data.data.find((item: PaymentMethod) => item.payment_method_id === responses[0].data.default_payment_method_id)
        setDefaultPaymentMethod(myDefaultPaymentMethod);

        reset({
          company_bank_account_id: myDefaultCompanyBankAccount && myDefaultCompanyBankAccount.company_bank_account_id,
          supplier_bank_account_id: myDefaultSupplierBankAcoount && myDefaultSupplierBankAcoount.supplier_bank_account_id || selectedRows && selectedRows[0].supplier_bank_account_id,
          payment_method_id: myDefaultPaymentMethod && myDefaultPaymentMethod.payment_method_id,
          payment_date: new Date(),
        }, { keepValues: true });

        showSnackBar(t("payments.info.apply-payment-filter"), "info");
        setPreSelectedRows((prev) => selectedRows?.map((row) => {
          if (row.payment_detail_id && row.payment_name === null) {
            return row.payment_detail_id
          }
        }));

        onChangeTotals(selectedRows ?? [], myDefaultSupplierBankAcoount.currency_code);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });

  }, []);

  useEffect(() => {
    onChangeTotals(mySelectedRows, currencySupplier?.currency_code);
  }, [exchangeRate])

  useEffect(() => {
    let currencyCodeFilter = mySelectedRows?.find((element) => element.currency_code !== "MXN");
    if ((currencySupplier?.currency_code !== "MXN" && currencySupplier?.currency_code !== undefined) ||
      (currencyCodeFilter && currencyCodeFilter.length > 0)) {
      let currencyId = comboCurrencies.find((element) => element.currency_code !== "MXN")?.currency_id ?? 0;
      let date = new Date(getValues("payment_date") ?? new Date());
      date?.setDate(date.getDate() + 1)
      GetExchangeRateFromCurrencyCode(currencyId, date).then((currency) => {
        setExchangeRate(currency?.rate ?? 1)
      });
    }
  }, [currencySupplier, mySelectedRows])



  function roundToFourDecimals(num: number): number {
    return Math.round(num * 10000) / 10000;
  }

  return (
    <>
      <Header
        title={t("payments.title-view")}
        child={
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-row w-75 border rounded me-5 ps-2 pt-1">
              <div className="d-flex flex-row me-2">
                <Typography variant="h5" display="block" gutterBottom>
                  <b>{t("receipts.dialog.balance")}:</b>
                </Typography>
              </div>
              <div className="d-flex flex-row">
                {formattedNumberCompany !== null && (
                  <div className={`${formattedNumberCompany < 0 ? "text-danger" : ""}`}>
                    <Typography variant="h6" display="block" gutterBottom>
                      {setFormatNumber({ value: formattedNumberCompany })}  {companyBalance.currency_code}
                    </Typography>
                  </div>
                )}
                {spinnerBalance && (
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    size={20}
                    thickness={4}
                    sx={{ marginX: 2 }}
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row-reverse w-100">
              <PrimaryButton
                disabled={!resourceFiscalSignature?.create || isEdit}
                onClick={() => {
                  let hasOnlySelectedAdvanceInvoices = mySelectedRows?.findIndex((item) => !item.product_service_key) ?? -1;
                  if (mySelectedRows.length === 0) {
                    showSnackBar(t("payments.info.add-details"), "warning");
                  } else if (hasOnlySelectedAdvanceInvoices === -1 && hasAdvanceSalesInvoice) {
                    showSnackBar(t("payments.info.select-purchase-invoices"), "warning");
                  } else {
                    trigger().then((responseTrigger) => {
                      if (!isLoadingCurrenciesValues && responseTrigger) setDialogConfirmApplyPayment(true);
                    });
                  }
                }}
                className="d-flex align-self-end w-25"
                variant="outlined"
              >
                {t("paymentsdetails.buttons.apply-payment")}
              </PrimaryButton>
              <Button
                onClick={() => {
                  setDialogPayment(false);
                  setClickedButtonGoBack((prev) => !prev);
                  setSelectedRows && setSelectedRows(() => []);
                }}
                disabled={loadingPost}
              >
                {t("generic.buttons.goback")}
              </Button>
            </div>
          </div>
        }
      />
      {!dataLoaded && <Spinner isBox={false} />}
      {
        dataLoaded &&
        <>
          <Box sx={{ minWidth: 1250 }} className="d-flex flex-column my-2 w-100">
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column custom-card-view w-100">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-50">

                  <Box display={"flex"} className="w-100" justifyContent={"space-between"}>
                    <Box className="w-50">
                      <Typography style={{ fontWeight: "bold" }}>{t("suppliers.title-view")}:</Typography>
                      <Typography>{defaultSupplierLocation?.business_name}</Typography>
                      <Typography>{defaultSupplierLocation?.supplier_rfc}</Typography>
                      <Typography>{mySupplierLocal?.tax_regime_code} - {mySupplierLocal?.tax_regime_description}</Typography>
                    </Box>
                    <Box className="w-50 mt-3">
                      <Controller
                        name="supplier_business_name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ maxWidth: "100%", minWidth: "80%" }}
                            options={comboSuppliersLocations}
                            defaultValue={defaultSupplierLocation}
                            getOptionLabel={(option) => `${option.business_name} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                            renderOption={(props, option: SupplierLocation) => (
                              <div key={option.supplier_location_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.supplier_location_id === value.supplier_location_id}
                            onChange={(_, values) => {
                              setDefaultSupplierLocation(values ?? undefined)
                              field.onChange(values?.business_name || null)
                            }}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("purchaseinvoices.fields.address")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.supplier_business_name}
                                helperText={GetError(errors.supplier_business_name?.type)}
                              />
                            )}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} sx={{ paddingRight: 1 }}>
                      {hasAdvanceSalesInvoice &&
                        <>
                          <Box sx={{ border: "solid 1px black", backgroundColor: "#FFFDE7", borderRadius: "3px" }} width={20} height={20} marginY={1} />
                          <Typography margin={1}>
                            {t("payments.info.advance-sales-invoice-selected")}
                          </Typography>
                        </>
                      }
                    </Box>
                  </Box>

                  <Box sx={{ paddingRight: 1, marginBottom: 0.5 }}>
                    <Divider />
                  </Box>
                  <div className="d-flex w-100">
                    <Controller
                      name="payment_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("payments.fields.payment_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.payment_name}
                          helperText={GetError(errors.payment_name?.type)}
                          size="small"
                          sx={{ width: "100%", paddingRight: 1 }}
                          InputProps={{ readOnly: true, disableUnderline: true }}
                          disabled={isEdit}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-50">
                  <div className="d-flex flex-row">
                    <div className={`${isSameCurrency ? "w-50" : "w-100"}`}>
                      <Controller
                        name="total_amount"
                        control={control}
                        rules={{ required: true, min: hasAdvanceSalesInvoice ? undefined : 0.000001, pattern: numberWithTwoDecimalsRegex }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("payments.fields.total_amount_payment")}`}
                            ref={field.ref}
                            value={(field.value && focusTextField == true) ? roundToFourDecimals(field.value * exchangeRate)
                              : (field.value) ? roundToFourDecimals(field.value) : field.value}
                            onChange={(event) => {
                              field.onChange(event.target.value);
                              onChangeTotals(mySelectedRows, currencySupplier?.currency_code); //Mueve los por pagar y pagado
                            }}
                            error={!!errors.total_amount}
                            onFocus={() => {
                              if (focusTextField == true)
                                setValue("total_amount", (field.value) ? field.value * exchangeRate : field.value)
                              setFocusTextField(false);
                            }}
                            helperText={GetError(errors.total_amount?.type)}
                            size="small"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", paddingRight: isSameCurrency ? 1 : 0
                            }}
                            type="number"
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    {isSameCurrency &&
                      <div className="w-50">
                        <Controller
                          name="total_amount"
                          control={control}
                          rules={{ required: true, min: hasAdvanceSalesInvoice ? undefined : 0.000001, pattern: numberWithTwoDecimalsRegex }}
                          render={({ field }) => (
                            <TextField
                              variant="filled"
                              label={`${t("payments.fields.total_amount_related")}`}
                              ref={field.ref}
                              value={(exchangeRate <= 0) ? 0 :
                                (field.value && focusTextField == false) ?
                                  roundToFourDecimals(field.value / exchangeRate) :
                                  (field.value) ? roundToFourDecimals(field.value) : field.value}
                              onFocus={() => {
                                if (focusTextField == false)
                                  setValue("total_amount", (field.value) ? field.value / exchangeRate : field.value)
                                setFocusTextField(true);
                              }}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                                onChangeTotals(mySelectedRows, currencySupplier?.currency_code); //Mueve los por pagar y pagado
                              }}
                              error={!!errors.total_amount}
                              helperText={GetError(errors.total_amount?.type)}
                              size="small"
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", paddingRight: !isSameCurrency ? 1 : 0
                              }}
                              type="number"
                              disabled={isEdit}
                            />
                          )}
                        />
                      </div>
                    }
                  </div>
                  <div className="d-flex flex-row w-100">
                    <div className="w-50">
                      <TextField
                        variant="filled"
                        label={`${t("payments.fields.exchange_rate")}`}
                        size="small"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1, paddingRight: 1
                        }}
                        value={(currencySupplier?.currency_code === '') ? 0 : exchangeRate}
                        onChange={(event) => {
                          let value = event.target.value;
                          value = value.replace(/[^0-9.]/g, '');
                          const parts = value.split('.');
                          if (parts.length > 2) {
                            value = `${parts[0]}.${parts.slice(1).join('')}`;
                          }
                          if (parts[1]?.length > 6) {
                            value = `${parts[0]}.${parts[1].substring(0, 6)}`;
                          }
                          const decimalValue = parseFloat(value);
                          setExchangeRate(decimalValue);
                        }}
                        type="number"
                        disabled={isEdit ||
                          currencySupplier?.currency_code === "MXN" &&
                          !(mySelectedRows.some(element => element.related_currency_code !== "MXN")) ||
                          currencySupplier?.currency_code === ''}
                      />
                    </div>
                    <div className="w-50">
                      <TextField
                        sx={{ width: "100%", marginTop: 1 }}
                        disabled={true}
                        size="small"
                        label={`${t("currencies.title-view")}`}
                        variant="filled"
                        value={`${currencySupplier?.currency_code} - ${currencySupplier?.currency_description}`}
                      />
                    </div>

                  </div>
                  <div className="d-flex flex-row w-100">
                    <div className="w-50">
                      <Controller
                        name="selected_amount"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("payments.fields.selected_amount")}`}
                            ref={field.ref}
                            value={setFormatNumber({ value: field.value ?? 0 })}
                            error={!!errors.selected_amount}
                            helperText={GetError(errors.selected_amount?.type)}
                            size="small"
                            disabled={isEdit}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1, paddingRight: 1
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {isLoadingCurrenciesValues ? <CircularProgress color="primary" size={25} /> : null}
                                </>
                              )
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </div>
                    <div className="w-50">
                      <Controller
                        name="difference_amount"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("payments.fields.difference_amount")}`}
                            ref={field.ref}
                            value={setFormatNumber({ value: field.value ?? 0 })}
                            error={!!errors.difference_amount}
                            helperText={GetError(errors.difference_amount?.type)}
                            size="small"
                            disabled={isEdit}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1,
                              '& .MuiInputBase-input': {
                                color: (field.value && field.value <= 0) ? 'red' : 'black',
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {isLoadingCurrenciesValues ? <CircularProgress color="primary" size={25} /> : null}
                                </>
                              )
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="d-flex">
                <Controller
                  name="supplier_bank_account_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboSupplierBankAccounts}
                      defaultValue={defaultSupplierBankAccount}
                      getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name} ${option.account_number ? `- ${option.account_number}` : ""}`}
                      renderOption={(props, option: SupplierBankAccount) => (
                        <div key={option.supplier_bank_account_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.account_alias} - {option.bank_name} {option.account_number ? `- ${option.account_number}` : ""}
                          </Box>
                        </div>
                      )}
                      sx={{ width: "50%", paddingRight: 1 }}
                      isOptionEqualToValue={(option, value) => option.supplier_bank_account_id === value.supplier_bank_account_id}
                      onChange={(_, values) => {
                        let myCurrency: Currency = {
                          currency_id: 0,
                          currency_code: values?.currency_code ?? "",
                          currency_description: values?.currency_description ?? "",
                          currency_symbol: values?.currency_symbol
                        }
                        if (values?.currency_code)
                          setExchangeRate(1)
                        setCurrencySupplier(myCurrency)
                        onChangeTotals(mySelectedRows, values?.currency_code)
                        field.onChange(values?.supplier_bank_account_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("payments.fields.supplierbankaccount")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.supplier_bank_account_id}
                          helperText={GetError(errors.supplier_bank_account_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  }
                />
                <Controller
                  name="company_bank_account_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboCompanyBankAccounts}
                      defaultValue={defaultCompanyBankAccount}
                      getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name} ${option.account_number ? `- ${option.account_number}` : ""}`}
                      renderOption={(props, option: CompanyBankAccount) => (
                        <div key={option.company_bank_account_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.account_alias} - {option.bank_name} {option.account_number ? `- ${option.account_number}` : ""}
                          </Box>
                        </div>
                      )}
                      sx={{ width: "50%" }}
                      isOptionEqualToValue={(option, value) => option.company_bank_account_id === value.company_bank_account_id}
                      onChange={(_, values) => {
                        field.onChange(values?.company_bank_account_id || null)
                        setCompanyBalance({
                          balance: values?.balance ?? 0,
                          currency_id: values?.currency_id ?? 0,
                          currency_code: values?.currency_code ?? ""
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("payments.fields.companybankaccount")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.company_bank_account_id}
                          helperText={GetError(errors.company_bank_account_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
              <div className="d-flex mt-2">
                <Controller
                  name="payment_date"
                  control={control}
                  rules={{}}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      label={`${t("payments.fields.payment_date")}`}
                      onChange={(_) => { field.onChange(_); onChangeTotals(mySelectedRows, currencySupplier?.currency_code) }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          size: "small",
                          sx: { width: "50%", paddingRight: 1 },
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.payment_date,
                          helperText: GetError(errors.payment_date?.type),
                        }
                      }}
                      defaultValue={dayjs()}
                      format="DD/MM/YYYY"
                      disabled={isEdit}
                    />
                  }
                />
                <Controller
                  name="payment_method_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboPaymentMethods}
                      defaultValue={defaultPaymentMethod}
                      getOptionLabel={(option) => `${option.code} - ${option.name}`}
                      renderOption={(props, option: PaymentMethod) => (
                        <div key={option.payment_method_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.name}
                          </Box>
                        </div>
                      )}
                      sx={{ width: "50%" }}
                      isOptionEqualToValue={(option, value) => option.payment_method_id === value.payment_method_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_method_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmethods.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_method_id}
                          helperText={GetError(errors.payment_method_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
            </form>
            <div className="mt-2">
              <DialogPayments
                Details={
                  <TableApplyPayment
                    rows={
                      rows.filter(row => row.is_authorized !== null && row.is_authorized !== undefined && row.is_authorized !== '' && row.is_authorized)
                    }
                    getValues={getValues}
                    setSelectedRows={setMySelectedRows}
                    onChangeTotals={onChangeTotals}
                    preSelectedRows={preSelectedRows}
                    setPreSelectedRows={setPreSelectedRows}
                    loadingPost={loadingPost}
                    isEdit={isEdit}
                    hasErrorPost={hasErrorPost}
                    isLoadingCurrenciesValues={isLoadingCurrenciesValues}
                  />
                }
                Stamping={
                  <StampingPayment
                    paymentLocal={getValues()}
                    getValues={getValues}
                  />
                }
              />
            </div>
            <DialogEntity
              open={dialogConfirmApplyPayment}
              title={<Header title={t("payments.title-view")} />}
              maxWidth={"sm"}
              fullWidth
              content={
                <ConfrimDialogPayment
                  getValues={getValues}
                  isEdit={isEdit}
                  isLoadingCurrenciesValues={isLoadingCurrenciesValues}
                  loadingPost={loadingPost}
                  onPost={onPost}
                  resourceFiscalSignature={resourceFiscalSignature}
                  setDialogConfirmApplyPayment={setDialogConfirmApplyPayment}
                  hasPositiveDifference={hasPositiveDifference}
                />
              }
            />
          </Box>
          <SnackbarComponent />
        </>
      }
    </>
  )

}