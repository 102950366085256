import { useTranslation } from "react-i18next";

export const FormsErrors = () => {

    const GetError = (errorType: any) => {
        const [t] = useTranslation("global");

        switch (errorType) {
            case "required":
                return t("generic.forms-errors.required");
            case "pattern":
                return t("generic.forms-errors.pattern");
            case "maxLength":
                return t("generic.forms-errors.maxLength");
            case "minLength":
                return t("generic.forms-errors.minLength");
            case "max":
                return t("generic.forms-errors.max");
            case "min":
                return t("generic.forms-errors.min");
            default:
                break;
        }
        return "";
    }

    return { GetError }
}