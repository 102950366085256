import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ButtonLoading, PrimaryButton } from "../../../../theme/buttons";
import { Dispatch, SetStateAction } from "react";

interface DialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    title: string,
    content: string,
    onAcept: () => void,
    isLoading: boolean,
    onCancelChangeCompany: () => void
}

export const DialogChangeCompany = ({ isLoading, open, setOpen, title, content, onAcept, onCancelChangeCompany }: DialogProps) => {
    const [t] = useTranslation("global");
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="text" className="m-1" onClick={onCancelChangeCompany}>{t("generic.buttons.cancel")}</Button>
                <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="btn m-1" onClick={onAcept}>{t("generic.buttons.accept")}</ButtonLoading>
            </DialogActions>
        </Dialog>
    )
}
