

import { Route, Routes } from 'react-router-dom'
import { TableTariffFractions } from './table'
import { AddTariffFractions } from './add'
import { ViewTariffFractions } from './view'

export const BrowserTariffFractions = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableTariffFractions/>}/>
            <Route path={"/add"} element={<AddTariffFractions/>}/>
            <Route path={"view"} element={<ViewTariffFractions/>}/>
        </Routes>
    )
}
