import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker } from "@mui/x-date-pickers";
import { Autocomplete, Box, TextField } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { Transportation } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportations';
import { VehicleConfiguration } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/vehicleConfigurations';
import dayjs from 'dayjs';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateTranportations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [comboVehicleConfigurations, setcomboVehicleConfigurations] = useState<VehicleConfiguration[]>([])
  const [defaultExpirationDate, setDefaultExpirationDate] = useState("")
  const [defaultVehicleConfig, setdefaultVehicleConfig] = useState<VehicleConfiguration>()
  const { transportationPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Transportation>({
    defaultValues: {
      vehicle_configuration_id: undefined,
      transportation_code: '',
      plate_number: '',
      vin: '',
      model_name: '',
      model_year: '',
      motor_number: '',
      permission_sct: '',
      permission_number_sct: '',
      transportation_weight: undefined,
      insurance_company: '',
      insurance_policy: '',
      insurance_expiration_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<Transportation> = (data) => onPut(data);

  const onPut = (data: Transportation) => {
    let myData = {
      vehicle_configuration_id: data.vehicle_configuration_id,
      transportation_code: data.transportation_code,
      plate_number: data.plate_number,
      vin: data.vin,
      model_name: data.model_name,
      model_year: data.model_year,
      motor_number: data.motor_number,
      permission_sct: data.permission_sct,
      permission_number_sct: data.permission_number_sct,
      transportation_weight: data.transportation_weight,
      insurance_company: data.insurance_company,
      insurance_policy: data.insurance_policy,
      insurance_expiration_date: data.insurance_expiration_date,
    }
    setLoadingPost(true);
    GenericPutResource(`/transportations/${transportationPayload.transportation_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/vehicleconfigurations"),
    ]
    Promise.all(myPromises)
      .then((responses) => {
        setcomboVehicleConfigurations(responses[0].data.data);
        setdefaultVehicleConfig(responses[0].data.data.find((vehicleconfig: VehicleConfiguration) => vehicleconfig.vehicle_config_id === transportationPayload.vehicle_configuration_id))
        let startDate = new Date(transportationPayload.insurance_expiration_date)
        setDefaultExpirationDate(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`)
        reset({
          vehicle_configuration_id: transportationPayload.vehicle_configuration_id,
          transportation_code: transportationPayload.transportation_code,
          plate_number: transportationPayload.plate_number,
          vin: transportationPayload.vin,
          model_name: transportationPayload.model_name,
          model_year: transportationPayload.model_year,
          motor_number: transportationPayload.motor_number,
          permission_sct: transportationPayload.permission_sct,
          permission_number_sct: transportationPayload.permission_number_sct,
          transportation_weight: transportationPayload.transportation_weight,
          insurance_company: transportationPayload.insurance_company,
          insurance_policy: transportationPayload.insurance_policy,
          insurance_expiration_date: transportationPayload.insurance_expiration_date,
        });
        setDataLoaded(true);
      });
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="vehicle_configuration_id"
                        control={control}
                        rules={{ required: true, maxLength: 3 }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboVehicleConfigurations}
                            defaultValue={defaultVehicleConfig}
                            getOptionLabel={(option) => `${option.vehicle_config_code} - ${option.vehicle_config_description}`}
                            renderOption={(props, option: VehicleConfiguration) => (
                              <div key={option.vehicle_config_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.vehicle_config_code} - {option.vehicle_config_description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.vehicle_config_id === value.vehicle_config_id}
                            onChange={(_, values) => {
                              field.onChange(values?.vehicle_config_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("transportations.fields.vehicle_configuration_id")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.vehicle_configuration_id}
                              />
                            )}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="transportation_code"
                        control={control}
                        rules={{ required: true, maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.transportation_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.transportation_code}
                            helperText={GetError(errors.transportation_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="plate_number"
                        control={control}
                        rules={{ maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.plate_number")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.plate_number}
                            helperText={GetError(errors.plate_number?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="vin"
                        control={control}
                        rules={{ required: true, maxLength: 17 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.vin")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.vin}
                            helperText={GetError(errors.vin?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="model_name"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.model_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.model_name}
                            helperText={GetError(errors.model_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="model_year"
                        control={control}
                        rules={{ maxLength: 4 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.model_year")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            type='number'
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            error={!!errors.model_year}
                            helperText={GetError(errors.model_year?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="motor_number"
                        control={control}
                        rules={{ required: true, maxLength: 20 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.motor_number")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.motor_number}
                            helperText={GetError(errors.motor_number?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="permission_sct"
                        control={control}
                        rules={{ required: true, maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.permission_sct")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.permission_sct}
                            helperText={GetError(errors.permission_sct?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className="w-50">
                      <Controller
                        name="permission_number_sct"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.permission_number_sct")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.permission_number_sct}
                            helperText={GetError(errors.permission_number_sct?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="transportation_weight"
                        control={control}
                        rules={{ required: true, }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.transportation_weight")}`}
                            ref={field.ref}
                            type='number'
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.transportation_weight}
                            helperText={GetError(errors.transportation_weight?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className="w-50">
                      <Controller
                        name="insurance_company"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.insurance_company")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.insurance_company}
                            helperText={GetError(errors.insurance_company?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="insurance_policy"
                        control={control}
                        rules={{ required: true, maxLength: 20 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("transportations.fields.insurance_policy")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.insurance_policy}
                            helperText={GetError(errors.insurance_policy?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <div className="w-50">
                      <Controller
                        name="insurance_expiration_date"
                        control={control}
                        rules={{ required: true, }}
                        render={({ field }) =>
                          <DatePicker
                            open={openDatePicker}
                            onClose={() => setOpenDatePicker(false)}
                            ref={field.ref}
                            defaultValue={dayjs(defaultExpirationDate)}
                            label={`${t("transportations.fields.insurance_expiration_date")}`}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                variant: "filled",
                                size: "small",
                                fullWidth: true,
                                onClick: () => setOpenDatePicker(true),
                                onBeforeInput: disableKeyboardEntry,
                                error: !!errors.insurance_expiration_date,
                                helperText: GetError(errors.insurance_expiration_date?.type),
                              },
                            }}
                            format="DD/MM/YYYY"
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
