import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Retention } from '../../../../interfaces/Sales/Catalogs/retentions';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateRetentions = ({ ...props }) => {

  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const {showSnackBar,SnackbarComponent}= useSnackBar();
  const { retentionPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Retention>({
    defaultValues: {
      retention_code: '',
      retention_name: '',
      retention_percent: undefined,
    }
  });
  const onSubmit: SubmitHandler<Retention> = (data) => onPut(data);

  const onPut = (data: Retention) => {
    let myData = {
      retention_code: data.retention_code,
      retention_name: data.retention_name ?? null,
      retention_percent: data.retention_percent,
    }
    setLoadingPost(true);
    GenericPutResource(`/retentions/${retentionPayload.retention_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"),'success');
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,'error');
      });
  }


  useEffect(() => {
    reset({
      retention_code: retentionPayload.retention_code,
      retention_name: retentionPayload.retention_name ?? undefined,
      retention_percent: retentionPayload.retention_percent,
    });
    setDataLoaded(true);
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="retention_code"
                        control={control}
                        rules={{ required: true, maxLength: 5 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("retentions.fields.retention_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.retention_code}
                            helperText={GetError(errors.retention_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="retention_percent"
                        control={control}
                        rules={{required: true, maxLength: 100 }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            variant="filled"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }
                            }}
                            type="number"
                            label={`${t("retentions.fields.retention_percent")}`}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.retention_percent}
                            helperText={GetError(errors.retention_percent?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="w-100 mt-3">
                    <Controller
                      name="retention_name"
                      control={control}
                      rules={{ maxLength: 30 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={t("retentions.fields.retention_name")}
                          ref={field.ref}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.retention_name}
                          helperText={GetError(errors.retention_name?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={isEdit}
                        />
                      }
                    />
                  </div>

                </div>

              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent/>
    </>
  )
}
