import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../../hooks/useDates";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { usePermissions } from "../../../../hooks/usePermissions";
import { RelatedSalesInvoice } from "../../../../interfaces/Sales/Catalogs/RelatedSalesInvoices/relatedSalesInvoices";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { PrimaryButton } from "../../../../theme/buttons";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../components/Header";
import { AddRelatedSalesInvoices } from "./add";
import { SalesInvoiceRelationshipType } from "../../../../interfaces/Sales/Catalogs/salesInvoiceRelationshipTypes";
import { UpdateRelatedSalesInvoice } from "./update";
import { UseFormSetValue } from "react-hook-form";
import { SalesInvoice } from "../../../../interfaces/Sales/Invoices/salesinvoices";

export const getUUIDFormat = (uuid: string, keyCode: string, setValue: UseFormSetValue<RelatedSalesInvoice>) => {
  let myUuid = uuid;
  if (keyCode !== 'Backspace' && keyCode !== '-') {
    switch (uuid.length) {
      case 8:
      case 13:
      case 18:
      case 23:
        myUuid = `${myUuid}-`
        break;
    }
    setValue("related_sales_invoice_uuid", myUuid);
  }
}

type RelatedSalesInvoicesIndexProsp = {
  salesInvoiceId: number | undefined,
  isEdit: boolean
}

export const RelatedSalesInvoicesIndex = ({
  salesInvoiceId,
  isEdit,
}: RelatedSalesInvoicesIndexProsp) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [relatedInvoicesData, setRelatedInvoicesData] = useState<GridRowsProp>([]);
  const [comboSalesInvoiceRelationshipTypes, setComboSalesInvoiceRelationshipTypes] = useState<SalesInvoiceRelationshipType[]>([]);
  const [relatedSalesInvoicePayload, setRelatedSalesInvoicePayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columns, setColumns] = useState<GridColDef[]>([
    { field: 'related_sales_invoice_name', headerName: t("relatedsalesinvoices.fields.related_sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_sales_invoice_uuid', headerName: t("relatedsalesinvoices.fields.related_sales_invoice_uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_invoice_relationship_type_code', headerName: t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_invoice_relationship_type_description', headerName: t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_description"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-relatedsalesinvoices");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-relatedsalesinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<RelatedSalesInvoice, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`relatedsalesinvoices.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  const handleAddRow = (newRow: RelatedSalesInvoice) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setRelatedInvoicesData((prevState) => [...relatedInvoicesData, myRow]);
  };

  const handleUpdateRow = (updateRow: RelatedSalesInvoice) => {
    if (relatedInvoicesData.length === 0) {
      return;
    }
    setRelatedInvoicesData((prevRows) => {
      const rowToUpdateIndex = relatedInvoicesData.findIndex(e => e.related_sales_invoice_id === updateRow.related_sales_invoice_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (relatedInvoicesData.length === 0) {
      return;
    }
    setRelatedInvoicesData((prevRows) => {
      const rowToDeleteIndex = relatedInvoicesData.findIndex(e => e.related_sales_invoice_id === deleteRow);

      return [
        ...relatedInvoicesData.slice(0, rowToDeleteIndex),
        ...relatedInvoicesData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/relatedsalesinvoices/bysalesinvoiceid/${salesInvoiceId}`)
      .then(async (response) => {
        setRelatedInvoicesData(response.data.data);

        await GenericGetResource(`/salesinvoicerelationshiptypes`)
          .then((responseCombo) => {
            setComboSalesInvoiceRelationshipTypes(responseCombo.data.data);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          });

        GetResourceByUrl(`/relatedsalesinvoices`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          });
      }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className='screen-container'>
            {resourceScreen?.create === true && !isEdit &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columns}
                setColumns={setColumns}
                data={relatedInvoicesData}
                entityId={"related_sales_invoice_id"}
                entity={`RelatedSalesInvoices`}
                preferences={myPreferences}
                namePreferences={"grid-relatedsalesinvoices"}
                nameOrderColumns={"grid-relatedsalesinvoices-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setRelatedSalesInvoicePayload}
              />
            </div>
          </div>
          <DialogEntity
            content={
              <UpdateRelatedSalesInvoice
                relatedSalesInvoicePayload={relatedSalesInvoicePayload}
                salesInvoiceId={salesInvoiceId}
                comboSalesInvoiceRelationshipTypes={comboSalesInvoiceRelationshipTypes}
                setOpenDialog={setOpenDialogUpdate}
                permissions={resourceScreen}
                DeleteRow={handleDeleteRow}
                UpdateRow={handleUpdateRow}
                isEdit={isEdit}
              />
            }
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("relatedsalesinvoices.title-view")} size='sm' />}
          />
          <DialogEntity
            content={
              <AddRelatedSalesInvoices
                setOpenDialog={setOpenDialogAdd}
                salesInvoiceId={salesInvoiceId}
                comboSalesInvoiceRelationshipTypes={comboSalesInvoiceRelationshipTypes}
                AddNewRow={handleAddRow}
              />
            }
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("relatedsalesinvoices.title-view")} size='sm' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
