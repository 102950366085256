import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { useEffect, useState } from "react";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Item } from "../../../interfaces/Commons/items";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SupplierItem } from "../../../interfaces/Purchases/Catalogs/supplierItems";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { PrimaryButton } from "../../../theme/buttons";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { Divider } from "../../../components/Commons/Divider";

export const UpdateSupplierItems = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboItems, setComboItems] = useState<Item[]>([]);
  const [defaultItem, setDefaultItem] = useState<Item>();
  const { supplierItemPayload, permissions } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<SupplierItem>({
    defaultValues: {
      item_id: undefined,
      item_price: undefined,
      supplier_reference: undefined,
      supplier_reference_description: undefined,
    }
  });
  const onSubmit: SubmitHandler<SupplierItem> = (data) => onPut(data);

  const onPut = (data: SupplierItem) => {
    let myData = {
      supplier_id: supplierItemPayload.supplier_id,
      item_id: data.item_id,
      item_price: data.item_price,
      supplier_reference: data.supplier_reference ?? null,
      supplier_reference_description: data.supplier_reference_description ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/supplieritems/${supplierItemPayload.supplier_item_id}`, myData)
      .then((response) => {
        props.UpdateRow(response.data);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/supplieritems/${supplierItemPayload?.supplier_item_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(supplierItemPayload?.supplier_item_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {

    let myPromises = [
      GenericGetResource("/items")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboItems(responses[0].data.data);

        reset({
          supplier_id: supplierItemPayload.supplier_id,
          item_id: supplierItemPayload.item_id,
          item_price: supplierItemPayload.item_price,
          supplier_reference: supplierItemPayload.supplier_reference ?? undefined,
          supplier_reference_description: supplierItemPayload.supplier_reference_description ?? undefined,
        });

        setDefaultItem(responses[0].data.data.find((item: Item) => item.item_id === supplierItemPayload.item_id));

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-50'>
                <TextField
                  variant="filled"
                  label={`${t("supplieritems.fields.business_name")}`}
                  value={supplierItemPayload.business_name}
                  size="small"
                  style={{ width: "100%" }}
                  sx={{ paddingRight: 2 }}
                  disabled={true}
                />
              </div>

            </div>

            <div className='mt-3 d-flex'>
              <div className='w-50'>
                <Controller
                  name="item_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboItems}
                      defaultValue={defaultItem}
                      getOptionLabel={(option) => `${option.item_code} - ${option.item_description}`}
                      renderOption={(props, option: Item) => (
                        <div key={option.item_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.item_code} - {option.item_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.item_id === value.item_id}
                      onChange={(_, values) => {
                        field.onChange(values?.item_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("items.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.item_id}
                          helperText={GetError(errors.item_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="item_price"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.item_price")}`}
                      type='number'
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                        "& input[type=number]": { MozAppearance: "textfield", }
                      }}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.item_price}
                      helperText={GetError(errors.item_price?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
            </div>

            <Divider />

            <div className='d-flex'>
              <div className='w-50'>
                <Controller
                  name="supplier_reference"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.supplier_reference")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.supplier_reference}
                      helperText={GetError(errors.supplier_reference?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="supplier_reference_description"
                  control={control}
                  rules={{ maxLength: 150 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("supplieritems.fields.supplier_reference_description")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.supplier_reference_description}
                      helperText={GetError(errors.supplier_reference_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                {permissions.update && <PrimaryButton onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions.delete ?
                <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
              }
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("items.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
