import { createContext, useReducer, useEffect, useState } from 'react'
import { User, LoginData } from '../interfaces/Security/users';
import { authReducer, AuthState } from './AuthReducer';
import sxNetCalaApi from '../api/sxNetCala';
import Swal from 'sweetalert2';
import { Menuitem } from '../interfaces/Security/menu';
import { useTranslation } from 'react-i18next';

type AuthContextProps = {
    errorMessage: string;
    token: string | null;
    user: User | null;
    resources: Map<String, number> | null,
    status: 'checking' | 'ahutenticated' | 'not-authenticated'
    signIn: (loginData: LoginData, toggleDisabled: () => void, isFromlogin: boolean) => Promise<any>;
    changeCompany: (loginData: LoginData, toggleDisabled: () => void, isFromlogin: boolean) => Promise<any>;
    logOut: () => void;
    addResources: (resource_id: Map<String, number>) => void;
}

const secrete = "Bearer";

const authInitialState: AuthState = {
    status: 'checking',
    token: null,
    user: null,
    errorMessage: '',
    resources: null,
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(authReducer, authInitialState);

    const [myResources, setMyResources] = useState<Map<String, number>>(new Map<String, number>);

    const { i18n } = useTranslation("global");

    const getResourceByResponse = (resource: Menuitem) => {
        if (resource.children.length) {
            for (let i = 0; i < resource.children.length; i++) {
                getResourceByResponse(resource.children[i]);
            }
        }
        else {
            myResources.set(resource.resource_route ?? '', resource.resource_id ?? 0);
        }
    }

    const loginResponse = async (response: any, toggleDisabled?: () => void) => {
        try {
            if (response.status !== 200) {
                localStorage.removeItem("token");
                return dispatch({ type: 'notAuthenticated' });
            }
            localStorage.setItem('token', response.data.token);
            localStorage.setItem("language", response.data.user.language);
            localStorage.setItem("schemaName", response.data.user.schema);
            i18n.changeLanguage(response.data.user.language);
            await sxNetCalaApi.get(`/menu/${response.data.user.group_id}`, {
                headers: {
                    'UserId': response.data.user.user_id,
                    'password': response.data.user.password,
                    'Authorization': `${secrete} ${response.data.token}`,
                }
            })
                .then(async (response1) => {
                    getResourceByResponse(response1.data);
                    setMyResources(myResources);
                    let myUser: User = response.data.user;
                    await sxNetCalaApi.get(`/groups/${response.data.user.group_id}`, {
                        headers: {
                            'UserId': response.data.user.user_id,
                            'password': response.data.user.password,
                            'Authorization': `${secrete} ${response.data.token}`,
                            'ResourceId': myResources.get("/ggroups"),
                            'GroupId': response.data.user.group_id,
                        }
                    }).then(async (response2) => {
                        myUser.group_name = response2.data.group_name
                    });
                    dispatch({ type: 'signIn', payload: { token: response.data.token, user: myUser, resources: myResources } });
                }).catch((error) => {
                    toggleDisabled && toggleDisabled();
                    throw error;
                });
        } catch (error) {
            throw error
        }
    }

    const validateToken = async () => {
        const token = await localStorage.getItem("token");
        if (!token) return dispatch({ type: 'notAuthenticated' });
        try {
            await sxNetCalaApi.get(`/login`, {
                headers: {
                    'token': `${secrete} ${token}`,
                }
            }).then(async (response) => {
                loginResponse(response);
            })
        }
        catch (error) {
            localStorage.removeItem("token");
            return dispatch({ type: 'notAuthenticated' });
        }
    }

    const signIn = async ({ email, password }: LoginData, toggleDisabled: () => void, isFromLogin: boolean): Promise<any> => {
        try {
            await sxNetCalaApi.post('/login', { email, password },
                { headers: { "Content-Type": "application/json" } })
                .then(async (response) => {
                    loginResponse(response, toggleDisabled);
                })
        } catch (error: any) {
            toggleDisabled();
            throw error;
        }

    };

    const changeCompany = async ({ email, password, SchemaName }: LoginData, toggleDisabled: () => void, isFromLogin: boolean): Promise<any> => {
        try {
            await sxNetCalaApi.put('/login', { email, password, SchemaName },
                { headers: { "Content-Type": "application/json" } })
                .then(async (response) => {
                    loginResponse(response, toggleDisabled);
                })
        } catch (error: any) {
            toggleDisabled();
            throw error;
        }
    };

    const logOut = async () => {
        await localStorage.removeItem('token');
        await localStorage.removeItem('language');
        await localStorage.removeItem('schemaName');
        dispatch({ type: 'logOut' });
    };

    const addResources = (resources: Map<String, number>) => { dispatch({ type: 'addResources', payload: resources ?? null }) };

    useEffect(() => {
        validateToken();
    }, []);

    return (
        <AuthContext.Provider value={{
            ...state,
            signIn,
            logOut,
            addResources,
            changeCompany,
        }}>
            {children}
        </AuthContext.Provider >
    )
}