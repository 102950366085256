import { useEffect, useState } from "react";
import { CancelledSalesInvoice } from "../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices";
import { GridColDef, GridRowModesModel, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { useLocation } from "react-router-dom";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { CancelledRelatedSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledRelatedSalesInvoices';
import { useDates } from "../../../../hooks/useDates";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../components/Header";
import { ViewCancelledRelatedSalesInvoices } from "./view";

type CancelledRealtedSalesInvoicesProps = {
  salesInvoicePayload?: CancelledSalesInvoice | undefined;
}
export const CancelledRealtedSalesInvoices = ({ salesInvoicePayload }: CancelledRealtedSalesInvoicesProps) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [CancelledRelatedSalesInvoicePayload, setCancelledRelatedSalesInvoicePayload] = useState({});
  const [myPreferences, setMyPreferences] = useState({});
  const [rows, setRows] = useState<CancelledRelatedSalesInvoice[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([
    { field: 'related_sales_invoice_name', headerName: t("relatedsalesinvoices.fields.related_sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_sales_invoice_uuid', headerName: t("relatedsalesinvoices.fields.related_sales_invoice_uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_invoice_relationship_type_code', headerName: t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_invoice_relationship_type_description', headerName: t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_description"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-cancelledrelatedsalesinvoices");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-cancelledrelatedsalesinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CancelledRelatedSalesInvoice, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`relatedsalesinvoices.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    GenericGetResource(`/cancelledrelatedsalesinvoices/bycancelledsalesinvoiceid/${salesInvoicePayload?.cancelled_sales_invoice_id}`)
      .then(async (response) => {
        setRows(response.data.data);
        loadColumnsOrder();
        loadPreferences();
        setDataLoaded(true);
      }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={rows}
              entityId={"cancelled_related_sales_invoice_id"}
              entity={`CancelledRelatedSalesInvoices`}
              preferences={myPreferences}
              namePreferences={"grid-cancelledrelatedsalesinvoices"}
              nameOrderColumns={"grid-cancelledrelatedsalesinvoices-columns"}
              isChildren={true}
              setOpenDialog={setOpenDialogView}
              setDataRow={setCancelledRelatedSalesInvoicePayload}
            />
          </div>
          <DialogEntity
            content={
              <ViewCancelledRelatedSalesInvoices
                relatedSalesInvoicePayload={CancelledRelatedSalesInvoicePayload}
                setOpenDialog={setOpenDialogView}
              />
            }
            open={openDialogView}
            setOpen={setOpenDialogView}
            title={<Header title={t("relatedsalesinvoices.title-view")} size='sm' />}
          />
        </>
      }
      <SnackbarComponent />
    </>
  )
}
