import GenericPromises from "../../api/GenericPromises";
import { ExchangeRateHistory } from '../../interfaces/Commons/currencies';

export const useCurrencies = () => {
  const { GenericGetResource } = GenericPromises();

  const GetExchangeRateFromCurrencyCode = async (
    currencyId: number,
    currencyDate: Date | undefined,
  ): Promise<ExchangeRateHistory | undefined> => {
    if (currencyDate === undefined) {
      let myAux: ExchangeRateHistory = { rate: -1 }
      return myAux;
    }
    else {
      let myExchangeRate: ExchangeRateHistory | undefined = await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(currencyId) ?? 0}`)
        .then(async (responseCurrency) => {
          let currentDate = currencyDate;

          let myNewDate = new Date(currentDate ?? Date.now());
          if (myNewDate.getDay() === 1) {
            myNewDate.setDate(myNewDate?.getDate() - 3);
          }
          else if (myNewDate.getDay() === 0) {
            myNewDate.setDate(myNewDate?.getDate() - 2);
          }
          else {
            myNewDate.setDate(myNewDate?.getDate() - 1);
          }

          let myCurrencyValues: ExchangeRateHistory[] = responseCurrency.data.data;

          return await myCurrencyValues.find((item) => {
            // Convertir la cadena de fecha a un objeto Date
            const itemDate = new Date(item.rate_date!);
            const formattedDate1: Date = new Date(`${itemDate.getFullYear()}-${itemDate.getMonth() + 1}-${itemDate.getDate()}`);
            const formattedDate2: Date = new Date(`${myNewDate.getFullYear()}-${myNewDate.getMonth() + 1}-${myNewDate.getDate()}`);

            return (formattedDate1.getTime() === formattedDate2.getTime());
          });
        });
      if (myExchangeRate === undefined) {
        let myAux: ExchangeRateHistory = { rate: -1 }
        return myAux;
      }
      else {
        return myExchangeRate;
      }
    }
  }

  const GetOperatorToGetDivisa = (
    currencyCodeSource: string,
    currencyCodeTarget: string,
  ) => {
    if(currencyCodeSource === "MXN" && currencyCodeTarget === "USD"){
      return "multiply";
    }
    else if(currencyCodeSource === "USD" && currencyCodeTarget === "MXN"){
      return "divide";
    }
  }

  return {
    GetExchangeRateFromCurrencyCode,
    GetOperatorToGetDivisa,
  }
}
