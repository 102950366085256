import { Control, Controller, FieldErrors, UseFormGetValues, } from "react-hook-form"
import { SalesInvoice } from '../../../interfaces/Sales/Invoices/salesinvoices';
import { Company } from "../../../interfaces/Security/companies"
import { PaymentMethod } from "../../../interfaces/Sales/Catalogs/paymentMethods"
import { PaymentMode } from "../../../interfaces/Sales/Catalogs/paymentModes"
import { Currency } from "../../../interfaces/Commons/currencies"
import { CFDI } from "../../../interfaces/Sales/Catalogs/CFDIs"
import { useTranslation } from "react-i18next"
import { FormsErrors } from "../../../hooks/Forms/FormsErrors"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Autocomplete, Box, Button, Switch, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import { CustomerLocation } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { ButtonLoading } from "../../../theme/buttons";
import { PaymentCondition } from "../../../interfaces/Sales/Catalogs/paymentConditions";

type InvoiceHeaderProps = {
  control: Control<SalesInvoice>,
  errors: FieldErrors<SalesInvoice>,
  SalesInvoiceLocal: SalesInvoice | undefined,
  company: Company | undefined,
  defaultPaymentMethod: PaymentMethod | undefined,
  defaultPaymentMode: PaymentMode | undefined,
  defaultCurrency: Currency | undefined,
  defaultCFDI: CFDI | undefined,
  defaultCustomerLocation: CustomerLocation | undefined,
  defaultPaymentConditions: PaymentCondition | undefined,
  comboCustomerLocations: CustomerLocation[],
  comboCurrencies: Currency[],
  getValues: UseFormGetValues<SalesInvoice>,
  comboPaymentMethods: PaymentMethod[],
  comboPaymentModes: PaymentMode[],
  comboCFDIs: CFDI[],
  locationEdit: boolean,
  comboPaymentConditions: PaymentCondition[],
  setLocationEdit: Dispatch<SetStateAction<boolean>>,
  isEdit?: boolean,
  isNotExchange?: boolean,
  setIsNotExchange: Dispatch<SetStateAction<boolean>>,
  myLocalInvoiceDocType: number,
  setIncludesTransportationDocument: Dispatch<SetStateAction<boolean>>,
  setIncludesExteriorComplement: Dispatch<SetStateAction<boolean>>
  loading: boolean,
}

export const UpdateInvoiceHeader = ({
  control,
  errors,
  SalesInvoiceLocal,
  company,
  defaultPaymentMethod,
  defaultPaymentMode,
  defaultCurrency,
  defaultCFDI,
  defaultCustomerLocation,
  defaultPaymentConditions,
  comboCustomerLocations,
  comboCurrencies,
  comboPaymentConditions,
  getValues,
  comboPaymentMethods,
  comboPaymentModes,
  comboCFDIs,
  locationEdit,
  setLocationEdit,
  isEdit,
  isNotExchange,
  setIsNotExchange,
  myLocalInvoiceDocType,
  setIncludesExteriorComplement,
  setIncludesTransportationDocument,
  loading
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();
  const { GetError } = FormsErrors();
  const [selectedSwitch, setSelectedSwitch] = useState(0);

  const editLocation = () => {
    (locationEdit == true) ? setLocationEdit(false) : setLocationEdit(true)
  }

  useEffect(() => {
    setIsNotExchange(false)
    if (SalesInvoiceLocal?.includes_exterior_complement) {
      setSelectedSwitch(1);
    }
    else if (SalesInvoiceLocal?.includes_transportation_document) {
      setSelectedSwitch(2);
    }
  }, [locationEdit, defaultCustomerLocation, isNotExchange]);

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <div className='d-flex w-50'>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{company?.business_name}</Typography>
              <Typography>{company?.rfc}</Typography>
              <Typography>{company?.phone_number}</Typography>
              <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
            </div>
          </Box>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{SalesInvoiceLocal?.customer_business_name}</Typography>
              <Typography>{SalesInvoiceLocal?.customer_rfc}</Typography>
              <Typography>{getValues("customer_street_name")}, {getValues("customer_city_name")}, {getValues("customer_state_name")}, {getValues("customer_country_name")}<Button size="small" sx={{ flex: 1 }} onClick={editLocation}><EditIcon /></Button></Typography>
            </div>
            <div className="d-flex">
              {locationEdit &&
                <Controller
                  name="customer_location_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ maxWidth: "100%", minWidth: "80%" }}
                      options={comboCustomerLocations}
                      defaultValue={defaultCustomerLocation}
                      getOptionLabel={(option) => `${option.business_name} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                      renderOption={(props, option: CustomerLocation) => (
                        <div key={option.customer_location_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.customer_location_id === value.customer_location_id}
                      onChange={(_, values) => {
                        field.onChange(values?.customer_location_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("salesinvoices.fields.address")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.customer_location_id}
                          helperText={GetError(errors.customer_location_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              }
            </div>
          </Box>
        </div>
        {/* Right Box*/}
        <div className='d-flex flex-column w-50'>
          {/* first Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>
              <Controller
                name="payment_method_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    options={comboPaymentMethods}
                    getOptionLabel={(option) => `${option.code} - ${option.name}` ?? ""}
                    defaultValue={defaultPaymentMethod}
                    renderOption={(props, option: PaymentMethod) => (
                      <div key={option.payment_method_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.code} - {option.name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option.payment_method_id === value.payment_method_id
                    }}
                    onChange={(_, values) => {
                      field.onChange(values?.payment_method_id || 0)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("paymentmethods.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.payment_method_id}
                        helperText={GetError(errors.payment_method_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                )}
              />
            </Box>

            <Box sx={{ flex: 9 }}>

              <Controller
                name="payment_mode_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboPaymentModes}
                    defaultValue={defaultPaymentMode}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    renderOption={(props, option: PaymentMode) => (
                      <div key={option.payment_mode_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.code} - {option.description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.payment_mode_id === value.payment_mode_id}
                    onChange={(_, values) => {
                      field.onChange(values?.payment_mode_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("paymentmodes.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.payment_mode_id}
                        helperText={GetError(errors.payment_mode_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 11 }} className="d-flex flex-column mx-2" >
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.tax_amount")}: {setFormatNumber({ value: getValues("tax_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.retention_amount")}: {setFormatNumber({ value: getValues("retention_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* second Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>
              {!isNotExchange &&
                <Controller
                  name="currency_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboCurrencies}
                      defaultValue={defaultCurrency}
                      getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                      renderOption={(props, option: Currency) => (
                        <div key={option.currency_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.currency_code} - {option.currency_description}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                      onChange={(_, values) => {
                        field.onChange(values?.currency_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("currencies.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.currency_id}
                          helperText={GetError(errors.currency_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              }
            </Box>
            <Box sx={{ flex: 9 }}>
              <Controller
                name="cfdi_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboCFDIs}
                    defaultValue={defaultCFDI}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    renderOption={(props, option: CFDI) => (
                      <div key={option.cfdi_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.code} - {option.description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.cfdi_id === value.cfdi_id}
                    onChange={(_, values) => {
                      field.onChange(values?.cfdi_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("salesinvoices.fields.cfdi")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.cfdi_id}
                        helperText={GetError(errors.cfdi_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.discount_amount")}:{setFormatNumber({ value: getValues("discount_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* Fourth row */}
          <div className="d-flex mt-2">
            <div className='w-50'>
              <Controller
                name="payment_condition_id"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 4 }}
                    options={comboPaymentConditions}
                    defaultValue={defaultPaymentConditions}
                    getOptionLabel={(option) => `${option.payment_condition_name}`}
                    renderOption={(props, option: PaymentCondition) => (
                      <div key={option.payment_condition_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.payment_condition_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.payment_condition_id === value.payment_condition_id}
                    onChange={(_, values) => {
                      field.onChange(values?.payment_condition_id || null)
                    }}
                    disabled={isEdit}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("paymentconditions.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.payment_condition_id}
                        helperText={GetError(errors.payment_condition_id?.type)}
                      />
                    )}
                  />
                )}
              />
            </div>
            <div className="w-50"></div>
            <div className="w-50 d-flex flex-column mx-2">
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("salesinvoices.fields.subtotal_amount")}: {setFormatNumber({ value: getValues("subtotal_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("salesinvoices.fields.total_amount")}: {setFormatNumber({ value: getValues("total_amount") ?? 0 })}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-2">
        <div className="w-50">
          <ButtonLoading isLoading={loading} type="submit" size="small" className="d-flex align-self-end w-100" variant="outlined" disabled={isEdit}>{t("generic.buttons.save")}</ButtonLoading>
        </div>
        <div className="w-50"></div>
        <div className="w-50">
          <Controller
            name="includes_exterior_complement"
            control={control}
            render={({ field }) =>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{t("salesinvoices.fields.includes_exterior_complement")}</Typography>
                <Switch
                  ref={field.ref}
                  onChange={(_, e) => {
                    field.onChange(e);
                    if (e) {
                      setSelectedSwitch(1);
                      setIncludesTransportationDocument(true);
                    }
                    else
                      setSelectedSwitch(0);
                  }}
                  value={field.value}
                  disabled={selectedSwitch === 2 ? true : isEdit}
                  defaultChecked={SalesInvoiceLocal?.includes_exterior_complement} />
              </Box>
            }
          />
        </div>
        <div className="w-25">
          <Controller
            name="includes_transportation_document"
            control={control}
            render={({ field }) =>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 1 }}>
                <Typography>{t("salesinvoices.fields.includes_transportation_document")}</Typography>
                <Switch
                  ref={field.ref}
                  onChange={(_, e) => {
                    field.onChange(e);
                    if (e) {
                      setSelectedSwitch(2);
                      setIncludesExteriorComplement(true);
                    }
                    else
                      setSelectedSwitch(0);
                  }}
                  value={field.value}
                  disabled={(myLocalInvoiceDocType !== 1) ? true : selectedSwitch === 1 ? true : isEdit}
                  checked={(myLocalInvoiceDocType !== 1) ? false : field.value}
                  defaultChecked={SalesInvoiceLocal?.includes_transportation_document} />
              </Box>
            }
          />
        </div>
        <div className="w-25">
          <Controller
            name="is_accounted"
            control={control}
            render={({ field }) =>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                <Typography>{t("salesinvoices.fields.is_accounted")}</Typography>
                <Switch
                  ref={field.ref}
                  onChange={(_, e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  disabled={true}
                  checked={field.value}
                  defaultChecked={SalesInvoiceLocal?.is_accounted} />
              </Box>
            }
          />
        </div>
      </div>
    </>
  )
}
