import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Company, CompanySignature } from '../../../../interfaces/Security/companies';
import { styled } from '@mui/material/styles';
import { TextField, IconButton, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { File, FileSQL } from '../../../../interfaces/Commons/files';
import { useFiles } from '../../../../hooks/useFiles';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

type TabCompanySignature = {
  companyPayload: Company | undefined,
  setCompanyLocal: Dispatch<SetStateAction<Company | undefined>>
  isEdit: boolean;
}

export const TabCompanySignature = ({
  companyPayload,
  setCompanyLocal,
  isEdit,
}: TabCompanySignature) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const { DecodeFileSQLToBase64 } = useFiles()
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [hiddenPassword, setHiddenPassword] = useState(false);
  const [fileKey, setFileKey] = useState<FileSQL>({});
  const [fileCer, setFileCer] = useState<FileSQL>({});
  const [uploadKey, setUploadKey] = useState(true);
  const [uploadCer, setUploadCer] = useState(true);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<CompanySignature>({
    defaultValues: {
      fiscal_password: '',
    }
  });

  const onSubmit: SubmitHandler<CompanySignature> = (data) => onPut(data);

  const onPut = (data: CompanySignature) => {
    trigger().then((responseTrigger) => {
      if (responseTrigger) {
        if (fileCer.data_file && fileKey.data_file) {
          if (companyPayload?.company_signature_id) {
            // put companysignature
            let myData = {
              fiscal_password: data.fiscal_password,
              name_certificate_file: fileCer.file_name?.slice(0, 50),
              certificate_file: fileCer.data_file,
              name_key_file: fileKey.file_name?.slice(0, 50),
              key_file: fileKey.data_file
            }
            setLoadingPost(true);
            GenericPutResource(`/companysignature/${companyPayload?.company_signature_id ?? 0}`, myData)
              .then(async () => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setMessageSnack(t("generic.snackbar.update"));
                setLoadingPost(false);
              })
              .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
                setMessageSnack(error.message);
                setLoadingPost(false);
              });
          }
          else {
            // post companysignature
            let myData = {
              fiscal_password: data.fiscal_password,
              name_certificate_file: fileCer.file_name,
              certificate_file: fileCer.data_file,
              name_key_file: fileKey.file_name,
              key_file: fileKey.data_file
            }
            GenericPostResource(`/companysignature`, myData)
              .then(async (responseCompanySignature) => {
                // put company with id 
                let myPutData = {
                  company_signature_id: responseCompanySignature.data.company_signature_id,
                }
                await GenericPutResource(`/companies/${companyPayload?.company_id ?? 0}`, myPutData)
                  .then(async (responseCompanyPut) => {
                    await setCompanyLocal((prev) => {
                      if (!prev) { return undefined; }
                      return {
                        ...prev,
                        company_signature_id: responseCompanySignature.data.company_signature_id,
                        company_id: companyPayload?.company_id ?? prev.company_id,
                      };
                    });

                    showSnackBar(t("generic.snackbar.update"), "success");
                    setMessageSnack(t("generic.snackbar.update"));
                    setLoadingPost(false);
                  })
              })
              .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
                setMessageSnack(error.message);
                setLoadingPost(false);
              });
          }
        }
        else {
          showSnackBar(t("companysignature.info.missing_files"), "warning");
        }
      }
    })
  }

  useEffect(() => {
    if (companyPayload?.company_signature_id) {
      GenericGetResource(`/companysignature/${companyPayload?.company_signature_id ?? 0}`)
        .then((response) => {
          reset({
            fiscal_password: response.data.fiscal_password,
            name_certificate_file: response.data.name_certificate_file,
            certificate_file: response.data.certificate_file,
            name_key_file: response.data.name_key_file,
            key_file: response.data.key_file,
          });
          setFileCer({
            data_file: response.data.certificate_file,
            file_name: response.data.name_certificate_file,
          });
          setFileKey({
            file_name: response.data.name_key_file,
            data_file: response.data.key_file,
          })
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }
    else {
      setDataLoaded(true);
    }
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex pt-3 flex-column'>
                    {/* First row */}
                    <div className='d-flex'>
                      <div className='w-50 d-flex'>
                        <Controller
                          name="fiscal_password"
                          control={control}
                          rules={{ required: true, maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              InputProps={{
                                endAdornment: <IconButton children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                              }}
                              InputLabelProps={{ shrink: true }}
                              ref={field.ref}
                              type={`${hiddenPassword ? 'text' : 'password'}`}
                              value={field.value}
                              variant='filled'
                              label={t("companies.fields.fiscal_password")}
                              onChange={field.onChange}
                              error={!!errors.fiscal_password}
                              helperText={GetError(errors.fiscal_password?.type)}
                              sx={{ width: "100%", paddingRight: 2 }}
                              size='small'
                            />
                          }
                        />
                      </div>
                      <div className='d-flex w-50 justify-content-around align-items-center'>
                        <div className='w-50'>
                          <ButtonStyled component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            {t("companies.upload.key")}
                            <VisuallyHiddenInput type="file" accept=".key" required={uploadKey} onChange={async (e) => {
                              await DecodeFileSQLToBase64(e).then(async (response) => {
                                if (response.type_of_file == "application/x-iwork-keynote-sffkey") {
                                  await setFileKey(response);
                                  setUploadKey(false);
                                }
                              });
                            }
                            }
                            />
                          </ButtonStyled>
                        </div>
                        <div className='w-50'>
                          <ButtonStyled component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            {t("companies.upload.cer")}
                            <VisuallyHiddenInput type="file" required={uploadCer} accept=".cer"
                              onChange={async (e) => {
                                await DecodeFileSQLToBase64(e).then(async (response) => {
                                  if (response.type_of_file == "application/x-x509-ca-cert") {
                                    await setFileCer(response);
                                    setUploadCer(false);
                                  }
                                });
                              }
                              } />
                          </ButtonStyled>
                        </div>
                      </div>
                    </div>
                    {/* Second row */}
                    <div className='d-flex pt-3'>
                      {getValues("key_file") ?
                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{t("companies.upload.keyprivate") + getValues("name_key_file")}</Typography></label>
                        :
                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{(fileKey.file_name == undefined) ? "" : t("companies.upload.keyprivate") + fileKey.file_name}</Typography></label>
                      }
                    </div>
                    <div className='d-flex pt-3'>
                      {getValues("certificate_file") ?
                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{t("companies.upload.certificate") + getValues("name_certificate_file")}</Typography></label>
                        :
                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{(fileCer.file_name == undefined) ? "" : t("companies.upload.certificate") + fileCer.file_name}</Typography></label>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton onClick={() => onPut(getValues())} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}