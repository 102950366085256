import { File, FileSQL } from '../../interfaces/Commons/files';
import GenericPromises from '../../api/GenericPromises';

export const useFiles = () => {

  const { GenericGet, GenericPost, GenericDelete } = GenericPromises();

  // Get only content of Base64 String
  const GetContentURLImage = (dataURL: string): string => {
    return dataURL.split(',')[1];
  }

  // When you upload file to get base64 info 
  const DecodeFileToBase64 = async (e: any): Promise<any> => {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let file = e.target.files[0];
        reader.onload = async () => {
          try {
            let myFile: File = {};
            myFile.dataURL = reader.result?.toString();
            myFile.file_name = file.name;
            myFile.type_of_file = file.type;
            // Resolve the promise with the response value
            resolve(myFile);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    }
    catch (error) {
      throw error;
    }
  }

  const DecodeFileSQLToBase64 = async (e: any): Promise<any> => {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let file = e.target.files[0];
        reader.onload = async () => {
          try {
            let myFile: FileSQL = {};
            myFile.data_file = reader.result?.toString();
            myFile.file_name = file.name;
            myFile.type_of_file = file.type;
            // Resolve the promise with the response value
            resolve(myFile);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    }
    catch (error) {
      throw error;
    }
  }

  // Get File information and content dataURL
  const GetFile = async (recordId: number, entity: string): Promise<any> => {
    try {
      let myFile: File = {};
      const responseData = await GenericGet(`/files/recordId/${recordId}/entity/${entity}/schema/${localStorage.getItem("schemaName")}`)
        .then(async (response) => {

          myFile.file_id = response.data.data[0].file_id;
          myFile.object_id = response.data.data[0].object_id;
          myFile.entity = response.data.data[0].entity;
          myFile.record_id = response.data.data[0].record_id;
          myFile.file_name = response.data.data[0].file_name;
          myFile.type_of_file = response.data.data[0].type_of_file;
          myFile.schema_name = response.data.data[0].schema_name;

          await GenericGet(`/files/${response.data.data[0].object_id}`)
            .then(async (response1) => {
              myFile.dataURL = await `data:${myFile.type_of_file};base64,${response1.data.content}`;
            })
        })
        .catch((error) => { console.log(error) });
      return myFile;
    }
    catch (error) {
      throw error
    }
  }

  // Get File information and content dataURL
  const GetFileById = async (id: number): Promise<any> => {
    try {
      let myFile: File = {};
      const responseData = await GenericGet(`/files/byId/${id}`)
        .then(async (response) => {

          myFile.file_id = response.data.data[0].file_id;
          myFile.object_id = response.data.data[0].object_id;
          myFile.entity = response.data.data[0].entity;
          myFile.record_id = response.data.data[0].record_id;
          myFile.file_name = response.data.data[0].file_name;
          myFile.type_of_file = response.data.data[0].type_of_file;
          myFile.schema_name = response.data.data[0].schema_name;

          await GenericGet(`/files/${response.data.data[0].object_id}`)
            .then(async (response1) => {
              myFile.dataURL = await `data:${myFile.type_of_file};base64,${response1.data.content}`;
            })
        })
        .catch((error) => { console.log(error) });
      return myFile;
    }
    catch (error) {
      throw error
    }
  }

  // Get Files information and content dataURL
  const GetFiles = async (recordId: number, entity: string) => {
    try {
      let myFiles: File[] = [];
      const response = await GenericGet(`/files/recordId/${recordId}/entity/${entity}/schema/${localStorage.getItem("schemaName")}`)
        .then(async (response) => {
          myFiles = response.data.data
          if (myFiles !== undefined) {
            for (let index = 0; index < response.data.data.length; index++) {
              const element = response.data.data[index];
              await GenericGet(`/files/${element.object_id}`)
                .then(async (response1) => {
                  element.dataURL = await `data:${element.type_of_file};base64,${response1.data.content}`;
                }).catch(() => {
                  return undefined;
                });
            }
            return await myFiles;
          }
        });
      return response;
    } catch (error) {
      throw error;
    }
  }

  const PostFiles = async (myData: any) => {
    try {
      await GetFiles(myData.recordId, myData.entity).then((response) => {
        if (response && response?.length > 0) {
          response.forEach(element => {
            GenericDelete(`/files/${element.file_id}/objectId/${element.object_id}`)
          });
        }
      })
      await GenericPost(`/files`, myData).then((response) => {
        return response;
      })
    } catch (error) {
      throw error;
    }
  }

  const DeleteFile = async (id: number) => {
    try {
      await GenericDelete(`/files/byId/${id}`).then((response) => {
        return response;
      })
    } catch (error) {
      throw error;
    }
  }


  const GetFileIfExists = async (id: number): Promise<any> => {
    let myFile: File = {};
    return await GenericGet(`/files/byId/${id}`)
      .then(async (response) => {

        if (response.data.data.length > 0) {
          myFile.file_id = response.data.data[0].file_id;
          myFile.object_id = response.data.data[0].object_id;
          myFile.entity = response.data.data[0].entity;
          myFile.record_id = response.data.data[0].record_id;
          myFile.file_name = response.data.data[0].file_name;
          myFile.type_of_file = response.data.data[0].type_of_file;
          myFile.schema_name = response.data.data[0].schema_name;

          await GenericGet(`/files/${response.data.data[0].object_id}`)
            .then(async (response1) => {
              myFile.dataURL = await `data:${myFile.type_of_file};base64,${response1.data.content}`;
            }).catch(async (error) => {
              await DeleteFile(id);
              throw error;
            });
          return myFile;
        }else {
          await DeleteFile(id);
          throw new Error("No file found");
        }
      })
      .catch(async (error) => {
        throw error;
      });
  }


  return {
    DecodeFileToBase64,
    GetFile,
    GetFiles,
    GetFileById,
    GetContentURLImage,
    PostFiles,
    DeleteFile,
    GetFileIfExists,
    DecodeFileSQLToBase64,
  }

}
