import { Route, Routes } from 'react-router-dom'
import { TableLanguage } from './table'
import { AddLanguages } from './add'
import { ViewLanguage } from './view'

export const BrowserLanguages = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableLanguage />} />
      <Route path={"/add"} element={<AddLanguages />} />
      <Route path={"/view"} element={<ViewLanguage />} />
    </Routes>
  )
}