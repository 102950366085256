import { TextField } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { CancelledShippingTracking } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledShippingTrackings';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

type CancelledShippingTrackingTabProps = {
  cancelledSalesInvoiceId: number | undefined,
}

export const CancelledShippingTrackingTab = ({
  cancelledSalesInvoiceId,
}: CancelledShippingTrackingTabProps) => {

  const [t] = useTranslation("global");
  const [shippingTrackingLocal, setShippingTrackingLocal] = useState<CancelledShippingTracking>()
  const { GenericGetResource } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  useEffect(() => {
    GenericGetResource(`/cancelledshippingtrackings/bycancelledsalesinvoiceid/${cancelledSalesInvoiceId}`)
      .then(async (response) => {
        setShippingTrackingLocal(response.data.data[0])
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        // navigate("/salesinvoices");
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <form className='d-flex flex-column justify-content-center'>
            <div className='custom-card-view w-100'>
              <div className="custom-card-row d-flex justify-content-between">
                <div className="w-100">
                  <div className='d-flex gap-3 w-100 '>
                    <div className='d-flex flex-column gap-3 w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.track_id")}`}
                        value={shippingTrackingLocal?.track_id}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.packages_quantity")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.packages_quantity}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.containers_quantity")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.containers_quantity}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.pallets_quantity")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.pallets_quantity}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className='d-flex flex-column gap-3 w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.gross_weight")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.gross_weight}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.real_gross_weight")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.real_gross_weight}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.net_weight")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.net_weight}
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        variant="filled"
                        label={`${t("shippingtrackings.fields.real_net_weight")}`}
                        type='number'
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        value={shippingTrackingLocal?.real_net_weight}
                        size="small"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className='d-flex w-100 pt-3'>
                    <TextField
                      variant="filled"
                      label={`${t("shippingtrackings.fields.dimensions")}`}
                      value={shippingTrackingLocal?.dimensions}
                      multiline
                      minRows={5}
                      size="small"
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      }
      <SnackbarComponent/>
    </>
  )
}
