import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../api/GenericPromises';
import { State } from '../../../interfaces/Commons/Locations/states';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { City } from '../../../interfaces/Commons/Locations/cities';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddCity = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboStates, setComboStates] = useState<State[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<City>({
    defaultValues: {
      city_code: undefined,
      city_name: "",
      state_id: undefined
    }
  });
  const onSubmit: SubmitHandler<City> = (data) => onPost(data);

  const onPost = (data: City) => {
    let myData = {
      city_code: data.city_code ?? null,
      city_name: data.city_name,
      state_id: data.state_id,
    }
    setLoadingPost(true);
    GenericPostResource("/cities", myData)
      .then((response) => {
        navigate("/cities", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/cities", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("cities.title")}`;
    })

    let myPromises = [GenericGetResource("/states")];

    Promise.all(myPromises)
      .then((responses) => {
        setComboStates(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });

  }, []);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("cities.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="d-flex justify-content-between">

                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="city_code"
                        control={control}
                        rules={{ maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("cities.fields.city_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.city_code}
                            helperText={GetError(errors.city_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="city_name"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("cities.fields.city_name")}`}
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.city_name}
                            helperText={GetError(errors.city_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex w-100 mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="state_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          options={comboStates}
                          getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                          renderOption={(props, option: State) => (
                            <div key={option.state_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.state_code} - {option.state_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                          onChange={(_, values) => {
                            field.onChange(values?.state_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("states.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.state_id}
                              helperText={GetError(errors.state_id?.type)}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
