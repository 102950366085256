import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Menuitem } from "../../../../interfaces/Security/menu";
import GenericPromises from "../../../../api/GenericPromises";
import { Button } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { UpdateTaxObjects } from "./update";
import { TaxObject } from "../../../../interfaces/Sales/Catalogs/taxObjects";
import { usePermissions } from "../../../../hooks/usePermissions";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../theme/buttons";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const ViewTaxObjects = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [taxObjectLocal, setTaxObjectLocal] = useState<TaxObject>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();


  const onDelete = () => {
    GenericDeleteResource(`/taxobjects/${taxObjectLocal?.tax_object_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/taxobjects", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }



  useEffect(() => {
    if (location.state === null) return navigate("/taxobjects", { state: {}, replace: true });
    GenericGetResource(`/taxobjects/${location.state.row.tax_object_id}`)
      .then(
        (response) => {
          setTaxObjectLocal(response.data);

          GetResourceByUrl(`/taxobjects`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("taxobjects.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/taxobjects", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <UpdateTaxObjects taxObjectPayload={taxObjectLocal} isEdit={resourceScreen?.update} />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("taxobjects.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
