import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { InvoiceDocumentType } from "../../../../../interfaces/Sales/Invoices/salesinvoices";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../../api/GenericPromises";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { PurchaseInvoice } from "../../../../../interfaces/Purchases/Invoices/purchaseInvoices";

type CopyPurchaseInvoiceProps = {
  setOpenDialogCopyPurchaseInvoice: Dispatch<SetStateAction<boolean>>,
  comboInvoiceDocumentTypes: InvoiceDocumentType[],
  defaultInvoiceDocumentType: InvoiceDocumentType | undefined,
  purchaseInvoiceLocal: PurchaseInvoice | undefined,
}

export const CopyPurchaseInvoice = ({
  setOpenDialogCopyPurchaseInvoice,
  comboInvoiceDocumentTypes,
  defaultInvoiceDocumentType,
  purchaseInvoiceLocal,
}: CopyPurchaseInvoiceProps) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { GenericGetResource, GenericPostAction } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [myInvoiceDocumentType, setMyInvoiceDocumentType] = useState<InvoiceDocumentType>();
  const [disabledPost, setDisabledPost] = useState(false);

  const onCopy = async () => {
    if (myInvoiceDocumentType !== null) {

      setDisabledPost(true);
      const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
      let myData = {
        purchase_invoice_name: responseConsecutive.data.data[0].Consecutive,
        purchase_invoice_date: purchaseInvoiceLocal?.purchase_invoice_date,
        sales_order: purchaseInvoiceLocal?.sales_order,
        supplier_id: purchaseInvoiceLocal?.supplier_id,
        supplier_business_name: purchaseInvoiceLocal?.supplier_business_name,
        supplier_comercial_name: purchaseInvoiceLocal?.supplier_comercial_name,
        supplier_rfc: purchaseInvoiceLocal?.supplier_rfc,
        supplier_street_name: purchaseInvoiceLocal?.supplier_street_name,
        supplier_location_id: purchaseInvoiceLocal?.supplier_location_id,
        supplier_postal_code: purchaseInvoiceLocal?.supplier_postal_code,
        supplier_bank_account_id: purchaseInvoiceLocal?.supplier_bank_account_id,
        supplier_city_id: 0,
        supplier_city_name: purchaseInvoiceLocal?.supplier_city_name,
        supplier_state_id: 0,
        supplier_state_name: purchaseInvoiceLocal?.supplier_state_name,
        supplier_state_abbr: purchaseInvoiceLocal?.supplier_state_abbr ?? null,
        supplier_country_id: 0,
        supplier_country_name: purchaseInvoiceLocal?.supplier_country_name,
        supplier_country_code: purchaseInvoiceLocal?.supplier_country_code ?? null,
        supplier_account_alias: purchaseInvoiceLocal?.supplier_account_alias ?? null,
        supplier_interbank_key: purchaseInvoiceLocal?.supplier_interbank_key ?? null,
        supplier_swift_code: purchaseInvoiceLocal?.supplier_swift_code ?? null,
        supplier_bank_name: purchaseInvoiceLocal?.supplier_bank_name ?? null,
        supplier_account_number: purchaseInvoiceLocal?.supplier_account_number ?? null,
        subtotal_amount: purchaseInvoiceLocal?.subtotal_amount,
        total_amount: purchaseInvoiceLocal?.total_amount,
        tax_amount: purchaseInvoiceLocal?.tax_amount,
        currency_id: purchaseInvoiceLocal?.currency_id,
        currency_code: purchaseInvoiceLocal?.currency_code,
        currency_description: purchaseInvoiceLocal?.currency_description,
        invoice_doc_type_id: myInvoiceDocumentType?.invoice_doc_type_id,
        invoice_doc_status_id: 1,
        payment_mode_code: purchaseInvoiceLocal?.payment_mode_code,
        payment_mode_description: purchaseInvoiceLocal?.payment_mode_description,
        payment_method_code: purchaseInvoiceLocal?.payment_method_code,
        payment_method_name: purchaseInvoiceLocal?.payment_method_name,
        cfdi_code: purchaseInvoiceLocal?.cfdi_code,
        cfdi_description: purchaseInvoiceLocal?.cfdi_description,
        discount_amount: purchaseInvoiceLocal?.discount_amount,
        supplier_tax_regime_id: 0,
        supplier_tax_regime_code: purchaseInvoiceLocal?.supplier_tax_regime_code,
        supplier_tax_regime_description: purchaseInvoiceLocal?.supplier_tax_regime_description,
        is_accounted: false,
        id_consecutive: myInvoiceDocumentType?.consecutive_id,
        notes: purchaseInvoiceLocal?.notes,
        retention_amount: purchaseInvoiceLocal?.retention_amount,
        payment_condition_id: purchaseInvoiceLocal?.payment_condition_id,
        payment_condition_days: purchaseInvoiceLocal?.payment_condition_days,
        payment_condition_name: purchaseInvoiceLocal?.payment_condition_name,
        payment_condition_description: purchaseInvoiceLocal?.payment_condition_description,
        payment_due_date: purchaseInvoiceLocal?.payment_due_date,
        supplier_language: purchaseInvoiceLocal?.supplier_language,
        supplier_email: purchaseInvoiceLocal?.supplier_email,
        supplier_phone_number: purchaseInvoiceLocal?.supplier_phone_number,
        company_business_name : purchaseInvoiceLocal?.company_business_name,
        company_street : purchaseInvoiceLocal?.company_street,
        company_city_id : purchaseInvoiceLocal?.company_city_id,
        company_city_name : purchaseInvoiceLocal?.company_city_name,
        company_state_id : purchaseInvoiceLocal?.company_state_id,
        company_state_name : purchaseInvoiceLocal?.company_state_name,
        company_state_abbr : purchaseInvoiceLocal?.company_state_abbr,
        company_country_id : purchaseInvoiceLocal?.company_country_id,
        company_country_name : purchaseInvoiceLocal?.company_country_name,
        company_country_code : purchaseInvoiceLocal?.company_country_code,
        company_postal_code : purchaseInvoiceLocal?.company_postal_code,
        company_rfc : purchaseInvoiceLocal?.company_rfc,
        company_tax_regime_id : purchaseInvoiceLocal?.company_tax_regime_id,
        company_tax_regime_code : purchaseInvoiceLocal?.company_tax_regime_code,
        company_tax_regime_description : purchaseInvoiceLocal?.company_tax_regime_description,
        company_email : purchaseInvoiceLocal?.company_email,
        company_phone_number : purchaseInvoiceLocal?.company_phone_number,
        exchange_rate: purchaseInvoiceLocal?.exchange_rate,
        expiration_date: purchaseInvoiceLocal?.expiration_date,
        supplier_sale_invoice_date: purchaseInvoiceLocal?.supplier_sale_invoice_date
      }
      GenericPostAction(`/purchaseinvoices/copy/${purchaseInvoiceLocal?.purchase_invoice_id}`, myData, false)
        .then(async (response) => {
          await navigate("/purchaseinvoices", { state: { row: response.data } });
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        })
        .finally(() => setDisabledPost(false));
    }
    else {
      showSnackBar(t("purchaseinvoices.info.required-doc-type"), "error");
    }
  }

  const handleChange = (e: any, value: any) => {
    setMyInvoiceDocumentType(value);
  }

  useEffect(() => {
    setMyInvoiceDocumentType(defaultInvoiceDocumentType);
    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box minWidth={400} className="d-flex flex-column m-1">
          <div className="d-flex w-100">
            <Autocomplete
              value={myInvoiceDocumentType || null}
              size="small"
              sx={{ flex: 2 }}
              options={comboInvoiceDocumentTypes}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option: InvoiceDocumentType) => (
                <div key={option.invoice_doc_type_id}>
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.name}
                  </Box>
                </div>
              )}
              isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("invoicedocumenttypes.title-view")}`}
                  variant="filled"
                  value={myInvoiceDocumentType?.name}
                />
              )}
            />
          </div>
          <div className="d-flex flex-row-reverse mt-4">
            <PrimaryButton disabled={disabledPost} onClick={() => onCopy()}>{t("generic.buttons.accept")}</PrimaryButton>
            <Button onClick={() => setOpenDialogCopyPurchaseInvoice(false)}>{t("generic.buttons.cancel")}</Button>
          </div>
          <SnackbarComponent />
        </Box>
      }
    </>
  )
}
