import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { Item } from '../../../../../interfaces/Commons/items';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CustomerItem } from '../../../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Divider } from '../../../../../components/Commons/Divider';

export const AddCustomerItems = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPostResource, GenericGetResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [comboItems, setComboItems] = useState<Item[]>([]);
    const [messageError, setMessageError] = useState("");
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        trigger,
        getValues,
        formState: { errors },
    } = useForm<CustomerItem>({
        defaultValues: {
            customer_id: undefined,
            item_id: undefined,
            item_price: undefined,
            customer_reference: undefined,
            customer_reference_description: undefined,
        }
    });
    const onSubmit: SubmitHandler<CustomerItem> = (data) => onPost(data);

    const onPost = (data: CustomerItem) => {
        let myData = {
            customer_id: props.customer_id,
            item_id: data.item_id,
            item_price: data.item_price,
            customer_reference: data.customer_reference ?? null,
            customer_reference_description: data.customer_reference_description ?? null,
        }
        setLoadingPost(true);
        GenericPostResource("/customeritems", myData)
            .then((response) => {
                let myItem = comboItems.find((item: Item) => item.item_id === myData.item_id);
                let myNewRow = {
                    ...response.data,
                    item_code: myItem?.item_code,
                    item_description: myItem?.item_description
                }
                props.AddNewRow(myNewRow);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
                setMessageError(error.message);
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        props.setOpenDialog(false);
    }

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/items")
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboItems(responses[0].data.data);

                setDataLoaded(true);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
                setMessageError(error.message);
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

                        <div className='d-flex'>
                            <div className='w-50'>
                                <TextField
                                    variant="filled"
                                    label={`${t("customeritems.fields.business_name")}`}
                                    value={props.business_name}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                    disabled={true}
                                />
                            </div>

                        </div>

                        <div className='mt-3 d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="item_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            ref={field.ref}
                                            size="small"
                                            sx={{ width: "100%", paddingRight: 2 }}
                                            options={comboItems}
                                            getOptionLabel={(option) => `${option.item_code} - ${option.item_description}`}
                                            renderOption={(props, option: Item) => (
                                                <div key={option.item_id}>
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.item_code} - {option.item_description}
                                                    </Box>
                                                </div>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.item_id === value.item_id}
                                            onChange={(_, values) => {
                                                field.onChange(values?.item_id || null)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t("items.title-view")}`}
                                                    variant="filled"
                                                    value={field.value}
                                                    error={!!errors.item_id}
                                                    helperText={GetError(errors.item_id?.type)}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="item_price"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.item_price")}`}
                                            type='number'
                                            ref={field.ref}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                "& input[type=number]": { MozAppearance: "textfield", }
                                            }}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.item_price}
                                            helperText={GetError(errors.item_price?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <Divider />

                        <div className='d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="customer_reference"
                                    control={control}
                                    rules={{ maxLength: 50 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.customer_reference")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.customer_reference}
                                            helperText={GetError(errors.customer_reference?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                        />
                                    }
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="customer_reference_description"
                                    control={control}
                                    rules={{ maxLength: 150 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("customeritems.fields.customer_reference_description")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.customer_reference_description}
                                            helperText={GetError(errors.customer_reference_description?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton onClick={() => {
                                trigger().then((response) => response && onSubmit(getValues()));
                            }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>
                    </form>

                </Box>
            }
            <SnackbarComponent />
        </>
    )
}
