import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Country } from '../../../interfaces/Commons/Locations/countries';
import { TextField } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const UpdateCountry = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { countryPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Country>({
        defaultValues: {
            country_code: undefined,
            country_name: "",
            country_id: 0,
        }
    });
    const onSubmit: SubmitHandler<Country> = (data) => onPut(data);

    const onPut = (data: Country) => {
        let myData = {
            country_code: data.country_code ?? null,
            country_name: data.country_name,
        }
        setLoadingPost(true);
        GenericPutResource(`/countries/${countryPayload.country_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
                setLoadingPost(false);
            });
    }

    useEffect(() => {

        reset({
            country_id: countryPayload.country_id,
            country_code: countryPayload.country_code ?? undefined,
            country_name: countryPayload.country_name
        });

        setDataLoaded(true);

    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="country_code"
                                            control={control}
                                            rules={{ maxLength: 10 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("countries.fields.country_code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.country_code}
                                                    helperText={GetError(errors.country_code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className='w-50'>
                                        <Controller
                                            name="country_name"
                                            control={control}
                                            rules={{ required: true, maxLength: 50 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("countries.fields.country_name")}`}
                                                    ref={field.ref}
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                        "& input[type=number]": { MozAppearance: "textfield", },
                                                    }}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.country_name}
                                                    helperText={GetError(errors.country_name?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                </div>

                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>

                </div>
            }
            <SnackbarComponent />
        </>
    )
}
