import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TableProjects } from './table'
import { AddProjects } from './add'
import { ViewProjects } from './view'

export const BrowserProjects = () => {
  return (
    <Routes>
        <Route path={'/'} element={<TableProjects/>}/>
        <Route path={"/add"} element={<AddProjects/>}/>
        <Route path={"/view"} element={<ViewProjects/>}/>
    </Routes>
  )
}
