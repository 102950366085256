import { DatePicker } from "@mui/x-date-pickers"
import { ProjectExtraField } from "../../../../../interfaces/Projects/Catalogs/projectExtraFields"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { AlertColor, TextField } from "@mui/material"
import GenericPromises from "../../../../../api/GenericPromises"
import { HookPutExtraFields } from "../helpers/hookPutExtraFields.tsx/hookPutExtraFields"
import dayjs from "dayjs"
import { Menuitem } from "../../../../../interfaces/Security/menu"
import { useFormulaEvaluator } from "../../../../../hooks/useFormulaEvaluator"
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner"
import { useTranslation } from "react-i18next"

type ProjectExtraFieldsProps = {
  value: ProjectExtraField,
  projectExtraFieldsData?: ProjectExtraField[],
  setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
  resourceScreen: Menuitem | undefined,
  isClosedProject: boolean,
  setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>,
  showSnackBar: (message: string, severity: AlertColor) => void,
}

export const ProjectExtraFieldDate = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
}: ProjectExtraFieldsProps) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { GenericPutResource } = GenericPromises();
  const { handleUpdateOnChangeField, onPutDate } = HookPutExtraFields();

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  return (
    <>
      <DatePicker
        open={isClosedProject ? !isClosedProject : openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        label={value.project_extra_field_name}
        onChange={(values) => {
          const dateValue = values ? dayjs(values).toISOString() : '';
          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, dateValue);
          onPutDate(value, values ? values?.toDate() : undefined, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
        }}
        slotProps={{
          textField: {
            variant: "filled",
            size: "small",
            fullWidth: true,
            onClick: () => setOpenDatePicker(true),
            onBeforeInput: disableKeyboardEntry,
          }
        }}
        value={value.project_extra_field_value ? dayjs(value.project_extra_field_value) : null}
        format="DD/MM/YYYY"
        disabled={!resourceScreen?.update}
        readOnly={isClosedProject}
      />
    </>
  )
}

export const ProjectExtraFieldString = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
}: ProjectExtraFieldsProps) => {
  const { GenericPutResource } = GenericPromises();
  const { onPut, handleUpdateOnChangeField } = HookPutExtraFields();
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      <TextField
        variant="filled"
        label={value.project_extra_field_name}
        onChange={(e) => {
          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, e.target.value);
          if (!isUpdating) {
            setIsUpdating((prev) => true);
          }
          if (isUpdating) {
            setTimeout(async () => {
              await onPut(value, e, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
              setIsUpdating((prev) => false);
            }, 3000);
          }
        }}
        value={value.project_extra_field_value}
        size="small"
        style={{ width: "100%" }}
        disabled={!resourceScreen?.update}
        InputProps={{ readOnly: isClosedProject }}
      />
    </>
  )
}

export const ProjectExtraFieldNumber = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
}: ProjectExtraFieldsProps) => {
  const { GenericPutResource } = GenericPromises();
  const { onPut, handleUpdateOnChangeField } = HookPutExtraFields();
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      <TextField
        variant="filled"
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
          "& input[type=number]": { MozAppearance: "textfield", }
        }}
        type="number"
        onChange={(e) => {
          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, e.target.value);
          if (!isUpdating) {
            setIsUpdating((prev) => true);
            setTimeout(async () => {
              await onPut(value, e, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
              setIsUpdating((prev) => false);
            }, 3000);
          }
        }}
        value={value.project_extra_field_value}
        label={value.project_extra_field_name}
        size="small"
        style={{ width: "100%" }}
        disabled={!resourceScreen?.update}
        InputProps={{ readOnly: isClosedProject }}
      />
    </>
  )
}

export const ProjectExtraFieldFormula = ({
  value,
  projectExtraFieldsData,
  resourceScreen,
}: ProjectExtraFieldsProps) => {
  const { EvaluateFormula } = useFormulaEvaluator();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [myValue, setMyValue] = useState<any>();
  const [t] = useTranslation("global");

  useEffect(() => {
    EvaluateFormula(value.project_extra_field_value ?? '', projectExtraFieldsData ?? [])
      .then((response: any) => {
        setMyValue(response);
        setDataLoaded((prev) => true);
      })
      .catch((error: any) => {
        setMyValue(`${t("projectextrafields.info.no-calculated")}  ${error.message}`);
        setDataLoaded((prev) => true);
      });
  }, []);

  return (
    <>
      {!dataLoaded &&
        <Spinner isBox={false} />
      }
      {dataLoaded &&
        <TextField
          variant="filled"
          label={value.project_extra_field_name}
          size="small"
          value={myValue}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={!resourceScreen?.update}
        />
      }

    </>
  )
}

