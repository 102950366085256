import { Route, Routes } from 'react-router-dom'
import { AddCancellationReason } from './add'
import { TableCancellationReason } from './table'
import { ViewCancellationReason } from './view'

export const BrowserCancellationReasons = () => {
    return (
      <Routes>
        <Route path={"/"} element={<TableCancellationReason />} />
        <Route path={"/add"} element={<AddCancellationReason />} />
        <Route path={"/view"} element={<ViewCancellationReason />} />
      </Routes>
    )
  }
  