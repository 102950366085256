import { Route, Routes } from 'react-router-dom'
import { TableTrailerType } from './table'
import { AddTrailerTypes } from './add'
import { ViewTrailerTypes } from './view'

export const BrowserTrailerTypes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableTrailerType />} />
      <Route path={"/add"} element={<AddTrailerTypes />} />
      <Route path={"/view"} element={<ViewTrailerTypes />} />
    </Routes>
  )
}