import * as React from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridValidRowModel,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TablePaginationProps, TextField } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import { useLocation } from 'react-router-dom';
import { CancelledSalesInvoiceDetail } from '../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import GenericPromises from '../../../api/GenericPromises';
import { CustomerItem } from '../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { PrimaryButton } from '../../../theme/buttons';
import { SnackbarComponent } from '../../Commons/SnackBar';

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  columns: GridColDef[],
  rowModesModel: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<CancelledSalesInvoiceDetail[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  isEdit?: boolean,
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  isEdit: boolean,
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, changeTotalValue } = props;
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const [severitySnack, setSeveritySnack] = React.useState("warning");
  const [comboItems, setComboItems] = React.useState<CustomerItem[]>([]);
  const [myItem, setMyitem] = React.useState<CustomerItem | null>(null);
  const [myQuantity, setMyQuantity] = React.useState<number>();
  const [myPrice, setMyPrice] = React.useState<number>();
  const myQuantityRef = React.useRef<any>(null);
  const location = useLocation();
  const { GenericPostResource } = GenericPromises();

  React.useEffect(() => {
    GenericGetResource(`/customeritems/bycustomerid/${location.state.row.customer_id ?? ""}`)
      .then((response) => {
        setComboItems(response.data.data);
      })
      .catch((error) => {
        setMessageSnack(error.message);
        setSeveritySnack("error");
        setOpenSnack(true);
      });
  }, []);

  const handleChange = (e: any, value: any) => {
    if (value === null) {
      setMyQuantity((prev) => 0);
      setMyPrice((prev) => 0);
    }
    else {
      myItem && myQuantityRef.current && myQuantityRef.current.focus()
      setMyPrice(comboItems.find((item: CustomerItem) => item.customer_items_id === value.customer_items_id)?.item_price ?? 0);
    }
    setMyitem((prev) => value);
  };

  const handleChangeTextfields = (e: any) => {
    let { name, value } = e.target;
    if (name === 'price') {
      setMyPrice((prev) => value)
    }
    else if (name === 'quantity') {
      setMyQuantity((prev) => value);
    }
  }

  const AddRecord = () => {
    if ((myQuantity === undefined || myQuantity <= 0) || (myPrice === undefined || myPrice <= 0)) {
      setMessageSnack(t("salesinvoicedetails.snackbar.warning"));
      setSeveritySnack("warning");
      setOpenSnack(true);
    }
    else {
      let mySalesInvoiceDetail = {
        quantity: myQuantity,
        price_by_unit: myPrice,
        discount: null,
        subtotal_tax_amount: null,
        subtotal_amount: 0,
        total_amount: myQuantity * myPrice,
        tax_id: null,
        tax_name: null,
        tax_percent: null,
        tax_code: null,
        name_item_family: null,
        name_item_type: null,
        item_weight:myItem?.weight ?? null,
        item_description: myItem?.item_description,
        item_code: myItem?.item_code,
        item_id: myItem?.item_id,
        unit_id: myItem?.unit_id,
        unit_code: myItem?.unit_code,
        unit_description: null,
        tax_object: null,
        product_service_key: null,
        cancelled_sales_invoice_id: location.state.row.cancelled_sales_invoice_id,
        sale_shipping_document_detail_id: null
      }
      GenericPostResource("/salesinvoicedetails", mySalesInvoiceDetail).then((response) => {
        setRows((oldRows) => [...oldRows, { cancelled_sales_invoice_detail_id: response.data.cancelled_sales_invoice_detail_id, item_code: myItem?.item_code, item_description: myItem?.item_description, quantity: myQuantity, price_by_unit: myPrice, unit_code: myItem?.unit_code, total_amount: mySalesInvoiceDetail.total_amount }]);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [response.data.cancelled_sales_invoice_detail_id]: { mode: GridRowModes.View },
        }));
        changeTotalValue(mySalesInvoiceDetail.total_amount, 0, 0);
        
        setMyitem((prev) => null);
        setMyQuantity((prev) => undefined);
        setMyPrice((prev) => undefined);
      })
    }
  }

  return (
    <GridToolbarContainer className='d-flex p-1 w-100'>
      {props.isEdit &&
        <>
          <Autocomplete
            value={myItem || null}
            size="small"
            sx={{ flex: 8 }}
            options={comboItems}
            getOptionLabel={(option) => `${option.item_code} - ${option.item_description}`}
            renderOption={(props, option: CustomerItem) => (
              <div key={option.customer_items_id}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.item_code} - {option.item_description}
                </Box>
              </div>
            )}
            isOptionEqualToValue={(option, value) => option.customer_items_id === value.customer_items_id}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("items.title-view")}`}
                variant="filled"
              />
            )}
          />
          {myItem &&
            <>
              <TextField
                inputRef={myQuantityRef}
                variant="filled"
                name="quantity"
                label={`${t("salesinvoicedetails.fields.quantity")}`}
                value={myQuantity || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
              />
              <TextField
                variant="filled"
                name="price"
                label={`${t("salesinvoicedetails.fields.price")}`}
                value={myPrice || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
              />
              <PrimaryButton variant='outlined' sx={{ flex: 1 }} onClick={AddRecord}><AddIcon /></PrimaryButton>
            </>
          }
        </>
      }
      <SnackbarComponent
        message={messageSnack}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        severity={severitySnack}
      />
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function CancellTableSalesInvoiceDetail({
  rows,
  columns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  setRows,
  setRowModesModel,
  changeTotalValue,
  isEdit,
}: EditableTableProps) {
  const [t] = useTranslation("global");

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => row["cancelled_sales_invoice_detail_id"]}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          toolbar: EditToolbar,
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, changeTotalValue, isEdit },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  );
}