import { Route, Routes } from "react-router-dom"
import { TableCustomerBankAccounts } from "./table"


export const BrowserCustomerBankAccounts = ({ ...props }) => {
  return (
    <Routes>
      {(props.customer_id) ?
        <Route path={"/"} element={<TableCustomerBankAccounts customer_id={props.customer_id} />} /> :
        <Route path={"/"} element={<TableCustomerBankAccounts setValue={props.setValue} add={props.add} BankAccountsData={props.BankAccountsData} setBankAccountsData={props.setBankAccountsData} />} />
      }
    </Routes>
  )
}
