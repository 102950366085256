import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import { GenericCardView } from "../../../components/GenericCardView";
import { RowCard } from '../../../interfaces/Commons/appInterfaces';
import GenericPromises from "../../../api/GenericPromises";
import { Button } from '@mui/material';
import { Menuitem } from "../../../interfaces/Security/menu";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { ItemFamily } from "../../../interfaces/Commons/items";
import { UpdateItemFamilies } from "./update";
import { usePermissions } from "../../../hooks/usePermissions";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../theme/buttons";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const ViewItemFamilies = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [itemFamilyLocal, setItemFamilyLocal] = useState<ItemFamily>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [rowsData, setRowsData] = useState<RowCard[]>([]);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/itemfamilies/${itemFamilyLocal?.item_family_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/itemfamilies", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/itemfamilies", { state: {}, replace: true });
    GenericGetResource(`/itemfamilies/${location.state.row.item_family_id}`)
      .then(
        (response) => {
          setItemFamilyLocal(response.data);
          setRowsData([
            {
              field: `${t("item-families.fields.item_family_name")}`,
              value: `${itemFamilyLocal?.item_family_name}`
            },
            {
              field: `${t("item-families.fields.item_family_description")}`,
              value: `${itemFamilyLocal?.item_family_description}`
            },
          ]);
          GetResourceByUrl(`/incoterms`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <div>
          <Header
            title={t("item-families.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="text" onClick={() => { navigate("/itemfamilies", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          <div>
            {resourceScreen?.update === true &&
              <UpdateItemFamilies itemFamilyPayload={itemFamilyLocal} />
            }
            {resourceScreen?.read && !resourceScreen?.update === true &&
              <GenericCardView rows={rowsData} width="50%" />
            }
          </div>

          <GenericDialog
            title={t("generic.dialog.delete")}
            content={t("item-families.dialog.delete")}
            open={openDialog}
            setOpen={setOpenDialog}
            onAcept={onDelete}
          />
          <SnackbarComponent />
        </div>
      }
    </>
  )
}
