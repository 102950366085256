import { Route, Routes } from 'react-router-dom'
import { TableMessagesMails } from './table'
import { AddMessagesMails } from './add'
import { ViewMessageMails } from './view'

export const BrowserMessagesMails = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableMessagesMails />} />
      <Route path={"/add"} element={<AddMessagesMails />} />
      <Route path={"/view"} element={<ViewMessageMails />} />
    </Routes>
  )
}