import React, { useEffect, useState } from 'react'
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowModesModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { useLocation } from 'react-router-dom';
import { CancelledSalesInvoice, CancelledSalesInvoiceDetail } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import CancellTableSalesInvoiceDetail from '../../../../components/Tables/TableCancelledSalesInvoices';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';

type InvoiceBodyProps = {
  salesInvoicePayload?: CancelledSalesInvoice | undefined;
}

export const CancelledSalesInvoicesInvoiceBody = ({
  salesInvoicePayload,
}: InvoiceBodyProps) => {
  const [rows, setRows] = React.useState<CancelledSalesInvoiceDetail[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  let columns: GridColDef[] = [
    {
      field: 'item_code',
      headerName: t("items.fields.item_code"),
      flex: 1,
    },
    {
      field: 'item_description',
      headerName: t("salesinvoicedetails.fields.concept"),
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: t("salesinvoicedetails.fields.quantity"),
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'price_by_unit',
      headerName: t("salesinvoicedetails.fields.price"),
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'unit_code',
      headerName: t("salesinvoicedetails.fields.unit_code"),
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'tax_id',
      headerName: t("taxes.title-view"),
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div>
          {params.row.tax_name} - {params.row.tax_percent}%
        </div>
      ),
      flex: 1
    },
    {
      field: 'retention_id',
      headerName: t("retentions.title-view"),
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div>
          {params.row.retention_name} - {params.row.retention_percent}
        </div>
      ),
      flex: 1
    },
    {
      field: 'discount',
      headerName: t("salesinvoicedetails.fields.discount"),
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'total_amount',
      headerName: t("salesinvoicedetails.fields.subtotal_amount"),
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
  ];

  const changeTotalValue = () => { }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    if (salesInvoicePayload !== undefined || location.state.row.cancelled_sales_invoice_id) {
      GenericGetResource(`/cancelledsalesinvoicedetails/bycancelledsalesinvoiceid/${salesInvoicePayload?.cancelled_sales_invoice_id ?? location.state.row.cancelled_sales_invoice_id}`)
        .then((response) => {
          setRows(response.data.data);
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  }, []);


  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <CancellTableSalesInvoiceDetail
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          handleRowEditStop={handleRowEditStop}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          changeTotalValue={changeTotalValue}
        />
      }
      <SnackbarComponent />
    </>
  )
}
