import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { SnackbarComponent } from '../../../../components/Commons/SnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import dayjs from 'dayjs';
import { Divider } from '../../../../components/Commons/Divider';
import { General } from './general';
import { Locations } from './locations';
import { Merchandise } from './merchandise';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import { CancelledMerchandiseDetail, CancelledTransportationDocument, CancelledTransportationDocumentLocation } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledTransportationDocuments';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

type IndexCancelledTransportationDocumentProps = {
    salesInvoicePayload?: CancelledSalesInvoice;
}

export const IndexCancelledTransportationDocument = ({
    salesInvoicePayload
}: IndexCancelledTransportationDocumentProps) => {
    const [t] = useTranslation("global");

    const { GenericGetResource } = GenericPromises();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [transportationDocumentLocal, setTransportationDocumentLocal] = useState<CancelledTransportationDocument>();
    const [transportationDocumentLocations, setTransportationDocumentLocations] = useState<CancelledTransportationDocumentLocation[]>([]);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    useEffect(() => {
        GenericGetResource(`/cancelledtransportationdocuments/bycancelledsalesinvoiceid/${salesInvoicePayload?.cancelled_sales_invoice_id}`)
            .then(async (responses) => {
                setTransportationDocumentLocal(responses.data.data[0]);
                await GenericGetResource(`/cancelledtransportationdocumentlocations/bycancelledtransportationdocumentid/${responses.data.data[0].cancelled_transportation_document_id}`)
                    .then((response) => {
                        setTransportationDocumentLocations(response.data.data);
                        setDataLoaded(true);
                    }).catch((error) => {
                        showSnackBar(error.message,"error");
                    });
            })
            .catch((error) => {
                showSnackBar(error.message,"error");
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <>
                    <div className='custom-card-view mb-2'>
                        <General
                            transportationdocument={transportationDocumentLocal}
                        />
                    </div>
                    <div className='custom-card-view'>
                        <div className='d-flex flex-row-reverse'>
                            {t("transportationdocuments.fields.total_distance")}: {transportationDocumentLocal?.total_distance ?? "0.00"}
                        </div>
                        <Locations
                            title={t("transportationdocuments.locations.source")}
                            transportationDocumentLocation={transportationDocumentLocations[0]}
                            isSource
                        />
                        {transportationDocumentLocations.length > 1 &&
                            <>
                                <Divider />
                                <Locations
                                    title={t("transportationdocuments.locations.target")}
                                    transportationDocumentLocation={transportationDocumentLocations[1]}
                                />
                                <Divider />
                                {transportationDocumentLocations.length > 2 &&
                                    <>
                                        <Locations
                                            title={t("transportationdocuments.locations.target")}
                                            transportationDocumentLocation={transportationDocumentLocations[2]}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className='custom-card-view mt-2'>
                        <Merchandise
                            transportationDocumentPayload={transportationDocumentLocal}
                        />
                    </div>
                </>
            }
            <SnackbarComponent/>
        </>
    )
}
