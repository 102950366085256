import React from 'react'
import { Divider } from '../Commons/Divider';

interface HeaderInterface {
  title: string;
  size?: 'lg' | 'md' | 'sm';
  child?: JSX.Element;
}

export const Header = ({ title, size = 'md', child = undefined }: HeaderInterface) => {
  return (
    <div>
      {size === 'lg' &&
        <>
          <div className='d-flex justify-content-between'>
            <h1>{title}</h1>
            {child &&
              <div className='w-75'>{child}</div>
            }
          </div>
          <Divider />
        </>
      }
      {size === 'md' &&
        <>
          <div className='d-flex justify-content-between'>
            <h3>{title}</h3>
            {child &&
              <div className='w-75'>{child}</div>
            }
          </div>
          <Divider />
        </>
      }
      {size === 'sm' &&
        <>
          <div className='d-flex justify-content-between'>
            <h4>{title}</h4>
            {child &&
              <div className='w-75'>{child}</div>
            }
          </div>
          <Divider />
        </>
      }
    </div>
  )
}
