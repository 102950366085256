import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { PrimaryButton } from "../../../theme/buttons";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import { usePermissions } from "../../../hooks/usePermissions";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../hooks/useDates";
import { MessageMails } from "../../../interfaces/Commons/messagesMails";

export const TableMessagesMails = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [messagesMailsData, setMessagesMailsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();

  const [columns, setColumns] = useState<GridColDef<MessageMails>[]>([
    { field: 'language_code', headerName: t("messagesmails.fields.language_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'name', headerName: t("messagesmails.fields.name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'prefix', headerName: t("messagesmails.fields.prefix"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'main', headerName: t("messagesmails.fields.main"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'subject', headerName: t("messagesmails.fields.subject"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'body', headerName: t("messagesmails.fields.body"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);


  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          setMessageSnack(t("generic.snackbar.delete"));
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-messagesmails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-messagesmails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<MessageMails, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`messagesmails.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }




  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("messagesmails.title")}`;
    })
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/messagesmails`)
      .then(
        (response) => {
          setMessagesMailsData(response.data.data);

          GetResourceByUrl(`/messagesmails`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, 'error');
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("messagesmails.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
            </div>
          }
        />

        <div className="d-flex justify-content-center">
          <DataTable columns={columns} setColumns={setColumns} data={messagesMailsData} entityId={"messages_mail_id"} entity={`MessageMails`} preferences={myPreferences} namePreferences={"grid-messagesmails"} nameOrderColumns={"grid-messagesmails-columns"} />
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
