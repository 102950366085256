import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Switch, TextField, Typography, } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { ProductServiceKey } from '../../../../interfaces/Sales/Catalogs/productServiceKeys';
import { HazardousMaterialKey } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/hazardousMaterialsKeys';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const UpdateProductServiceKeys = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboHazardousMaterialKeys, setComboHazardousMaterialKeys] = useState<HazardousMaterialKey[]>([]);
  const [defaultHazardousMaterial, setDefaultHazardousMaterial] = useState<HazardousMaterialKey>();
  const [hasHazardousMaterial, setHasHazardousMaterial] = useState(true);
  const { productServiceKeysPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ProductServiceKey>({
    defaultValues: {
      product_service_key: '',
      description: '',
      includes_transportation_document: false,
      is_dangerous_material: false,
      hazardous_material_key_id: undefined,
    }
  });

  const onSubmit: SubmitHandler<ProductServiceKey> = (data) => onPut(data);

  const onPut = (data: ProductServiceKey) => {
    let myData = {
      product_service_key: data.product_service_key,
      description: data.description,
      includes_transportation_document: data.includes_transportation_document,
      is_dangerous_material: data.is_dangerous_material,
      hazardous_material_key_id: data.hazardous_material_key_id ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/productservicekeys/${productServiceKeysPayload.product_service_key_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    GenericGetResource(`/hazardousmaterialskeys`)
      .then((response) => {
        setComboHazardousMaterialKeys(response.data.data);
        reset({
          product_service_key: productServiceKeysPayload.product_service_key,
          description: productServiceKeysPayload.description,
          includes_transportation_document: productServiceKeysPayload.includes_transportation_document,
          is_dangerous_material: productServiceKeysPayload.is_dangerous_material,
          hazardous_material_key_id: productServiceKeysPayload.hazardous_material_key_id,
        });

        if (productServiceKeysPayload.hazardous_material_key_id) {
          setHasHazardousMaterial(false);
          let myCombo: HazardousMaterialKey[] = response.data.data;
          setDefaultHazardousMaterial((prev) => myCombo.find((item) => (item.hazardous_material_key_id === productServiceKeysPayload.hazardous_material_key_id)));
        }

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="product_service_key"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("productservicekeys.fields.product_service_key")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.product_service_key}
                            helperText={GetError(errors.product_service_key?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("productservicekeys.fields.description")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.description}
                            helperText={GetError(errors.description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                  </div>
                  <div className='my-2 pt-2 d-flex'>
                    <div className='w-50'>

                      <div className="w-100">
                        <Controller
                          name="includes_transportation_document"
                          control={control}
                          render={({ field }) =>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                              <Typography>{t("productservicekeys.fields.includes_transportation_document")}</Typography>
                              <Switch
                                ref={field.ref}
                                onChange={(_, e) => {
                                  field.onChange(e);
                                }}
                                value={field.value}
                                checked={field.value}
                                defaultChecked={productServiceKeysPayload?.includes_transportation_document}
                                disabled={isEdit}
                              />
                            </Box>
                          }
                        />
                      </div>
                      <div className="w-100">
                        <Controller
                          name="is_dangerous_material"
                          control={control}
                          render={({ field }) =>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                              <Typography>{t("productservicekeys.fields.is_dangerous_material")}</Typography>
                              <Switch
                                ref={field.ref}
                                onChange={(_, e) => {
                                  field.onChange(e);
                                  setHasHazardousMaterial(!hasHazardousMaterial);
                                    if (!hasHazardousMaterial) {
                                      reset({ hazardous_material_key_id: undefined }, { keepValues: true, keepErrors: false });
                                    }
                                }}
                                value={field.value}
                                checked={field.value}
                                defaultChecked={productServiceKeysPayload?.is_dangerous_material}
                                disabled={isEdit}
                              />
                            </Box>
                          }
                        />
                      </div>
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="hazardous_material_key_id"
                        control={control}
                        rules={{ required: !hasHazardousMaterial }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            defaultValue={defaultHazardousMaterial}
                            options={comboHazardousMaterialKeys}
                            getOptionLabel={(option) => `${option.hazardous_material_key} - ${option.hazardous_material_description}`}
                            renderOption={(props, option: HazardousMaterialKey) => (
                              <div key={option.hazardous_material_key_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.hazardous_material_key} - {option.hazardous_material_description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.hazardous_material_key_id === value.hazardous_material_key_id}
                            onChange={(_, values) => {
                              field.onChange(values?.hazardous_material_key_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("hazardousmaterialskeys.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.hazardous_material_key_id}
                                helperText={GetError(errors.hazardous_material_key_id?.type)}
                              />
                            )}
                            disabled={hasHazardousMaterial}
                          />
                        )}
                      />
                    </div>
                  </div>

                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}