import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { State } from '../../../interfaces/Commons/Locations/states';
import { Country } from '../../../interfaces/Commons/Locations/countries';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddState = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<State>({
    defaultValues: {
      state_code: undefined,
      state_name: "",
      state_abbr: "",
      country_id: undefined,
    }
  });
  const onSubmit: SubmitHandler<State> = (data) => onPost(data);

  const onPost = (data: State) => {
    let myData = {
      state_code: data.state_code ?? null,
      state_name: data.state_name,
      state_abbr: data.state_abbr,
      country_id: data.country_id,
    }
    setLoadingPost(true);
    GenericPostResource("/states", myData)
      .then((response) => {
        navigate("/states", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/states", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("states.title")}`;
    })

    let myPromises = [GenericGetResource("/countries")];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });

  }, []);



  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("states.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="d-flex justify-content-between">

                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="state_code"
                        control={control}
                        rules={{ maxLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("states.fields.state_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.country_code}
                            helperText={GetError(errors.state_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="state_name"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("states.fields.state_name")}`}
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.state_name}
                            helperText={GetError(errors.state_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex w-100 mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="country_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          options={comboCountries}
                          getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                          renderOption={(props, option: Country) => (
                            <div key={option.country_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.country_code} - {option.country_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                          onChange={(_, values) => {
                            field.onChange(values?.country_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("countries.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.country_id}
                              helperText={GetError(errors.country_id?.type)}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="state_abbr"
                      control={control}
                      rules={{ maxLength: 3, required: true }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          variant="filled"
                          label={`${t("states.fields.state_abbr")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.state_abbr}
                          helperText={GetError(errors.state_abbr?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
