import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../../api/GenericPromises";
import { ProjectStagesTaskReminderTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTaskRemindersTemplate";
import { usePermissions } from "../../../../../hooks/usePermissions";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { AddProjectStagesTaskRemindersTemplate } from "./add";
import { Header } from "../../../../../components/Header";
import { UpdateProjectStagesTaskRemindersTemplate } from "./update";
import { AuthContext } from "../../../../../context/AuthContext";
import "./index.css";

export const TableProjectStagesTaskRemindersTemplate = ({ ...props }) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(true);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [openDialogAddReminder, setOpenDialogAddReminder] = useState(false);
  const [openDialogUpdateReminder, setOpenDialogUpdateReminder] =
    useState(false);
  const [
    projectStagesTaskRemindersTemplateLocal,
    setProjectStagesTaskRemindersTemplateLocal,
  ] = useState<ProjectStagesTaskReminderTemplate[]>([]);
  const [selectedReminderId, setSelectedReminderId] = useState<
    number | undefined
  >();
  const { user } = useContext(AuthContext);
  const { projectStagesTaskTemplatePayload } = props;

  const handleAddReminder = (newRow: ProjectStagesTaskReminderTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesTaskRemindersTemplateLocal((prevState) => {
      return [...projectStagesTaskRemindersTemplateLocal, myRow];
    });
  };

  const handleUpdateReminder = (
    updateRow: ProjectStagesTaskReminderTemplate
  ) => {
    if (projectStagesTaskRemindersTemplateLocal.length === 0) {
      return;
    }
    setProjectStagesTaskRemindersTemplateLocal((prevRows) => {
      const rowToUpdateIndex =
        projectStagesTaskRemindersTemplateLocal.findIndex(
          (e) =>
            e.project_stage_task_reminder_template_id ===
            updateRow.project_stage_task_reminder_template_id
        );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteReminder = (deleteRow: number) => {
    if (projectStagesTaskRemindersTemplateLocal.length === 0) {
      return;
    }

    setProjectStagesTaskRemindersTemplateLocal((prevRows) => {
      const rowToDeleteIndex =
        projectStagesTaskRemindersTemplateLocal.findIndex(
          (e) => e.project_stage_task_reminder_template_id === deleteRow
        );

      const updatedData = [
        ...projectStagesTaskRemindersTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectStagesTaskRemindersTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projectstagestaskreminderstemplate/byprojectstagestasktemplateid/${projectStagesTaskTemplatePayload.project_stage_task_template_id}`
    )
      .then(async (response) => {
        setProjectStagesTaskRemindersTemplateLocal(response.data.data);

        GetResourceByUrl(`/projectstagestaskreminderstemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <Drawer
          anchor="right"
          open={props.open}
          onClose={props.onClose}
          slotProps={{ backdrop: { invisible: true } }}
        >
          <Grid
            container
            className="reminders-container"
            flexDirection={"column"}
            spacing={2}
            marginTop={6}
            sx={{
              backgroundColor: "#faf9f8",
              width:400
            }}
          >
            <Grid item>
              <Typography
                className="task-name"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                {
                  projectStagesTaskTemplatePayload.project_stage_task_template_name
                }
              </Typography>
            </Grid>
            {projectStagesTaskRemindersTemplateLocal.map((reminder) => (
              <Grid item key={reminder.project_stage_task_reminder_template_id}>
                <Box
                  className="reminder"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  }}
                >
                  <Typography sx={{ flexGrow: 1, alignContent: "center" }}>
                    {reminder.project_stage_task_reminder_template_name}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedReminderId(
                        reminder.project_stage_task_reminder_template_id
                      );
                      setOpenDialogUpdateReminder(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}

            <Grid item>
              <Box
                className="reminder"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                <Typography sx={{flexGrow: 1, alignContent: "center" }}>
                  {t("reminders.actions.create")}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenDialogAddReminder(true);
                  }}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Drawer>
      )}
      <DialogEntity
        content={
          <AddProjectStagesTaskRemindersTemplate
            handleAddReminder={handleAddReminder}
            setOpenDialog={setOpenDialogAddReminder}
            project_stage_task_template_id={
              projectStagesTaskTemplatePayload?.project_stage_task_template_id
            }
          />
        }
        open={openDialogAddReminder}
        setOpen={setOpenDialogAddReminder}
        title={<Header title={t("reminders.title-view")} size="sm" />}
      />

      <DialogEntity
        content={
          <UpdateProjectStagesTaskRemindersTemplate
            permissions={resourceScreen}
            setOpenDialog={setOpenDialogUpdateReminder}
            handleUpdateReminder={handleUpdateReminder}
            handleDeleteReminder={handleDeleteReminder}
            projectStagesTaskRemindersTemplatePayload={
              projectStagesTaskRemindersTemplateLocal?.find(
                (reminder) =>
                  reminder.project_stage_task_reminder_template_id ===
                  selectedReminderId
              ) || {}
            }
          />
        }
        open={openDialogUpdateReminder}
        setOpen={setOpenDialogUpdateReminder}
        title={<Header title={t("reminders.title-view")} size="sm" />}
      />
    </>
  );
};
