import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { AuthContext } from '../../../context/AuthContext';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { PrimaryButton } from '../../../theme/buttons';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { Header } from '../../../components/Header';
import { UpdateItemSuppliers } from './update';
import { AddItemSuppliers } from './add';
import { usePermissions } from '../../../hooks/usePermissions';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';
import { SupplierItem } from '../../../interfaces/Purchases/Catalogs/supplierItems';

export const TableItemSuppliers = ({ ...props }) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { TimeConverter } = useDates();
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [supplierItemsData, setSupplierItemsData] = useState<GridRowsProp>([]);
  const [supplierItemPayload, setSupplierItemPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columnsSupplierItems, setColumnsSupplierItems] = useState<GridColDef<SupplierItem>[]>([
    { field: 'business_name', headerName: t("supplieritems.fields.business_name"), headerClassName: 'header-grid-table', flex: 1, },
    { field: 'item_price', headerName: t("supplieritems.fields.item_price"), headerClassName: 'header-grid-table', flex: 1, align: 'left', headerAlign: 'left', type: 'number' },
    { field: 'supplier_reference', headerName: t("supplieritems.fields.supplier_reference"), headerClassName: 'header-grid-table', flex: 1, },
    { field: 'supplier_reference_description', headerName: t("supplieritems.fields.supplier_reference_description"), headerClassName: 'header-grid-table', flex: 1, },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-supplieritems-items");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-supplieritems-items-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<SupplierItem, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) {

        }
        else {
          element.headerName = t(`suppliers.fields.${element.field}`);
        }
      }
      setColumnsSupplierItems(myJson);
    }
  }

  const handleAddRow = (newRow: SupplierItem) => {
    let myRow = {
      ...newRow,
      item_code: props.item_code,
      item_description: props.item_description,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setSupplierItemsData((prevState) => [...supplierItemsData, myRow]);
  };

  const handleUpdateRow = (updateRow: SupplierItem) => {
    if (supplierItemsData.length === 0) {
      return;
    }
    setSupplierItemsData((prevRows) => {
      const rowToUpdateIndex = supplierItemsData.findIndex(e => e.supplier_item_id === updateRow.supplier_item_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (supplierItemsData.length === 0) {
      return;
    }
    setSupplierItemsData((prevRows) => {
      const rowToDeleteIndex = supplierItemsData.findIndex(e => e.supplier_item_id === deleteRow);
      return [
        ...supplierItemsData.slice(0, rowToDeleteIndex),
        ...supplierItemsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/supplieritems/byitemid/${props.item_id}`)
      .then(
        (response) => {
          setSupplierItemsData(response.data.data);

          GetResourceByUrl(`/supplieritems`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, 'error');
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            {resourceScreen?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsSupplierItems}
                setColumns={setColumnsSupplierItems}
                data={supplierItemsData}
                entityId={"supplier_item_id"}
                entity={`SupplierItems`}
                preferences={myPreferences}
                namePreferences={"grid-supplieritems-items"}
                nameOrderColumns={"grid-supplieritems-items-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setSupplierItemPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateItemSuppliers supplierItemPayload={supplierItemPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("supplieritems.relation")} size='sm' />}
          />
          <DialogEntity
            content={<AddItemSuppliers setOpenDialog={setOpenDialogAdd} item_id={props.item_id} item_code={props.item_code} item_description={props.item_description} AddNewRow={handleAddRow} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("supplieritems.relation")} size='sm' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
