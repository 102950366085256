import { Route, Routes } from 'react-router-dom'
import { TablePaymentsDetails } from './table'

export const BrowserPaymentsDetails = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePaymentsDetails />} />
    </Routes>
  )
}
