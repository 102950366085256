import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { ProjectGroup } from "../../../../interfaces/Projects/Catalogs/projectGroups";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Group } from "../../../../interfaces/Security/groups";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { PrimaryButton } from "../../../../theme/buttons";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { Divider } from "../../../../components/Commons/Divider";

type UpdateProjectGroupsProps = {
  projectGroupPayload: ProjectGroup,
  permissions: Menuitem,
  project_name: string,
  setOpenDialogUpdate: Dispatch<SetStateAction<boolean>>,
  DeleteRow: (deleteRow: number) => void,
  UpdateRow: (updateRow: ProjectGroup) => void,
  isClosedProject:boolean
}

export const UpdateProjectGroups = ({
  projectGroupPayload,
  permissions,
  project_name,
  setOpenDialogUpdate,
  DeleteRow,
  UpdateRow,
  isClosedProject
}: UpdateProjectGroupsProps) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [defaultGroup, setDefaultGroup] = useState<Group>();
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<ProjectGroup>({
    defaultValues: {}
  });
  const onSubmit: SubmitHandler<ProjectGroup> = (data) => onPut(data);

  const onPut = (data: ProjectGroup) => {
    let myData = {
      group_id: data.group_id,
      project_group_name: data.project_group_name ?? null,
      project_group_description: data.project_group_description ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/projectgroups/${projectGroupPayload.project_group_id}`, myData)
      .then((response) => {
        UpdateRow(response.data);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        setOpenDialogUpdate(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/projectgroups/${projectGroupPayload.project_group_id}`)
      .then(() => {
        DeleteRow(projectGroupPayload?.project_group_id ?? 0);
        setOpenDialogUpdate(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialogUpdate(false);
      })
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboGroups(responses[0].data.data);

        reset({
          group_id: projectGroupPayload.group_id,
          project_id: projectGroupPayload.project_id,
          project_group_name: projectGroupPayload.project_group_name,
          project_group_description: projectGroupPayload.project_group_description,
        });

        setDefaultGroup(responses[0].data.data.find((item: Group) => item.group_id === projectGroupPayload.group_id));

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex mb-2'>

              <Controller
                name="group_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboGroups}
                    getOptionLabel={(option) => option.group_name}
                    defaultValue={defaultGroup}
                    renderOption={(props, option: Group) => (
                      <div key={option.group_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.group_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                    onChange={(_, values) => {
                      field.onChange(values?.group_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("groups.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.group_id}
                        helperText={GetError(errors.group_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                    readOnly={isClosedProject}
                  />
                )}
              />

            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => setOpenDialogUpdate(false)}>{t("generic.buttons.goback")}</Button>
                {permissions.update && <PrimaryButton onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }} variant='outlined' className='m-1' disabled={loadingPost || isClosedProject}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions.delete ?
                <PrimaryButton onClick={() => setOpenDialogDelete(true)} disabled={isClosedProject}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
              }
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectgroups.dialog.delete")}
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}