import { Route, Routes } from 'react-router-dom'
import { TableUnits } from './table'
import { AddUnits } from './add'
import { ViewUnits } from './view'

export const BrowserUnits = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableUnits />} />
      <Route path={"/add"} element={<AddUnits />} />
      <Route path={"/view"} element={<ViewUnits />} />
    </Routes>
  )
}