import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../api/GenericPromises";
import { useDates } from "../../../hooks/useDates";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { usePermissions } from "../../../hooks/usePermissions";
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import { Header } from "../../../components/Header";
import { UpdateSupplierLocations } from "./update";
import { AddSupplierLocations } from "./add";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import { PrimaryButton } from "../../../theme/buttons";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";


export const TableSupplierLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { showSnackBar, SnackbarComponent } = useSnackBar()
  const { GenericGetResource } = GenericPromises();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [numTemp, setNumTemp] = useState(0);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [supplierLocationsData, setSupplierLocationsData] = useState<GridRowsProp>([]);
  const [supplierLocationPayload, setSupplierLocationPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();

  const [shouldLoadLocations, setShouldLoadLocations] = useState(true)
  const [columnsSupplierLocations, setColumnsSupplierLocations] = useState<GridColDef<SupplierLocation>[]>([
    { field: 'business_name', headerName: t("supplierlocations.fields.business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'supplier_rfc', headerName: t("supplierlocations.fields.supplier_rfc"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'Address', headerName: t("supplierlocations.fields.Address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
      }
    },
    {
      field: 'fiscal_address', headerName: t("supplierlocations.fields.fiscal_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'supply_address', headerName: t("supplierlocations.fields.supply_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'default_supply_address', headerName: t("supplierlocations.fields.default_supply_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);



  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.update"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-supplierlocations");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-supplierlocations-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] == 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<SupplierLocation, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === 'Address') {
            element.valueGetter = function (params: GridValueGetterParams<SupplierLocation, any, GridTreeNodeWithRender>) {
              return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
            }
          }
          else if (element.field !== 'last_update_user') {
            element.headerName = t(`suppliers.fields.${element.field}`);
          }
          else if (element.field === "fiscal_address") {
            element.valueGetter = function (params: GridValueGetterParams<SupplierLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field === "supply_address") {
            element.valueGetter = function (params: GridValueGetterParams<SupplierLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field === "default_supply_address") {
            element.valueGetter = function (params: GridValueGetterParams<SupplierLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
        }
      }
      setColumnsSupplierLocations(myJson);
    }
  }

  const handleAddRow = (newRow: SupplierLocation) => {
    setShouldLoadLocations(true);
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setSupplierLocationsData((prevState) => [...supplierLocationsData, myRow]);
    if (props.add)
      setNumTemp(numTemp + 1)
  };

  const handleUpdateRow = (updateRow: SupplierLocation) => {
    setShouldLoadLocations(true);
    if (supplierLocationsData.length === 0) {
      return;
    }
    setSupplierLocationsData((prevRows) => {
      const rowToUpdateIndex = supplierLocationsData.findIndex(e => e.supplier_location_id === updateRow.supplier_location_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    setShouldLoadLocations(true);
    if (supplierLocationsData.length == 0) {
      return [supplierLocationsData];
    }
    setSupplierLocationsData((prevRows) => {
      const rowToDeleteIndex = supplierLocationsData.findIndex(e => e.supplier_location_id === deleteRow);
      return [
        ...supplierLocationsData.slice(0, rowToDeleteIndex),
        ...supplierLocationsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };


  useEffect(() => {
    if(shouldLoadLocations==true){
      if (!props.add) {
        if (location.state !== null && location.state.method) methodUsed();
        GenericGetResource(`/supplierlocations/bysupplierid/${props.supplier_id}`)
          .then(
            (response) => {
              setSupplierLocationsData(response.data.data);
              if (response.data.data.length > 0) {
                if (!response.data.data.find((location : SupplierLocation) => location.country_name == "MEXICO") && !props.isNotMexican) {
                  props.setIsNotMexican(true);
                }
                else if (response.data.data.find((location : SupplierLocation) => location.country_name == "MEXICO" && props.isNotMexican)) {
                  props.setIsNotMexican(false);
                  props.setValue("supplier_rfc", "");
                }
              }
              GetResourceByUrl(`/supplierlocations`)
                .then((response1) => {
                  setResourceScreen((prev) => response1);
                  loadColumnsOrder();
                  loadPreferences();
                  setDataLoaded(true);
                  setShouldLoadLocations(false);
                })
                .catch((error) => {
                  showSnackBar(error.message, "error");
                  setUnauthorized(false);
                });
            }
          ).catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
      else {
        if (props.LocationsData.length > 0 && supplierLocationsData.length < 1) {
          setSupplierLocationsData(props.LocationsData);
          setNumTemp(props.LocationsData.length);
        }
        else {
          props.setLocationsData(supplierLocationsData);
          setNumTemp(supplierLocationsData.length);
        }
        if (location.state !== null && location.state.method) methodUsed();
        GetResourceByUrl(`/supplierlocations`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
            setShouldLoadLocations(false);
          })
  
          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
    }
  }, [handleDeleteRow, handleAddRow, handleUpdateRow]);

  useEffect(() => {
    if (!props.add) {
      if (supplierLocationsData.length > 0) {
        if (!supplierLocationsData.find((location) => location.country_name == "MEXICO") && !props.isNotMexican) {
          props.setIsNotMexican(true);
        }
        else if (supplierLocationsData.find((location) => location.country_name == "MEXICO" && props.isNotMexican)) {
          props.setIsNotMexican(false);
          props.setValue("supplier_rfc", "");
        }
      }
      props.reset({ supplier_rfc: props.getValues("supplier_rfc") });
    }
  }, [])

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{ }</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            {resourceScreen?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsSupplierLocations}
                setColumns={setColumnsSupplierLocations}
                data={supplierLocationsData}
                entityId={"supplier_location_id"}
                entity={`Supplierlocations`}
                preferences={myPreferences}
                namePreferences={"grid-supplierlocations"}
                nameOrderColumns={"grid-supplierlocations-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setSupplierLocationPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateSupplierLocations setValue={props.setValue} supplierLocationsData={supplierLocationsData} supplierLocationPayload={supplierLocationPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} add={props.add} showSnackBar={showSnackBar} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("supplierlocations.title-view")} size='sm' />}
          />
          <DialogEntity
            content={<AddSupplierLocations setValue={props.setValue} add={props.add} setOpenDialog={setOpenDialogAdd} supplier_id={props.supplier_id} AddNewRow={handleAddRow} UpdateRow={handleUpdateRow} numTemp={numTemp} supplierLocationsData={supplierLocationsData} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("supplierlocations.title-view")} size='md' />}
          />
          <SnackbarComponent />

        </>
      }
    </>
  )
}
