import { useTranslation } from 'react-i18next';
import { CancelledSalesInvoice } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices';
import { TextField } from '@mui/material';

type AnnotationsCancelledSalesInvoice = {
    SalesInvoiceLocal: CancelledSalesInvoice | undefined
}

export const AnnotationsCancelledSalesInvoice = ({
    SalesInvoiceLocal
}: AnnotationsCancelledSalesInvoice) => {
    const [t] = useTranslation("global");


    return (
        <>
            <div className='w-100'>
                <TextField
                    label={`${t("salesinvoices.fields.notes")}`}
                    variant="filled"
                    size="small"
                    value={SalesInvoiceLocal?.notes}
                    sx={{ width: "100%", paddingRight: 4 }}
                    disabled={true}
                    multiline
                    minRows={5}
                />
            </div>
        </>
    )
}