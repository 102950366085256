import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import React, { useContext, useEffect, useState } from "react";
import "./index.css"
import { AccordionItemMenu } from "./AccordionItem";
import { Box, Button, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import ResourceEntities from "./resourceEntities";
import { AuthContext } from "../../../context/AuthContext";
import GenericPromises from "../../../api/GenericPromises";
import { Menuitem } from "../../../interfaces/Security/menu";
import { SnackbarComponent } from "../../Commons/SnackBar";
import { Link } from "react-router-dom";

export const AccordionMenu = ({ ...props }) => {
    const [menuLoaded, setMenuLoaded] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [menuData, setMenuData] = useState<Menuitem[]>([]);
    const { user } = useContext(AuthContext);
    const { GenericGet } = GenericPromises();
    const GetResource = ResourceEntities();

    const [menuParents, setMenuParents] = useState<ParentMenu[]>([])

    interface ParentMenu {
        open: boolean;
        option_menu_id: number;
        name: string;
    }

    const addNewParent = (data: Menuitem[]) => {
        try {
            let myParentsMenus: ParentMenu[] = [];
            data.forEach(element => {
                if (element.prefix != 'Generic' && !(menuParents.find((menu: ParentMenu) => menu.option_menu_id == element.option_menu_id))) {
                    let newParentMenu: ParentMenu = {
                        open: false,
                        option_menu_id: element.option_menu_id,
                        name: element.description
                    };
                    myParentsMenus.push(newParentMenu);
                }
            });
            setMenuParents([...menuParents, ...myParentsMenus]);
        }
        catch {
        }
    }

    const modifyParent = (id: number) => {
        let newMenu = menuParents.map((varMenu: any) => {
            if (varMenu.option_menu_id === id) {
                return {
                    ...varMenu,
                    open: !(varMenu.open)
                };
            } else {
                return varMenu;
            }
        })
        setMenuParents(newMenu);
    }

    useEffect(() => {
        if (user?.group_id != null) {
            GenericGet(`/menu/${user?.group_id}`)
                .then((response) => {
                    setMenuData(response.data.children);
                    addNewParent(response.data.children);
                    setMenuLoaded(true);
                }).catch((error) => {
                    setMessageError(error.message);
                    setOpenSnack(true);
                })
        }

    }, [user?.group_id, user?.password, user?.user_email, user]);

    if (menuData?.length === 0) return <></>

    return (
        <>{menuLoaded &&
            <>
                {menuData.map((subMenu, index1) => {
                    return <div key={index1} style={{ backgroundColor: 'secondary.main' }}>
                        <>
                            {subMenu.resource_type_name !== "Generic" &&
                                <>
                                    <ListItem disablePadding sx={{ display: 'block', width: '115%' }} onClick={() => {
                                        modifyParent(subMenu.option_menu_id)
                                    }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: props.open ? 'initial' : 'center',
                                                px: 3,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    paddingLeft: 0,
                                                    mr: props.open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Tooltip title={GetResource(subMenu.option_name)?.translation} placement="right-start" disableFocusListener disableTouchListener disableHoverListener={props.open}>
                                                    <div>
                                                        {GetResource(subMenu.option_name)?.icon}
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>

                                            <ListItemText primary={GetResource(subMenu.option_name)?.translation} sx={{ opacity: props.open ? 1 : 0 }} />

                                        </ListItemButton>
                                    </ListItem>
                                </>
                            }
                        </>
                        <Accordion>
                            {subMenu.children?.map((item, index2) => (
                                <AccordionItem key={index2}>
                                    {({ open }: any) => (
                                        <Box sx={{ backgroundColor: 'secondary.main' }}>
                                            {(item.prefix !== "EntyC" && item.prefix !== "Doc"&& item.prefix !== "Act" && item.prefix !== "Rpt" && item.prefix !== "Generic") ? <>
                                                {(menuParents.find((parent: ParentMenu) => parent.option_menu_id === item.parent_option_id))?.open ?
                                                    <Box sx={{ border: 'none', padding: 'none' }}>
                                                        <AccordionHeader className={`${item.prefix !== "EntyC" ? "custom" : ""}`}>
                                                                <Box sx={{ border: 'none', padding: 'none' }}>
                                                                    {
                                                                        (item.prefix === "Enty") && item.resource_id &&
                                                                        <Link to={item.resource_route ?? ""} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                            <Box sx={{ border: 'none', padding: 'none' }}>
                                                                                <AccordionItemMenu title={item.option_name} resource_name={item.resource_name} open={props.open} level={item.level} />
                                                                            </Box>
                                                                        </Link>
                                                                    }
                                                                    {
                                                                        (!item.resource_id) &&
                                                                        <Box sx={{ border: 'none', padding: 'none' }}>
                                                                            <AccordionItemMenu title={item.option_name} itemName={item.option_name} open={props.open} level={item.level} />
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                        </AccordionHeader>
                                                        <AccordionBody>
                                                            <div>
                                                                {item.children.map((option, index1) => {
                                                                    return <React.Fragment key={index1}>
                                                                        {option.prefix === "Enty" &&
                                                                            <Link to={option.resource_route ?? ""} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                <AccordionItemMenu title={option.option_name} resource_name={option.resource_name} open={props.open} level={option.level} />
                                                                            </Link>
                                                                        }
                                                                    </React.Fragment>
                                                                })}
                                                            </div>
                                                        </AccordionBody>
                                                    </Box>
                                                    : <></>}
                                            </> : <></>}
                                        </Box>
                                    )}
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                })}
            </>
        }
            <SnackbarComponent
                openSnack={openSnack}
                setOpenSnack={setOpenSnack}
                severity={"error"}
                message={messageError}
            />
        </>
    );
}